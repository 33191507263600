import axios from 'axios';
import Cookies from 'js-cookie';

const API = axios.create({
    baseURL: process.env.NODE_ENV.includes('production')
        ? process.env.REACT_APP_SERVER_URL
            ? process.env.REACT_APP_SERVER_URL
            : ''
        : 'http://localhost:3030/api',
});

export const getWithdrawals = (limit, page, sortBy, order) => {
    const token = Cookies.get('refreshToken');
    if (token && limit && (page || page === 0) && sortBy && order) {
        console.log('Inside getWithdrawals');
        let backendPage = page + 1;
        return API.get(
            `/withdrawal?limit=${limit}&page=${backendPage}&${sortBy}=${order}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            }
        );
    }
};
