import { useState, useEffect } from 'react';

// mui
import {
    Box,
    Button,
    Typography,
    Modal,
    TextField,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
} from '@mui/material';

// form
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// icons
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

// api
import { CheckBox } from '@mui/icons-material';
import { getAllTypes } from 'src/api/types/getTypes';


import { createAttrType } from 'src/api/typesAttrs/createAttrType';
import { getAllCategories } from 'src/api/categories/getAllCategories';
import { getAllGroupTypes } from 'src/api/groupTypes/createGroupType';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    paddingX: 7,
    paddingTop: 1,
    paddingBottom: 4,
    borderRadius: '10px',
    maxHeight: '90vh',
    overflow: 'scroll',
    '@media (max-width: 650px)': {
        paddingX: 4,
        width: '90%',
    },
};

function AddTypeAttribute({ notify }) {

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [submittedFormData, setSubmittedFormData] = useState(null);

    const [productTypes, setProductTypes] = useState(null);

    const [selectedProductType, setSelectedProductType] = useState([]);

    const [categories, setCategories] = useState(null);

    const [selectedCategory, setSelectedCategory] = useState(null);

    const [selectedGroup, setSelectedGroup] = useState(null);

    const schema = yup.object().shape({
        name: yup.string().max(60).required(),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const submitCategoryTypeData = (data) => {
        setSubmittedFormData(data);
    };

    useEffect(() => {
        const getAllProductTypes = async () => {
            try {
                const data = await getAllTypes();
                if (data && data.data) {
                    setProductTypes(data.data.types);
                }
            } catch (error) {
                console.log(error);
            }
        };
        getAllProductTypes();
    }, []);

    // USE EFFECT WHEN EXTERNAL URLS READY, AND SEND ALL DATA
    useEffect(() => {
        if (submittedFormData) {

            const sendAddCategoryData = async () => {
                try {
                    submittedFormData.productType = selectedProductType

                    submittedFormData.category = selectedCategory

                    if(selectedGroup) {
                        submittedFormData.attributeGroup = selectedGroup
                    }

                    if(allGroupTypes?.find((group) => group._id === selectedGroup)?.groupType === 'range') {
                        submittedFormData.range = {
                            min: Number(submittedFormData.min),
                            max: Number(submittedFormData.max),
                        }
                    }

                    const data = await createAttrType({...submittedFormData});
                    if (data && data.data) {
                        //console.log('CATEGORY CREATED');
                        setSubmittedFormData(null);
                        reset();
                        if (notify) {
                            notify('add');
                        }
                        handleClose();
                    }
                } catch (error) {
                    console.log(error);
                }
            };
            sendAddCategoryData();
        }
    }, [submittedFormData]);

    // Get all categories
    useEffect(() => {
        const fetchAllCategories = async () => {
            try {
                const data = await getAllCategories()
                setCategories(data.data)
            } catch (error) {
                console.log(error)
            }
        }
        fetchAllCategories()
    }, [])

    const [allGroupTypes, setAllGroupTypes] = useState(null);

    // Get all attribute groups
    useEffect(() => {
        const fetchAllGroups = async () => {
            try {
                const data = await getAllGroupTypes()
                setAllGroupTypes(data.data.attrs)
            } catch (error) {
                console.log(error)
            }
        }
        fetchAllGroups()
    }, [])

    const handleGroupChange = (e) => {
        setSelectedGroup(e.target.value)
        // reset the min and max values
        reset({min: '', max: ''})
    }

    return (
        <Box>
            <Button
                onClick={handleOpen}
                variant='contained'
                startIcon={<AddIcon />}
            >
                Добавить атрибут
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby='add-category-modal'
                aria-describedby='add-category-modal'
            >
                <Box sx={style}>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'end',
                        }}
                    >
                        <IconButton
                            aria-label='close'
                            onClick={() => {
                                handleClose();
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            margin: '0',
                            padding: '0',
                            width: '100%',
                            marginBottom: '0.5rem',
                            textAlign: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                fontSize: '1.8rem',
                            }}
                        >
                            Добавить атрибут
                        </Typography>
                    </Box>
                    <form onSubmit={handleSubmit(submitCategoryTypeData)}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                rowGap: '1rem',
                            }}
                        >

                            <TextField
                                required
                                fullWidth
                                variant={`outlined`}
                                label='Наименование атрибута'
                                error={errors.name && true}
                                helperText={errors.name?.message}
                                {...register('name')}
                            />

                            {/* Dispaly and Select multiple From All ProductTypes */}
                            <FormControl required fullWidth>
                                <InputLabel id='category-select-label'>
                                    Выбрать категорию
                                </InputLabel>
                                <Select
                                    labelId='category-select-label'
                                    id='category-select'
                                    onChange={(e) => 
                                        (setSelectedCategory(e.target.value),
                                        console.log(e.target.value))
                                    }
                                >
                                    {categories && categories.map((category) => (
                                        <MenuItem key={category._id} value={category._id}>
                                            {category.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            {/* Dispaly and Select multiple From All ProductTypes */}
                            <FormControl
                                fullWidth
                                error={errors.type && true}
                                variant={`outlined`}
                            >
                                <InputLabel id='category-type-label'>
                                    Вид товара
                                </InputLabel>
                                <Select
                                    disabled={!selectedCategory}
                                    label='Category Types'
                                    {...register('type')}
                                    value={selectedProductType}
                                    onChange={(e) => {
                                        // Add type name to selectedProductTypes
                                            setSelectedProductType(e.target.value);
                                    }}
                                >
                                    {categories?.find((category) => category._id === selectedCategory)?.productTypes.map((type) => (
                                            <MenuItem
                                                key={type._id}
                                                value={type._id}
                                            >
                                                {type.name}
                                            </MenuItem>
                                    ))}

                                </Select>
                                <FormHelperText>
                                    {errors.type?.message}
                                </FormHelperText>
                            </FormControl>

                            {/* Dispaly and Select multiple From All Group */}
                            <FormControl fullWidth>
                                <InputLabel id='group-select-label'>
                                    Выбрать группу
                                </InputLabel>
                                <Select
                                    labelId='group-select-label'
                                    id='group-select'
                                    onChange={(e) => handleGroupChange(e)}
                                >
                                    {allGroupTypes && allGroupTypes.map((group) => (
                                        <MenuItem key={group._id} value={group._id}>
                                            {group.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                           
                            {allGroupTypes?.find((group) => group._id === selectedGroup)?.groupType === 'range' && (
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '1rem',
                                }}>
                                    <TextField
                                        required
                                        fullWidth
                                        variant={`outlined`}
                                        label='Минимальное значение'
                                        error={errors.min && true}
                                        helperText={errors.min?.message}
                                        {...register('min')}
                                    />
                                    <TextField
                                        required
                                        fullWidth
                                        variant={`outlined`}
                                        label='Максимальное значение'
                                        error={errors.max && true}
                                        helperText={errors.max?.message}
                                        {...register('max')}
                                    />
                                </Box>
                            )}

                            <Button
                                disabled={submittedFormData ? true : false}
                                size={`large`}
                                type={`submit`}
                                variant='contained'
                                startIcon={<AddIcon />}
                                sx={{ marginTop: '1rem' }}
                            >
                                Добавить
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Modal>
        </Box>
    );
}

export default AddTypeAttribute;
