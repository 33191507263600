import axios from "axios";
import Cookies from "js-cookie";

const API = axios.create({
  baseURL:
    (process.env.NODE_ENV.includes("production") &&
      process.env.REACT_APP_SERVER_URL) ||
    "http://localhost:3030/api",
});

export const deleteFeaturePrice = (id) => {
    console.log('delete', id);
  const token = Cookies.get("refreshToken");

  if (token && id) {
    return API.delete(`/feature-prices/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  }
};
