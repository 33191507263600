// component
import SvgColor from '../../../components/svg-color';

// mui icons
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import AtmIcon from '@mui/icons-material/Atm';
import BalanceIcon from '@mui/icons-material/Balance';
import CategoryIcon from '@mui/icons-material/Category';
import DiscountIcon from '@mui/icons-material/Discount';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import MergeTypeIcon from '@mui/icons-material/MergeType';
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';
import PaidIcon from '@mui/icons-material/Paid';
import ReviewsIcon from '@mui/icons-material/Reviews';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import ChatIcon from '@mui/icons-material/Chat';
import ApiIcon from '@mui/icons-material/Api';
import { LocalOffer } from '@mui/icons-material';

// ----------------------------------------------------------------------

const icon = (name) => (
    <SvgColor
        src={`/assets/icons/navbar/${name}.svg`}
        sx={{ width: 1, height: 1 }}
    />
);

const userSettings = sessionStorage.getItem('userSettings');
const userSettingsObj = JSON.parse(userSettings);


const navConfig = [
    /*
    {
        title: 'dashboard',
        path: '/dashboard/app',
        icon: icon('ic_analytics'),
    }, */
];

if (userSettingsObj?.role === 'admin') {
    navConfig.push(
        {
            title: 'Пользователи',
            path: '/dashboard/user',
            icon: icon('ic_user'),
        },
    );
}

const allowedModetator = ['admin', 'moderator'];

if (allowedModetator.includes(userSettingsObj?.role)) {
    navConfig.push(
        {
            title: 'Продукты',
            path: '/dashboard/products',
            icon: icon('ic_cart'),
        },
        {
            title: 'Отзывы',
            path: '/dashboard/reviews',
            icon: <ReviewsIcon />,
        },
        {
            title: 'Заказы',
            path: '/dashboard/orders',
            icon: <LocalShippingIcon />,
        },
        {
            title: 'Споры',
            path: '/dashboard/disputes',
            icon: <BalanceIcon />,
        },
        {
            title: 'Транзакции',
            path: '/dashboard/transactions',
            icon: <PaidIcon />,
        },
        {
            title: 'Кошельки',
            path: '/dashboard/wallets',
            icon: <AccountBalanceWalletIcon />,
        },
        {
            title: 'Снятие средств',
            path: '/dashboard/withdrawals',
            icon: <AtmIcon />,
        },
        {
            title: 'Уведомления',
            path: '/dashboard/notifications',
            icon: <NotificationsRoundedIcon />,
        },
        {
            title: 'Объявления',
            path: '/dashboard/ads',
            icon: <AdsClickIcon />,
        }, 
        {
            title: 'промокоды',
            path: '/dashboard/promocodes',
            icon: <DiscountIcon />,
        },
    );
}

const allowedMarketing = ['admin', 'marketing', 'moderator'];

if (allowedMarketing.includes(userSettingsObj?.role)) {
    navConfig.push(
        {
            title: 'Игры',
            path: '/dashboard/categories',
            icon: <CategoryIcon />,
            children: [
                {
                    title: 'Виды продуктов',
                    path: '/dashboard/categories/types',
                    icon: <MergeTypeIcon />,
                },
                {
                    title: 'Атрибуты видов',
                    path: '/dashboard/categories/typeAttrs',
                    icon: <MergeTypeIcon />,
                },
                {
                    title: 'Группы аттрибутов',
                    path: '/dashboard/categories/groups',
                    icon: <MergeTypeIcon />,
                },
                {
                    title: 'Игровые серверы',
                    path: '/dashboard/categories/servers',
                    icon: <MergeTypeIcon />,
                },
            ],
        },
        {
            title: 'ПО',
            path: '/dashboard/software',
            icon: <ApiIcon />,
        },
        {
            title: 'СЕО',
            path: '/dashboard/seo',
            icon: <TravelExploreIcon />,
        },
    );
}

const allowedSupport = ['admin', 'moderator', 'support'];

if(allowedSupport.includes(userSettingsObj?.role)){
    navConfig.push(
        {
            title: 'Чат',
            path: '/dashboard/chats',
            icon: <ChatIcon />,
        },
    )
}

if(userSettingsObj?.role === 'admin') {
    navConfig.push(
        {
            title: 'Цены',
            path: '/dashboard/feature-prices',
            icon: <LocalOffer />,
        },
    )
}



export default navConfig;
