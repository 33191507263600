import axios from 'axios';
import Cookies from 'js-cookie';

const API = axios.create({
    baseURL: process.env.NODE_ENV.includes('production')
        ? process.env.REACT_APP_SERVER_URL
            ? process.env.REACT_APP_SERVER_URL
            : ''
        : 'http://localhost:3030/api',
});

export const createProduct = (productData) => {
    const token = Cookies.get('refreshToken');
    console.log(token)
    if (token && productData) {
        console.log('Inside createProduct');
        return API.post(`/item`, {productData}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });
    }
};
