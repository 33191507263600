import React from 'react';
import { useState, useEffect } from 'react';

// mui
import {
    Box,
    Typography,
    Modal,
    IconButton,
    MenuItem,
    Button,
} from '@mui/material';

// icons
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';

// api
import { deleteAd } from 'src/api/ads/deleteAds';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    paddingX: 7,
    paddingTop: 1,
    paddingBottom: 4,
    borderRadius: '10px',
    maxHeight: '90vh',
    '@media (max-width: 650px)': {
        paddingX: 4,
        width: '90%',
    },
};

function DeleteAdModal({ notify, ad }) {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [currentAd, setCurrentAd] = useState(null);

    useEffect(() => {
        setCurrentAd(ad);
    }, [ad]);

    const handleDelete = async () => {
        try {
            const response = await deleteAd(ad._id);
            if (response && response.data) {
                console.log('Ad deleted successfully');
                if (notify) {
                    notify('delete');
                }
                handleClose();
            }
        } catch (error) {
            console.error('Error deleting ad:', error);
            if (notify) {
                notify('error');
            }
            handleClose();
        }
    };
    console.log();

    return (
        <Box>
            <MenuItem onClick={handleOpen}>
                <DeleteIcon sx={{ mr: 2 }} /> Удалить{' '}
            </MenuItem>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby='add-ad-modal'
                aria-describedby='add-ad-modal'
            >
                <Box sx={style}>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'end',
                        }}
                    >
                        <IconButton
                            aria-label='close'
                            onClick={() => {
                                handleClose();
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            margin: '0',
                            padding: '0',
                            width: '100%',
                            marginBottom: '0.5rem',
                            textAlign: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                fontSize: '1.8rem',
                                '@media (max-width: 767px)': {
                                    fontSize: '1.1rem',
                                },
                            }}
                        >
                            {currentAd && currentAd.name
                                ? `Do you really want to delete ad:`
                                : null}
                        </Typography>
                        <Typography 
                            sx={{
                                    fontWeight: 'bold',
                                    fontSize: '1.8rem',
                                    '@media (max-width: 767px)': {
                                        fontSize: '1.1rem',
                                    },
                                    color: '#9B59B6',
                                }}
                        >
                            {currentAd && currentAd.name
                                ? currentAd.name
                                : null}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: '1rem',
                        }}
                    >
                        <Button
                            type={`submit`}
                            variant='contained'
                            sx={{ width: '150px', height: '40px', backgroundColor: 'error.main' }}
                            onClick={handleDelete}
                        >
                            Delete
                        </Button>
                        <Button
                            type={`submit`}
                            variant='contained'
                            size='medium'
                            sx={{
                                width: '150px',
                                height: '40px',
                                marginLeft: '3rem',
                            }}
                            onClick={handleClose}
                        >
                            Отменить
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
}

export default DeleteAdModal;
