
import { useState, useEffect } from 'react';

// mui
import {
    Box,
    Button,
    Typography,
    Modal,
    TextField,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
    Card,
} from '@mui/material';

// form
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// icons
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import CameraAltRoundedIcon from '@mui/icons-material/CameraAltRounded';

// api
import { sendMediaToBack } from 'src/api/categories/sendMediaToBack';
import { createSoftware } from 'src/api/software/createSoftware';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    paddingX: 7,
    paddingTop: 1,
    paddingBottom: 4,
    borderRadius: '10px',
    maxHeight: '90vh',
    overflow: 'scroll',
    '@media (max-width: 650px)': {
        paddingX: 4,
        width: '90%',
    },
};

function AddSoftwareModal({ notify }) {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [media, setMedia] = useState(null);

    const [submittedFormData, setSubmittedFormData] = useState(null);


    const schema = yup.object().shape({
        name: yup.string().max(20).required(),
        icon: yup.string().max(20).required(),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(schema),
    });


    const submitSoftwareData = (data) => {
        if (media && media.file) {
            //console.log('DATA: ' + JSON.stringify(data));
            uploadMedia();
            setSubmittedFormData(data)
        }
    };

    // USE EFFECT WHEN EXTERNAL URLS READY, AND SEND ALL DATA
    useEffect(() => {
        if (
            submittedFormData &&
            media &&
            media.externalUrl
        ) {
            const sendAddSoftwareData = async () => {
                try {
                    const data = await createSoftware({
                        ...submittedFormData,
                        media: media.externalUrl,
                    });
                    if (data && data.data) {
                        //console.log('CATEGORY CREATED');
                        setSubmittedFormData(null);
                        setMedia(null);
                        reset();
                        if (notify) {
                            notify('add');
                        }
                        handleClose();
                    }
                } catch (error) {
                    console.log(error);
                }
            };
            sendAddSoftwareData();
        }
    }, [media, submittedFormData]);

    // Media change
    const handleMediaChange = (e) => {
        const picture = e.target.files[0];
        if (picture) {
            setMedia({
                file: picture,
                url: URL.createObjectURL(picture),
                externalUrl: null,
            });
        }
    };

    // Media
    const uploadMedia = async () => {
        try {
            let formData = new FormData();
            formData.append('image', media.file);
            const data = await sendMediaToBack(formData);
            if (data && data.data && data.data.media) {
                setMedia({ ...media, externalUrl: data.data.media });
            }
        } catch (error) {
            console.log(error);
        }
    };


    return (
        <Box>
            <Button
                onClick={handleOpen}
                variant='contained'
                startIcon={<AddIcon />}
            >
                Новое ПО
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby='add-software-modal'
                aria-describedby='add-software-modal'
            >
                <Box sx={style}>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'end',
                        }}
                    >
                        <IconButton
                            aria-label='close'
                            onClick={() => {
                                handleClose();
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            margin: '0',
                            padding: '0',
                            width: '100%',
                            marginBottom: '0.5rem',
                            textAlign: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                fontSize: '1.8rem',
                            }}
                        >
                            Добавить Новое ПО
                        </Typography>
                    </Box>
                    <form onSubmit={handleSubmit(submitSoftwareData)}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                rowGap: '1rem',
                            }}
                        >
                            <Box
                                sx={{
                                    width: '100%',
                                    height: '200px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    marginTop: '1rem',
                                    marginBottom: '1.5rem',
                                }}
                            >
                                <Box
                                    sx={{
                                        width: '32%',
                                        height: '100%',
                                        textAlign: 'center',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: 'bold',
                                            fontSize: '1.2rem',
                                        }}
                                    >{`Медиа*`}</Typography>
                                    <input
                                        hidden
                                        accept='image/*'
                                        type='file'
                                        onChange={handleMediaChange}
                                        onClick={(e) => (e.target.value = null)}
                                        id={`add-software-media-upload-input`}
                                    />
                                    <label
                                        htmlFor={`add-software-media-upload-input`}
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                        }}
                                    >
                                        <Card
                                            sx={{
                                                width: '100%',
                                                height: '100%',
                                                ...(media &&
                                                    media.url && {
                                                        backgroundImage: `url('${media.url}')`,
                                                        backgroundSize: 'cover',
                                                        backgroundPosition:
                                                            'center',
                                                        backgroundRepeat:
                                                            'no-repeat',
                                                    }),
                                            }}
                                        >
                                            <Button
                                                component='span'
                                                sx={{
                                                    position: 'relative',
                                                    borderRadius: '10px',
                                                    width: '100%',
                                                    height: '100%',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'end',
                                                    textTransform: 'none',
                                                    backgroundColor: '#bfbfbf',
                                                    '&:hover': {
                                                        backgroundColor:
                                                            '#a3a3a3',
                                                    },
                                                    ...(media &&
                                                        media.url && {
                                                            opacity: '0.4',
                                                        }),
                                                }}
                                            >
                                                <CameraAltRoundedIcon
                                                    sx={{
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        translate: '-50% -50%',
                                                    }}
                                                />
                                            </Button>
                                        </Card>
                                    </label>
                                </Box>
                            </Box>

                            <TextField
                                required
                                fullWidth
                                variant={`outlined`}
                                label='Наименование ПО'
                                error={errors.name && true}
                                helperText={errors.name?.message}
                                {...register('name')}
                            />

                            <TextField
                                required
                                fullWidth
                                variant={`outlined`}
                                label='Иконка ПО'
                                error={errors.icon && true}
                                helperText={errors.icon?.message}
                                {...register('icon')}
                            />

                            <Button
                                disabled={!media?.file}
                                size={`large`}
                                type={`submit`}
                                variant='contained'
                                startIcon={<AddIcon />}
                                sx={{ marginTop: '1rem' }}
                            >
                                Добавить ПО
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Modal>
        </Box>
    );
}

export default AddSoftwareModal;
