export const isoToReadableDate = (isoDate) => {
    let readableDate = null;
    if (isoDate) {
        const splitDate = isoDate.slice(0, 10).split('-');
        const readableDate = `${splitDate[2]}/${splitDate[1]}/${splitDate[0]}`;
        return readableDate;
    } else {
        return null;
    }
};
