import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
// @mui
import {
    Card,
    Table,
    Stack,
    Paper,
    Popover,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    Snackbar,
    Alert,
} from '@mui/material';
// components
import Scrollbar from '../components/scrollbar';
// sections
import {
    TransactionListHead,
} from 'src/sections/@dashboard/transactions';
import { getTransactions } from 'src/api/transactions/getTransactions';
import TableStatusCell from 'src/components/table-status-cell';
import { isoToReadableDate } from 'src/utils/isoToReadableDate';

import { nanoid } from 'nanoid';
import AddTransactionModal from 'src/components/add-transaction-modal';

// ----------------------------------------------------------------------

// IDS SHOULD BE EQUAL TO PROPERTIES IN THE OBJECTS
const TABLE_HEAD = [
    { id: 'user', label: 'Пользователь', align: 'left' },
    { id: 'status', label: 'Статус', align: 'left' },
    { id: 'createdAt', label: 'Дата', align: 'left' },
    { id: 'total', label: 'Итого', align: 'left' },
    { id: '' },
];

// ----------------------------------------------------------------------

export default function TransactionsPage() {
    const [open, setOpen] = useState(null);

    const [page, setPage] = useState(0);

    const [order, setOrder] = useState('desc');

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState('createdAt');

    const [rowsPerPage, setRowsPerPage] = useState(5);

    const [transactions, setTransactions] = useState(null);

    const [transactionsTotal, setTransactionsTotal] = useState(0);

    const [success, setSuccess] = useState(false);

    const [successMessage, setSuccessMessage] = useState(null);

    const [transactionAddedNotification, setTranasactionAddedNotification] = useState(null);

    useEffect(() => {
        setTransactions(null);
        setSelected([]);
        const getTransactionsData = async () => {
            try {
                const data = await getTransactions(
                    rowsPerPage,
                    page,
                    orderBy,
                    order
                );
                if (data && data.data && data.data.transactions) {
                    console.log('DATA GOT TRANSACTIONS');
                    setTransactions(data.data.transactions);
                }
                if (data && data.data && data.data.totalTransactions) {
                    setTransactionsTotal(data.data.totalTransactions);
                }
            } catch (error) {
                setTransactions(null);
                setSelected([]);
            }
        };
        getTransactionsData();
    }, [rowsPerPage, page, orderBy, order]);

    const handleOpenMenu = (event) => {
        setOpen(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpen(null);
    };

    const handleRequestSort = (event, property) => {
        setTransactions(null);
        setSelected([]);
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        const indeterminate =
            selected.length > 0 &&
            transactions &&
            transactions.length &&
            selected.length < transactions.length;
        if (
            event.target.checked &&
            !indeterminate &&
            transactions &&
            transactions.length > 0
        ) {
            const newSelecteds = transactions.map(
                (transaction) => transaction._id
            );
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, transactionId) => {
        const selectedIndex = selected.indexOf(transactionId);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, transactionId);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setTransactions(null);
        setSelected([]);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setTransactions(null);
        setSelected([]);
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const emptyRows =
        page > 0
            ? Math.max(0, (1 + page) * rowsPerPage - transactionsTotal)
            : 0;

    const isNotFound = false;

    // Display success message when success message is set
    useEffect(() => {
        if (successMessage) {
            handleSuccessOpen();
        }
    }, [successMessage]);

    const notifyTransaction = (message) => {
        setPage(0);
        setTranasactionAddedNotification(nanoid());
        switch (message) {
            case 'add':
                setSuccessMessage('Transaction added!');
                break;
            case 'edit':
                setSuccessMessage('Transaction edited!');
                break;
            case 'delete':
                setSuccessMessage('Transaction deleted!');
                break;
            case 'error':
                setSuccessMessage('Error occured');
                break;
            default:
                setSuccessMessage(null);
                break;
        }
    };

    const handleSuccessOpen = () => {
        setSuccess(true);
    };

    const handleSuccessClose = () => {
        setSuccess(false);
        setSuccessMessage(null);
    };

    return (
        <>
            <Helmet>
                <title> Transactions | Mentup Dashboard </title>
            </Helmet>

            <Container>
                {/* Alert */}
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    open={success}
                    autoHideDuration={6000}
                    onClose={handleSuccessClose}
                >
                    <Alert
                        variant='filled'
                        onClose={handleSuccessClose}
                        severity={
                            successMessage === 'Error occured'
                                ? 'error'
                                : 'success'
                        }
                        sx={{ width: '100%' }}
                    >
                        {successMessage && successMessage}
                    </Alert>
                </Snackbar>
                {/* Page Header */}
                <Stack
                    direction='row'
                    alignItems='center'
                    justifyContent='space-between'
                    mb={5}
                >
                    <Typography variant='h4' gutterBottom>
                        Транзакции
                    </Typography>
                    <AddTransactionModal notify={notifyTransaction} />
                </Stack>


                {/* Table */}
                <Card>
                    {/* <TransactionListToolbar /> */}
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <TransactionListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={
                                        transactions && transactions.length
                                            ? transactions.length
                                            : 0
                                    }
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                />
                                <TableBody>
                                    {transactions &&
                                        transactions.map(
                                            (transaction, index) => {
                                                const selectedTransaction =
                                                    selected.indexOf(
                                                        transaction._id
                                                    ) !== -1;

                                                return (
                                                    <TableRow
                                                        hover
                                                        key={index}
                                                        tabIndex={-1}
                                                        role='checkbox'
                                                        selected={
                                                            selectedTransaction
                                                        }
                                                    >
                                                        {/* <TableCell padding='checkbox'>
                                                            <Checkbox
                                                                checked={
                                                                    selectedTransaction
                                                                }
                                                                onChange={(
                                                                    event
                                                                ) => {
                                                                    if (
                                                                        transaction._id
                                                                    ) {
                                                                        handleClick(
                                                                            event,
                                                                            transaction._id
                                                                        );
                                                                    }
                                                                }}
                                                            />
                                                        </TableCell> */}

                                                        <TableCell align='left'>
                                                            {transaction.user &&
                                                            transaction.user
                                                                .username ? (
                                                                <Typography>
                                                                    {
                                                                        transaction
                                                                            .user
                                                                            .username
                                                                    }
                                                                </Typography>
                                                            ) : null}
                                                        </TableCell>

                                                        <TableCell align='left'>
                                                            {transaction.status && (
                                                                <TableStatusCell
                                                                    contents={
                                                                        transaction.status
                                                                    }
                                                                />
                                                            )}
                                                        </TableCell>

                                                        <TableCell align='left'>
                                                            {transaction.createdAt && (
                                                                <Typography>
                                                                    {isoToReadableDate(
                                                                        transaction.createdAt
                                                                    )}
                                                                </Typography>
                                                            )}
                                                        </TableCell>

                                                        <TableCell align='left'>
                                                            {(transaction.total ||
                                                                transaction.total ===
                                                                    0) && (
                                                                <Typography>
                                                                    {`£${transaction.total}`}
                                                                </Typography>
                                                            )}
                                                        </TableCell>

                                                        {/* <TableCell align='right'>
                                                        <IconButton
                                                            size='large'
                                                            color='inherit'
                                                            onClick={
                                                                handleOpenMenu
                                                            }
                                                        >
                                                            <Iconify
                                                                icon={
                                                                    'eva:more-vertical-fill'
                                                                }
                                                            />
                                                        </IconButton>
                                                    </TableCell> */}
                                                    </TableRow>
                                                );
                                            }
                                        )}
                                    {emptyRows > 0 && (
                                        <TableRow
                                            style={{ height: 53 * emptyRows }}
                                        >
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>

                                {isNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell
                                                align='center'
                                                colSpan={6}
                                                sx={{ py: 3 }}
                                            >
                                                <Paper
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Typography
                                                        variant='h6'
                                                        paragraph
                                                    >
                                                        Не найдено
                                                    </Typography>

                                                    <Typography variant='body2'>
                                                        Не найдено
                                                        &nbsp;
                                                        <strong>
                                                            &quot;
                                                            {'Search Value'}
                                                            &quot;
                                                        </strong>
                                                        .
                                                        <br /> Попробуйте проверить опечатки или корректируйте запрос.
                                                    </Typography>
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        labelRowsPerPage="Выводить по:"
                        rowsPerPageOptions={[5, 10, 25]}
                        component='div'
                        count={transactionsTotal && transactionsTotal}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>

            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 140,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            ></Popover>
        </>
    );
}
