/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback, useEffect } from "react";

import { fetchNotificationsSubscribe } from "src/api/notifications/fetchNotificationsSubscribe";
import { WorkerName } from "src/constants";
import { useServiceWorker } from ".";
import { useNotificationPermission } from "../notificationPermission";
import { useSelector } from "react-redux";

export const useAlertServiceWorker = () => {
  if (typeof window !== "object") {
    return null;
  }
  const currentUser = useSelector((state) => state.user);

  const { permission, requestPermission } = useNotificationPermission();
  const { getServiceWorker } = useServiceWorker();

  const getBrowserSubscription = useCallback(async (registration) => {
    const subscription = await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey:
        "BIZorvdXZ6tyshlmUBogbVlb-tG71e8l3b5EIlyOEzqHGC5rimS8wO1do_qZ-qLZ_AJmUeQhnQkYmd5WbCpGFcc",
    });
    return subscription;
  }, []);

  useEffect(() => {
    async function execute() {
      if (!currentUser) {
        return;
      }

      if (!permission || permission !== "granted") {
        await requestPermission();
        return;
      }

      const registration = await getServiceWorker(WorkerName.Alerts);
      const subscription = await getBrowserSubscription(registration);

      await fetchNotificationsSubscribe(subscription);
    }

    execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permission]);
};
