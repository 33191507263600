import React from 'react';
import { useState } from 'react';

// mui
import {
    Box,
    Button,
    Typography,
    Modal,
    TextField,
    IconButton,
} from '@mui/material';

// form
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// icons
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

// api
import { createReview } from 'src/api/reviews/createReview';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    paddingX: 7,
    paddingTop: 1,
    paddingBottom: 4,
    borderRadius: '10px',
    maxHeight: '90vh',
    overflow: 'scroll',
    '@media (max-width: 650px)': {
        width: '90%',
        paddingX: 4,
    },
};

function AddReviewModal({ notify }) {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const schema = yup.object().shape({
        user: yup
            .string('ID пользователя должен быть строкой.')
            .max(50, 'ID пользователя должен быть не более 50 символов.')
            .required('ID пользователя обязателен.'),
        aboutUser: yup
            .string('Информация о пользователе должна быть строкой.')
            .max(50, 'Информация о пользователе должна быть не более 50 символов.')
            .required('Информация о пользователе обязательна.'),
        product: yup
            .string('ID продукта должен быть строкой.')
            .max(50, 'ID продукта должен быть не более 50 символов.')
            .required('ID продукта обязателен.'),
        rating: yup.number().integer().min(1).max(5).required('Рейтинг обязателен.'),
        text: yup.string().max(300, 'Текст должен быть не более 300 символов.'),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const submitReviewData = (data) => {
        console.log(data);
        sendReviewData(data);
        reset();
    };

    const sendReviewData = async (data) => {
        if (data) {
            try {
                const response = await createReview(data);
                if (response) {
                    console.log('RESPONSE:' + response);
                    if (notify) {
                        notify('add');
                    }
                    handleClose();
                }
            } catch (error) {
                console.log(error);
                if (notify) {
                    console.log('Calling notify')
                    notify('error');
                }
                handleClose();
            }
        }
    };

    return (
        <Box>
            <Button
                onClick={handleOpen}
                variant='contained'
                startIcon={<AddIcon />}
            >
                Новый отзыв
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby='add-review-modal'
                aria-describedby='add-review-modal'
            >
                <Box sx={style}>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'end',
                        }}
                    >
                        <IconButton
                            aria-label='close'
                            onClick={() => {
                                handleClose();
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            margin: '0',
                            padding: '0',
                            width: '100%',
                            marginBottom: '0.5rem',
                            textAlign: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                fontSize: '1.8rem',
                            }}
                        >
                            Добавить новый отзыв
                        </Typography>
                    </Box>
                    <form onSubmit={handleSubmit(submitReviewData)}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                rowGap: '1rem',
                            }}
                        >
                            <TextField
                                required
                                fullWidth
                                variant={`outlined`}
                                label='ID пользователя'
                                error={errors.user && true}
                                helperText={errors.user?.message}
                                {...register('user')}
                            />

                            <TextField
                                required
                                fullWidth
                                variant={`outlined`}
                                label='Пользователю'
                                error={errors.aboutUser && true}
                                helperText={errors.aboutUser?.message}
                                {...register('aboutUser')}
                            />

                            <TextField
                                required
                                fullWidth
                                variant={`outlined`}
                                label='ID продукта'
                                error={errors.product && true}
                                helperText={errors.product?.message}
                                {...register('product')}
                            />

                            <TextField
                                required
                                fullWidth
                                variant={`outlined`}
                                label='Рейтинг'
                                error={errors.rating && true}
                                helperText={errors.rating?.message}
                                {...register('rating')}
                            />

                            <TextField
                                fullWidth
                                multiline
                                rows={4}
                                variant={`outlined`}
                                label='Текст'
                                error={errors.text && true}
                                helperText={errors.text?.message}
                                {...register('text')}
                            />

                            <Button
                                size={`large`}
                                type={`submit`}
                                variant='contained'
                                startIcon={<AddIcon />}
                                sx={{ marginTop: '1rem' }}
                            >
                                Добавить отзыв
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Modal>
        </Box>
    );
}

export default AddReviewModal;
