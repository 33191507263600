import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material';
import { nanoid } from 'nanoid';

// mui
import {
    Container,
    Stack,
    Card,
    Typography,
    Box,
    TextField,
    MenuItem,
    FormHelperText,
    Button,
    Select,
    FormControl,
    InputLabel,
    InputAdornment,
    IconButton,
    Snackbar,
    Alert,
} from '@mui/material';

// components
import UserPasswordModal from 'src/components/user-password-modal';

// form
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// icons
import VisibilityIcon from '@mui/icons-material/Visibility';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import CloseIcon from '@mui/icons-material/Close';
import CameraAltRoundedIcon from '@mui/icons-material/CameraAltRounded';

// api
import { getUser } from 'src/api/users/getUser';
import { sendMediaToBack } from 'src/api/categories/sendMediaToBack';
import { deleteMultipleImages } from 'src/api/images/deleteMultipleImages';
import { changeUserStatus, updateUser } from 'src/api/users/updateUser';

function UserEditPage() {

    const { id } = useParams();

    const theme = useTheme();

    const [userData, setUserData] = useState(null);

    const [avatar, setAvatar] = useState(null);

    const [cover, setCover] = useState(null);

    const [pictureCodesToDelete, setPictureCodesToDelete] = useState(null);

    const [submittedFormData, setSubmittedFormData] = useState(null);

    const [userEditedNotification, setUserEditedNotification] = useState(null);

    const [success, setSuccess] = useState(false);

    const [successMessage, setSuccessMessage] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        resetAll();
        const getUserData = async (id) => {
            try {
                const res = await getUser(id);
                if (res && res.data) {
                    setUserData(res.data.user);
                    if (res.data.user.avatar) {
                        setAvatar({
                            file: null,
                            url: null,
                            externalUrl: res.data.user.avatar,
                        });
                    }
                    if (res.data.user.coverImage) {
                        setCover({
                            file: null,
                            url: null,
                            externalUrl: res.data.user.coverImage,
                        });
                    }
                }
            } catch (error) {
                console.log(error)
                notifyUserEdited('error');
                navigate('/dashboard/user');
            }
        };
        if (id) {
            getUserData(id);
        }
    }, [id]);

    useEffect(() => {
        if (
            submittedFormData &&
            avatar &&
            avatar.externalUrl &&
            cover &&
            cover.externalUrl
        ) {
            const sendUpdatedUserData = async () => {
                try {
                    if (userData && userData._id) {
                        const data = await updateUser(
                            {
                                ...submittedFormData,
                                ...(avatar &&
                                    avatar.file &&
                                    avatar.externalUrl && {
                                        avatar: avatar.externalUrl,
                                    }),
                                ...(cover &&
                                    cover.file &&
                                    cover.externalUrl && {
                                        coverImage: cover.externalUrl,
                                    }),
                            },
                            userData._id
                        );

                        if (data && data.data) {
                            console.log('USER EDITED');
                            notifyUserEdited('edit');
                        }
                    }
                } catch (error) {
                    console.log(error);
                    notifyUserEdited('error');
                }
            };
            sendUpdatedUserData();
        }
    }, [submittedFormData, avatar, cover]);

    // Display success message when success message is set
    useEffect(() => {
        if (successMessage) {
            handleSuccessOpen();
        }
    }, [successMessage]);

    const schema = yup.object().shape({
        username: yup.string().min(3).max(20).required(),
        email: yup.string().email().required(),
        role: yup.string().oneOf(['admin', 'vendor', 'buyer', 'moderator', 'support', 'marketing']).required(),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const submitUserData = (data) => {
        // TO GET AVATAR FROM STATE IF PRESENT
        if (pictureCodesToDelete && pictureCodesToDelete.length > 0) {
            deletePicturesFromBackend(pictureCodesToDelete);
        } else {
            if (avatar && avatar.file && !avatar.externalUrl) {
                uploadPicture(avatar.file, 'avatar');
            }
            if (cover && cover.file && !cover.externalUrl) {
                uploadPicture(cover.file, 'cover');
            }
        }
        setSubmittedFormData(data);
    };

    const deletePicturesFromBackend = async (codes) => {
        try {
            const data = await deleteMultipleImages(codes);
            if (
                data &&
                data.data &&
                data.data.message &&
                data.data.message === 'Images deleted'
            ) {

                if (avatar && avatar.file && !avatar.externalUrl) {
                    uploadPicture(avatar.file, 'avatar');
                }
                if (cover && cover.file && !cover.externalUrl) {
                    uploadPicture(cover.file, 'cover');
                }
            }
        } catch (error) {
            console.log(error);
            notifyUserEdited('error');
        }
    };

    const uploadPicture = async (file, type) => {
        try {
            let formData = new FormData();
            formData.append('image', file);
            const data = await sendMediaToBack(formData);
            if (data && data.data && data.data.media && type) {
                if (type === 'avatar') {
                    setAvatar((prevState) => {
                        return {
                            ...prevState,
                            url: null,
                            externalUrl: data.data.media,
                        };
                    });
                } else if (type === 'cover') {
                    setCover((prevState) => {
                        return {
                            ...prevState,
                            url: null,
                            externalUrl: data.data.media,
                        };
                    });
                }
            }
        } catch (error) {
            console.log(error);
            // reset() and navigate - 1
            notifyUserEdited('error');
        }
    };

    const handleAvatarChange = (event) => {
        const picture = event.target.files[0];
        if (picture) {
            removeAvatar();
            setAvatar({
                file: picture,
                url: URL.createObjectURL(picture),
                externalUrl: null,
            });
        }
    };

    const handleCoverChange = (event) => {
        const picture = event.target.files[0];
        if (picture) {
            removeCover();
            setCover({
                file: picture,
                url: URL.createObjectURL(picture),
                externalUrl: null,
            });
        }
    };

    const removeAvatar = () => {
        if (avatar && avatar.externalUrl) {
            let tempArray = [];
            if (pictureCodesToDelete && pictureCodesToDelete.length > 0) {
                tempArray = [...pictureCodesToDelete];
            }

            const regex = /\/([a-f\d]{64})\?/; // Regular expression to match the hash
            try {
                const match = avatar.externalUrl.match(regex);

                if (match && match[1]) {
                    const extractedHash = match[1];
                    if (
                        extractedHash !==
                        'a1c0f0dad0f6736325135dc69ec6dc0984058f11aa04150231c114efc1508290'
                    ) {
                        tempArray.push(extractedHash);
                    }
                }
            } catch (error) {
                console.log(error);
                notifyUserEdited('error');
            }
            setPictureCodesToDelete([...tempArray]);
        }
    };

    const removeCover = () => {
        if (cover && cover.externalUrl) {
            let tempArray = [];
            if (pictureCodesToDelete && pictureCodesToDelete.length > 0) {
                tempArray = [...pictureCodesToDelete];
            }

            const regex = /\/([a-f\d]{64})\?/; // Regular expression to match the hash
            try {
                const match = cover.externalUrl.match(regex);

                if (match && match[1]) {
                    const extractedHash = match[1];
                    if (
                        extractedHash !==
                        'a1c0f0dad0f6736325135dc69ec6dc0984058f11aa04150231c114efc1508290'
                    ) {
                        tempArray.push(extractedHash);
                    }
                }
            } catch (error) {
                console.log(error);
                notifyUserEdited('error');
            }
            setPictureCodesToDelete([...tempArray]);
        }
    };

    const notifyUserEdited = (message) => {
        setUserEditedNotification(nanoid());
        switch (message) {
            case 'add':
                setSuccessMessage('Пользователь добавлен!');
                break;
            case 'edit':
                setSuccessMessage('Пользователь отредактирован!');
                break;
            case 'delete':
                setSuccessMessage('Пользователь удалён!');
                break;
            case 'error':
                setSuccessMessage('Произошла ошибка');
                break;
            default:
                setSuccessMessage(null);
                break;
        }
    };

    const handleSuccessOpen = () => {
        setSuccess(true);
    };

    const handleSuccessClose = () => {
        setSuccess(false);
        setSuccessMessage(null);
    };

    const resetAll = () => {
        setAvatar(null);
        setCover(null);
        setUserData(null);
        setPictureCodesToDelete(null);
        setSubmittedFormData(null);
    };

    const switchUserStatus = async (e) => {
        try {
            if(e.target?.value) {
                // set userData.settings.status to e.target.value
                setUserData((prevState) => ({
                    ...prevState,
                    settings: {
                        ...prevState.settings,
                        status: e.target.value,
                    },
                }));
                await changeUserStatus(
                    userData._id,
                    {
                        status: e.target.value,
                    }
                )
                .then((res) => {
                    console.log(res)
                })
                .catch((error) => {
                    console.log(error);
                })
            }
        
        } catch (error) {
            console.log(error);
            //notifyUserEdited('error');
        }
    }


    return (
        <>
            <Helmet>
                <title> User Edit | Mentup Dashboard </title>
            </Helmet>
            {userData ? (
                <Container>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        open={success}
                        autoHideDuration={6000}
                        onClose={handleSuccessClose}
                    >
                        <Alert
                            variant='filled'
                            onClose={handleSuccessClose}
                            severity={
                                successMessage === 'Error occured'
                                    ? 'Ошибка'
                                    : 'Успешно'
                            }
                            sx={{ width: '100%' }}
                        >
                            {successMessage && successMessage}
                        </Alert>
                    </Snackbar>
                    <Stack
                        direction='row'
                        alignItems='center'
                        justifyContent='left'
                        mb={2}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'start',
                                columnGap: '0.5rem',
                            }}
                        >
                            <IconButton
                                onClick={() => {
                                    navigate(-1);
                                }}
                            >
                                <ArrowBackIosNewRoundedIcon />
                            </IconButton>
                            <Typography variant='h4'>
                                {userData && userData.username
                                    ? `Пользователь: ${userData.username}`
                                    : 'User Edit'}
                            </Typography>
                        </Box>
                    </Stack>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <form
                            onSubmit={handleSubmit(submitUserData)}
                            style={{ width: '100%' }}
                        >
                            <Box
                                sx={{
                                    width: '100%',
                                    height: 'fit-content',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'start',
                                    alignItems: 'start',
                                    gap: '1rem',
                                }}
                            >
                                <Box
                                    sx={{
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'start',
                                        justifyContent: 'space-between',
                                        '@media (max-width: 500px)': {
                                            flexDirection: 'column',
                                        },
                                    }}
                                >
                                    {/* <Box sx={{ height: '80px', width: '80px' }}>
                                    <input
                                        hidden
                                        accept='image/*'
                                        type='file'
                                        onChange={handleAvatarUpload}
                                        onClick={(e) => (e.target.value = null)}
                                        id={`user-image-upload-input`}
                                    />
                                    <label htmlFor={`user-image-upload-input`}>
                                        <Button
                                            component='span'
                                            sx={{
                                                position: 'relative',
                                                width: '100%',
                                                height: '100%',
                                                borderRadius: '50%',
                                            }}
                                        >
                                            {avatar && avatar.url ? (
                                                <Box
                                                    sx={{
                                                        width: '100%',
                                                        height: '100%',
                                                        backgroundImage: `url("${avatar.url}")`,
                                                        backgroundSize: 'cover',
                                                        backgroundPosition:
                                                            'center',
                                                        backroundRepeat:
                                                            'no-repeat',
                                                        borderRadius: '50%',
                                                    }}
                                                ></Box>
                                            ) : (
                                                <AccountCircleIcon
                                                    sx={{
                                                        fontSize: '5rem',
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        translate: '-50% -50%',
                                                    }}
                                                />
                                            )}
                                        </Button>
                                    </label>
                                </Box> */}
                                    <Box
                                        sx={{ height: '100px', width: '100px' }}
                                    >
                                        <input
                                            hidden
                                            accept='image/*'
                                            type='file'
                                            onChange={handleAvatarChange}
                                            onClick={(e) =>
                                                (e.target.value = null)
                                            }
                                            id={`edit-user-image-upload-input`}
                                        />
                                        <label
                                            htmlFor={`edit-user-image-upload-input`}
                                        >
                                            <Box
                                                sx={{
                                                    position: 'relative',
                                                    width: '100%',
                                                    height: '100%',
                                                }}
                                            >
                                                <Button
                                                    component='span'
                                                    sx={{
                                                        position: 'relative',
                                                        width: '80px',
                                                        height: '80px',
                                                        borderRadius: '50%',
                                                        ...(avatar &&
                                                            (avatar.url ||
                                                                avatar.externalUrl) && {
                                                                opacity: '0.6',
                                                            }),
                                                    }}
                                                >
                                                    {avatar &&
                                                    (avatar.url ||
                                                        avatar.externalUrl) ? (
                                                        <>
                                                            <Box
                                                                sx={{
                                                                    width: '100%',
                                                                    height: '100%',
                                                                    ...(avatar.url && {
                                                                        backgroundImage: `url("${avatar.url}")`,
                                                                    }),
                                                                    ...(avatar.externalUrl && {
                                                                        backgroundImage: `url("${avatar.externalUrl}")`,
                                                                    }),
                                                                    backgroundSize:
                                                                        'cover',
                                                                    backgroundPosition:
                                                                        'center',
                                                                    backroundRepeat:
                                                                        'no-repeat',
                                                                    borderRadius:
                                                                        '50%',
                                                                }}
                                                            ></Box>
                                                            <CameraAltRoundedIcon
                                                                sx={{
                                                                    position:
                                                                        'absolute',
                                                                    top: '50%',
                                                                    left: '50%',
                                                                    translate:
                                                                        '-50% -50%',
                                                                }}
                                                            />
                                                        </>
                                                    ) : (
                                                        <AccountCircleIcon
                                                            sx={{
                                                                fontSize:
                                                                    '5rem',
                                                                position:
                                                                    'absolute',
                                                                top: '50%',
                                                                left: '50%',
                                                                translate:
                                                                    '-50% -50%',
                                                            }}
                                                        />
                                                    )}
                                                </Button>
                                            </Box>
                                        </label>
                                    </Box>
                                    <Box
                                        sx={{
                                            boxSize: 'border-box',
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'start',
                                            justifyContent: 'space-between',
                                            paddingLeft: '2rem',
                                            '@media (max-width: 500px)': {
                                                marginTop: '1rem',
                                                padding: '0',
                                            },
                                        }}
                                    >
                                        <Box sx={{ textAlign: 'center' }}>
                                            <Typography variant={`h4`}>
                                                Товары
                                            </Typography>
                                            <Typography
                                                sx={{ fontSize: '1.5rem' }}
                                            >
                                                {userData &&
                                                userData.products &&
                                                userData.products.length >= 0
                                                    ? userData.products.length
                                                    : null}
                                            </Typography>
                                        </Box>
                                        <Box sx={{ textAlign: 'center' }}>
                                            <Typography variant={`h4`}>
                                                Покупки
                                            </Typography>
                                            <Typography
                                                sx={{ fontSize: '1.5rem' }}
                                            >
                                                {userData &&
                                                userData.purchases &&
                                                userData.purchases.length >= 0
                                                    ? userData.purchases.length
                                                    : null}
                                            </Typography>
                                        </Box>
                                        <Box sx={{ textAlign: 'center' }}>
                                            <Typography variant={`h4`}>
                                                Продажи
                                            </Typography>
                                            <Typography
                                                sx={{ fontSize: '1.5rem' }}
                                            >
                                                {userData &&
                                                userData.itemsSold &&
                                                userData.itemsSold.length >= 0
                                                    ? userData.itemsSold.length
                                                    : null}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box sx={{
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'space-between',
                                }}>
                                    <Box
                                        sx={{
                                            boxSize: 'border-box',
                                            width: '50%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'start',
                                            alignItems: 'start',
                                            gap: '1rem',
                                            '@media (max-width: 900px)': {
                                                padding: '2rem',
                                                width: '100%',
                                            },
                                            '@media (max-width: 650px)': {
                                                paddingX: '0',
                                            },
                                        }}
                                    >
                                    <Box
                                        sx={{
                                            width: '100%',
                                            height: '100px',
                                            textAlign: 'left',
                                            marginBottom: '1.5rem',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontWeight: 'bold',
                                                fontSize: '1rem',
                                            }}
                                        >{`Cover Image`}</Typography>
                                        <input
                                            hidden
                                            accept='image/*'
                                            type='file'
                                            onChange={handleCoverChange}
                                            onClick={(e) =>
                                                (e.target.value = null)
                                            }
                                            id={`add-user-cover-upload-input`}
                                        />
                                        <label
                                            htmlFor={`add-user-cover-upload-input`}
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                            }}
                                        >
                                            <Card
                                                sx={{
                                                    width: '100%',
                                                    height: '100%',
                                                    position: 'relative',
                                                    ...(cover &&
                                                        cover.url && {
                                                            backgroundImage: `url('${cover.url}')`,
                                                        }),
                                                    ...(cover &&
                                                        cover.externalUrl && {
                                                            backgroundImage: `url('${cover.externalUrl}')`,
                                                        }),
                                                    backgroundSize: 'cover',
                                                    backgroundPosition:
                                                        'center',
                                                    backgroundRepeat:
                                                        'no-repeat',
                                                }}
                                            >
                                                <Button
                                                    component='span'
                                                    sx={{
                                                        position: 'relative',
                                                        borderRadius: '10px',
                                                        width: '100%',
                                                        height: '100%',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                        justifyContent: 'end',
                                                        textTransform: 'none',
                                                        backgroundColor:
                                                            '#bfbfbf',
                                                        '&:hover': {
                                                            backgroundColor:
                                                                '#a3a3a3',
                                                        },
                                                        ...(cover &&
                                                            (cover.url ||
                                                                cover.externalUrl) && {
                                                                opacity: '0.6',
                                                            }),
                                                    }}
                                                >
                                                    <CameraAltRoundedIcon
                                                        sx={{
                                                            position:
                                                                'absolute',
                                                            top: '50%',
                                                            left: '50%',
                                                            translate:
                                                                '-50% -50%',
                                                        }}
                                                    />
                                                </Button>
                                            </Card>
                                        </label>
                                    </Box>
                                    <TextField
                                        required
                                        fullWidth
                                        variant={`outlined`}
                                        label='Имя пользователя'
                                        error={errors.username && true}
                                        helperText={errors.username?.message}
                                        {...register('username')}
                                        value={
                                            userData && userData.username
                                                ? userData.username
                                                : ''
                                        }
                                        onChange={(e) => {
                                            setUserData((prevState) => ({
                                                ...prevState,
                                                username: e.target.value,
                                            }));
                                        }}
                                    />
                                    <TextField
                                        required
                                        fullWidth
                                        variant={`outlined`}
                                        label='Email'
                                        error={errors.email && true}
                                        helperText={errors.email?.message}
                                        {...register('email')}
                                        value={
                                            userData && userData.email
                                                ? userData.email
                                                : ''
                                        }
                                        onChange={(e) => {
                                            setUserData((prevState) => ({
                                                ...prevState,
                                                email: e.target.value,
                                            }));
                                        }}
                                    />
                                    <FormControl fullWidth>
                                        <InputLabel id='add-user-input-role-label'>
                                            Role
                                        </InputLabel>
                                        <Select
                                            required
                                            labelId='add-user-input-role-label'
                                            id='add-user-role-input'
                                            label='Role'
                                            defaultValue={'buyer'}
                                            error={errors.role && true}
                                            {...register('role')}
                                            value={
                                                userData?.settings?.role
                                                    ? userData.settings.role
                                                    : ''
                                            }
                                            onChange={(e) => {
                                                setUserData((prevState) => ({
                                                    ...prevState,
                                                    role: e.target.value,
                                                }));
                                            }}
                                        >
                                            <MenuItem value={`buyer`}>
                                                Покупатель
                                            </MenuItem>
                                            <MenuItem value={`vendor`}>
                                                Вендор
                                            </MenuItem>
                                            <MenuItem value={`admin`}>
                                                Админ
                                            </MenuItem>
                                            <MenuItem value={`support`}>
                                                Поддержка
                                            </MenuItem>
                                            <MenuItem value={`moderator`}>
                                                Модератор
                                            </MenuItem>
                                            <MenuItem value={`marketing`}>
                                                Маркетинг
                                            </MenuItem>
                                        </Select>
                                        {errors.role?.message ? (
                                            <FormHelperText
                                                sx={{
                                                    color: `${theme.palette.error.main}`,
                                                }}
                                            >
                                                {errors.role?.message}
                                            </FormHelperText>
                                        ) : null}
                                    </FormControl>
                                    <Box
                                        sx={{
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'end',
                                            alignItems: 'start',
                                        }}
                                    >
                                        <Button
                                            size={`large`}
                                            type={`submit`}
                                            variant='contained'
                                            sx={{
                                                marginTop: '1rem',
                                                '@media (max-width: 900px)': {
                                                    width: '100%',
                                                },
                                            }}
                                        >
                                            Сохранить
                                        </Button>
                                        
                                    </Box>
                                    </Box>
                                    <Box sx={{
                                        width: '45%',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        flexWrap: 'wrap',
                                        rowGap: '1.5rem',
                                        gap: '2rem',
                                        justifyContent: 'start',
                                        alignItems: 'start',
                                        marginTop: '1rem',
                                    }}>
                                        <Box>
                                            <Typography variant={`h6`}>ИП Аддресс</Typography>
                                            <Typography sx={{ fontSize: '1.1rem' }}>
                                                {userData?.settings?.ip
                                                    ? userData.settings.ip
                                                    : null}
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography variant={`h6`}>% Площядки</Typography>
                                            <Typography sx={{ fontSize: '1.1rem' }}>
                                                {userData?.settings?.marketRate
                                                    ? userData.settings.marketRate + '%'
                                                    : null}
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography variant={`h6`}>Статус</Typography>
                                            <Select
                                                required
                                                labelId='add-user-input-status-label'
                                                id='add-user-status-input'
                                                label='Status'
                                                defaultValue={'active'}
                                                error={errors.status && true}
                                                {...register('status')}
                                                value={
                                                    userData?.settings?.status
                                                        ? userData.settings.status
                                                        : ''
                                                }
                                                onChange={(e) => switchUserStatus(e)}
                                            >
                                                <MenuItem value={`active`}>
                                                    Активный
                                                </MenuItem>
                                                <MenuItem value={`inactive`}>
                                                    Неактивный
                                                </MenuItem>
                                                <MenuItem value={`banned`}>
                                                    Забанен
                                                </MenuItem>
                                            </Select>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </form>
                        {/* <Box
                        sx={{
                            width: '50%',
                            boxSizing: 'border-box',
                            paddingLeft: '2rem',
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '1.5rem',
                        }}
                    >
                        <Box>
                            <Typography variant={`h4`}>Listings</Typography>
                            <Typography sx={{ fontSize: '1.5rem' }}>
                                100
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant={`h4`}>Orders</Typography>
                            <Typography sx={{ fontSize: '1.5rem' }}>
                                100
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant={`h4`}>Sales</Typography>
                            <Typography sx={{ fontSize: '1.5rem' }}>
                                100
                            </Typography>
                        </Box>
                    </Box> */}
                    </Box>
                </Container>
            ) : null}
        </>
    );
}

export default UserEditPage;
