import React from 'react';

// mui
import { Box, Divider, Typography } from '@mui/material';

function Row({ label, value }) {
    return (
        <>
            <Box
                sx={{
                    width: '100%',
                    boxSizing: 'border-box',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    padding: '0.5rem 0',
                    overflow: 'scroll',
                    ...(label &&
                        label === 'Notes' && {
                            flexDirection: 'column',
                            alignItems: 'center',
                        }),
                }}
            >
                <Typography
                    sx={{
                        fontSize: '1.2rem',
                        fontWeight: 'bold',
                    }}
                >
                    {label && label !== 'Notes'
                        ? `${label}:`
                        : label && label === 'Notes'
                        ? `${label}`
                        : ''}
                </Typography>
                <Typography
                    sx={{
                        fontSize: '1.2rem',
                    }}
                >
                    {value ? `${value}` : ''}
                </Typography>
            </Box>
            <Divider />
        </>
    );
}

export default Row;
