import React from 'react';
import { useState } from 'react';
import { useTheme } from '@mui/material';

// mui
import {
    Box,
    MenuItem,
    Modal,
    IconButton,
    Typography,
    Button,
} from '@mui/material';

// Icons
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';

// Modal Style
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    paddingX: 7,
    paddingTop: 1,
    paddingBottom: 4,
    borderRadius: '10px',
    maxHeight: '90vh',
    overflow: 'scroll',
    '@media (max-width: 650px)': {
        width: '90%',
        paddingX: 3,
    },
};

function DeletePromocodeModal() {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const theme = useTheme();

    return (
        <Box>
            <MenuItem sx={{ color: 'error.main' }} onClick={handleOpen}>
                <DeleteIcon sx={{ mr: 2 }} />
                Delete
            </MenuItem>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby='modal-delete-promocode-confirmation'
                aria-describedby='modal-delete-promocode-confirmation'
            >
                <Box sx={style}>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'end',
                        }}
                    >
                        <IconButton
                            aria-label='close'
                            onClick={() => {
                                handleClose();
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            margin: '0',
                            padding: '0',
                            width: '100%',
                            marginBottom: '0.5rem',
                            textAlign: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                fontSize: '1.8rem',
                            }}
                        >
                            Delete selected promocode ?
                        </Typography>
                        <Box
                            sx={{
                                boxSizing: 'border-box',
                                marginTop: '1rem',
                                padding: '1rem 2rem',
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                '@media (max-width: 650px)': {
                                    paddingX: '0',
                                },
                                '@media (max-width: 400px)': {
                                    rowGap: '2rem',
                                    flexDirection: 'column',
                                },
                            }}
                        >
                            <Button variant='contained' size='large'>
                                CANCEL
                            </Button>
                            <Button
                                variant='contained'
                                size='large'
                                sx={{
                                    backgroundColor: `${theme.palette.error.main}`,
                                }}
                            >
                                DELETE
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
}

export default DeletePromocodeModal;
