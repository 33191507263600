import axios from 'axios';
import Cookies from 'js-cookie';

const API = axios.create({
    baseURL: process.env.NODE_ENV.includes('production')
        ? process.env.REACT_APP_SERVER_URL
            ? process.env.REACT_APP_SERVER_URL
            : ''
        : 'http://localhost:3030/api',
});

const header = () => {
    const token = Cookies.get('refreshToken');
    if (token) {
        return {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        };
    }
};

export const getAllDisputes = ({limit, page, sortBy, order}) => {
        return API.get(
            `/dispute/admin/getAll?limit=${limit}&page=${page}&${sortBy}=${order}`,
            header()
        )
};
