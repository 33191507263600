import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import Cookies from "js-cookie";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import OtpInput from "react-otp-input";

import { Box, Button, Stack, TextField } from "@mui/material";
import { setUser } from "src/store/slices/userSlice";

export default function LoginForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [step, setStep] = useState(0);

  const schema = yup.object().shape({
    email: yup.string().email().required(),
  });

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmitEmail = async (data) => {
    setEmail(data.email);
    setIsLoading(true);

    try {
      const url = process.env.NODE_ENV.includes("production")
        ? `${process.env.REACT_APP_SERVER_URL}/auth/login/email`
        : "http://localhost:3030/api/auth/login/email";

      await axios.post(
        url,
        { ...data, type: "admin" },
        { credentials: "same-origin" }
      );

      setStep(1);
    } catch (error) {
      console.log(error);
      setError("email", { message: "Invalid email" });
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmitOtp = async () => {
    setIsLoading(true);

    try {
      const url = process.env.NODE_ENV.includes("production")
        ? `${process.env.REACT_APP_SERVER_URL}/auth/login/otp`
        : "http://localhost:3030/api/auth/login/otp";

      const response = await axios.post(
        url,
        { email, otp, type: "admin" },
        { credentials: "same-origin" }
      );

      const { user, userSettings } = response.data;
      if (user?._id && user?.avatar && user?.username && user?.email) {
        sessionStorage.setItem(
          "user",
          JSON.stringify({
            _id: user._id,
            avatar: user.avatar,
            username: user.username,
            email: user.email,
          })
        );
        dispatch(
          setUser({
            _id: user._id,
            avatar: user.avatar,
            username: user.username,
            email: user.email,
          })
        );
      }

      if (userSettings?.refreshToken) {
        const token = userSettings.refreshToken;
        Cookies.set("refreshToken", token, {
          expires: 1,
        });
        sessionStorage.setItem("userSettings", JSON.stringify(userSettings));
      }

      if (userSettings?.role === "admin") {
        navigate(`/dashboard/user`);
      } else if (userSettings?.role === "moderator") {
        navigate(`/dashboard/categories`);
      }
      else if (userSettings?.role === "marketing") {
        navigate(`/dashboard/categories`);
      } else {
        navigate(`/dashboard/categories`);
      }
    } catch (error) {
      console.log(error);
      setError("email", { message: "Invalid email" });
    } finally {
      setIsLoading(false);
    }
  };

  return step === 0 ? (
    <Box component="form" onSubmit={handleSubmit(onSubmitEmail)} sx={{ mt: 1 }}>
      <Stack spacing={3}>
        <TextField
          margin="normal"
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          error={!!errors.email}
          helperText={errors.email?.message}
          {...register("email")}
        />
      </Stack>

      <Button
        type="submit"
        fullWidth
        size="large"
        variant="contained"
        color="primary"
        sx={{ mt: 3, mb: 2 }}
        disabled={isLoading}
      >
        {isLoading ? "Loading..." : "Next"}
      </Button>
    </Box>
  ) : step === 1 ? (
    <Box
      sx={{
        mt: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Stack spacing={3}>
        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={6}
          inputStyle={{
            background: "#1f1d220d",
            border: "1px solid #1f1d2280",
            borderRadius: "3px",
            color: "#1f1d22",
            fontSize: "24px",
            height: "52px",
            padding: "8px 12px",
            textAlign: "center",
            transition: "all 200ms cubic-bezier(0, 0, 0.2, 1)",
            width: "42px",
          }}
          containerStyle={{
            width: "100%",
          }}
          shouldAutoFocus={true}
          skipDefaultStyles
          renderSeparator={<div style={{ width: "8px" }} />}
          renderInput={(props) => <input {...props} />}
        />
      </Stack>

      <Button
        fullWidth
        size="large"
        variant="contained"
        color="primary"
        sx={{ mt: 3, mb: 2 }}
        disabled={isLoading}
        onClick={onSubmitOtp}
      >
        {isLoading ? "Loading..." : "Sign In"}
      </Button>
    </Box>
  ) : null;
}
