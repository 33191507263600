import axios from 'axios';
import Cookies from 'js-cookie';

const API = axios.create({
    baseURL: process.env.NODE_ENV.includes('production')
        ? process.env.REACT_APP_SERVER_URL
            ? process.env.REACT_APP_SERVER_URL
            : ''
        : 'http://localhost:3030/api',
});

export const sendMediaToBack = (formData, type) => {
    const token = Cookies.get('refreshToken');
    if (token && formData) {
        if(type) {
            formData.append('type', type);
        }
        return API.post(`/image/uploadAdmin`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`,
            },
        });
    }
};