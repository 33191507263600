export const regions = [
    { name: 'Глобальный', code: '' },
    { name: 'Европа', code: '' },
    { name: 'Северная Америка', code: '' },
    { name: 'Южная Америка', code: '' },
    { name: 'США', code: '' },
    { name: 'Афганистан', code: 'AF' },
    { name: 'Аландские острова', code: 'AX' },
    { name: 'Албания', code: 'AL' },
    { name: 'Алжир', code: 'DZ' },
    { name: 'Американское Самоа', code: 'AS' },
    { name: 'Андорра', code: 'AD' },
    { name: 'Ангола', code: 'AO' },
    { name: 'Ангилья', code: 'AI' },
    { name: 'Антарктика', code: 'AQ' },
    { name: 'Антигуа и Барбуда', code: 'AG' },
    { name: 'Аргентина', code: 'AR' },
    { name: 'Армения', code: 'AM' },
    { name: 'Аруба', code: 'AW' },
    { name: 'Австралия', code: 'AU' },
    { name: 'Австрия', code: 'AT' },
    { name: 'Азербайджан', code: 'AZ' },
    { name: 'Багамские Острова', code: 'BS' },
    { name: 'Бахрейн', code: 'BH' },
    { name: 'Бангладеш', code: 'BD' },
    { name: 'Барбадос', code: 'BB' },
    { name: 'Беларусь', code: 'BY' },
    { name: 'Бельгия', code: 'BE' },
    { name: 'Белиз', code: 'BZ' },
    { name: 'Бенин', code: 'BJ' },
    { name: 'Бермудские острова', code: 'BM' },
    { name: 'Бутан', code: 'BT' },
    { name: 'Боливия', code: 'BO' },
    { name: 'Босния и Герцеговина', code: 'BA' },
    { name: 'Ботсвана', code: 'BW' },
    { name: 'Остров Буве', code: 'BV' },
    { name: 'Бразилия', code: 'BR' },
    { name: 'Британская территория в Индийском океане', code: 'IO' },
    { name: 'Бруней-Даруссалам', code: 'BN' },
    { name: 'Болгария', code: 'BG' },
    { name: 'Буркина-Фасо', code: 'BF' },
    { name: 'Бурунди', code: 'BI' },
    { name: 'Камбоджа', code: 'KH' },
    { name: 'Камерун', code: 'CM' },
    { name: 'Канада', code: 'CA' },
    { name: 'Кабо-Верде', code: 'CV' },
    { name: 'Каймановы острова', code: 'KY' },
    { name: 'Центрально-Африканская Республика', code: 'CF' },
    { name: 'Чад', code: 'TD' },
    { name: 'Чили', code: 'CL' },
    { name: 'Китай', code: 'CN' },
    { name: 'Остров Рождества', code: 'CX' },
    { name: 'Кокосовые (Килинг) острова', code: 'CC' },
    { name: 'Колумбия', code: 'CO' },
    { name: 'Коморские Острова', code: 'KM' },
    { name: 'Конго', code: 'CG' },
    { name: 'Демократическая Республика Конго', code: 'CD' },
    { name: 'Острова Кука', code: 'CK' },
    { name: 'Коста-Рика', code: 'CR' },
    { name: `Кот-д'Ивуар`, code: 'CI' },
    { name: 'Хорватия', code: 'HR' },
    { name: 'Куба', code: 'CU' },
    { name: 'Кипр', code: 'CY' },
    { name: 'Чешская Республика', code: 'CZ' },
    { name: 'Дания', code: 'DK' },
    { name: 'Джибути', code: 'DJ' },
    { name: 'Доминика', code: 'DM' },
    { name: 'Доминиканская Республика', code: 'DO' },
    { name: 'Эквадор', code: 'EC' },
    { name: 'Египет', code: 'EG' },
    { name: 'Сальвадор', code: 'SV' },
    { name: 'Экваториальная Гвинея', code: 'GQ' },
    { name: 'Эритрея', code: 'ER' },
    { name: 'Эстония', code: 'EE' },
    { name: 'Эфиопия', code: 'ET' },
    { name: 'Фолклендские (Мальвинские) острова', code: 'FK' },
    { name: 'Фарерские острова', code: 'FO' },
    { name: 'Фиджи', code: 'FJ' },
    { name: 'Финляндия', code: 'FI' },
    { name: 'Франция', code: 'FR' },
    { name: 'Французская Гвиана', code: 'GF' },
    { name: 'Французская Полинезия', code: 'PF' },
    { name: 'Французские Южные территории', code: 'TF' },
    { name: 'Габон', code: 'GA' },
    { name: 'Гамбия', code: 'GM' },
    { name: 'Грузия', code: 'GE' },
    { name: 'Германия', code: 'DE' },
    { name: 'Гана', code: 'GH' },
    { name: 'Гибралтар', code: 'GI' },
    { name: 'Греция', code: 'GR' },
    { name: 'Гренландия', code: 'GL' },
    { name: 'Гренада', code: 'GD' },
    { name: 'Гваделупа', code: 'GP' },
    { name: 'Гуам', code: 'GU' },
    { name: 'Гватемала', code: 'GT' },
    { name: 'Гернси', code: 'GG' },
    { name: 'Гвинея', code: 'GN' },
    { name: 'Гвинея-Бисау', code: 'GW' },
    { name: 'Гайана', code: 'GY' },
    { name: 'Гаити', code: 'HT' },
    { name: 'Остров Херд и Макдональд', code: 'HM' },
];
