
import { useState, useEffect } from 'react';

// mui
import {
    Box,
    Button,
    Typography,
    Modal,
    TextField,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
    Card,
} from '@mui/material';

// form
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// icons
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

// api
import { createServer } from 'src/api/servers/createServer';
import { getCategories } from 'src/api/categories/getCategories';
import { getAllCategories } from 'src/api/categories/getAllCategories';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    paddingX: 7,
    paddingTop: 1,
    paddingBottom: 4,
    borderRadius: '10px',
    maxHeight: '90vh',
    overflow: 'scroll',
    '@media (max-width: 650px)': {
        paddingX: 4,
        width: '90%',
    },
};

function AddServerModal({ notify }) {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [submittedFormData, setSubmittedFormData] = useState(null);

    const [categories, setCategories] = useState(null)
    const [selectedCategory, setSelectedCategory] = useState(null)

    const schema = yup.object().shape({
        name: yup.string().max(20).required(),
        slug: yup.string().max(20),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(schema),
    });


    const submitServerData = (data) => {
        setSubmittedFormData(data)
    };

    // USE EFFECT WHEN EXTERNAL URLS READY, AND SEND ALL DATA
    useEffect(() => {
        if (submittedFormData) {
            const sendAddServerData = async () => {
                try {
                    const data = await createServer({
                        ...submittedFormData,
                        category: selectedCategory
                    });
                    if (data && data.data) {
                        //console.log('CATEGORY CREATED');
                        setSubmittedFormData(null);
                        reset();
                        handleClose();
                    }
                } catch (error) {
                    console.log(error);
                }
            };
            sendAddServerData();
        }
    }, [submittedFormData]);

    // Get all categories
    useEffect(() => {
        const fetchAllCategories = async () => {
            try {
                const data = await getAllCategories()
                setCategories(data.data)
            } catch (error) {
                console.log(error)
            }
        }
        fetchAllCategories()
    }, [])

    return (
        <Box>
            <Button
                onClick={handleOpen}
                variant='contained'
                startIcon={<AddIcon />}
            >
                Новый сервер
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby='add-server-modal'
                aria-describedby='add-server-modal'
            >
                <Box sx={style}>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'end',
                        }}
                    >
                        <IconButton
                            aria-label='close'
                            onClick={() => {
                                handleClose();
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            margin: '0',
                            padding: '0',
                            width: '100%',
                            marginBottom: '0.5rem',
                            textAlign: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                fontSize: '1.8rem',
                            }}
                        >
                            Добавить новый сервер
                        </Typography>
                    </Box>
                    <form onSubmit={handleSubmit(submitServerData)}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                rowGap: '1rem',
                            }}
                        >

                            <TextField
                                required
                                fullWidth
                                variant={`outlined`}
                                label='Название сервера'
                                error={errors.name && true}
                                helperText={errors.name?.message}
                                {...register('name')}
                            />

                            {/* Select category from all available */}
                                
                            <FormControl required fullWidth>
                                <InputLabel id='category-select-label'>
                                    Выбрать категорию
                                </InputLabel>
                                <Select
                                    labelId='category-select-label'
                                    id='category-select'
                                    onChange={(e) => 
                                        (setSelectedCategory(e.target.value),
                                        console.log(e.target.value))
                                    }
                                >
                                    {categories && categories.map((category) => (
                                        <MenuItem key={category._id} value={category._id}>
                                            {category.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <TextField
                                required
                                fullWidth
                                variant={`outlined`}
                                label='slug сервера'
                                error={errors.slug && true}
                                helperText={errors.slug?.message}
                                {...register('slug')}
                            />

                            <Button
                                size={`large`}
                                type={`submit`}
                                variant='contained'
                                startIcon={<AddIcon />}
                                sx={{ marginTop: '1rem' }}
                            >
                                Добавить сервер
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Modal>
        </Box>
    );
}

export default AddServerModal;
