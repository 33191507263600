import { Helmet } from 'react-helmet-async';
import { filter, setWith } from 'lodash';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { nanoid } from 'nanoid';

// @mui
import {
    Card,
    Table,
    Stack,
    Paper,
    Avatar,
    Popover,
    Checkbox,
    TableRow,
    MenuItem,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableContainer,
    TablePagination,
    Snackbar,
    Alert,
} from '@mui/material';

// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import DeleteUserModal from 'src/components/delete-user-modal';
import WithdrawalModal from 'src/components/withdrawal-modal/WithdrawalModal';

// sections
import {
    WithdrawalListHead,
    WithdrawalListToolbar,
} from '../sections/@dashboard/withdrawals';

// api
import { getWithdrawals } from 'src/api/withdrawals/getWithdrawals';
import { isoToReadableDate } from 'src/utils/isoToReadableDate';
import TableStatusCell from 'src/components/table-status-cell/TableStatusCell';

// ----------------------------------------------------------------------

// IDS SHOULD BE EQUAL TO PROPERTIES IN THE OBJECTS
const TABLE_HEAD = [
    { id: 'id', label: 'ID', align: 'left' },
    { id: 'user', label: 'Пользователь', align: 'left' },
    { id: 'amount', label: 'Сумма', align: 'left' },
    { id: 'updatedAt', label: 'Обновлено', align: 'left' },
    { id: 'createdAt', label: 'Дата', align: 'left' },
    { id: 'status', label: 'Статус', align: 'center' },
    { id: '' },
];

// ----------------------------------------------------------------------

export default function WithdrawalsPage() {
    console.log('Withdrawals PAGE');
    const [open, setOpen] = useState(null);

    const [page, setPage] = useState(0);

    const [order, setOrder] = useState('desc');

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState('updatedAt');

    const [rowsPerPage, setRowsPerPage] = useState(5);

    const [withdrawals, setWithdrawals] = useState(null);

    const [withdrawalsTotal, setWithdrawalsTotal] = useState(0);

    const [withdrawalToEdit, setWithdrawalToEdit] = useState(null);

    const [success, setSuccess] = useState(false);

    const [successMessage, setSuccessMessage] = useState(null);

    const [withdrawalUpdatedNotification, setWithdrawalUpdatedNotification] =
        useState(null);

    useEffect(() => {
        setWithdrawals(null);
        setSelected([]);
        const getWithdrawalsData = async () => {
            try {
                console.log('CALLING GET WITHDRAWALS');

                const data = await getWithdrawals(
                    rowsPerPage,
                    page,
                    orderBy,
                    order
                );
                if (data && data.data && data.data.withdrawals) {
                    console.log('DATA GOT WITHDRAWALS');
                    setWithdrawals(data.data.withdrawals);
                }
                if (data && data.data && data.data.totalWithdrawals) {
                    setWithdrawalsTotal(data.data.totalWithdrawals);
                }
            } catch (error) {
                setWithdrawals(null);
                setSelected([]);
            }
        };
        getWithdrawalsData();
    }, [rowsPerPage, page, orderBy, order, withdrawalUpdatedNotification]);

    // Display success message when success message is set
    useEffect(() => {
        if (successMessage) {
            handleSuccessOpen();
        }
    }, [successMessage]);

    const notifyWithdrawalUpdated = (message) => {
        if (message === 'add' || message === 'delete' || message === 'edit') {
            setPage(0);
            setWithdrawalUpdatedNotification(nanoid());
        }
        handleCloseMenu();
        switch (message) {
            case 'add':
                setSuccessMessage('Снятие средств добавлено!');
                break;
            case 'edit':
                setSuccessMessage('Снятие средств обновлено!');
                break;
            case 'delete':
                setSuccessMessage('Снятие средств удалено!');
                break;
            case 'error':
                setSuccessMessage('Произошла ошибка');
                break;
            default:
                setSuccessMessage(null);
                break;
        }
    };

    const handleSuccessOpen = () => {
        setSuccess(true);
    };

    const handleSuccessClose = () => {
        setSuccess(false);
        setSuccessMessage(null);
    };

    const handleOpenMenu = (event, withdrawal) => {
        if (withdrawal) {
            setWithdrawalToEdit(withdrawal);
            setOpen(event.currentTarget);
        }
    };

    const handleCloseMenu = () => {
        setWithdrawalToEdit(null);
        setOpen(null);
    };

    const handleRequestSort = (event, property) => {
        setWithdrawals(null);
        setSelected([]);
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        const indeterminate =
            selected.length > 0 &&
            withdrawals &&
            withdrawals.length &&
            selected.length < withdrawals.length;
        if (
            event.target.checked &&
            !indeterminate &&
            withdrawals &&
            withdrawals.length > 0
        ) {
            const newSelecteds = withdrawals.map(
                (withdrawal) => withdrawal._id
            );
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, withdrawalId) => {
        const selectedIndex = selected.indexOf(withdrawalId);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, withdrawalId);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setWithdrawals(null);
        setSelected([]);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setWithdrawals(null);
        setSelected([]);
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - withdrawalsTotal) : 0;

    const isNotFound = false;

    return (
        <>
            <Helmet>
                <title> Withdrawals | Mentup Dashboard </title>
            </Helmet>

            <Container>
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    open={success}
                    autoHideDuration={6000}
                    onClose={handleSuccessClose}
                >
                    <Alert
                        variant='filled'
                        onClose={handleSuccessClose}
                        severity={
                            successMessage === 'Error occured'
                                ? 'Ошибка'
                                : 'Успешно'
                        }
                        sx={{ width: '100%' }}
                    >
                        {successMessage && successMessage}
                    </Alert>
                </Snackbar>
                <Stack
                    direction='row'
                    alignItems='center'
                    justifyContent='space-between'
                    mb={5}
                >
                    <Typography variant='h4' gutterBottom>
                    Снятие средств
                    </Typography>
                </Stack>

                <Card>
                    {/* <WithdrawalListToolbar /> */}

                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <WithdrawalListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={
                                        withdrawals && withdrawals.length
                                            ? withdrawals.length
                                            : 0
                                    }
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                />
                                <TableBody>
                                    {withdrawals &&
                                        withdrawals.map((withdrawal, index) => {
                                            const selectedWithdrawal =
                                                selected.indexOf(
                                                    withdrawal._id
                                                ) !== -1;

                                            return (
                                                <TableRow
                                                    hover
                                                    key={index}
                                                    tabIndex={-1}
                                                    role='checkbox'
                                                    selected={
                                                        selectedWithdrawal
                                                    }
                                                >
                                                    {/* <TableCell padding='checkbox'>
                                                        <Checkbox
                                                            checked={
                                                                selectedWithdrawal
                                                            }
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                if (
                                                                    withdrawal._id
                                                                ) {
                                                                    handleClick(
                                                                        event,
                                                                        withdrawal._id
                                                                    );
                                                                }
                                                            }}
                                                        />
                                                    </TableCell> */}

                                                    <TableCell align='left'>
                                                        {withdrawal._id ? (
                                                            <Typography>
                                                                {withdrawal._id}
                                                            </Typography>
                                                        ) : null}
                                                    </TableCell>

                                                    <TableCell align='left'>
                                                        {withdrawal.user ? (
                                                            <Typography>
                                                                {
                                                                    withdrawal.user
                                                                }
                                                            </Typography>
                                                        ) : null}
                                                    </TableCell>

                                                    <TableCell align='left'>
                                                        {withdrawal.amount ||
                                                        withdrawal.amount ===
                                                            0 ? (
                                                            <Typography>
                                                                {`£${withdrawal.amount}`}
                                                            </Typography>
                                                        ) : null}
                                                    </TableCell>

                                                    <TableCell align='left'>
                                                        {withdrawal.updatedAt ? (
                                                            <Typography>
                                                                {isoToReadableDate(
                                                                    withdrawal.updatedAt
                                                                )}
                                                            </Typography>
                                                        ) : null}
                                                    </TableCell>

                                                    <TableCell align='left'>
                                                        {withdrawal.createdAt ? (
                                                            <Typography>
                                                                {isoToReadableDate(
                                                                    withdrawal.createdAt
                                                                )}
                                                            </Typography>
                                                        ) : null}
                                                    </TableCell>

                                                    <TableCell align='left'>
                                                        {withdrawal.status ? (
                                                            <TableStatusCell
                                                                contents={
                                                                    withdrawal.status
                                                                }
                                                            />
                                                        ) : null}
                                                    </TableCell>

                                                    <TableCell align='right'>
                                                        <IconButton
                                                            size='large'
                                                            color='inherit'
                                                            onClick={(e) => {
                                                                if (
                                                                    withdrawal
                                                                ) {
                                                                    handleOpenMenu(
                                                                        e,
                                                                        withdrawal
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            <Iconify
                                                                icon={
                                                                    'eva:more-vertical-fill'
                                                                }
                                                            />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow
                                            style={{ height: 53 * emptyRows }}
                                        >
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>

                                {isNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell
                                                align='center'
                                                colSpan={6}
                                                sx={{ py: 3 }}
                                            >
                                                <Paper
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Typography
                                                        variant='h6'
                                                        paragraph
                                                    >
                                                        Не найдено
                                                    </Typography>

                                                    <Typography variant='body2'>
                                                        Не найдено
                                                        &nbsp;
                                                        <strong>
                                                            &quot;
                                                            {'Search Value'}
                                                            &quot;
                                                        </strong>
                                                        .
                                                        <br /> Попробуйте проверить опечатки или корректируйте запрос.
                                                    </Typography>
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        labelRowsPerPage="Выводить по:"
                        rowsPerPageOptions={[5, 10, 25]}
                        component='div'
                        count={withdrawalsTotal && withdrawalsTotal}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>

            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <WithdrawalModal
                    withdrawal={withdrawalToEdit}
                    notify={notifyWithdrawalUpdated}
                />
            </Popover>
        </>
    );
}
