import axios from 'axios';
import Cookies from 'js-cookie';

const API = axios.create({
    baseURL: process.env.NODE_ENV.includes('production')
        ? process.env.REACT_APP_SERVER_URL
            ? process.env.REACT_APP_SERVER_URL
            : ''
        : 'http://localhost:3030/api',
});

export const updateAd = (adData, adId) => {
    const token = Cookies.get('refreshToken');
    if (token && adData && adId) {
        //console.log('Inside updateAd');
        return API.patch(`/ads/${adId}`, adData, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        });
    }
};
