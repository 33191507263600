import React from 'react';
import { useState } from 'react';

// mui
import {
    Box,
    Button,
    Typography,
    Modal,
    TextField,
    IconButton,
    MenuItem,
    InputAdornment,
} from '@mui/material';

// form
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// icons
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { updatePassword } from 'src/api/password/updatePassword';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    paddingX: 4,
    paddingTop: 1,
    paddingBottom: 4,
    borderRadius: '10px',
    maxHeight: '90vh',
    overflow: 'scroll',
    '@media (max-width: 650px)': {
        width: '90%',
    },
};

function UserPasswordModal({ notify }) {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [showCurrentPassword, setShowCurrentPassword] = useState(false);

    const [showNewPassword, setShowNewPassword] = useState(false);

    const schema = yup.object().shape({
        currentPassword: yup
            .string('Old password must be a string.')
            .max(20, 'Old password must be at most 20 characters.')
            .required('Old password is required.'),
        newPassword: yup
            .string('New password must be a string.')
            .min(8, 'New password must be at least 8 characters.')
            .max(20, 'New password must be at most 20 characters.')
            .required('New password is required.'),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const sendPasswordData = async (data) => {
        try {
            const res = await updatePassword(data);
            if (res) {
                if (notify) {
                    notify('password');
                }
                resetAll();
                handleClose();
            }
        } catch (err) {
            console.log(err);
            notify('error');
            resetAll();
            handleClose();
        }
    };

    const submitPasswordData = (data) => {
        console.log('Inside submitPasswordData1');
        console.log(data);
        console.log('Inside submitPasswordData2');
        sendPasswordData(data);
    };

    const resetAll = () => {
        reset();
        setShowNewPassword(false);
        setShowCurrentPassword(false);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Button
                size={`medium`}
                variant='outlined'
                sx={{
                    width: '100%',
                }}
                onClick={handleOpen}
            >
                Change Password
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby='user-change-password-modal'
                aria-describedby='user-change-password-modal'
            >
                <Box sx={style}>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'end',
                        }}
                    >
                        <IconButton
                            aria-label='close'
                            onClick={() => {
                                handleClose();
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            margin: '0',
                            padding: '0',
                            width: '100%',
                            marginBottom: '0.5rem',
                            textAlign: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                fontSize: '1.8rem',
                            }}
                        >
                            Change Password
                        </Typography>
                    </Box>
                    <form onSubmit={handleSubmit(submitPasswordData)}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                rowGap: '1rem',
                                padding: '1rem 3rem',
                                '@media (max-width: 650px)': {
                                    paddingX: 0,
                                },
                            }}
                        >
                            <TextField
                                required
                                fullWidth
                                type={showCurrentPassword ? 'text' : `password`}
                                variant={`outlined`}
                                label='Old Password'
                                error={errors.currentPassword && true}
                                helperText={errors.currentPassword?.message}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <IconButton
                                                onClick={() =>
                                                    setShowCurrentPassword(
                                                        (prevState) =>
                                                            !prevState
                                                    )
                                                }
                                                edge='end'
                                            >
                                                <VisibilityIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                {...register('currentPassword')}
                            />

                            <TextField
                                required
                                fullWidth
                                type={showNewPassword ? 'text' : `password`}
                                variant={`outlined`}
                                label='New Password'
                                error={errors.newPassword && true}
                                helperText={errors.newPassword?.message}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <IconButton
                                                onClick={() =>
                                                    setShowNewPassword(
                                                        (prevState) =>
                                                            !prevState
                                                    )
                                                }
                                                edge='end'
                                            >
                                                <VisibilityIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                {...register('newPassword')}
                            />

                            <Button
                                size={`large`}
                                type={`submit`}
                                variant='contained'
                                sx={{ marginTop: '1rem' }}
                            >
                                Submit
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Modal>
        </Box>
    );
}

export default UserPasswordModal;
