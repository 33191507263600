import { Box, Button, TextField, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";

import Typing from "src/components/typing";
import { useTranslate } from "src/contexts/translate/translate.context";
import { useMessages } from "src/hooks/messages";
import MessageUpload from "./MessageUpload";
import { MessageListItem } from "./message-list-item";

export const MessagesContainer = ({ chat, unreadCount, markAsRead }) => {
  const { translate } = useTranslate();
  const messages = useMessages(chat);

  const user = JSON.parse(sessionStorage.getItem("user"));

  const [message, setMessage] = useState("");

  const displayTypingUsers = useMemo(() => {
    const usernames = (messages.typing || []).map(({ username }) => username);
    if (!usernames.length) {
      return "";
    }

    return `${usernames.join(", ")} ${
      usernames.length > 1
        ? translate("chat.areTyping")
        : translate("chat.isTyping")
    }`;
  }, [messages.typing, translate]);

  const handleSend = async (e) => {
    let chatId = chat?._id;
    if (!chatId) {
      return;
    }
    messages.create(e?.target?.value || message);
    setMessage("");
  };

  useEffect(() => {
    if (unreadCount || messages?.items?.length) {
      markAsRead();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unreadCount, messages?.items?.length]);

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        height: "100%",
        boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.35)",
      }}
    >
      <Box
        sx={{
          flex: 1,
          padding: "10px",
          display: "flex",
          flexDirection: "column-reverse",
          overflowY: "scroll",
          gap: "10px",
        }}
      >
        {messages.items.map((message, index) => (
          <MessageListItem key={`${message._id}-${index}`} message={message} />
        ))}
      </Box>

      {!!displayTypingUsers && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "8px",
            padding: "10px",
          }}
        >
          <Typing />

          <Typography
            sx={{
              fontSize: "0.8rem",
              color: "#9e9e9e",
              textAlign: "center",
            }}
          >
            {displayTypingUsers}
          </Typography>
        </Box>
      )}

      {user?._id === chat?.advisor && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px",
            width: "100%",
            gap: "10px",
          }}
        >
          <MessageUpload
            chatId={chat?._id}
            percents={messages.percent}
            handleUpload={messages.uploadFile}
          />
          <TextField
            value={message}
            id="outlined-basic"
            label="Message"
            variant="outlined"
            fullWidth
            onChange={(e) => setMessage(e.target.value)}
            onKeyUp={(e) => e.key === "Enter" && handleSend(e)}
          />
          <Button
            variant="contained"
            size="x-large"
            className="send-button button"
            onClick={(e) => handleSend(e)}
            sx={{
              marginRight: "10px",
              paddingLeft: "20px",
              paddingRight: "20px",
            }}
          >
            <Typography>{translate("chat.messages.send")}</Typography>
          </Button>
        </Box>
      )}
    </Box>
  );
};
