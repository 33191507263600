import { IconButton, InputAdornment, OutlinedInput } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";

import Iconify from "src/components/iconify";

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  zIndex: 1,
  width: 250,
  borderRadius: 0,
  backgroundColor: theme.palette.background.paper,
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

export default function ChatSearch({ value, handleSearch }) {
  return (
    <StyledSearch
      value={value}
      onChange={(e) => handleSearch(e.target.value)}
      placeholder="Search chat..."
      startAdornment={
        <InputAdornment position="start">
          <Iconify
            icon="eva:search-fill"
            sx={{
              color: "text.disabled",
              width: 20,
              height: 20,
            }}
          />
        </InputAdornment>
      }
      endAdornment={
        value && (
          <InputAdornment position="end">
            <IconButton onClick={() => handleSearch("")} edge="end">
              <Iconify
                icon="ic:round-close"
                sx={{
                  color: "text.disabled",
                  width: 20,
                  height: 20,
                }}
              />
            </IconButton>
          </InputAdornment>
        )
      }
    />
  );
}
