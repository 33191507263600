import { useEffect, useState } from 'react';

// mui
import {
    Box,
    Button,
    Typography,
    Modal,
    IconButton,
    MenuItem,
} from '@mui/material';

// icons
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';

// api
import { deleteCategoryType } from '../../api/types/deleteType';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    paddingX: 7,
    paddingTop: 1,
    paddingBottom: 4,
    borderRadius: '10px',
    maxHeight: '90vh',
    overflow: 'scroll',
    '@media (max-width: 650px)': {
        paddingX: 4,
        width: '90%',
    },
};

function DeleteCategoryTypeModal({ type, message }) {
    const [open, setOpen] = useState(false);
  
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [submit, setSubmit] = useState(false);


    useEffect(() => {
        if (submit) {
        const handleDeleteType = async () => {
            try {
              const data = await deleteCategoryType(type._id);
              if (data && data.data) {
                if (message) {
                  message('delete');
                }
                handleClose();
                setSubmit(false);
              }
            } catch (error) {
              if (message) {
                message('error');
              }
              setSubmit(false);
              handleClose();
            }
          };
        
        handleDeleteType();
        }
    }, [submit]);
    


    return (
        <Box>
            <MenuItem onClick={handleOpen}>
                <DeleteIcon sx={{ mr: 2 }} /> Delete{' '}
            </MenuItem>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby='edit-category-modal'
                aria-describedby='edit-category-modal'
            >
                <Box sx={style}>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'end',
                        }}
                    >
                        <IconButton
                            aria-label='close'
                            onClick={() => {
                                handleClose();
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            margin: '0',
                            padding: '0',
                            width: '100%',
                            marginBottom: '0.5rem',
                            textAlign: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                fontSize: '1.8rem',
                            }}
                        >
                            {type && type.name
                                ? `Delete category type: ${type.name} ?`
                                : `Edit Category`}
                        </Typography>


                    </Box>
                    <Box>
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                fontSize: '1.4rem',
                            }}
                        >
                            Are you sure you want to delete this category type?
                        </Typography>
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                fontSize: '1.2rem',
                            }}
                        >
                            This action cannot be undone.
                        </Typography>

                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '1rem',
                        }}
                    >
                        <Button
                            variant='outlined'
                            sx={{
                                width: '100%',
                                maxWidth: '300px',
                                backgroundColor: '#ff0000',
                                color: '#fff',
                                '&:hover': {
                                    backgroundColor: '#ff0000',
                                },
                            }}
                            onClick={() => setSubmit(true)}
                        >
                            Delete
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
}

export default DeleteCategoryTypeModal;
