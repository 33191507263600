import axios from "axios";
import Cookies from "js-cookie";

const API = axios.create({
  baseURL: process.env.NODE_ENV.includes("production")
    ? process.env.REACT_APP_SERVER_URL
      ? process.env.REACT_APP_SERVER_URL
      : ""
    : "http://localhost:3030/api",
});

export const getUsers = (limit, page, sortBy, order, searchValue) => {
  const token = Cookies.get("refreshToken");
  if (token && limit && (page || page === 0) && sortBy && order) {
    let backendPage = page + 1;
    if(!searchValue) {
      return API.get(
        `/auth/allUsers?limit=${limit}&page=${backendPage}&${sortBy}=${order}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } else {
      return API.get(
        `/auth/allUsers?limit=${limit}&page=${backendPage}&${sortBy}=${order}&search=${searchValue}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    }
  }
};

export const getUsersByIds = (ids) => {
  const token = Cookies.get("refreshToken");
  if (token && ids?.length) {
    return API.post(
      "/auth/search/ids",
      { ids },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }
};
