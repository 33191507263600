import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isoToReadableDate } from 'src/utils/isoToReadableDate';
// @mui
import {
    Card,
    Table,
    Stack,
    Paper,
    Alert,
    Avatar,
    Popover,
    Checkbox,
    TableRow,
    MenuItem,
    TableBody,
    TableCell,
    Snackbar,
    Container,
    Typography,
    IconButton,
    TableContainer,
    TablePagination,
} from '@mui/material';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import DeleteUserModal from 'src/components/delete-user-modal';
import AddUserModal from 'src/components/add-user-modal/AddUserModal';
import TableStatusCell from 'src/components/table-status-cell/TableStatusCell';

// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';

// icons
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

// utils
import { nanoid } from 'nanoid';

// api
import { getUsers } from 'src/api/users/getUsers';
import { CategoryListToolbar } from 'src/sections/@dashboard/categories';

// ----------------------------------------------------------------------

// IDS SHOULD BE EQUAL TO PROPERTIES IN THE OBJECTS
const TABLE_HEAD = [
    { id: 'sortUsername', label: 'Имя пользователя', alignRight: false },
    { id: 'sortCreatedAt', label: 'Зарегистрирован', alignRight: false },
    { id: 'sortLastUpdatedAt', label: 'Последнее обновление', alignRight: false },
    { id: 'sortRole', label: 'Роль', alignRight: false },
    { id: 'sortStatus', label: 'Статус', alignRight: false },
    { id: '' },
];

// ----------------------------------------------------------------------

export default function UserPage() {
    const [open, setOpen] = useState(null);

    const [page, setPage] = useState(0);

    const [order, setOrder] = useState('desc');

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState('sortLastUpdatedAt');

    const [rowsPerPage, setRowsPerPage] = useState(5);

    const [users, setUsers] = useState(null);

    const [usersTotal, setUsersTotal] = useState(0);

    const [userAddedNotification, setUserAddedNotification] = useState(null);

    const [success, setSuccess] = useState(false);

    const [successMessage, setSuccessMessage] = useState(null);

    const [userIdToEdit, setUserIdToEdit] = useState(null);

    useEffect(() => {
        setUsers(null);
        setSelected([]);
        const getUsersData = async () => {
            try {
                const data = await getUsers(rowsPerPage, page, orderBy, order);
                if (data && data.data && data.data.users) {
                    setUsers(data.data.users);
                    console.log(data.data.users)
                }
                if (data && data.data && data.data.total) {
                    setUsersTotal(data.data.total);
                }
            } catch (error) {
                setUsers(null);
                setSelected([]);
            }
        };
        getUsersData();
    }, [rowsPerPage, page, orderBy, order, userAddedNotification]);

    // Display success message when success message is set
    useEffect(() => {
        if (successMessage) {
            handleSuccessOpen();
        }
    }, [successMessage]);

    const notifyUserAdded = (message) => {
        setPage(0);
        setUserAddedNotification(nanoid());
        switch (message) {
            case 'add':
                setSuccessMessage('Пользователь добавлен!');
                break;
            case 'edit':
                setSuccessMessage('Пользователь отредактирован!');
                break;
            case 'delete':
                setSuccessMessage('Пользователь удалён!');
                break;
            case 'error':
                setSuccessMessage('Произошла ошибка');
                break;
            default:
                setSuccessMessage(null);
                break;
        }
    };

    const navigate = useNavigate();

    const handleOpenMenu = (event, id) => {
        if (id) {
            setUserIdToEdit(id);
            setOpen(event.currentTarget);
        }
    };

    const handleCloseMenu = () => {
        setOpen(null);
    };

    const handleRequestSort = (event, property) => {
        setUsers(null);
        setSelected([]);
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        const indeterminate =
            selected.length > 0 &&
            users &&
            users.length &&
            selected.length < users.length;
        if (
            event.target.checked &&
            !indeterminate &&
            users &&
            users.length > 0
        ) {
            const newSelecteds = users.map((user) => user._id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, userId) => {
        const selectedIndex = selected.indexOf(userId);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, userId);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setUsers(null);
        setSelected([]);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setUsers(null);
        setSelected([]);
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleSuccessOpen = () => {
        setSuccess(true);
    };

    const handleSuccessClose = () => {
        setSuccess(false);
        setSuccessMessage(null);
    };

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - usersTotal) : 0;

    const isNotFound = false;

    const handleSearch = async (searchValue) => {
        setUsers(null);
        setSelected([]);
        setPage(0);
        try {
            let data = null;
            if(searchValue.length >= 3) {
                data = await getUsers(rowsPerPage, page, orderBy, order, searchValue)
            } else if(searchValue.length === 0) {
                data = await getUsers(rowsPerPage, page, orderBy, order)
            }
            if (data && data.data && data.data.users) {
                setUsers(data.data.users);
            }
            if (data && data.data && data.data.total) {
                setUsersTotal(data.data.total);
            }
            
        } catch (error) {
            setUsers(null);
            setSelected([]);
        }
    }

    return (
        <>
            <Helmet>
                <title> Users | Mentup Dashboard </title>
            </Helmet>

            <Container>
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    open={success}
                    autoHideDuration={6000}
                    onClose={handleSuccessClose}
                >
                    <Alert
                        variant='filled'
                        onClose={handleSuccessClose}
                        severity={
                            successMessage === 'Error occured'
                                ? 'Ошибка'
                                : 'Успешно'
                        }
                        sx={{ width: '100%' }}
                    >
                        {successMessage && successMessage}
                    </Alert>
                </Snackbar>
                <Stack
                    direction='row'
                    alignItems='center'
                    justifyContent='space-between'
                    mb={5}
                >
                    <Typography variant='h4' gutterBottom>
                        Пользователи
                    </Typography>
                    {/* <Button
                        variant='contained'
                        startIcon={<Iconify icon='eva:plus-fill' />}
                    >
                        New User
                    </Button> */}
                    <AddUserModal notify={notifyUserAdded} />
                </Stack>

                <Card>
                    {/* <UserListToolbar numSelected={selected.length} /> */}
                    <CategoryListToolbar
                        handleSearchGlobal={handleSearch}
                    />
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <UserListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={
                                        users && users.length ? users.length : 0
                                    }
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                />
                                <TableBody>
                                    {users &&
                                        users.map((user, index) => {
                                            const selectedUser =
                                                selected.indexOf(user._id) !==
                                                -1;

                                            return (
                                                <TableRow
                                                    hover
                                                    key={index}
                                                    tabIndex={-1}
                                                    role='checkbox'
                                                    selected={selectedUser}
                                                >
                                                    {/* <TableCell padding='checkbox'>
                                                        <Checkbox
                                                            checked={
                                                                selectedUser
                                                            }
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                if (user._id) {
                                                                    handleClick(
                                                                        event,
                                                                        user._id
                                                                    );
                                                                }
                                                            }}
                                                        />
                                                    </TableCell> */}

                                                    <TableCell
                                                        component='th'
                                                        scope='row'
                                                        padding='none'
                                                        sx={{
                                                            paddingLeft: '1rem',
                                                        }}
                                                    >
                                                        <Stack
                                                            direction='row'
                                                            alignItems='center'
                                                            spacing={2}
                                                        >
                                                            {user.avatar ? (
                                                                <Avatar
                                                                    alt={
                                                                        user.username
                                                                            ? user.username
                                                                            : 'User avatar'
                                                                    }
                                                                    src={
                                                                        user.avatar
                                                                    }
                                                                />
                                                            ) : (
                                                                <AccountCircleIcon
                                                                    sx={{
                                                                        height: '2.5rem',
                                                                        width: '2.5rem',
                                                                    }}
                                                                />
                                                            )}

                                                            <Typography
                                                                variant='subtitle2'
                                                                noWrap
                                                            >
                                                                {user.username &&
                                                                    user.username}
                                                            </Typography>
                                                        </Stack>
                                                    </TableCell>

                                                    <TableCell align='left'>
                                                        {user.createdAt &&
                                                            isoToReadableDate(
                                                                user.createdAt
                                                            )}
                                                    </TableCell>

                                                    <TableCell align='left'>
                                                        {user.updatedAt &&
                                                            isoToReadableDate(
                                                                user.updatedAt
                                                            )}
                                                    </TableCell>

                                                    <TableCell align='left'>
                                                        {user.settings.role && user.settings.role}
                                                    </TableCell>

                                                    <TableCell align='left'>
                                                        <TableStatusCell
                                                            contents={
                                                                user.settings.status
                                                                    ? user.settings.status
                                                                    : ''
                                                            }
                                                        />
                                                    </TableCell>

                                                    <TableCell align='right'>
                                                        <IconButton
                                                            size='large'
                                                            color='inherit'
                                                            onClick={(e) => {
                                                                if (user._id) {
                                                                    handleOpenMenu(
                                                                        e,
                                                                        user._id
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            <Iconify
                                                                icon={
                                                                    'eva:more-vertical-fill'
                                                                }
                                                            />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow
                                            style={{ height: 53 * emptyRows }}
                                        >
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>

                                {isNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell
                                                align='center'
                                                colSpan={6}
                                                sx={{ py: 3 }}
                                            >
                                                <Paper
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Typography
                                                        variant='h6'
                                                        paragraph
                                                    >
                                                        Не найдено
                                                    </Typography>

                                                    <Typography variant='body2'>
                                                        Не найдено
                                                        &nbsp;
                                                        <strong>
                                                            &quot;
                                                            {'Search Value'}
                                                            &quot;
                                                        </strong>
                                                        .
                                                        <br /> Попробуйте проверить опечатки или корректируйте запрос.
                                                    </Typography>
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        labelRowsPerPage="Выводить по:"
                        rowsPerPageOptions={[5, 10, 25]}
                        component='div'
                        count={usersTotal && usersTotal}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>

            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <MenuItem
                    onClick={() => {
                        if (userIdToEdit) {
                            navigate(`/dashboard/user/${userIdToEdit}`);
                        }
                    }}
                >
                    <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                    редактировать
                </MenuItem>

                {/* <DeleteUserModal /> */}
            </Popover>
        </>
    );
}
