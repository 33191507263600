import axios from 'axios';
import Cookies from 'js-cookie';

const API = axios.create({
    baseURL: process.env.NODE_ENV.includes('production')
        ? process.env.REACT_APP_SERVER_URL
            ? process.env.REACT_APP_SERVER_URL
            : ''
        : 'http://localhost:3030/api',
});

const header = () => {
    return {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${Cookies.get('refreshToken')}`,
        },
    };
}

export const deleteCategory = (categoryId) => {
    return API.delete(`/category/${categoryId}`, header());
};
