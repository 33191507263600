/* eslint-disable no-unused-expressions */
import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import Cookies from 'js-cookie';
import { useEffect, useState, useRef } from 'react';
import { nanoid } from 'nanoid';
import axios from 'axios';
import { useTheme } from '@emotion/react';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';

// mui
import {
    IconButton,
    TextField,
    Card,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    useMediaQuery,
    InputAdornment,
    FormHelperText,
    ToggleButton,
    ToggleButtonGroup,
    Autocomplete,
} from '@mui/material';
import Pagination from '@mui/material/Pagination';

// icons
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import EditIcon from '@mui/icons-material/Edit';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';

// form
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// api
import { deleteMultipleImages } from 'src/api/images/deleteMultipleImages';
import { uploadImage } from 'src/api/images/uploadImage';
import { createProduct } from 'src/api/products/addProduct';
import { getAllCategories } from 'src/api/categories/getAllCategories';
import { getPromocodesNoSorting } from 'src/api/promocodes/getPromocodesNoSorting';
import { updateProduct } from 'src/api/products/updateProduct';
import { isoToReadableDate } from 'src/utils/isoToReadableDate';
import { getPromocodesOfUser } from 'src/api/promocodes/getPromocodesOfUser';
import { addProductCode } from 'src/api/products/ProductCodes';
import { createPromocode } from 'src/api/promocodes/createPromocode';

// utils
import { regions } from 'src/utils/regions';

const modalStyle = {
    color: 'white',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    paddingTop: 1,
    borderRadius: '10px',
    maxHeight: '90vh',
    overflow: 'scroll',
    '@media (max-width: 650px)': {
        width: '61%',
    },
};

const productFormat = {
    type: '',
    quantity: 1,
    name: '',
    media: [],
    description: '',
    price: null,
    category: null,
    promocode: null,
    user: null,
    codes: null,
    region: 'Global',
    currency: '',
};

const additionalProductInfoFormat = {
    customPromo: {
        name: '',
        code: '',
        discount: 0,
        global: false,
        user: null,
        expiryDate: null,
    },
    customPromoDateSimpleFormat: null,
};

export default function AddProductModal(props) {
    const smallScreen = useMediaQuery('(max-width:500px)');

    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [open3, setOpen3] = React.useState(false);
    const [open4, setOpen4] = React.useState(false);
    const [open5, setOpen5] = React.useState(false);

    const handleOpen = () => setOpen(true);
    const handleOpen2 = () => setOpen2(true);
    const handleOpen3 = () => setOpen3(true);
    const handleOpen4 = () => setOpen4(true);
    const handleOpen5 = () => setOpen5(true);

    const handleClose = () => setOpen(false);
    const handleClose2 = () => setOpen2(false);
    const handleClose3 = () => setOpen3(false);
    const handleClose4 = () => setOpen4(false);
    const handleClose5 = () => {
        setOpen5(false);
        setAddedProduct(null);
    };

    const type = props.type;
    let domElementType = '';

    {
        type && type === 'editProduct' ? (domElementType = '-edit') : null;
    }
    {
        type && type === 'addProduct' ? (domElementType = '-add') : null;
    }

    const theme = useTheme();

    // Temporary state of a new product
    const [productToAdd, setProductToAdd] = useState(productFormat);

    const [additionalProductInfo, setAdditionalProductInfo] = useState(
        additionalProductInfoFormat
    );

    // Images to delete from backend
    const [imagesToDelete, setImagesToDelete] = useState([]);

    const [newPictureCodes, setNewPictureCodes] = useState([]);

    // Product Types
    const types = ['Аккаунт', 'Пожертвование', 'Код', 'Прочее'];

    // All Categories
    const [allCategories, setAllCategories] = useState(null);

    // Subcategories
    // const [subcategories, setSubCategories] = useState(null);

    // Promocodes
    const [promocodes, setPromocodes] = useState(null);

    // Product Pictures
    const [productMedia, setProductMedia] = useState([]);

    const [minimumPictureMessage, setMinimumPictureMessage] = useState(false);

    const [maximumPictureMessage, setMaximumPictureMessage] = useState(false);

    // State storing 2 urls for picture rearranging (Mobile)
    const [mobilePictureIndexes, setMobilePictureIndexes] = useState([]);

    // State storing the newly added product for confirmation
    const [addedProduct, setAddedProduct] = useState(null);

    // Drag Start : reference for detecting the element involved
    const dragStartElement = useRef(null);

    // Drag Leave : reference for detecting the element involved
    const dragLeaveElement = useRef(null);

    // Drag Enter : reference for detecting the element involved
    const dragEnterElement = useRef(null);

    const [page, setPage] = useState(1);

    const [showTitleEmojis, setShowTitleEmojis] = useState(false);

    const [showDescriptionEmojis, setShowDescriptionEmojis] = useState(false);

    const [addProductCodes, setAddProductCodes] = useState(false);

    const appendNewProduct = props.appendNewProduct;

    // Product data validation schema
    const schema1 = yup.object().shape({
        ...(type === 'addProduct' && { user: yup.string().max(50).required() }),
        ...(types && types.length > 0
            ? {
                  productType: yup
                      .string()
                      .oneOf(
                          [...types],
                          'Product type must be a value from the available selections.'
                      )
                      .required(),
              }
            : { productType: yup.string().nullable() }),
        name: yup.string().min(5).max(50).required(),
        description: yup.string().max(300).required(),
        ...(allCategories && Object.keys(allCategories).length > 0
            ? {
                  category: yup
                      .string()
                      .oneOf(Object.keys(allCategories))
                      .required(),
              }
            : { category: yup.string().nullable() }),
    });

    const schema2 = yup.object().shape({
        price: yup
            .number()
            .min(0)
            .max(1000)
            .required()
            .test(
                'Valid price value',
                'Price value must be between 0 and 1000 and have 2 decimal places at most',
                (value) => {
                    if (
                        value.toString().match(/^\d+\.\d{1,2}$/) ||
                        value.toString().match(/^\d+$/)
                    ) {
                        return true;
                    }
                    return false;
                }
            ),
        currency: yup
            .string()
            .oneOf(
                ['RUB', 'USD'],
                'Currency must be a value from the available selections.'
            )
            .required(),
        quantity: yup.number().integer().min(1).max(1000).required(),
        promocode: yup
            .mixed((value) => {
                if (value === 'None' || value === 'Custom') {
                    return true;
                } else if (
                    value &&
                    promocodes &&
                    Object.keys(promocodes) &&
                    Object.keys(promocodes).length > 0 &&
                    Object.keys(promocodes).some(
                        (promocode) => promocode === value
                    )
                ) {
                    return true;
                }
                return false;
            })
            .required(),
        ...(!(regions && regions.length > 0) && {
            region: yup.string().max(200).nullable(),
        }),
        ...(regions &&
            regions.length > 0 && {
                region: yup
                    .string()
                    .required()
                    .test(
                        'Valid region value',
                        'Region must be one of the provided options.',
                        (value) => {
                            console.log('VALUE TO TEST: ' + value);
                            if (
                                regions.some((region) => region.name === value)
                            ) {
                                return true;
                            } else {
                                return false;
                            }
                        }
                    ),
            }),
        ...(productToAdd.promocode === 'Custom' && {
            customPromoCode: yup
                .string('Custom Promode must be a string')
                .min(5, 'Custom Promocode must be at least 5 characters')
                .max(30, 'Custom Promocode must be at most 30 characters')
                .required('Custom Promocode is required')
                .test(
                    'Valid Custom Promocode Value',
                    'Custom Promocode Value must contain letters and numbers only',
                    (value) => value.match(/^[a-zA-Z0-9]+$/)
                ),
            customPromoName: yup
                .string('Custom Promocode Name must be a string')
                .min(5, 'Custom Promocode Name must be at least 5 characters')
                .max(30, 'Custom Promocode Name must be at most 30 characters')
                .required('Custom Promocode Name is required'),
            customPromoDiscount: yup
                .number('Custom Promocode Discount must be a number')
                .min(1, 'Custom Promocode Discount must be at least 1%')
                .max(100, 'Custom Promocode Discount must be at most 100%')
                .required('Custom Promocode Discount is required')
                .test(
                    'Valid discount value',
                    'Discount value must be between 1 and 100 and have 2 decimal places at most',
                    (value) => {
                        if (
                            value.toString().match(/^\d+\.\d{1,2}$/) ||
                            value.toString().match(/^\d+$/)
                        ) {
                            return true;
                        }
                        return false;
                    }
                ),
            ...(additionalProductInfo &&
                additionalProductInfo.customPromo &&
                additionalProductInfo.customPromo.global === true && {
                    customPromoDiscount: yup
                        .number('Custom Promocode Discount must be a number')
                        .min(1, 'Custom Promocode Discount must be at least 1%')
                        .max(
                            13,
                            'Custom Promocode Discount must be at most 13%'
                        )
                        .required('Custom Promocode Discount is required')
                        .test(
                            'Valid discount value',
                            'Discount value must be between 1 and 100 and have 2 decimal places at most',
                            (value) => {
                                if (
                                    value.toString().match(/^\d+\.\d{1,2}$/) ||
                                    value.toString().match(/^\d+$/)
                                ) {
                                    return true;
                                }
                                return false;
                            }
                        ),
                }),
            customPromoExpiryDate: yup
                .date('Custom Promocode Expiry Date must be have a date format')
                .required('Custom Promocode Expiry Date is required')
                .min(
                    new Date(),
                    'Custom Promocode Expiry Date must be in the future'
                ),
        }),
    });

    const [productCodesValidations, setProductCodesValidations] = useState({});
    const schema4 = yup.object().shape({
        codesConfirmation: yup
            .boolean('Confirmation must be a boolean')
            .required('Confirmation is required'),
        ...(productCodesValidations &&
            Object.keys(productCodesValidations).length ===
                productToAdd.quantity &&
            productCodesValidations),
    });

    const {
        register: register1,
        handleSubmit: handleSubmit1,
        formState: { errors: errors1 },
        reset: reset1,
    } = useForm({
        resolver: yupResolver(schema1),
    });

    const {
        control: control2,
        register: register2,
        handleSubmit: handleSubmit2,
        formState: { errors: errors2 },
        reset: reset2,
    } = useForm({
        resolver: yupResolver(schema2),
    });

    const {
        register: register4,
        handleSubmit: handleSubmit4,
        formState: { errors: errors4 },
        reset: reset4,
    } = useForm({
        resolver: yupResolver(schema4),
    });

    const draggableDragStart = (index) => {
        dragStartElement.current = index;
    };

    const touchStart = (index) => {
        if (mobilePictureIndexes.includes(index)) {
            setMobilePictureIndexes([]);
        } else {
            if (mobilePictureIndexes.length === 0) {
                setMobilePictureIndexes([index]);
            } else if (mobilePictureIndexes.length === 1) {
                rearrangeProductMedia(mobilePictureIndexes[0], index);
                setMobilePictureIndexes([]);
            }
        }
    };

    const containerDragLeave = (e, index) => {
        e.preventDefault();
        dragLeaveElement.current = index;
    };

    const containerDragEnter = (e, index) => {
        e.preventDefault();
        dragEnterElement.current = index;
        if (
            (dragLeaveElement.current || dragLeaveElement.current === 0) &&
            (dragEnterElement.current || dragEnterElement.current === 0) &&
            dragLeaveElement.current !== dragEnterElement.current &&
            (dragStartElement.current || dragStartElement.current === 0)
        ) {
            if (dragStartElement.current !== dragEnterElement.current) {
                dragStartElement.current = dragEnterElement.current;
                rearrangeProductMedia(
                    dragLeaveElement.current,
                    dragEnterElement.current
                );
            }
        }
    };

    const handleProductPictureUpload = (event) => {
        const pictures = event.target.files;
        let tempPictureArray = [];
        // console.log('PICTURES: ' + JSON.stringify(pictures));
        if (
            pictures &&
            productMedia.length + Object.keys(pictures).length > 7
        ) {
            setMaximumPictureMessage(true);
        } else {
            // console.log('HANDLE PICTRUE UPLOAD ELSE');
            if (pictures) {
                // console.log('HANDLE PICTRUE UPLOAD ELSEE IF');
                for (const key in pictures) {
                    const picture = pictures[key];
                    if (picture) {
                        try {
                            if (key < Object.keys(pictures).length) {
                                console.log(
                                    'CURRENT KEY BEING PROCESSED: ' + key
                                );
                                const pictureUrl = URL.createObjectURL(picture);
                                // setProductMedia([
                                //     ...productMedia,
                                // {
                                //     pictureFile: picture,
                                //     pictureUrl: pictureUrl,
                                //     pictureId: nanoid(),
                                // },
                                // ]);
                                tempPictureArray.push({
                                    pictureFile: picture,
                                    pictureUrl: pictureUrl,
                                    pictureId: nanoid(),
                                    type: 'localPicture',
                                });
                            }
                        } catch (err) {
                            console.log(
                                'ERROR KEY: ' +
                                    key +
                                    ' ERROR VALUE: ' +
                                    pictures[key]
                            );
                            console.log(err);
                        }
                    }
                }
            }
        }
        if (tempPictureArray.length > 0) {
            setProductMedia((prevState) => [...prevState, ...tempPictureArray]);
        }
    };

    const deletePicturesFromBackend = async (codes) => {
        console.log('DELETING PICTURES');
        try {
            if (codes && codes.length > 0) {
                console.log('INSIDE DELETE IMAGES FROM BACKEND');
                const data = await deleteMultipleImages(codes);
                if (
                    data &&
                    data.data &&
                    data.data.message &&
                    data.data.message === 'Images deleted'
                ) {
                    uploadNewPictures();
                }
            }
        } catch (error) {
            console.log(error);
            return;
        }
        return;
    };

    const uploadNewPictureToBackend = async (formdata, index) => {
        console.log('UPLOADING');

        try {
            const res = await uploadImage(formdata);
            if (res && res.data && res.data.media) {
                console.log('RES.MEDIA DETECTED');
                setNewPictureCodes((prevCodes) => [
                    ...prevCodes,
                    { code: res.data.media, index },
                ]);
                console.log(
                    'ADDED TO NEW PICTURE CODES LOCAL: ' + res.data.media
                );
            } else {
                console.log('RES.MEDIA NOT DETECTED');
            }
        } catch (error) {
            console.log(error);
        }
    };

    // Set new product to edit when props changes
    useEffect(() => {
        if (!props.product) {
            setProductToAdd(productFormat);
        } else {
            setProductToAdd(props.product);
        }
        setAdditionalProductInfo(additionalProductInfoFormat);
        setProductMedia([]);
        setMinimumPictureMessage(false);
        setMaximumPictureMessage(false);
        setMobilePictureIndexes([]);
        setImagesToDelete([]);
        setAddProductCodes(false);
        setNewPictureCodes([]);
        setPromocodes(null);
        reset1();
        reset2();
        reset4();
    }, [props.product]);

    useEffect(() => {
        if (
            productToAdd.media &&
            productToAdd.media.length > 0 &&
            type === 'editProduct'
        ) {
            let newMediaArray = [];
            for (const url of productToAdd.media) {
                const regex = /\/([a-f\d]{64})\?/; // Regular expression to match the hash
                try {
                    const match = url.match(regex);

                    if (match && match[1]) {
                        const extractedHash = match[1];

                        newMediaArray.push({
                            pictureFile: null,
                            pictureUrl: url,
                            pictureId: nanoid(),
                            type: 'backendPicture',
                            code: extractedHash,
                        });
                    } else {
                        console.log('Hash not found in the input string.');
                    }
                } catch (err) {
                    console.log(err);
                }
            }
            setProductMedia((prevState) => [...prevState, ...newMediaArray]);
        }
    }, [productToAdd.media]);

    const removeProductPicture = (pictureObject) => {
        if (
            type === 'editProduct' &&
            pictureObject.type === 'backendPicture' &&
            pictureObject.code &&
            pictureObject.code !==
                'a1c0f0dad0f6736325135dc69ec6dc0984058f11aa04150231c114efc1508290'
        ) {
            setImagesToDelete((prevState) => [
                ...prevState,
                pictureObject.code,
            ]);
        }
        setProductMedia((prevState) =>
            prevState.filter(
                (item) => item.pictureId !== pictureObject.pictureId
            )
        );
        setMobilePictureIndexes([]);
    };

    const rearrangeProductMedia = (index1, index2) => {
        let pictureObject1 = productMedia[index1];
        let pictureObject2 = productMedia[index2];
        setProductMedia((prevState) =>
            prevState.map((item, index) => {
                if (index === index1) {
                    return pictureObject2;
                } else if (index === index2) {
                    return pictureObject1;
                } else {
                    return item;
                }
            })
        );
    };

    const sendPromoToBackend = async (promoData) => {
        try {
            const res = await createPromocode(promoData);
            if (res && res.data) {
                setProductToAdd((prevState) => {
                    return { ...prevState, promocode: res.data, ready: true };
                });
            }
        } catch (error) {
            console.log(error);
            if (props && props.notify) {
                props.notify('error');
            }
        }
    };

    const productSubmission = () => {
        const token = Cookies.get('refreshToken');
        // e.preventDefault();

        if (
            productToAdd &&
            !(productToAdd.price === null || productToAdd.price === '')
        ) {
            // if (productMedia.length === 0) {
            //     setMinimumPictureMessage(true);
            // } else {
            if (productToAdd.promocode === 'Custom' && additionalProductInfo) {
                sendPromoToBackend({
                    ...additionalProductInfo.customPromo,
                    user:
                        type === 'addProduct'
                            ? productToAdd.user
                            : type === 'editProduct' && productToAdd.user
                            ? productToAdd.user._id
                            : null,
                });
            }
            setProductToAdd((prevState) => {
                return {
                    ...prevState,
                    // KEEP MEDIA PROPETY NULL. IMAGE FILES WILL BE SEPARATELY ATTACHED TO FORMDATA
                    media: prevState.media,
                    category: prevState.category && prevState.category._id,
                    promocode:
                        prevState.promocode === 'None'
                            ? null
                            : prevState.promocode,
                    ...(prevState.promocode !== 'Custom' && { ready: true }),
                    ...(type === 'addProduct' && {
                        instantDelivery: addProductCodes,
                    }),
                };
            });
        }

        // }
    };

    const productSent = (res) => {
        handleClose();
        handleClose2();
        handleClose3();
        handleClose4();
        setProductToAdd(productFormat);
        setAdditionalProductInfo(additionalProductInfoFormat);
        setProductMedia([]);
        setMinimumPictureMessage(false);
        setMaximumPictureMessage(false);
        setMobilePictureIndexes([]);
        setImagesToDelete([]);
        setAddProductCodes(false);
        setNewPictureCodes([]);
        reset1();
        reset2();
        reset4();
        // handleOpen5();
        console.log('PRODUCT ADDED RES.DATA: ' + JSON.stringify(res.data));
        // res.data ? setAddedProduct(res.data) : null;
        // type && type === 'addProduct' && appendNewProduct && res.data
        //     ? appendNewProduct(res.data)
        //     : null;
    };

    // Submit modals functions
    const submitModal1 = (data) => {
        console.log('SUBMITTED MODAL 1');
        console.log('DATA1: ' + JSON.stringify(data));
        if (allCategories !== null) {
            setProductToAdd((prevState) => {
                return {
                    ...prevState,
                    // name: data.name && data.name,
                    // description: data.description && data.description,
                    type: data.productType && data.productType,
                    category:
                        allCategories[data.category] &&
                        allCategories[data.category],
                };
            });

            if (productToAdd && productToAdd.user) {
                const getPromocodesData = async (id) => {
                    try {
                        const response = await getPromocodesOfUser(id);
                        if (response) {
                            if (response.data) {
                                // let filteredPromocodes = response.data.filter(
                                //     (promocode) => (promocode.user = user)
                                // );
                                // if (filteredPromocodes.length > 0) {
                                //     setPromocodes(filteredPromocodes);
                                // } else {
                                //     setPromocodes([]);
                                // }

                                let allPromocodesObject = {};
                                for (const promocode of response.data) {
                                    if (
                                        promocode &&
                                        promocode.code &&
                                        promocode.discount
                                    ) {
                                        allPromocodesObject[
                                            `${promocode.code} : ${promocode.discount}%`
                                        ] = promocode;
                                    }
                                }
                                setPromocodes(allPromocodesObject);
                            } else {
                                setPromocodes([]);
                            }
                        }
                    } catch (err) {
                        console.log(err);
                        setPromocodes([]);
                    }
                };

                let userId = null;
                if (type === 'addProduct') {
                    userId = productToAdd.user;
                }
                if (type === 'editProduct') {
                    userId = productToAdd.user._id;
                }
                if (userId) {
                    getPromocodesData(userId);
                }
            }

            handleClose();
            handleOpen2();
        }
    };

    const submitModal2 = (data) => {
        console.log('SUBMITTED MODAL 2');

        let customPromoValid = true;
        if (
            data.promocode === 'Custom' &&
            data.customPromoCode &&
            data.customPromoName &&
            data.customPromoDiscount &&
            data.customPromoExpiryDate
        ) {
            try {
                let isoDate = new Date(
                    data.customPromoExpiryDate
                ).toISOString();
                if (isoDate && isoDate.length > 0 && isoDate.slice(0, 10)) {
                    setAdditionalProductInfo((prevState) => {
                        return {
                            ...prevState,
                            customPromo: {
                                ...prevState.customPromo,
                                code: data.customPromoCode,
                                name: data.customPromoName,
                                discount: data.customPromoDiscount,
                                expiryDate: isoDate,
                            },
                            customPromoDateSimpleFormat: isoDate.slice(0, 10),
                        };
                    });
                } else {
                    customPromoValid = false;
                }
            } catch (error) {
                console.log(error);
                customPromoValid = false;
            }
        }
        console.log('BEFORE IF2');
        if (promocodes !== null) {
            console.log('INSIDE IF2');
            let tempPromocode = null;
            if (customPromoValid) {
                if (promocodes && promocodes[data.promocode]) {
                    tempPromocode = promocodes[data.promocode];
                } else if (
                    data.promocode === 'None' ||
                    data.promocode === 'Custom'
                ) {
                    tempPromocode = data.promocode;
                } else {
                    tempPromocode = null;
                }
            }
            setProductToAdd((prevState) => {
                return {
                    ...prevState,
                    price: (data.price || data.price === 0) && data.price,
                    promocode: tempPromocode,
                    quantity: data.quantity && data.quantity,
                };
            });
            handleClose2();
            handleOpen3();
        }
    };

    const submitModal4 = (data) => {
        console.log('SUBMITTED MODAL 4');
        console.log('DATA: ' + JSON.stringify(data));
        let tempCodesArray = [];
        if (data && data.codesConfirmation === true) {
            for (let property in data) {
                if (property !== 'codesConfirmation') {
                    tempCodesArray.push(data[property]);
                }
            }
            tempCodesArray.length > 0 &&
                setProductToAdd((prevState) => {
                    return {
                        ...prevState,
                        codes: tempCodesArray,
                    };
                });
        }

        if (data && data.codesConfirmation === false) {
            setProductToAdd((prevState) => {
                return {
                    ...prevState,
                    codes: null,
                };
            });
        }

        // Check again to make sure validations are present before submitting
        if (addProductCodes) {
            if (
                Object.keys(productCodesValidations).length ===
                productToAdd.quantity
            ) {
                productSubmission();
            } else {
                return;
            }
        } else {
            productSubmission();
        }
    };

    // Picture codes sorting based on index
    const sortPictureCodes = (pictureCodes) => {
        if (pictureCodes && pictureCodes.length > 0) {
            let sortedPictureCodes = [...Array(pictureCodes.length)];
            for (let i = 0; i < pictureCodes.length; i++) {
                const pictureCodeObject = pictureCodes[i];
                sortedPictureCodes[pictureCodeObject.index] =
                    pictureCodeObject.code;
            }
            return sortedPictureCodes;
        }
        return null;
    };

    // Upload all new pictures
    const uploadNewPictures = () => {
        if (productMedia && productMedia.length > 0) {
            for (let i = 0; i < productMedia.length; i++) {
                let editPictureFormData = new FormData();
                const fileObject = productMedia[i];

                if (fileObject && fileObject.type === 'localPicture') {
                    console.log('TYPE LOCAL');
                    editPictureFormData.append('image', fileObject.pictureFile);
                    uploadNewPictureToBackend(editPictureFormData, i);
                } else if (
                    fileObject.type === 'backendPicture' &&
                    fileObject.code
                ) {
                    console.log('TYPE BACKEND');
                    setNewPictureCodes((prevCodes) => [
                        ...prevCodes,
                        { code: fileObject.code, index: i },
                    ]);
                    console.log(
                        'ADDED TO NEW PICTURE CODES BACK: ' + fileObject.code
                    );
                }
            }
        }
    };

    // Send product codes to backend
    const sendCodesToBackend = async (res, productCodes, operationType) => {
        for (let i = 0; i < productCodes.length; i++) {
            const code = productCodes[i];
            // Add Product Code referencing productId
            try {
                await addProductCode(res.data._id, code);
            } catch (err) {
                console.log(err);
            }
        }
        productSent(res);
        if (props && props.notify && operationType) {
            if (operationType === 'add') {
                props.notify('add');
            } else if (operationType === 'edit') {
                props.notify('edit');
            }
        }
    };

    // Add yup product codes validations
    useEffect(() => {
        if (!addProductCodes) {
            setProductCodesValidations({});
        }
        if (addProductCodes && productToAdd.quantity > 0) {
            let tempObject = {};
            for (let i = 0; i < productToAdd.quantity; i++) {
                tempObject[i] = yup
                    .string('Code must be a string')
                    .min(5, 'Code must be at least 5 characters')
                    .max(100, 'Code must be at most 100 characters')
                    .required('Code is required')
                    .test(
                        'Valid Code Value',
                        'Code Value must contain letters and numbers only',
                        (value) => value.match(/^[a-zA-Z0-9]+$/)
                    );
            }
            setProductCodesValidations(tempObject);
        }
    }, [addProductCodes, productToAdd.quantity]);

    // Reset Added Product after unmount
    useEffect(() => {
        return () => {
            console.log('SETTING ADDED PRODUCT TO NULL');
            setAddedProduct(null);
        };
    }, []);

    useEffect(() => {
        return () => {
            productMedia.map((pictureObject) => {
                console.log('REVOKING OBJECT URL');
                URL.revokeObjectURL(pictureObject.pictureUrl);
            });
        };
    }, []);

    useEffect(() => {
        if (productToAdd.ready) {
            if (type && type === 'addProduct') {
                uploadNewPictures();
            }
            if (type && type === 'editProduct') {
                if (imagesToDelete && imagesToDelete.length > 0) {
                    deletePicturesFromBackend(imagesToDelete);
                } else {
                    uploadNewPictures();
                }
            }
        }
    }, [productToAdd]);

    // When all codes for new pictures are received from the backend
    useEffect(() => {
        if (
            productToAdd &&
            productToAdd.ready &&
            newPictureCodes.length === productMedia.length
        ) {
            console.log(
                'ALL READY, newPictureCodes: ' + JSON.stringify(newPictureCodes)
            );
            let sortedPictureCodes = sortPictureCodes(newPictureCodes);
            if (sortedPictureCodes) {
                console.log('SOrted picture codes: ' + sortedPictureCodes);
                console.log('ALL PICTURE CODES RECEIVED');

                if (type === 'addProduct') {
                    const sendNewProductData = async (productData) => {
                        try {
                            let productCodes = productData.codes;

                            delete productData.ready;
                            delete productData.codes;

                            const response = await createProduct(productData);
                            if (
                                response &&
                                response.data &&
                                addProductCodes &&
                                productCodes &&
                                productCodes.length > 0
                            ) {
                                sendCodesToBackend(
                                    response,
                                    productCodes,
                                    'add'
                                );
                            } else if (
                                response &&
                                response.data &&
                                !addProductCodes
                            ) {
                                productSent(response);
                                if (props && props.notify) {
                                    props.notify('add');
                                }
                            }
                        } catch (error) {
                            console.log(error);
                        }
                    };

                    // let formData = new FormData();
                    // formData.append(
                    //     'product',
                    //     JSON.stringify({
                    //         productToAdd,
                    //         media: sortedPictureCodes,
                    //     })
                    // );
                    sendNewProductData({
                        ...productToAdd,
                        media: sortedPictureCodes,
                    });
                } else if (type === 'editProduct') {
                    const sendUpdatedProductData = async (
                        productData,
                        productId
                    ) => {
                        try {
                            delete productData.ready;
                            delete productData.codes;
                            const response = await updateProduct(
                                productData,
                                productId
                            );
                            if (response) {
                                productSent(response);
                                if (props && props.notify) {
                                    props.notify('edit');
                                }
                            }
                        } catch (error) {
                            console.log(error);
                        }
                    };
                    if (
                        productToAdd &&
                        productToAdd._id &&
                        productToAdd.user &&
                        productToAdd.user._id
                    ) {
                        sendUpdatedProductData(
                            {
                                product: {
                                    ...productToAdd,
                                    media: sortedPictureCodes,
                                    user: productToAdd.user._id,
                                },
                            },
                            productToAdd._id
                        );
                    }
                }
            }
        }
    }, [newPictureCodes]);

    // Hide maximum / minimum picture message when product media changes
    useEffect(() => {
        if (productMedia && productMedia.length > 0) {
            setMinimumPictureMessage(false);
        }
        if (productMedia && productMedia.length <= 7) {
            setMaximumPictureMessage(false);
        }
    }, [productMedia]);

    // Get all categories
    useEffect(() => {
        const getAllCategoriesData = async () => {
            try {
                const response = await getAllCategories();
                if (response && response.data) {
                    let allCategoriesTempObject = {};
                    for (const category of response.data) {
                        allCategoriesTempObject[category.name] = category;
                    }
                    setAllCategories(allCategoriesTempObject);
                }
            } catch (err) {
                console.log(err);
            }
        };
        getAllCategoriesData();
    }, []);

    // Get all subcategories
    // useEffect(() => {
    //     if (
    //         productToAdd.category &&
    //         productToAdd.category.subcategories !== undefined
    //     ) {
    //         setSubCategories([...productToAdd.category.subcategories]);
    //     }
    // }, [productToAdd.category]);

    // Create empty product codes array when user approves
    useEffect(() => {
        if (addProductCodes) {
            let emptyArray = [];
            for (let i = 0; i < productToAdd.quantity; i++) {
                emptyArray.push('');
            }
            setProductToAdd((prevState) => {
                return {
                    ...prevState,
                    codes: [...emptyArray],
                };
            });
        } else {
            setProductToAdd((prevState) => {
                return {
                    ...prevState,
                    codes: null,
                };
            });
        }
    }, [addProductCodes]);

    if (type === 'addProduct') {
        // console.log('Product to Add: ' + JSON.stringify(productToAdd));
        // console.log(
        //     'Additional Product Info: ' + JSON.stringify(additionalProductInfo)
        // );
    } else if (type === 'editProduct') {
        // console.log('Product to Edit: ' + JSON.stringify(productToAdd));
        // console.log(
        //     'Additional Product Info EDIT: ' +
        //         JSON.stringify(additionalProductInfo)
        // );
        console.log('New Picture Codes: ' + JSON.stringify(newPictureCodes));
        console.log('Pictures to delete: ' + JSON.stringify(imagesToDelete));
        // console.log('PROMOCODES: ' + JSON.stringify(promocodes));
    }
    // console.log('Product Media: ' + JSON.stringify(productMedia));
    // console.log('Images to delete: ' + JSON.stringify(imagesToDelete));
    // console.log('New picture codes: ' + JSON.stringify(newPictureCodes));
    // console.log('Promocodes: ' + JSON.stringify(promocodes));
    console.log('PRODUCT REGION: ' + productToAdd.region);

    if (
        (type === 'editProduct' && productToAdd && productToAdd._id) ||
        type === 'addProduct'
    ) {
        return (
            <Box
                sx={{
                    width: '100%',
                    ...(type === 'addProduct' && {
                        display: 'flex',
                        flexDirection: 'row',
                        alignContent: 'center',
                        justifyContent: 'end',
                        marginBottom: '1rem',
                    }),
                }}
            >
                {type === 'addProduct' ? (
                    <Button
                        variant='contained'
                        startIcon={<AddIcon />}
                        sx={{ fontWeight: 'bold' }}
                        onClick={handleOpen}
                    >
                        {`Добавить продукт`}
                    </Button>
                ) : null}

                {type === 'editProduct' ? (
                    <MenuItem onClick={handleOpen}>
                        <EditRoundedIcon sx={{ mr: 2 }} /> Редактировать{' '}
                    </MenuItem>
                ) : null}

                <Modal
                    open={open}
                    keepMounted
                    onClose={handleClose}
                    aria-labelledby='add product form'
                    aria-describedby='form to add new product details'
                >
                    <Box sx={{ ...modalStyle }}>
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'end',
                            }}
                        >
                            <IconButton
                                aria-label='close'
                                onClick={() => {
                                    handleClose();
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <Box
                            sx={{
                                margin: '0',
                                padding: '0',
                                width: '100%',
                                marginBottom: '1rem',
                                textAlign: 'center',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: 'bold',
                                    fontSize: '1.6rem',
                                    color: `${theme.palette.text.primary}`,
                                }}
                            >
                                {type === 'addProduct'
                                    ? 'Добавить продукт'
                                    : type === 'editProduct' &&
                                      productToAdd &&
                                      productToAdd._id
                                    ? `Редактирование товара: ${productToAdd._id}`
                                    : null}
                            </Typography>
                        </Box>
                        <form onSubmit={handleSubmit1(submitModal1)}>
                            <Box
                                sx={{
                                    width: '100%',
                                    rowGap: '1rem',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                }}
                            >
                                {type === 'addProduct' && (
                                    <TextField
                                        required
                                        label='ID пользователя'
                                        variant='outlined'
                                        sx={{ width: '100%' }}
                                        error={errors1.user && true}
                                        helperText={
                                            errors1.user?.message &&
                                            errors1.user?.message
                                        }
                                        {...register1('user')}
                                        value={
                                            productToAdd.user
                                                ? productToAdd.user
                                                : ''
                                        }
                                        onChange={(e) => {
                                            setProductToAdd((prevState) => {
                                                return {
                                                    ...prevState,
                                                    user: e.target.value,
                                                };
                                            });
                                        }}
                                    />
                                )}
                                <TextField
                                    required
                                    label='Имя'
                                    variant='outlined'
                                    sx={{ width: '100%' }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <IconButton
                                                    onClick={() => {
                                                        setShowTitleEmojis(
                                                            (prevState) =>
                                                                !prevState
                                                        );
                                                    }}
                                                >
                                                    <EmojiEmotionsIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    error={
                                        (errors1.name ||
                                            productToAdd.name.length > 50) &&
                                        true
                                    }
                                    helperText={
                                        errors1.name?.message
                                            ? errors1.name?.message
                                            : productToAdd.name.length > 50
                                            ? 'name must be at most 50 characters'
                                            : null
                                    }
                                    {...register1('name')}
                                    value={
                                        productToAdd.name && productToAdd.name
                                    }
                                    onChange={(e) => {
                                        setProductToAdd((prevState) => {
                                            return {
                                                ...prevState,
                                                name: e.target.value,
                                            };
                                        });
                                    }}
                                />
                                {showTitleEmojis && (
                                    <Box
                                        sx={{
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Picker
                                            data={data}
                                            theme={theme.palette.mode}
                                            onEmojiSelect={(emoji) => {
                                                setProductToAdd((prevState) => {
                                                    return {
                                                        ...prevState,
                                                        name: prevState.name
                                                            ? prevState.name +
                                                              emoji.native
                                                            : emoji.native,
                                                    };
                                                });
                                            }}
                                        />
                                    </Box>
                                )}
                                <TextField
                                    required
                                    label='Описание'
                                    variant='outlined'
                                    sx={{ width: '100%' }}
                                    multiline
                                    rows={3}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <IconButton
                                                    onClick={() => {
                                                        setShowDescriptionEmojis(
                                                            (prevState) =>
                                                                !prevState
                                                        );
                                                    }}
                                                >
                                                    <EmojiEmotionsIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    error={
                                        (errors1.description ||
                                            productToAdd.description.length >
                                                300) &&
                                        true
                                    }
                                    helperText={
                                        errors1.description?.message
                                            ? errors1.description?.message
                                            : productToAdd.description.length >
                                              300
                                            ? 'description must be at most 300 characters'
                                            : null
                                    }
                                    {...register1('description')}
                                    value={
                                        productToAdd.description &&
                                        productToAdd.description
                                    }
                                    onChange={(e) => {
                                        setProductToAdd((prevState) => {
                                            return {
                                                ...prevState,
                                                description: e.target.value,
                                            };
                                        });
                                    }}
                                />
                                {showDescriptionEmojis && (
                                    <Box
                                        sx={{
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Picker
                                            data={data}
                                            theme={theme.palette.mode}
                                            onEmojiSelect={(emoji) => {
                                                setProductToAdd((prevState) => {
                                                    return {
                                                        ...prevState,
                                                        description:
                                                            prevState.description
                                                                ? prevState.description +
                                                                  emoji.native
                                                                : emoji.native,
                                                    };
                                                });
                                            }}
                                        />
                                    </Box>
                                )}
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        '@media (max-width: 500px)': {
                                            flexDirection: 'column',
                                            rowGap: '1rem',
                                        },
                                    }}
                                >
                                    <FormControl
                                        sx={{
                                            width: '48%',
                                            '@media (max-width: 500px)': {
                                                width: '100%',
                                            },
                                        }}
                                    >
                                        <InputLabel
                                            id={`product${domElementType}-type-selection-label`}
                                        >
                                            Тип
                                        </InputLabel>
                                        <Select
                                            required
                                            labelId={`product${domElementType}-type-selection-label`}
                                            defaultValue=''
                                            label='Тип'
                                            error={errors1.productType && true}
                                            {...register1('productType')}
                                            value={
                                                productToAdd.type
                                                    ? productToAdd.type
                                                    : ''
                                            }
                                            onChange={(e) => {
                                                setProductToAdd((prevState) => {
                                                    return {
                                                        ...prevState,
                                                        type: e.target.value,
                                                    };
                                                });
                                            }}
                                        >
                                            {types && types.length > 0
                                                ? types.map((item, index) => (
                                                      <MenuItem
                                                          key={index}
                                                          value={item}
                                                      >
                                                          {item}
                                                      </MenuItem>
                                                  ))
                                                : null}
                                        </Select>
                                        {errors1.productType?.message ? (
                                            <FormHelperText
                                                sx={{
                                                    color: `${theme.palette.error.main}`,
                                                }}
                                            >
                                                {errors1.productType?.message}
                                            </FormHelperText>
                                        ) : null}
                                    </FormControl>
                                    <FormControl
                                        sx={{
                                            width: '48%',
                                            '@media (max-width: 500px)': {
                                                width: '100%',
                                            },
                                        }}
                                    >
                                        <InputLabel
                                            id={`product${domElementType}-category-selection-label`}
                                        >
                                            Категория
                                        </InputLabel>
                                        <Select
                                            required={
                                                allCategories
                                                    ? Object.keys(allCategories)
                                                          .length > 0
                                                    : false
                                            }
                                            labelId={`product${domElementType}-category-selection-label`}
                                            defaultValue=''
                                            label='Категория'
                                            error={errors1.category && true}
                                            {...register1('category')}
                                            // value={
                                            //     productToAdd.category &&
                                            //     productToAdd.category[0] &&
                                            //     productToAdd.category[0].name
                                            //         ? productToAdd.category[0].name
                                            //         : ''
                                            // }
                                            value={
                                                productToAdd.category &&
                                                productToAdd.category.name
                                                    ? productToAdd.category.name
                                                    : ''
                                            }
                                            onChange={(e) => {
                                                setProductToAdd((prevState) => {
                                                    return {
                                                        ...prevState,
                                                        category:
                                                            allCategories[
                                                                e.target.value
                                                            ],
                                                    };
                                                });
                                            }}
                                        >
                                            {allCategories &&
                                            Object.keys(allCategories).length >
                                                0 ? (
                                                Object.keys(allCategories).map(
                                                    (item, index) => (
                                                        <MenuItem
                                                            key={index}
                                                            value={item}
                                                        >
                                                            {item}
                                                        </MenuItem>
                                                    )
                                                )
                                            ) : (
                                                <MenuItem value={''} disabled>
                                                    No Categories
                                                </MenuItem>
                                            )}
                                        </Select>
                                        {errors1.category?.message ? (
                                            <FormHelperText
                                                sx={{
                                                    color: `${theme.palette.error.main}`,
                                                }}
                                            >
                                                {errors1.category?.message}
                                            </FormHelperText>
                                        ) : null}
                                    </FormControl>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'end',
                                    marginTop: '2rem',
                                }}
                            >
                                <Button
                                    type='submit'
                                    variant='contained'
                                    disabled={
                                        productToAdd.name.length > 50 ||
                                        productToAdd.description.length > 300
                                    }
                                    sx={{ fontWeight: 'bold', width: '25%' }}
                                    endIcon={<ArrowForwardIosRoundedIcon />}
                                >
                                    Далее
                                </Button>
                            </Box>
                        </form>
                    </Box>
                </Modal>
                <Modal
                    open={open2}
                    onClose={handleClose2}
                    aria-labelledby='add product form'
                    aria-describedby='form to add new product details'
                >
                    <Box sx={modalStyle}>
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'end',
                            }}
                        >
                            <IconButton
                                aria-label='close'
                                onClick={() => {
                                    handleClose2();
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <Box
                            sx={{
                                margin: '0',
                                padding: '0',
                                width: '100%',
                                marginBottom: '1rem',
                                textAlign: 'center',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: 'bold',
                                    fontSize: '1.6rem',
                                    color: `${theme.palette.text.primary}`,
                                }}
                            >
                                {type === 'addProduct'
                                    ? 'Добавить продукт'
                                    : type === 'editProduct' &&
                                      productToAdd &&
                                      productToAdd._id
                                    ? `Редактирование товара: ${productToAdd._id}`
                                    : null}
                            </Typography>
                        </Box>
                        <form onSubmit={handleSubmit2(submitModal2)}>
                            <Box
                                sx={{
                                    width: '100%',
                                    rowGap: '1rem',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        '@media (max-width: 500px)': {
                                            flexDirection: 'column',
                                            rowGap: '1rem',
                                        },
                                    }}
                                >
                                    <TextField
                                        required
                                        label='Цена'
                                        variant='outlined'
                                        sx={{
                                            width: '48%',
                                            '@media (max-width: 500px)': {
                                                width: '100%',
                                            },
                                        }}
                                        error={errors2.price && true}
                                        helperText={errors2.price?.message}
                                        {...register2('price')}
                                        value={
                                            productToAdd.price ||
                                            productToAdd.price === 0
                                                ? productToAdd.price
                                                : ''
                                        }
                                        onChange={(e) => {
                                            setProductToAdd((prevState) => {
                                                return {
                                                    ...prevState,
                                                    price: e.target.value,
                                                };
                                            });
                                        }}
                                    />
                                    <TextField
                                        required
                                        type='number'
                                        label='Количество'
                                        variant='outlined'
                                        sx={{
                                            width: '48%',
                                            '@media (max-width: 500px)': {
                                                width: '100%',
                                            },
                                        }}
                                        error={errors2.quantity && true}
                                        helperText={errors2.quantity?.message}
                                        {...register2('quantity')}
                                        value={
                                            productToAdd.quantity
                                                ? productToAdd.quantity
                                                : ''
                                        }
                                        onChange={(e) => {
                                            setProductToAdd((prevState) => {
                                                return {
                                                    ...prevState,
                                                    quantity: e.target.value,
                                                };
                                            });
                                        }}
                                    />
                                </Box>
                                <FormControl
                                    sx={{
                                        width: '100%',
                                    }}
                                >
                                    <InputLabel
                                        id={`product${domElementType}-currency-selection-label`}
                                    >
                                        Валюта
                                    </InputLabel>
                                    <Select
                                        required
                                        labelId={`product${domElementType}-currency-selection-label`}
                                        label='Валюта'
                                        defaultValue=''
                                        error={errors2.currency && true}
                                        {...register2('currency')}
                                        value={
                                            productToAdd.currency
                                                ? productToAdd.currency
                                                : ''
                                        }
                                        onChange={(e) => {
                                            setProductToAdd((prevState) => {
                                                return {
                                                    ...prevState,
                                                    currency: e.target.value,
                                                };
                                            });
                                        }}
                                    >
                                        <MenuItem value={'RUB'}>RUB</MenuItem>
                                        <MenuItem value={'USD'}>USD</MenuItem>
                                    </Select>
                                    {errors2.currency?.message ? (
                                        <FormHelperText
                                            sx={{
                                                color: `${theme.palette.error.main}`,
                                            }}
                                        >
                                            {errors2.currency?.message}
                                        </FormHelperText>
                                    ) : null}
                                </FormControl>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        '@media (max-width: 500px)': {
                                            flexDirection: 'column',
                                            rowGap: '1rem',
                                        },
                                    }}
                                >
                                    <FormControl
                                        sx={{
                                            width: '48%',
                                            '@media (max-width: 500px)': {
                                                width: '100%',
                                            },
                                        }}
                                    >
                                        <InputLabel
                                            id={`product${domElementType}-promocode-selection-label`}
                                        >
                                            Промокод
                                        </InputLabel>
                                        <Select
                                            required
                                            labelId={`product${domElementType}-promocode-selection-label`}
                                            label='Промокод'
                                            defaultValue=''
                                            error={errors2.promocode && true}
                                            {...register2('promocode')}
                                            value={
                                                productToAdd.promocode &&
                                                productToAdd.promocode.code &&
                                                productToAdd.promocode.discount
                                                    ? productToAdd.promocode
                                                          .code +
                                                      ' : ' +
                                                      productToAdd.promocode
                                                          .discount +
                                                      '%'
                                                    : productToAdd.promocode ===
                                                          'None' ||
                                                      productToAdd.promocode ===
                                                          'Custom'
                                                    ? productToAdd.promocode
                                                    : ''
                                            }
                                            onChange={(e) => {
                                                setProductToAdd((prevState) => {
                                                    return {
                                                        ...prevState,
                                                        promocode:
                                                            e.target.value ===
                                                                'None' ||
                                                            e.target.value ===
                                                                'Custom'
                                                                ? e.target.value
                                                                : promocodes[
                                                                      e.target
                                                                          .value
                                                                  ],
                                                    };
                                                });
                                            }}
                                        >
                                            <MenuItem value={'None'}>
                                                None
                                            </MenuItem>
                                            <MenuItem value={'Custom'}>
                                                Custom
                                            </MenuItem>
                                            {promocodes &&
                                            Object.keys(promocodes).length > 0
                                                ? Object.keys(promocodes).map(
                                                      (item, index) => (
                                                          <MenuItem
                                                              key={index}
                                                              value={item}
                                                          >
                                                              {item}
                                                          </MenuItem>
                                                      )
                                                  )
                                                : null}
                                        </Select>
                                        {errors2.promocode?.message ? (
                                            <FormHelperText
                                                sx={{
                                                    color: `${theme.palette.error.main}`,
                                                }}
                                            >
                                                {errors2.promocode?.message}
                                            </FormHelperText>
                                        ) : null}
                                    </FormControl>
                                    <Controller
                                        defaultValue={productToAdd.region ?? ''}
                                        render={({
                                            field: { onChange, ...field },
                                        }) => {
                                            return (
                                                <Autocomplete
                                                    disablePortal
                                                    id={`product${domElementType}-region-selection`}
                                                    options={regions}
                                                    getOptionLabel={(option) =>
                                                        option && option.name
                                                            ? option.name
                                                            : option
                                                    }
                                                    sx={{ width: '48%' }}
                                                    isOptionEqualToValue={(
                                                        option,
                                                        value
                                                    ) => {
                                                        return (
                                                            option.name ===
                                                            value
                                                        );
                                                    }}
                                                    value={
                                                        productToAdd.region &&
                                                        productToAdd.region
                                                    }
                                                    onChange={(e, newValue) => {
                                                        setProductToAdd(
                                                            (prevState) => {
                                                                return {
                                                                    ...prevState,
                                                                    region:
                                                                        newValue &&
                                                                        newValue.name
                                                                            ? newValue.name
                                                                            : newValue,
                                                                };
                                                            }
                                                        );
                                                        onChange(
                                                            newValue &&
                                                                newValue.name
                                                                ? newValue.name
                                                                : newValue
                                                        );
                                                    }}
                                                    renderInput={(params) => {
                                                        return (
                                                            <TextField
                                                                required
                                                                label='Регион'
                                                                error={
                                                                    errors2.region &&
                                                                    true
                                                                }
                                                                helperText={
                                                                    errors2
                                                                        .region
                                                                        ?.message
                                                                }
                                                                {...params}
                                                            />
                                                        );
                                                    }}
                                                />
                                            );
                                        }}
                                        name='region'
                                        control={control2}
                                        rules={{ required: true }}
                                    />
                                </Box>
                                {productToAdd.promocode &&
                                productToAdd.promocode === 'Custom' ? (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            rowGap: '1rem',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                                '@media (max-width: 550px)': {
                                                    flexDirection: 'column',
                                                    rowGap: '1rem',
                                                },
                                            }}
                                        >
                                            <TextField
                                                required
                                                label='Custom Promocode'
                                                variant='outlined'
                                                sx={{
                                                    width: '48%',
                                                    '@media (max-width: 550px)':
                                                        {
                                                            width: '100%',
                                                        },
                                                }}
                                                error={
                                                    errors2.customPromoCode &&
                                                    true
                                                }
                                                helperText={
                                                    errors2.customPromoCode
                                                        ?.message
                                                }
                                                {...register2(
                                                    'customPromoCode'
                                                )}
                                                value={
                                                    additionalProductInfo.customPromo &&
                                                    additionalProductInfo
                                                        .customPromo.code
                                                        ? additionalProductInfo
                                                              .customPromo.code
                                                        : ''
                                                }
                                                onChange={(e) => {
                                                    setAdditionalProductInfo(
                                                        (prevState) => {
                                                            return {
                                                                ...prevState,
                                                                customPromo: {
                                                                    ...prevState.customPromo,
                                                                    code: e
                                                                        .target
                                                                        .value,
                                                                },
                                                            };
                                                        }
                                                    );
                                                }}
                                            />
                                            <TextField
                                                required
                                                label='Promocode Name'
                                                variant='outlined'
                                                sx={{
                                                    width: '48%',
                                                    '@media (max-width: 550px)':
                                                        {
                                                            width: '100%',
                                                        },
                                                }}
                                                error={
                                                    errors2.customPromoName &&
                                                    true
                                                }
                                                helperText={
                                                    errors2.customPromoName
                                                        ?.message
                                                }
                                                {...register2(
                                                    'customPromoName'
                                                )}
                                                value={
                                                    additionalProductInfo.customPromo &&
                                                    additionalProductInfo
                                                        .customPromo.name
                                                        ? additionalProductInfo
                                                              .customPromo.name
                                                        : ''
                                                }
                                                onChange={(e) => {
                                                    setAdditionalProductInfo(
                                                        (prevState) => {
                                                            return {
                                                                ...prevState,
                                                                customPromo: {
                                                                    ...prevState.customPromo,
                                                                    name: e
                                                                        .target
                                                                        .value,
                                                                },
                                                            };
                                                        }
                                                    );
                                                }}
                                            />
                                        </Box>

                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                                alignItems: 'start',
                                                justifyContent: 'space-between',
                                                '@media (max-width: 500px)': {
                                                    flexDirection: 'column',
                                                    rowGap: '1rem',
                                                },
                                            }}
                                        >
                                            <TextField
                                                required
                                                type='number'
                                                label='Discount (%)'
                                                variant='outlined'
                                                sx={{
                                                    width: '48%',
                                                    '@media (max-width: 500px)':
                                                        {
                                                            width: '100%',
                                                        },
                                                }}
                                                error={
                                                    errors2.customPromoDiscount &&
                                                    true
                                                }
                                                helperText={
                                                    errors2.customPromoDiscount
                                                        ?.message
                                                }
                                                {...register2(
                                                    'customPromoDiscount'
                                                )}
                                                value={
                                                    additionalProductInfo
                                                        .customPromo.discount
                                                        ? additionalProductInfo
                                                              .customPromo
                                                              .discount
                                                        : ''
                                                }
                                                onChange={(e) => {
                                                    setAdditionalProductInfo(
                                                        (prevState) => {
                                                            return {
                                                                ...prevState,
                                                                customPromo: {
                                                                    ...prevState.customPromo,
                                                                    discount:
                                                                        e.target
                                                                            .value,
                                                                },
                                                            };
                                                        }
                                                    );
                                                }}
                                            />
                                            <TextField
                                                required
                                                type='date'
                                                label='Expiry Date'
                                                variant='outlined'
                                                sx={{
                                                    width: '48%',
                                                    '@media (max-width: 500px)':
                                                        {
                                                            width: '100%',
                                                        },
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={
                                                    errors2.customPromoExpiryDate &&
                                                    true
                                                }
                                                helperText={
                                                    errors2
                                                        .customPromoExpiryDate
                                                        ?.message
                                                }
                                                {...register2(
                                                    'customPromoExpiryDate'
                                                )}
                                                value={
                                                    additionalProductInfo.customPromoDateSimpleFormat
                                                        ? additionalProductInfo.customPromoDateSimpleFormat
                                                        : ''
                                                }
                                                InputProps={{
                                                    inputProps: {
                                                        max: '9999-12-31',
                                                    },
                                                }}
                                                onChange={(e) => {
                                                    try {
                                                        let isoDate = new Date(
                                                            e.target.value
                                                        ).toISOString();
                                                        setAdditionalProductInfo(
                                                            (prevState) => {
                                                                return {
                                                                    ...prevState,
                                                                    customPromo:
                                                                        {
                                                                            ...prevState.customPromo,
                                                                            expiryDate:
                                                                                isoDate,
                                                                        },
                                                                    customPromoDateSimpleFormat:
                                                                        isoDate &&
                                                                        isoDate.length >
                                                                            0 &&
                                                                        isoDate.slice(
                                                                            0,
                                                                            10
                                                                        )
                                                                            ? isoDate.slice(
                                                                                  0,
                                                                                  10
                                                                              )
                                                                            : '',
                                                                };
                                                            }
                                                        );
                                                    } catch (error) {
                                                        console.log(
                                                            'Error: ' + error
                                                        );
                                                        setAdditionalProductInfo(
                                                            (prevState) => {
                                                                return {
                                                                    ...prevState,
                                                                    customPromo:
                                                                        {
                                                                            ...prevState.customPromo,
                                                                            expiryDate:
                                                                                '',
                                                                        },
                                                                    customPromoDateSimpleFormat:
                                                                        '',
                                                                };
                                                            }
                                                        );
                                                    }
                                                }}
                                            />
                                        </Box>
                                        <Typography
                                            sx={{
                                                fontWeight: 'bold',
                                                fontSize: '1rem',
                                                color: `${theme.palette.text.primary}`,
                                                textAlign: 'center',
                                                width: '100%',
                                            }}
                                        >{`Global`}</Typography>
                                        <ToggleButtonGroup
                                            color='primary'
                                            value={
                                                additionalProductInfo &&
                                                additionalProductInfo.customPromo &&
                                                additionalProductInfo
                                                    .customPromo.global === true
                                                    ? 'yes'
                                                    : 'no'
                                            }
                                            exclusive
                                            onChange={(e) => {
                                                setAdditionalProductInfo(
                                                    (prevState) => {
                                                        return {
                                                            ...prevState,
                                                            customPromo: {
                                                                ...prevState.customPromo,
                                                                global:
                                                                    e.target
                                                                        .value ===
                                                                    'yes'
                                                                        ? true
                                                                        : false,
                                                            },
                                                        };
                                                    }
                                                );
                                            }}
                                            aria-label='Promocode Global Value'
                                            fullWidth
                                        >
                                            <ToggleButton value={'yes'}>
                                                Yes
                                            </ToggleButton>
                                            <ToggleButton value={'no'}>
                                                No
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </Box>
                                ) : null}
                            </Box>
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    marginTop: '2rem',
                                }}
                            >
                                {' '}
                                <Button
                                    variant='contained'
                                    sx={{ fontWeight: 'bold', width: '25%' }}
                                    startIcon={<ArrowBackIosRoundedIcon />}
                                    onClick={() => {
                                        handleClose2();
                                        handleOpen();
                                    }}
                                >
                                    Back
                                </Button>
                                <Button
                                    variant='contained'
                                    sx={{ fontWeight: 'bold', width: '25%' }}
                                    endIcon={<ArrowForwardIosRoundedIcon />}
                                    type='submit'
                                >
                                    Next
                                </Button>
                            </Box>
                        </form>
                    </Box>
                </Modal>
                <Modal
                    open={open3}
                    onClose={handleClose3}
                    aria-labelledby='add product form'
                    aria-describedby='form to add new product details'
                    keepMounted={true}
                >
                    <Box sx={modalStyle}>
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'end',
                            }}
                        >
                            <IconButton
                                aria-label='close'
                                onClick={() => {
                                    handleClose3();
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <Box
                            sx={{
                                margin: '0',
                                padding: '0',
                                width: '100%',
                                marginBottom: '1rem',
                                textAlign: 'center',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: 'bold',
                                    fontSize: '1.6rem',
                                    color: `${theme.palette.text.primary}`,
                                }}
                            >
                                {type === 'addProduct'
                                    ? 'Добавить продукт'
                                    : type === 'editProduct' &&
                                      productToAdd &&
                                      productToAdd._id
                                    ? `Редактирование товара: ${productToAdd._id}`
                                    : null}
                            </Typography>
                        </Box>
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                if (productMedia.length === 0) {
                                    setMinimumPictureMessage(true);
                                } else {
                                    if (type === 'addProduct') {
                                        handleClose3();
                                        handleOpen4();
                                    } else if (type === 'editProduct') {
                                        productSubmission();
                                    }
                                }
                            }}
                        >
                            <Box
                                sx={{
                                    margin: '0',
                                    padding: '0',
                                    width: '100%',
                                    textAlign: 'left',
                                    marginBottom:
                                        !minimumPictureMessage ||
                                        !maximumPictureMessage
                                            ? '1rem'
                                            : '0',
                                    marginLeft: '0.3rem',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: 'bold',
                                        fontSize: '1.1rem',
                                        color: `${theme.palette.text.primary}`,
                                    }}
                                >
                                    Добавить изображение <span> (Максимум 7)</span>
                                </Typography>
                            </Box>
                            <Typography
                                hidden={!minimumPictureMessage}
                                sx={{
                                    fontSize: '0.7rem',
                                    marginLeft: '0.3rem',
                                    color: `${theme.palette.error.main}`,
                                    marginBottom: minimumPictureMessage
                                        ? '1rem'
                                        : '0',
                                }}
                            >
                                Пожалуйста, загрузите 1 фотографию
                            </Typography>
                            <Typography
                                hidden={!maximumPictureMessage}
                                sx={{
                                    fontSize: '0.7rem',
                                    marginLeft: '0.3rem',
                                    color: `${theme.palette.error.main}`,
                                    marginBottom: maximumPictureMessage
                                        ? '1rem'
                                        : '0',
                                }}
                            >
                                Maximum 7 pictures allowed
                            </Typography>
                            <Typography
                                hidden={
                                    !(
                                        productToAdd.price === null ||
                                        productToAdd.price === ''
                                    )
                                }
                                sx={{
                                    fontSize: '0.7rem',
                                    marginLeft: '0.3rem',
                                    color: `${theme.palette.error.main}`,
                                    marginBottom:
                                        productToAdd.price === null ||
                                        productToAdd.price === ''
                                            ? '1rem'
                                            : '0',
                                }}
                            >
                                Пожалуйста, введите правильную цену товара
                            </Typography>

                            <Box
                                sx={{
                                    width: '100%',
                                    rowGap: '1rem',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Box
                                    className={`draggable-images-main-container${domElementType}`}
                                    sx={{
                                        width: '100%',
                                        height: 'fit-content',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        flexWrap: 'wrap',
                                        justifyContent: 'start',
                                        rowGap: '1rem',
                                        columnGap: '2%',
                                        '@media (max-width: 700px)': {
                                            columnGap: '4%',
                                        },
                                        '@media (max-width: 500px)': {
                                            columnGap: '0',
                                            flexDirection: 'column',
                                        },
                                    }}
                                >
                                    <Box
                                        className={`product-image-upload-container${domElementType}`}
                                        sx={{
                                            height: '90px',
                                            width: '32%',
                                            boxSizing: 'border-box',
                                            backgroundColor: 'transparent',
                                            borderRadius: '10px',
                                            padding: '0.3rem',
                                            '@media (max-width: 700px)': {
                                                width: '48%',
                                            },
                                            '@media (max-width: 500px)': {
                                                height: '110px',
                                                width: '100%',
                                            },
                                        }}
                                    >
                                        <input
                                            multiple
                                            hidden
                                            disabled={productMedia.length >= 7}
                                            accept='image/*'
                                            type='file'
                                            onChange={
                                                handleProductPictureUpload
                                            }
                                            onClick={(e) =>
                                                (e.target.value = null)
                                            }
                                            id={`product${domElementType}-image-upload-input`}
                                        />
                                        <label
                                            htmlFor={`product${domElementType}-image-upload-input`}
                                        >
                                            <Button
                                                disabled={
                                                    productMedia.length >= 7
                                                }
                                                component='span'
                                                variant='vendorAddProductPicture'
                                                sx={{ position: 'relative' }}
                                            >
                                                <CameraAltIcon
                                                    sx={{
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        translate: '-50% -50%',
                                                    }}
                                                />
                                            </Button>
                                        </label>
                                    </Box>
                                    {productMedia.map(
                                        (pictureObject, index) => (
                                            <Box
                                                key={index}
                                                className={`container${domElementType}`}
                                                sx={{
                                                    height: '90px',
                                                    width: '32%',
                                                    boxSizing: 'border-box',
                                                    backgroundColor:
                                                        'transparent',
                                                    borderRadius: '10px',
                                                    padding: '0.3rem',
                                                    ...(mobilePictureIndexes.includes(
                                                        index
                                                    ) && { padding: '0.6rem' }),
                                                    '@media (max-width: 700px)':
                                                        {
                                                            width: '48%',
                                                        },
                                                    '@media (max-width: 500px)':
                                                        {
                                                            height: '110px',
                                                            width: '100%',
                                                        },
                                                }}
                                                onDragLeave={(e) =>
                                                    containerDragLeave(e, index)
                                                }
                                                onDragEnter={(e) =>
                                                    containerDragEnter(e, index)
                                                }
                                                onDragOver={(e) =>
                                                    e.preventDefault()
                                                }
                                            >
                                                <Box
                                                    className={`draggable${domElementType}`}
                                                    draggable
                                                    sx={{
                                                        backgroundImage: `url('${pictureObject.pictureUrl}')`,
                                                        ...(index === 0 && {
                                                            border: `2px solid ${theme.palette.primary.main}`,
                                                        }),
                                                        ...(mobilePictureIndexes.includes(
                                                            pictureObject.pictureUrl
                                                        ) && {
                                                            padding: '0.6rem',
                                                        }),
                                                        height: '100%',
                                                        width: '100%',
                                                        borderRadius: '10px',
                                                        boxSizing: 'border-box',
                                                        position: 'relative',
                                                        backgroundSize: 'cover',
                                                        backgroundRepeat:
                                                            'no-repeat',
                                                        backgroundPosition:
                                                            'center',
                                                        cursor: 'grab',
                                                    }}
                                                    onDragStart={() =>
                                                        draggableDragStart(
                                                            index
                                                        )
                                                    }
                                                    onTouchStart={() =>
                                                        touchStart(index)
                                                    }
                                                >
                                                    <IconButton
                                                        sx={{
                                                            position:
                                                                'absolute',
                                                            top: '0.1rem',
                                                            right: '0.2rem',
                                                            color: 'white',
                                                            opacity: '0.8',
                                                            zIndex: '1',
                                                            backgroundColor:
                                                                'rgba(0,0,0,0.5)',
                                                            width: '22px',
                                                            height: '22px',
                                                        }}
                                                        onClick={() =>
                                                            removeProductPicture(
                                                                pictureObject
                                                            )
                                                        }
                                                    >
                                                        <ClearRoundedIcon />
                                                    </IconButton>
                                                </Box>
                                            </Box>
                                        )
                                    )}
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    marginTop: '2rem',
                                }}
                            >
                                {' '}
                                <Button
                                    variant='contained'
                                    sx={{ fontWeight: 'bold', width: '25%' }}
                                    startIcon={<ArrowBackIosRoundedIcon />}
                                    onClick={() => {
                                        handleClose3();
                                        handleOpen2();
                                    }}
                                >
                                    Back
                                </Button>
                                <Button
                                    variant='contained'
                                    sx={{ fontWeight: 'bold', width: '25%' }}
                                    type='submit'
                                >
                                    {type === 'editProduct' ? 'Submit' : 'Next'}
                                </Button>
                            </Box>
                        </form>
                    </Box>
                </Modal>
                <Modal
                    open={open4}
                    onClose={handleClose4}
                    aria-labelledby='add product form'
                    aria-describedby='form to add new product, product codes section'
                >
                    <Box sx={modalStyle}>
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'end',
                            }}
                        >
                            <IconButton
                                aria-label='close'
                                onClick={() => {
                                    handleClose4();
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <Box
                            sx={{
                                margin: '0',
                                padding: '0',
                                width: '100%',
                                marginBottom: '1rem',
                                textAlign: 'center',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: 'bold',
                                    fontSize: '1.6rem',
                                    color: `${theme.palette.text.primary}`,
                                }}
                            >
                                {type === 'addProduct'
                                    ? 'Добавить продукт'
                                    : type === 'editProduct' &&
                                      productToAdd &&
                                      productToAdd._id
                                    ? `Редактировать продукт: ${productToAdd._id}`
                                    : null}
                            </Typography>
                        </Box>
                        <form onSubmit={handleSubmit4(submitModal4)}>
                            <Box
                                sx={{
                                    width: '100%',
                                    rowGap: '1rem',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <FormControl
                                    sx={{
                                        width: '100%',
                                    }}
                                >
                                    <InputLabel
                                        id={`product${domElementType}-codes-selection-question-label`}
                                    >
                                        Добавить коды продукта?
                                    </InputLabel>
                                    <Select
                                        required
                                        labelId={`product${domElementType}-codes-selection-question-label`}
                                        defaultValue=''
                                        label='Добавить коды продукта?'
                                        error={
                                            errors4.codesConfirmation && true
                                        }
                                        {...register4('codesConfirmation')}
                                        value={addProductCodes}
                                        onChange={(e) =>
                                            setAddProductCodes(e.target.value)
                                        }
                                    >
                                        <MenuItem value={true}>
                                            {'Yes'}
                                        </MenuItem>
                                        <MenuItem value={false}>
                                            {'No'}
                                        </MenuItem>
                                    </Select>
                                    {errors4.codesConfirmation?.message ? (
                                        <FormHelperText
                                            sx={{
                                                color: `${theme.palette.error.main}`,
                                            }}
                                        >
                                            {errors4.codesConfirmation?.message}
                                        </FormHelperText>
                                    ) : null}
                                </FormControl>
                                {addProductCodes === true
                                    ? [...Array(productToAdd.quantity)].map(
                                          (n, index) => (
                                              <TextField
                                                  key={index}
                                                  required
                                                  label={`Code ${index + 1}`}
                                                  variant='outlined'
                                                  sx={{ width: '100%' }}
                                                  error={errors4[index] && true}
                                                  helperText={
                                                      errors4[index]?.message
                                                  }
                                                  {...register4(`${index}`)}
                                                  value={
                                                      productToAdd.codes &&
                                                      (productToAdd.codes[
                                                          index
                                                      ] ||
                                                          productToAdd.codes[
                                                              index
                                                          ] === '')
                                                          ? productToAdd.codes[
                                                                index
                                                            ]
                                                          : ''
                                                  }
                                                  onChange={(e) => {
                                                      if (productToAdd.codes) {
                                                          let newArray = [
                                                              ...productToAdd.codes,
                                                          ];

                                                          newArray[index] =
                                                              e.target.value;

                                                          setProductToAdd(
                                                              (prevState) => {
                                                                  return {
                                                                      ...prevState,
                                                                      codes: newArray,
                                                                  };
                                                              }
                                                          );
                                                      }
                                                  }}
                                              />
                                          )
                                      )
                                    : null}
                                <Box
                                    sx={{
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        marginTop: '2rem',
                                    }}
                                >
                                    <Button
                                        variant='contained'
                                        sx={{
                                            fontWeight: 'bold',
                                            width: '25%',
                                        }}
                                        startIcon={<ArrowBackIosRoundedIcon />}
                                        onClick={() => {
                                            handleClose4();
                                            handleOpen3();
                                        }}
                                    >
                                        Back
                                    </Button>
                                    <Button
                                        variant='contained'
                                        sx={{
                                            fontWeight: 'bold',
                                            width: '25%',
                                        }}
                                        type='submit'
                                    >
                                        Submit
                                    </Button>
                                </Box>
                            </Box>
                        </form>
                    </Box>
                </Modal>
                <Modal
                    open={open5}
                    onClose={handleClose5}
                    aria-labelledby='add product confirmation'
                    aria-describedby='add product confirmation'
                    keepMounted={true}
                >
                    <Box sx={modalStyle}>
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'end',
                            }}
                        >
                            <IconButton
                                aria-label='close'
                                onClick={() => {
                                    handleClose5();
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <Box
                            sx={{
                                margin: '0',
                                padding: '0',
                                width: '100%',
                                marginBottom: '1rem',
                                textAlign: 'center',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: 'bold',
                                    fontSize: '1.6rem',
                                    color: `${theme.palette.text.primary}`,
                                }}
                            >
                                Your New Product
                            </Typography>
                        </Box>
                        <Box sx={{ marginBottom: '1rem' }}>
                            {/* <RecommendedProduct
                            variant={'productAddedConfirmation'}
                            product={addedProduct}
                        /> */}
                        </Box>
                    </Box>
                </Modal>
            </Box>
        );
    } else {
        return null;
    }
}
/* eslint-disable-line */
