import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { Box, List, ListItemText } from '@mui/material';
//
import { StyledNavItem, StyledNavItemIcon } from './styles';
import { useEffect, useState } from 'react';

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => (
          <NavItem key={item.title} item={item} />
        ))}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, path, icon, info, children } = item;

  // Check route and check it item is selected
  const isMatched = (path) => {
    return window.location.pathname === path;
  };

  const [selected, setSelected] = useState(false);

  useEffect(() => {
    setSelected(isMatched(path));
  }, [path]);

  return (
    <>
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        '&.active': {
          color: 'text.primary',
          bgcolor: 'action.selected',
          fontWeight: 'fontWeightBold',
        },
      }}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

      <ListItemText disableTypography primary={title} />

    </StyledNavItem>

      {(children && children) || (selected && children)
        ? children.map((subItem) => 
            <StyledNavItem
          key={subItem.title}
          component={RouterLink}
          to={subItem.path}
          sx={{
            '&.active': {
              color: 'text.primary',
              bgcolor: 'grey.300',
              fontWeight: 'fontWeightBold',
            },
          }}
        >
          <StyledNavItemIcon>{subItem.icon && subItem.icon}</StyledNavItemIcon>

          <span>---</span> <ListItemText disableTypography primary={subItem.title} />
        </StyledNavItem>

      )
        : null
    }

    </>

  );
}
