
import { useState, useEffect } from 'react';

// mui
import {
    Box,
    Button,
    Typography,
    Modal,
    TextField,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
    Card,
} from '@mui/material';

// form
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// icons
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import CameraAltRoundedIcon from '@mui/icons-material/CameraAltRounded';

// api
import { sendMediaToBack } from 'src/api/categories/sendMediaToBack';
import { createCategory } from 'src/api/categories/createCategory';
import { getAllTypes } from 'src/api/types/getTypes';



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    paddingX: 7,
    paddingTop: 1,
    paddingBottom: 4,
    borderRadius: '10px',
    maxHeight: '90vh',
    overflow: 'scroll',
    '@media (max-width: 650px)': {
        paddingX: 4,
        width: '90%',
    },
};

function AddCategoryModal({ notify }) {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [media, setMedia] = useState(null);
    const [banner, setBanner] = useState(null);

    const [submittedFormData, setSubmittedFormData] = useState(null);


    const schema = yup.object().shape({
        name: yup.string().max(60).required(),
        type: yup.array().min(1).required(),
        primaryType: yup.string().required(),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(schema),
    });

    // Get All Product Types
    const [productTypes, setProductTypes] = useState(null);
    const [selectedProductTypes, setSelectedProductTypes] = useState([]);
    const [allSelectedProductTypes, setAllSelectedProductTypes] = useState([]);

    // if selectedproductTypes changes, set allSelectedProductTypes to be the same array with selectedProductTypes but with objects instead of ids
    useEffect(() => {
        if (selectedProductTypes.length > 0) {
            const allSelectedProductTypes = selectedProductTypes.map((typeId) => {
                const type = productTypes.find((type) => type._id === typeId);
                return type;
            });
            setAllSelectedProductTypes(allSelectedProductTypes);
        } else {
            setAllSelectedProductTypes([]);
        }
    }, [selectedProductTypes]);


    useEffect(() => {
        const getAllProductTypes = async () => {
            try {
                const data = await getAllTypes();
                if (data && data.data) {
                    setProductTypes(data.data.types);
                }
            } catch (error) {
                console.log(error);
            }
        };
        getAllProductTypes();
    }, []);


    const submitCategoryData = (data) => {
        if (media && media.file && banner && banner.file) {
            //console.log('DATA: ' + JSON.stringify(data));
            uploadMedia();
            uploadBanner();
            setSubmittedFormData(data)
        }
    };

    // USE EFFECT WHEN EXTERNAL URLS READY, AND SEND ALL DATA
    useEffect(() => {
        if (
            submittedFormData &&
            media &&
            media.externalUrl &&
            banner &&
            banner.externalUrl
        ) {
            const sendAddCategoryData = async () => {
                try {
                    const data = await createCategory({
                        ...submittedFormData,
                        media: media.externalUrl,
                        banner: banner.externalUrl,
                    });
                    if (data && data.data) {
                        //console.log('CATEGORY CREATED');
                        setSubmittedFormData(null);
                        setMedia(null);
                        setBanner(null);
                        reset();
                        if (notify) {
                            notify('add');
                        }
                        handleClose();
                    }
                } catch (error) {
                    console.log(error);
                }
            };
            sendAddCategoryData();
        }
    }, [media, banner, submittedFormData]);

    // Media change
    const handleMediaChange = (e) => {
        const picture = e.target.files[0];
        if (picture) {
            setMedia({
                file: picture,
                url: URL.createObjectURL(picture),
                externalUrl: null,
            });
        }
    };

    // Banner change
    const handleBannerChange = (e) => {
        const picture = e.target.files[0];
        if (picture) {
            setBanner({
                file: picture,
                url: URL.createObjectURL(picture),
                externalUrl: null,
            });
        }
    };

    // Media
    const uploadMedia = async () => {
        try {
            let formData = new FormData();
            formData.append('image', media.file);
            const imageType = 'catMedia'
            const data = await sendMediaToBack(formData, imageType);
            if (data && data.data && data.data.media) {
                setMedia({ ...media, externalUrl: data.data.media });
            }
        } catch (error) {
            console.log(error);
        }
    };

    // Banner
    const uploadBanner = async () => {
        try {
            let formData = new FormData();
            formData.append('image', banner.file);
            const imageType = 'category'
            const data = await sendMediaToBack(formData, imageType);
            if (data && data.data && data.data.media) {
                setBanner({ ...banner, externalUrl: data.data.media });
            }
        } catch (error) {
            console.log(error);
        }
    };


    return (
        <Box>
            <Button
                onClick={handleOpen}
                variant='contained'
                startIcon={<AddIcon />}
            >
                Новая категория
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby='add-category-modal'
                aria-describedby='add-category-modal'
            >
                <Box sx={style}>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'end',
                        }}
                    >
                        <IconButton
                            aria-label='close'
                            onClick={() => {
                                handleClose();
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            margin: '0',
                            padding: '0',
                            width: '100%',
                            marginBottom: '0.5rem',
                            textAlign: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                fontSize: '1.8rem',
                            }}
                        >
                            Добавление категории
                        </Typography>
                    </Box>
                    <form onSubmit={handleSubmit(submitCategoryData)}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                rowGap: '1rem',
                            }}
                        >
                            <Box
                                sx={{
                                    width: '100%',
                                    height: '200px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    marginTop: '1rem',
                                    marginBottom: '1.5rem',
                                }}
                            >
                                <Box
                                    sx={{
                                        width: '32%',
                                        height: '100%',
                                        textAlign: 'center',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: 'bold',
                                            fontSize: '1.2rem',
                                        }}
                                    >{`Медиа*`}</Typography>
                                    <input
                                        hidden
                                        accept='image/*'
                                        type='file'
                                        onChange={handleMediaChange}
                                        onClick={(e) => (e.target.value = null)}
                                        id={`add-category-media-upload-input`}
                                    />
                                    <label
                                        htmlFor={`add-category-media-upload-input`}
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                        }}
                                    >
                                        <Card
                                            sx={{
                                                width: '100%',
                                                height: '100%',
                                                ...(media &&
                                                    media.url && {
                                                        backgroundImage: `url('${media.url}')`,
                                                        backgroundSize: 'cover',
                                                        backgroundPosition:
                                                            'center',
                                                        backgroundRepeat:
                                                            'no-repeat',
                                                    }),
                                            }}
                                        >
                                            <Button
                                                component='span'
                                                sx={{
                                                    position: 'relative',
                                                    borderRadius: '10px',
                                                    width: '100%',
                                                    height: '100%',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'end',
                                                    textTransform: 'none',
                                                    backgroundColor: '#bfbfbf',
                                                    '&:hover': {
                                                        backgroundColor:
                                                            '#a3a3a3',
                                                    },
                                                    ...(media &&
                                                        media.url && {
                                                            opacity: '0.4',
                                                        }),
                                                }}
                                            >
                                                <CameraAltRoundedIcon
                                                    sx={{
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        translate: '-50% -50%',
                                                    }}
                                                />
                                            </Button>
                                        </Card>
                                    </label>
                                </Box>

                                <Box
                                    sx={{
                                        width: '62%',
                                        height: '100%',
                                        textAlign: 'center',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: 'bold',
                                            fontSize: '1.2rem',
                                        }}
                                    >{`Баннер*`}</Typography>
                                    <input
                                        hidden
                                        accept='image/*'
                                        type='file'
                                        onChange={handleBannerChange}
                                        onClick={(e) => (e.target.value = null)}
                                        id={`add-category-banner-upload-input`}
                                    />
                                    <label
                                        htmlFor={`add-category-banner-upload-input`}
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                        }}
                                    >
                                        <Card
                                            sx={{
                                                width: '100%',
                                                height: '100%',
                                                ...(banner &&
                                                    banner.url && {
                                                        backgroundImage: `url('${banner.url}')`,
                                                        backgroundSize: 'cover',
                                                        backgroundPosition:
                                                            'center',
                                                        backgroundRepeat:
                                                            'no-repeat',
                                                    }),
                                            }}
                                        >
                                            <Button
                                                component='span'
                                                sx={{
                                                    position: 'relative',
                                                    borderRadius: '10px',
                                                    width: '100%',
                                                    height: '100%',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'end',
                                                    textTransform: 'none',
                                                    backgroundColor: '#bfbfbf',
                                                    '&:hover': {
                                                        backgroundColor:
                                                            '#a3a3a3',
                                                    },
                                                    ...(banner &&
                                                        banner.url && {
                                                            opacity: '0.4',
                                                        }),
                                                }}
                                            >
                                                <CameraAltRoundedIcon
                                                    sx={{
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        translate: '-50% -50%',
                                                    }}
                                                />
                                            </Button>
                                        </Card>
                                    </label>
                                </Box>
                            </Box>

                            <TextField
                                required
                                fullWidth
                                variant={`outlined`}
                                label='Название категории'
                                error={errors.name && true}
                                helperText={errors.name?.message}
                                {...register('name')}
                            />

                            <TextField
                                required
                                fullWidth
                                variant={`outlined`}
                                label='Описание категории'
                                error={errors.description && true}
                                helperText={errors.description?.message}
                                {...register('description')}
                            />


                            {/* Dispaly and Select multiple From All ProductTypes */}
                            <FormControl
                                fullWidth
                                error={errors.type && true}
                                variant={`outlined`}
                            >
                                <InputLabel id='category-type-label'>
                                    Тип категории
                                </InputLabel>
                                <Select
                                    multiple
                                    label='Category Types'
                                    {...register('type')}
                                    value={selectedProductTypes}
                                    onChange={(e) => {
                                        // Add type name to selectedProductTypes
                                            setSelectedProductTypes(e.target.value);
                                    }}
                                >
                                    {productTypes &&
                                        productTypes.map((type) => (
                                            <MenuItem
                                                key={type._id}
                                                value={type._id}
                                            >
                                                {type.name}
                                            </MenuItem>
                                    ))}

                                </Select>
                                <FormHelperText>
                                    {errors.type?.message}
                                </FormHelperText>
                            </FormControl>


                            {/* Choose Primary ProductType from selected product types */}
                            {allSelectedProductTypes.length > 0 && (
                                <FormControl
                                    fullWidth
                                    error={errors.primaryType && true}
                                    variant={`outlined`}
                                >
                                    <InputLabel id='category-primary-type-label'>
                                        Primary Category Type
                                    </InputLabel>
                                    <Select
                                        label='Primary Category Type'
                                        {...register('primaryType')}

                                    >
                                        {allSelectedProductTypes.map((type) => (
                                            <MenuItem
                                                key={type._id}
                                                value={type._id}
                                            >
                                                {type.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText>
                                        {errors.primaryType?.message}
                                    </FormHelperText>
                                </FormControl>
                            )}

                            <Button
                                disabled={!media?.file || !banner?.file}
                                size={`large`}
                                type={`submit`}
                                variant='contained'
                                startIcon={<AddIcon />}
                                sx={{ marginTop: '1rem' }}
                            >
                                Добавить категорию
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Modal>
        </Box>
    );
}

export default AddCategoryModal;
