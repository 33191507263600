import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box, Link } from '@mui/material';

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 40,
        height: 40,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 512 512">
        <defs>
          <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="-55.8379" y1="428.7081" x2="-56.3272" y2="428.2742" gradientTransform="matrix(184 0 0 -173.2664 10445 74371.375)">
            <stop offset="0" stopColor={PRIMARY_MAIN} />
            <stop offset="1" stopColor={PRIMARY_LIGHT} />
          </linearGradient>

          <linearGradient id="SVGID_00000083072958705458522850000006088007139493094321_" gradientUnits="userSpaceOnUse" x1="-54.8336" y1="428.7088" x2="-55.3228" y2="428.2749" gradientTransform="matrix(-184 0 0 -173.2664 -9777 74371.375)">
            <stop offset="0" stopColor={PRIMARY_MAIN} />
            <stop offset="1" stopColor={PRIMARY_MAIN} />
          </linearGradient>

          <linearGradient id="SVGID_00000053518519986466738110000010977591442946204033_" gradientUnits="userSpaceOnUse" x1="-56.4225" y1="428.832" x2="-56.4225" y2="427.8535" gradientTransform="matrix(368 0 0 -256 21005 109781)">
            <stop offset="0" stopColor={PRIMARY_MAIN} />
            <stop offset="1" stopColor={PRIMARY_DARK} />
          </linearGradient>
        </defs>

        <g fill={PRIMARY_MAIN} fillRule="evenodd" stroke="none" strokeWidth="1">
          {/* The paths from the provided SVG */}
          <path className="st0" d="M81.8,81.3c43.3,22.4,45.3,23.4,45.4,23.5c0,0,0.6,0.3,43.8,22.7c-25.5,47.5-41.8,75.6-48.8,84.3
            c-10.5,13.1-22,23-36.1,28.7c-29.7,14-66.6,14.6-95.1-2.6L81.8,81.3z" />
          <path style={{ fill: 'url(#SVGID_00000083072958705458522850000006088007139493094321_)' }} d="
            M492.2,237.7c-28.5,17.3-65.4,16.6-95.1,2.6c-14.1-5.7-25.6-15.6-36.1-28.7c-7-8.7-23.3-36.8-48.8-84.3
            c43.2-22.3,43.8-22.6,43.8-22.7c0.1,0,2.1-1.1,45.4-23.5L492.2,237.7z" />
          <g>
            <path style={{ fill: 'url(#SVGID_00000053518519986466738110000010977591442946204033_)' }} d="
              M412.1,99.5C366.8,20.3,320-54.3,266.2,55.2c-7.4,14.1-12.7,41.4-24.7,41.4v-0.1c-12,0-17.4-27.4-24.7-41.4
              C163-54.5,116.2,20.2,70.9,99.4c-3.4,6-6.7,11.7-9.5,16.6c103.8-65.7,95,132.8,180.1,134.3v0.1c85-1.6,76.3-200,180.1-134.3
              C418.8,111.2,415.5,105.5,412.1,99.5" />
          </g>
        </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <Link to="/dashboard" component={RouterLink} sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default Logo;
