import { Box, Button, FormControl, Grid, TextField, Typography } from '@mui/material'

// React
import React, { useEffect, useState } from 'react'

// API
import { getPageSeo } from '../../api/seo/getPageSeo'
import { createPageSeo } from '../../api/seo/createPageSeo'
import { updatePageSeo } from '../../api/seo/updatePageSeo'

// quill
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'

const CategorySeoFrom = ({categoryName, type, notify}) => {

    // States
    // Description
    const [description, setDescription] = useState('')
    // Meta
    const [metaTitle, setMetaTitle] = useState('')
    const [metaDescription, setMetaDescription] = useState('')
    const [metaKeywords, setMetaKeywords] = useState('')
    // Graph
    const [graphTitle, setGraphTitle] = useState('')
    const [graphType, setGraphType] = useState('')
    const [graphUrl, setGraphUrl] = useState('')
    const [graphDescription, setGraphDescription] = useState('')

    // Handlers
    // META
    const handleMetaTitle = (e) => {
        setMetaTitle(e.target.value)
    }
    const handleMetaDescription = (e) => {
        setMetaDescription(e.target.value)
    }
    const handleMetaKeywords = (e) => {
        setMetaKeywords(e.target.value)
    }
    // GRAPH
    const handleGraphTitle = (e) => {
        setGraphTitle(e.target.value)
    }
    const handleGraphType = (e) => {
        setGraphType(e.target.value)
    }
    const handleGraphUrl = (e) => {
        setGraphUrl(e.target.value)
    }
    const handleGraphDescription = (e) => {
        setGraphDescription(e.target.value)
    }
    const setEditorValue = (value) => {
        setDescription(value)
    }

    const [alert, setAlert] = useState('')

    const [isPageSeo, setIsPageSeo] = useState(false)

    const [id, setId] = useState('')


    // On mount call api to get seo data
    useEffect(() => {
        // Call api to get seo data
        const getSeoData = async () => {
            if(categoryName && type) {
                await getPageSeo({categoryName: categoryName, typeName: type.name})
                .then((res) => {
                    setIsPageSeo(true)
                    setId(res.data._id)
                    // META
                    setMetaTitle(res.data.metaTitle)
                    setMetaDescription(res.data.metaDescription)
                    setMetaKeywords(res.data.metaKeywords)

                    // Graph
                    setGraphTitle(res.data.graphTitle)
                    setGraphType(res.data.graphType)
                    setGraphUrl(res.data.graphUrl)
                    setGraphDescription(res.data.graphDescription)

                    // Description
                    setDescription(res.data.description)
                })
                .catch((err) => {
                    //setAlert(err.response.data.message)
                })
            }
        }
        if(type) getSeoData()

    }, [type])

    // On submit call api to update seo data
    const handleSubmit = () => {
        // Call api to update seo data
        const updateSeoData = async () => {
            if(categoryName && type) {
                const data = {
                    // meta
                    metaTitle: metaTitle,
                    metaDescription: metaDescription,
                    metaKeywords: metaKeywords,
                    // graph
                    graphTitle: graphTitle,
                    graphType: graphType,
                    graphUrl: graphUrl,
                    graphDescription: graphDescription,

                    // description
                    description: description,

                    // references
                    categoryName: categoryName, 
                    typeName: type.name
                }
                if(!isPageSeo) {
                    await createPageSeo(data)
                    .then((res) => {
                        notify('add')
                        //setAlert('')
                    })
                    .catch((err) => {
                        //notify('error')
                        //setAlert(err.response.data.message)
                    })
                    .finally(() => {
                        setIsPageSeo(true)
                    })
                } else {
                    await updatePageSeo(id, data)
                    .then((res) => {
                        //notify('edit')
                        //setAlert('')
                    })
                    .catch((err) => {
                        //notify('error')
                        //setAlert(err.response.data.message)
                    })
                }
                
            }
        }
        updateSeoData()
    }


  return (
    <FormControl sx={{
        width: '100%',
        padding: '1rem',
        paddingX: '2rem',
        gap: '1rem',
      }}>
        <Typography variant='h6' gutterBottom>
          Edit SEO for {categoryName} - {type.name}
        </Typography>
        
        {alert &&
            <Typography variant='h6' gutterBottom color='red'>
                {alert}
            </Typography>
        }
        <Grid container spacing={2}>
            <Grid item xs={6} >
                {/* Title Tag */}
                <TextField
                    size='fullWidth'
                    id="outlined-basic"
                    label="Title Tag"
                    variant="outlined"
                    value={metaTitle}
                    onChange={(e) => handleMetaTitle(e)}
                    helperText='The title of the webpage displayed in search engine results. Example: Mentup - Category - Type'
                    sx={{
                        width: '100%',
                    }}
                />
            </Grid>
            <Grid item xs={6}>
               {/* Meta Description */}
                <TextField
                id="outlined-basic"
                label="Meta Description"
                variant="outlined"
                value={metaDescription}
                onChange={(e) => handleMetaDescription(e)}
                helperText='The description of the webpage displayed in search engine results. ( Example: Mentup is a marketplace. )'
                />
            </Grid>
            
            {/*<Grid item xs={4}>
                 Robots 
                <TextField
                    id="outlined-basic"
                    label="Robots"
                    variant="outlined"
                    helperText='The instructions for search engines crawlers. (Example: noindex, nofollow)'
                />
            </Grid>*/}
        </Grid>


        

        {/* Meta Keywords */}
        <TextField
          id="outlined-basic"
          label="Meta Keywords"
          variant="outlined"
          value={metaKeywords}
            onChange={(e) => handleMetaKeywords(e)}
          helperText='Example:  Keyword1, Keyword2, Keyword3'
        />

        {/* Open Graph Meta Tags */}
        <Typography variant='h6' gutterBottom>
          Open Graph Meta Tags
        </Typography>
          <Grid container spacing={2}>
            <Grid item xs={4} >

                {/* Open Graph Title */}
                <TextField
                    id="outlined-basic"
                    label="Open Graph Title"
                    variant="outlined"
                    value={graphTitle}
                    onChange={(e) => handleGraphTitle(e)}
                    helperText='The title of the webpage displayed in social media. ( Example: Mentup )'
                />
            </Grid>
            <Grid item xs={4}>
                {/* Open Graph Type */}
                <TextField
                    id="outlined-basic"
                    label="Open Graph Type"
                    variant="outlined"
                    value={graphType}
                    onChange={(e) => handleGraphType(e)}
                    helperText='The type of the webpage displayed in social media. Example(website, article, product, etc.)'
                  />
            </Grid>
            <Grid item xs={4}>
                {/* Open Graph URL */}
                <TextField
                    id="outlined-basic"
                    label="Open Graph URL"
                    variant="outlined"
                    value={graphUrl}
                    onChange={(e) => handleGraphUrl(e)}
                    helperText='The URL of the webpage displayed in social media.( Example: https://mentup.com/ )'
                />
            </Grid>
        </Grid>
            
        {/* Open Graph Description */}  
        <TextField
            id="outlined-basic"
            label="Open Graph Description"
            variant="outlined"
            value={graphDescription}
            onChange={(e) => handleGraphDescription(e)}
            helperText='The description of the webpage displayed in social media. ( Example: Mentup is a marketplace. )'
        />    

        <Typography variant='h6' gutterBottom>
          HTML SEO Description
        </Typography>       

        <ReactQuill
            value={description}
            onChange={setEditorValue}
        />  

        <Box sx={{ mt: 2 }} >
          <Button 
            variant="contained" 
            sx={{ mr: 2 }}
            onClick={() => handleSubmit()}
            >
            Save
          </Button>
        </Box>
      </FormControl>
  )
}

export default CategorySeoFrom