import React, { useEffect } from 'react';
import { useState } from 'react';

// mui
import {
    Box,
    Button,
    Typography,
    Modal,
    TextField,
    IconButton,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    FormHelperText,
} from '@mui/material';

// form
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// icons
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { min } from 'lodash';

// api
import { updateNotification } from 'src/api/notifications/updateNotification';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    paddingX: 7,
    paddingTop: 1,
    paddingBottom: 4,
    borderRadius: '10px',
    maxHeight: '90vh',
    overflow: 'scroll',
    '@media (max-width: 650px)': {
        width: '90%',
    },
    '@media (max-width: 500px)': {
        paddingX: 4,
    },
};

function EditNotificationModal({ notification, notify }) {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [notificationData, setNotificationData] = useState(null);

    const schema = yup.object().shape({
        message: yup.string().max(500).required(),
    });

    useEffect(() => {
        setNotificationData(notification);
    }, [notification]);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const submitNotificationData = (data) => {
        console.log(data);
        sendNotificationData(data);
    };

    const sendNotificationData = async (data) => {
        if (data && notification && notification._id) {
            console.log('YES DATA, PREPARING TO CALL API');
            try {
                const response = await updateNotification(
                    data,
                    notification._id
                );
                if (response) {
                    console.log('RESPONSE:' + response);
                    if (notify) {
                        notify('edit');
                    }
                    handleClose();
                }
            } catch (error) {
                console.log(error);
                if (notify) {
                    notify('error');
                }
                handleClose();
            }
        }
    };

    return (
        <Box>
            <MenuItem onClick={handleOpen}>
                <EditRoundedIcon sx={{ mr: 2 }} />
                Редактировать
            </MenuItem>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby='edit-notification-modal'
                aria-describedby='edit-notification-modal'
            >
                <Box sx={style}>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'end',
                        }}
                    >
                        <IconButton
                            aria-label='close'
                            onClick={() => {
                                handleClose();
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            margin: '0',
                            padding: '0',
                            width: '100%',
                            marginBottom: '0.5rem',
                            textAlign: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                fontSize: '1.8rem',
                            }}
                        >
                            {notificationData && notificationData._id
                                ? `Edit notification: ${notificationData._id}`
                                : `Edit Notification`}
                        </Typography>
                    </Box>
                    <form onSubmit={handleSubmit(submitNotificationData)}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                rowGap: '1rem',
                                padding: '1rem 3rem',
                                '@media (max-width: 650px)': {
                                    paddingX: 0,
                                },
                            }}
                        >
                            <TextField
                                required
                                fullWidth
                                variant={`outlined`}
                                label='ID пользователя'
                                InputProps={{
                                    readOnly: true,
                                }}
                                value={
                                    notificationData &&
                                    notificationData.user &&
                                    notificationData.user._id
                                        ? notificationData.user._id
                                        : ''
                                }
                            />

                            <TextField
                                required
                                fullWidth
                                variant={`outlined`}
                                label='From User ID'
                                InputProps={{
                                    readOnly: true,
                                }}
                                value={
                                    notificationData &&
                                    notificationData.fromUser &&
                                    notificationData.fromUser._id
                                        ? notificationData.fromUser._id
                                        : ''
                                }
                            />

                            <TextField
                                required
                                multiline
                                rows={4}
                                fullWidth
                                variant={`outlined`}
                                label='Message'
                                error={errors.message && true}
                                helperText={errors.message?.message}
                                {...register('message')}
                                value={
                                    notificationData && notificationData.message
                                        ? notificationData.message
                                        : ''
                                }
                                onChange={(e) => {
                                    setNotificationData({
                                        ...notificationData,
                                        message: e.target.value,
                                    });
                                }}
                            />

                            <Button
                                size={`large`}
                                type={`submit`}
                                variant='contained'
                                startIcon={<AddIcon />}
                                sx={{ marginTop: '1rem' }}
                            >
                                Add Notification
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Modal>
        </Box>
    );
}

export default EditNotificationModal;
