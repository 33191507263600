import { Cookie } from '@mui/icons-material';
import axios from 'axios';

const API = axios.create({
    baseURL: process.env.NODE_ENV.includes('production')
        ? process.env.REACT_APP_SERVER_URL
            ? process.env.REACT_APP_SERVER_URL
            : ''
        : 'http://localhost:3030/api',
});

const header = () => {
    const token = Cookie.get('refreshToken');
    if (token) {
        return {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        };
    }
}

export const getTypes = (rowsPerPage,
    page,
    orderBy,
    order,
    searchValue
) => {
    return API.get(`/productTypes/adm/all?rowsPerPage=${rowsPerPage}&page=${page}&orderBy=${orderBy}&order=${order}&searchValue=${searchValue}`);
};

export const getAllTypes = () => {
    return API.get(`/productTypes/adm/all`);
}
