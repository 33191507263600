import axios from 'axios';
import Cookies from 'js-cookie';

const API = axios.create({
    baseURL: process.env.NODE_ENV.includes('production')
        ? process.env.REACT_APP_SERVER_URL
            ? process.env.REACT_APP_SERVER_URL
            : ''
        : 'http://localhost:3030/api',
});


export const createUser = (userData) => {
    const token = Cookies.get('refreshToken');
    if (userData && token) {
        return API.post(`/auth/register/${token}`, userData);
    }
};
