import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Button(theme) {
    return {
        MuiButton: {
            styleOverrides: {
                root: {
                    '&:hover': {
                        boxShadow: 'none',
                    },
                },
                sizeLarge: {
                    height: 48,
                },
                containedInherit: {
                    color: theme.palette.grey[800],
                    boxShadow: theme.customShadows.z8,
                    '&:hover': {
                        backgroundColor: theme.palette.grey[400],
                    },
                },
                containedPrimary: {
                    boxShadow: theme.customShadows.primary,
                },
                containedSecondary: {
                    boxShadow: theme.customShadows.secondary,
                },
                outlinedInherit: {
                    border: `1px solid ${alpha(theme.palette.grey[500], 0.32)}`,
                    '&:hover': {
                        backgroundColor: theme.palette.action.hover,
                    },
                },
                textInherit: {
                    '&:hover': {
                        backgroundColor: theme.palette.action.hover,
                    },
                },
            },
            variants: [
                {
                    props: { variant: 'vendorAddProductPicture' },
                    variant: 'contained',
                    style: ({ theme }) => ({
                        borderRadius: '10px',
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'end',
                        textTransform: 'none',
                        ...(theme.palette.mode === 'dark' && {
                            backgroundColor: '#8f8f8f',
                            '&:hover': {
                                backgroundColor: '#8f8f8f',
                            },
                        }),
                        ...(theme.palette.mode === 'light' && {
                            backgroundColor: '#bfbfbf',
                            '&:hover': {
                                backgroundColor: '#a3a3a3',
                            },
                        }),
                    }),
                },
            ],
        },
    };
}
