import PropTypes from 'prop-types';
// @mui
import { styled, alpha } from '@mui/material/styles';
import {
    Toolbar,
    Tooltip,
    IconButton,
    Typography,
    OutlinedInput,
    InputAdornment,
} from '@mui/material';
// component
import Iconify from '../../../components/iconify';
import DeleteMultipleUsersModal from 'src/components/delete-multiple-users-modal/DeleteMultipleUsersModal';

// ----------------------------------------------------------------------

const StyledRoot = styled(Toolbar)(({ theme }) => ({
    height: 96,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1, 0, 3),
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
    width: 240,
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter,
    }),
    '&.Mui-focused': {
        width: 320,
        boxShadow: theme.customShadows.z8,
    },
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
    },
}));

// ----------------------------------------------------------------------

UserListToolbar.propTypes = {
    numSelected: PropTypes.number,
};

export default function UserListToolbar({ numSelected }) {
    return (
        <StyledRoot
            sx={{
                ...(numSelected > 0 && {
                    color: 'primary.main',
                    bgcolor: 'primary.lighter',
                }),
            }}
        >
            {/* {numSelected > 0 ? (
                <Typography component='div' variant='subtitle1'>
                    {numSelected} selected
                </Typography>
            ) : ( */}
                <StyledSearch
                    placeholder='Search user...'
                    startAdornment={
                        <InputAdornment position='start'>
                            <Iconify
                                icon='eva:search-fill'
                                sx={{
                                    color: 'text.disabled',
                                    width: 20,
                                    height: 20,
                                }}
                            />
                        </InputAdornment>
                    }
                />
            {/* )} */}

            {/* {numSelected > 0 && (
                <Tooltip title='Delete'>
                    <IconButton>
                        <Iconify icon='eva:trash-2-fill' />
                    </IconButton>
                </Tooltip>
                <DeleteMultipleUsersModal usersNumber={numSelected} />
            )} */}

            {/* ONLY IF USING FILTERING */}
            {/* ) : (
                <Tooltip title='Filter list'>
                    <IconButton>
                        <Iconify icon='ic:round-filter-list' />
                    </IconButton>
                </Tooltip>
            )} */}
        </StyledRoot>
    );
}
