import React from 'react';
import { useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';

// @mui
import {
    Box,
    MenuItem,
    Modal,
    IconButton,
    Typography,
    FormControl,
    InputLabel,
    Select,
    FormHelperText,
    TextField,
    Button,
} from '@mui/material';

// icons
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import CloseIcon from '@mui/icons-material/Close';

// form
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const modalStyle = {
    color: 'white',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    paddingTop: 1,
    borderRadius: '10px',
    maxHeight: '90vh',
    overflow: 'scroll',
    '@media (max-width: 650px)': {
        width: '61%',
    },
};

function ProductCodesModal({ product }) {
    const theme = useTheme();

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => setOpen(true);

    const handleClose = () => setOpen(false);

    const [codes, setCodes] = useState([]);

    const [addProductCodes, setAddProductCodes] = React.useState(false);

    const [productCodesValidations, setProductCodesValidations] = useState({});

    // Add yup product codes validations
    useEffect(() => {
        if (!addProductCodes) {
            setProductCodesValidations({});
        }
        if (addProductCodes && product.quantity > 0) {
            let tempObject = {};
            for (let i = 0; i < product.quantity; i++) {
                tempObject[i] = yup
                    .string('Code must be a string')
                    .min(5, 'Code must be at least 5 characters')
                    .max(100, 'Code must be at most 100 characters')
                    .required('Code is required')
                    .test(
                        'Valid Code Value',
                        'Code Value must contain letters and numbers only',
                        (value) => value.match(/^[a-zA-Z0-9]+$/)
                    );
            }
            setProductCodesValidations(tempObject);
        }
    }, [addProductCodes, product]);

    const schema = yup.object().shape({
        codesConfirmation: yup
            .boolean('Confirmation must be a boolean')
            .required('Confirmation is required'),
        ...(productCodesValidations &&
            product &&
            Object.keys(productCodesValidations).length === product.quantity &&
            productCodesValidations),
    });

    const {
        register: register,
        handleSubmit: handleSubmit,
        formState: { errors: errors },
        reset: reset,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const submitModal = (data) => {
        console.log('DATA: ' + JSON.stringify(data));
        let tempCodesArray = [];
        if (data && data.codesConfirmation === true) {
            for (let property in data) {
                if (property !== 'codesConfirmation') {
                    tempCodesArray.push(data[property]);
                }
            }
            tempCodesArray.length > 0 && setCodes(tempCodesArray);
        }

        if (data && data.codesConfirmation === false) {
            setCodes([]);
        }

        // Check again to make sure validations are present before submitting
        if (addProductCodes) {
            if (
                Object.keys(productCodesValidations).length === product.quantity
            ) {
                // productSubmission();
            } else {
                return;
            }
        } else {
            // productSubmission();
        }
    };

    return (
        <Box>
            <MenuItem onClick={handleOpen}>
                <EditRoundedIcon sx={{ mr: 2 }} /> Изменить коды
            </MenuItem>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby='edit product codes'
                aria-describedby='form to add or edit product codes'
            >
                <Box sx={modalStyle}>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'end',
                        }}
                    >
                        <IconButton
                            aria-label='close'
                            onClick={() => {
                                handleClose();
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            margin: '0',
                            padding: '0',
                            width: '100%',
                            marginBottom: '1rem',
                            textAlign: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                fontSize: '1.6rem',
                                color: `${theme.palette.text.primary}`,
                            }}
                        >
                            Редактируйте коды для продукта: ID TO ADD
                        </Typography>
                    </Box>
                    <form onSubmit={handleSubmit(submitModal)}>
                        <Box
                            sx={{
                                width: '100%',
                                rowGap: '1rem',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                            }}
                        >
                            <FormControl
                                sx={{
                                    width: '100%',
                                }}
                            >
                                <InputLabel
                                    id={`product-codes-selection-question-label`}
                                >
                                    Добавить коды продуктов?
                                </InputLabel>
                                <Select
                                    required
                                    labelId={`product-codes-selection-question-label`}
                                    defaultValue=''
                                    label='Добавить коды продуктов?'
                                    error={errors.codesConfirmation && true}
                                    {...register('codesConfirmation')}
                                    value={addProductCodes}
                                    onChange={(e) =>
                                        setAddProductCodes(e.target.value)
                                    }
                                >
                                    <MenuItem value={true}>{'Да'}</MenuItem>
                                    <MenuItem value={false}>{'Нет'}</MenuItem>
                                </Select>
                                {errors.codesConfirmation?.message ? (
                                    <FormHelperText
                                        sx={{
                                            color: `${theme.palette.error.main}`,
                                        }}
                                    >
                                        {errors.codesConfirmation?.message}
                                    </FormHelperText>
                                ) : null}
                            </FormControl>
                            {addProductCodes === true
                                ? [...Array(product.quantity)].map(
                                      (n, index) => (
                                          <TextField
                                              key={index}
                                              required
                                              label={`Code ${index + 1}`}
                                              variant='outlined'
                                              sx={{ width: '100%' }}
                                              error={errors[index] && true}
                                              helperText={
                                                  errors[index]?.message
                                              }
                                              {...register(`${index}`)}
                                              value={
                                                  product.codes &&
                                                  (product.codes[index] ||
                                                      product.codes[index] ===
                                                          '')
                                                      ? product.codes[index]
                                                      : ''
                                              }
                                              onChange={(e) => {
                                                  if (codes) {
                                                      let newArray = [...codes];

                                                      newArray[index] =
                                                          e.target.value;

                                                      setCodes(
                                                          (prevState) =>
                                                              newArray
                                                      );
                                                  }
                                              }}
                                          />
                                      )
                                  )
                                : null}
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    marginTop: '2rem',
                                }}
                            >
                                <Button
                                    variant='contained'
                                    sx={{
                                        fontWeight: 'bold',
                                        width: '25%',
                                    }}
                                    type='submit'
                                >
                                    Отправить
                                </Button>
                            </Box>
                        </Box>
                    </form>
                </Box>
            </Modal>
        </Box>
    );
}

export default ProductCodesModal;
