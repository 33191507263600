import React from 'react';
import { useState, useEffect } from 'react';

// mui
import {
    Box,
    Button,
    Typography,
    Modal,
    TextField,
    IconButton,
    MenuItem,
} from '@mui/material';

// form
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// icons
import CloseIcon from '@mui/icons-material/Close';
import EditRoundedIcon from '@mui/icons-material/EditRounded';

// api
import { createReview } from 'src/api/reviews/createReview';
import { updateReview } from 'src/api/reviews/updateReview';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    paddingX: 7,
    paddingTop: 1,
    paddingBottom: 4,
    borderRadius: '10px',
    maxHeight: '90vh',
    overflow: 'scroll',
    '@media (max-width: 650px)': {
        width: '90%',
        paddingX: 4,
    },
};

function EditReviewModal({ notify, review }) {
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);

    const handleClose = () => setOpen(false);

    const [reviewData, setReviewData] = useState(null);

    useEffect(() => {
        if (review) {
            setReviewData({
                ...review,
                user: review.user && review.user._id ? review.user._id : null,
                aboutUser:
                    review.aboutUser && review.aboutUser._id
                        ? review.aboutUser._id
                        : null,
                product:
                    review.product && review.product._id
                        ? review.product._id
                        : null,
            });
        } else if (!review) {
            setReviewData(review);
        }
    }, [review]);

    const schema = yup.object().shape({
        // user: yup
        //     .string('User ID must be a string.')
        //     .max(50, 'User ID must be at most 50 characters.')
        //     .required('User ID is required.'),
        // aboutUser: yup
        //     .string('About User ID must be a string.')
        //     .max(50, 'About User ID must be at most 50 characters.')
        //     .required('About User ID is required.'),
        // product: yup
        //     .string('Product ID is required.')
        //     .max(50, 'Product ID must be at most 50 characters.')
        //     .required('Product ID is required.'),
        rating: yup.number().integer().min(1).max(5).required(),
        text: yup.string().max(300),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const submitReviewData = (data) => {
        console.log(data);
        sendReviewData(data);
    };

    const sendReviewData = async (data) => {
        if (data && review && review._id) {
            try {
                const response = await updateReview(data, review._id);
                if (response) {
                    console.log('RESPONSE:' + response);
                    if (notify) {
                        notify('edit');
                    }
                    handleClose();
                }
            } catch (error) {
                console.log(error);
                if (notify) {
                    notify('error');
                }
                handleClose();
            }
        }
    };

    console.log('REVIEW DATA: ' + JSON.stringify(reviewData));

    return (
        <Box>
            <MenuItem onClick={handleOpen}>
                <EditRoundedIcon sx={{ mr: 2 }} /> Редактировать{' '}
            </MenuItem>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby='edit-review-modal'
                aria-describedby='edit-review-modal'
            >
                <Box sx={style}>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'end',
                        }}
                    >
                        <IconButton
                            aria-label='close'
                            onClick={() => {
                                handleClose();
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            margin: '0',
                            padding: '0',
                            width: '100%',
                            marginBottom: '1rem',
                            textAlign: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                fontSize: '1.8rem',
                            }}
                        >
                            {reviewData && reviewData._id
                                ? `Edit review: ${reviewData._id}`
                                : `Edit Review`}
                        </Typography>
                    </Box>
                    {reviewData ? (
                        <form onSubmit={handleSubmit(submitReviewData)}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    rowGap: '1rem',
                                }}
                            >
                                <TextField
                                    required
                                    readOnly
                                    fullWidth
                                    variant={`outlined`}
                                    label='ID пользователя'
                                    error={errors.user && true}
                                    helperText={errors.user?.message}
                                    value={
                                        reviewData && reviewData.user
                                            ? reviewData.user
                                            : ''
                                    }
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    // onChange={(e) => {
                                    //     setReviewData({
                                    //         ...reviewData,
                                    //         user: e.target.value,
                                    //     });
                                    // }}
                                />

                                <TextField
                                    required
                                    fullWidth
                                    readOnly
                                    variant={`outlined`}
                                    label='ID на пользователя'
                                    error={errors.aboutUser && true}
                                    helperText={errors.aboutUser?.message}
                                    value={
                                        reviewData && reviewData.aboutUser
                                            ? reviewData.aboutUser
                                            : ''
                                    }
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    // onChange={(e) => {
                                    //     setReviewData({
                                    //         ...reviewData,
                                    //         aboutUser: e.target.value,
                                    //     });
                                    // }}
                                />

                                <TextField
                                    required
                                    fullWidth
                                    readOnly
                                    variant={`outlined`}
                                    label='ID продукта'
                                    error={errors.product && true}
                                    helperText={errors.product?.message}
                                    value={
                                        reviewData && reviewData.product
                                            ? reviewData.product
                                            : ''
                                    }
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    // onChange={(e) => {
                                    //     setReviewData({
                                    //         ...reviewData,
                                    //         product: e.target.value,
                                    //     });
                                    // }}
                                />

                                <TextField
                                    required
                                    fullWidth
                                    variant={`outlined`}
                                    label='Рейтинг'
                                    error={errors.rating && true}
                                    helperText={errors.rating?.message}
                                    {...register('rating')}
                                    value={
                                        reviewData && reviewData.rating
                                            ? reviewData.rating
                                            : ''
                                    }
                                    onChange={(e) => {
                                        setReviewData({
                                            ...reviewData,
                                            rating: e.target.value,
                                        });
                                    }}
                                />

                                <TextField
                                    fullWidth
                                    multiline
                                    rows={4}
                                    variant={`outlined`}
                                    label='Текст'
                                    error={errors.text && true}
                                    helperText={errors.text?.message}
                                    {...register('text')}
                                    value={
                                        reviewData && reviewData.text
                                            ? reviewData.text
                                            : ''
                                    }
                                    onChange={(e) => {
                                        setReviewData({
                                            ...reviewData,
                                            text: e.target.value,
                                        });
                                    }}
                                />

                                <Button
                                    size={`large`}
                                    type={`submit`}
                                    variant='contained'
                                    sx={{ marginTop: '1rem' }}
                                >
                                    Отправить
                                </Button>
                            </Box>
                        </form>
                    ) : null}
                </Box>
            </Modal>
        </Box>
    );
}

export default EditReviewModal;
