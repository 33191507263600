import { Navigate, useRoutes } from 'react-router-dom';
import { nanoid } from 'nanoid';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';

// Protected Route
import ProtectedRoute from './ProtectedRoute';
import DashboardAppPage from './pages/DashboardAppPage';
import CategoriesPage from './pages/CategoriesPage';
import UserPage from './pages/UserPage';
import UserEditPage from './pages/UserEditPage';
import ReviewsPage from './pages/ReviewsPage';
import ProductsPage from './pages/ProductsPage';
import PromocodesPage from './pages/PromocodesPage';
import OrdersPage from './pages/OrdersPage';
import OrderEditPage from './pages/OrderEditPage';
import TransactionsPage from './pages/TransactionsPage';
import WalletsPage from './pages/WalletsPage';
import WithdrawalsPage from './pages/WithdrawalsPage';
import GeneralSettingsPage from './pages/GeneralSettingsPage';
import ProfilePage from './pages/ProfilePage';
import NotificationsPage from './pages/NotificationsPage';
import AdsPage from './pages/AdsPage';
import TypesPage from './pages/TypesPage';
import CategorySeoPage from './pages/CategorySeoPage';
import SeoPage from './pages/SeoPage';
import SeoPageSingle from './pages/SeoPageSingle';
import DisputePage from './pages/DisputesPage';
import DisputePageSingle from './pages/DisputePageSingle';
import ChatsPage from './pages/ChatsPage';
import SoftwarePage from './pages/SoftwarePage';
import FeaturePricesPage from './pages/FeaturePricesPage';
import ServersPage from './pages/ServersPage' 
import TypeAttributesPage from './pages/TypeAttributesPage';
import AttributeGroupPage from './pages/AttributeGroupPage';


// ----------------------------------------------------------------------

export default function Router() {
    const routes = useRoutes([
        {
            path: '',
            element: <ProtectedRoute key={nanoid()} />,
            children: [
                {
                    path: '/dashboard',
                    element: <DashboardLayout />,
                    children: [
                        {
                            element: <Navigate to='/dashboard/app' />,
                            index: true,
                        },
                        { path: 'app', element: <DashboardAppPage /> },
                        { path: 'profile', element: <ProfilePage /> },
                        { path: 'user', element: <UserPage /> },
                        { path: 'chats', element: <ChatsPage /> },
                        { path: 'feature-prices', element: <FeaturePricesPage /> },
                        { path: 'user/:id', element: <UserEditPage /> },
                        { path: 'reviews', element: <ReviewsPage /> },
                        {
                            path: 'notifications',
                            element: <NotificationsPage />,
                        },
                        { path: 'products', element: <ProductsPage /> },
                        { path: 'orders', element: <OrdersPage /> },
                        { path: 'order/:id', element: <OrderEditPage /> },
                        { path: 'categories', element: <CategoriesPage /> },
                        { path: 'software', element: <SoftwarePage /> },
                        { path: 'promocodes', element: <PromocodesPage /> },
                        { path: 'transactions', element: <TransactionsPage /> },
                        { path: 'wallets', element: <WalletsPage /> },
                        { path: 'withdrawals', element: <WithdrawalsPage /> },
                        { path: 'settings', element: <GeneralSettingsPage /> },
                        { path: 'ads', element: <AdsPage /> },
                        { path: 'categories/types', element: <TypesPage /> },
                        { path: 'categories/typeAttrs', element: <TypeAttributesPage /> },
                        { path: 'categories/groups', element: <AttributeGroupPage /> },
                        { path: 'categories/servers', element: <ServersPage /> },
                        { path: 'categories/seo/:slug', element: <CategorySeoPage /> },
                        { path: 'seo', element: <SeoPage /> },
                        { path: 'seo/:name', element: <SeoPageSingle /> },
                        { path: 'disputes', element: <DisputePage /> },
                        { path: 'disputes/:id', element: <DisputePageSingle /> },
                    ],
                },
            ],
        },
        {
            path: 'login',
            element: <LoginPage />,
        },
        {
            element: <SimpleLayout />,
            children: [
                // { element: <Navigate to='/dashboard/app' />, index: true },
                { path: '404', element: <Page404 /> },
                { path: '*', element: <Navigate to='/404' /> },
            ],
        },
        {
            path: '*',
            element: <Navigate to='/404' replace />,
        },
    ]);

    return routes;
}
