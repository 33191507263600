import Cookies from 'js-cookie';
import axios from 'axios';

const API = axios.create({
    baseURL: process.env.NODE_ENV.includes('production')
        ? process.env.REACT_APP_SERVER_URL || ''
        : 'http://localhost:3030/api',
});

const header = () => {
    const token = Cookies.get('refreshToken');
    if (!token) {
        throw new Error('Token is missing');
    }
    return {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    };
}

export const getTypesAttrs = (
    rowsPerPage,
    page,
    orderBy,
    order,
    searchValue
) => {
    return API.get(`/typeAttributes/all?rowsPerPage=${rowsPerPage}&page=${page}&orderBy=${orderBy}&order=${order}&searchValue=${searchValue}`, header());
};
