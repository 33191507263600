import React from 'react';
import { useTheme } from "@emotion/react";

// @mui
import {Box,MenuItem,Modal, IconButton, Typography, Button} from "@mui/material";

// icons
import Disable from "@mui/icons-material/WebAssetOff";
import Enable from "@mui/icons-material/WebAsset";
import CloseIcon from "@mui/icons-material/Close";
import { updateProduct } from 'src/api/products/updateProduct';
import { disableProduct } from 'src/api/products/disableProduct';
import { enableProduct } from 'src/api/products/enableProduct';

// form

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  paddingTop: 1,
  borderRadius: "10px",
  maxHeight: "90vh",
  //overflow: 'scroll',
  "@media (max-width: 650px)": {
    width: "61%",
  },
};

function DisableEnableModal({ product, notify }) {
  console.log(product)
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const disableOpen = () => setOpen(true);
  const disableClose = () => setOpen(false);

  const handleDisabled = async () => {
    const res = await disableProduct(product._id)
    if(res.status === 200){
      notify("disabled");
    } else {
      notify("error");
    }
  };

  const handleEnabled = async () => {
    const res = await enableProduct(product._id)
    if(res.status === 200){
      notify("enabled");
    } else {
      notify("error");
    }
  };

  return (
    <div>
      <MenuItem onClick={disableOpen}>
        <Enable sx={{ mr: 2 }} /> Включить/Выключить
      </MenuItem>
      <Modal
        open={open}
        onClose={disableClose}
        aria-labelledby="delete-listing-confirmation-request"
        aria-describedby="delete-listing-confirmation-request"
      >
        <Box sx={modalStyle}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <IconButton
              aria-label="close"
              onClick={() => {
                disableClose();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              margin: "0",
              padding: "0",
              width: "100%",
              marginBottom: "1rem",
              textAlign: "center",
            }}
          >
            <Typography
              variant="addProductModalHeading"
              sx={{
                fontWeight: "bold",
                fontSize: "1.6rem",
              }}
            >
              Включить/выключить продукт{" "}
            </Typography>
          </Box>
          <Box
            sx={{
              marginTop: "2rem",
              width: "100%",
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-around",
              "@media (max-width: 350px)": {
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-around",
              },
            }}
          >
             <Button
                variant="contained"
                sx={{
                  backgroundColor: `${theme.palette.success.main}`,
                  "&:hover": {
                    backgroundColor: `${theme.palette.success.main}`,
                    opacity: "0.9",
                  },
                }}
                onClick={handleEnabled}
                disabled={product && !product.disabled}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  Вкл.{" "}
                </Typography>
              </Button> 

            <Button
              variant="contained"
              sx={{
                backgroundColor: `${theme.palette.error.main}`,
                "&:hover": {
                  backgroundColor: `${theme.palette.error.main}`,
                  opacity: "0.9",
                },
              }}
              onClick={handleDisabled}
              disabled={product && product.disabled}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                }}
              >
                Выкл.{" "}
              </Typography>
            </Button>

            <Button variant="contained" onClick={disableClose}>
              <Typography
                sx={{
                  fontWeight: "bold",
                }}
              >
                Отменить
              </Typography>
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default DisableEnableModal;
