import { yupResolver } from "@hookform/resolvers/yup";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { createFeaturePrice } from "src/api/featurePrices/create";
import { useTheme } from "@emotion/react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  paddingX: 7,
  paddingTop: 1,
  paddingBottom: 4,
  borderRadius: "10px",
  maxHeight: "90vh",
  overflow: "scroll",
  "@media (max-width: 650px)": {
    width: "90%",
    paddingX: 4,
  },
};

function AddFeaturePriceModal({ notify }) {
  const theme = useTheme();

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const schema = yup.object().shape({
    days: yup.number().integer().min(1).max(100).required(),
    price: yup.number().integer().min(1).required(),
    featureType: yup
      .string()
      .oneOf(["ProductFeatured", "CatalogFeatured"])
      .required(),
  });

  const sendData = async (data) => {
    if (data) {
      try {
        const response = await createFeaturePrice(data);
        if (response) {
          if (notify) {
            notify("add");
          }
          handleClose();
        }
      } catch (error) {
        console.log(error);
        if (notify) {
          notify("error");
        }
        handleClose();
      }
    }
  };

  const submitData = (data) => {
    sendData(data);
    reset();
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <Box>
      <Button onClick={handleOpen} variant="contained" startIcon={<AddIcon />}>
        Новый премиум товар
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="add-feature-price-modal"
        aria-describedby="add-feature-price-modal"
      >
        <Box sx={style}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <IconButton
              aria-label="close"
              onClick={() => {
                handleClose();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              margin: "0",
              padding: "0",
              width: "100%",
              marginBottom: "0.5rem",
              textAlign: "center",
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "1.8rem",
              }}
            >
              Добавить премиум товар
            </Typography>
          </Box>
          <form onSubmit={handleSubmit(submitData)}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                rowGap: "1rem",
              }}
            >
              <FormControl>
                <InputLabel id={`feature-product-price-type-selection-label`}>
                  Feature Type
                </InputLabel>
                <Select
                  required
                  labelId={`feature-product-price-type-selection-label`}
                  defaultValue=""
                  label="Type"
                  error={errors.featureType && true}
                  {...register("featureType")}
                >
                  <MenuItem value="ProductFeatured">Product Featured</MenuItem>
                  <MenuItem value="CatalogFeatured">Catalog Featured</MenuItem>
                </Select>
                {errors.featureType?.message ? (
                  <FormHelperText
                    sx={{
                      color: `${theme.palette.error.main}`,
                    }}
                  >
                    {errors.featureType?.message}
                  </FormHelperText>
                ) : null}
              </FormControl>

              <TextField
                type="number"
                required
                fullWidth
                variant="outlined"
                label="Дни"
                max={100}
                min={1}
                error={errors.days && true}
                helperText={errors.days?.message}
                {...register("days")}
              />

              <TextField
                type="number"
                required
                fullWidth
                variant="outlined"
                label="Цена (в рублях)"
                min={1}
                error={errors.price && true}
                helperText={errors.price?.message}
                {...register("price")}
              />

              <Button
                size="large"
                type="submit"
                variant="contained"
                startIcon={<AddIcon />}
                sx={{ marginTop: "1rem" }}
              >
                Добавить премиум товар
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </Box>
  );
}

export default AddFeaturePriceModal;
