import { useState, useEffect } from 'react';

// mui
import {
    Box,
    Button,
    Typography,
    Modal,
    TextField,
    IconButton,
} from '@mui/material';

// form
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// icons
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

// api
import { createCategoryType } from '../../api/types/createType';
import { CheckBox } from '@mui/icons-material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    paddingX: 7,
    paddingTop: 1,
    paddingBottom: 4,
    borderRadius: '10px',
    maxHeight: '90vh',
    overflow: 'scroll',
    '@media (max-width: 650px)': {
        paddingX: 4,
        width: '90%',
    },
};

function AddCategoryModal({ notify }) {

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [submittedFormData, setSubmittedFormData] = useState(null);

    const schema = yup.object().shape({
        name: yup.string().max(20).required(),
        disabled: yup.boolean(),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const submitCategoryTypeData = (data) => {
        setSubmittedFormData(data);
    };

    // USE EFFECT WHEN EXTERNAL URLS READY, AND SEND ALL DATA
    useEffect(() => {
        if (submittedFormData) {
            const sendAddCategoryData = async () => {
                try {

                    const data = await createCategoryType({...submittedFormData});
                    if (data && data.data) {
                        //console.log('CATEGORY CREATED');
                        setSubmittedFormData(null);
                        reset();
                        if (notify) {
                            notify('add');
                        }
                        handleClose();
                    }
                } catch (error) {
                    console.log(error);
                }
            };
            sendAddCategoryData();
        }
    }, [submittedFormData]);

    const handleDisabledChange = (event) => {   
        setSubmittedFormData({
            ...submittedFormData,
            disabled: event.target.checked,
        });
    }



    return (
        <Box>
            <Button
                onClick={handleOpen}
                variant='contained'
                startIcon={<AddIcon />}
            >
                Новый тип категории
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby='add-category-modal'
                aria-describedby='add-category-modal'
            >
                <Box sx={style}>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'end',
                        }}
                    >
                        <IconButton
                            aria-label='close'
                            onClick={() => {
                                handleClose();
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            margin: '0',
                            padding: '0',
                            width: '100%',
                            marginBottom: '0.5rem',
                            textAlign: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                fontSize: '1.8rem',
                            }}
                        >
                            Добавить новый тип категории
                        </Typography>
                    </Box>
                    <form onSubmit={handleSubmit(submitCategoryTypeData)}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                rowGap: '1rem',
                            }}
                        >

                            <TextField
                                required
                                fullWidth
                                variant={`outlined`}
                                label='Наименование типа категории'
                                error={errors.name && true}
                                helperText={errors.name?.message}
                                {...register('name')}
                            />


                            <Box

                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: 'bold',
                                        fontSize: '1.2rem',
                                    }}
                                >
                                    Выключено
                                </Typography>
                                <CheckBox
                                checked={true}
                                onChange={(e) => handleDisabledChange(e)}
                                {...register('disabled')} />
                            </Box>
                            

                        
                            <Button
                                disabled={submittedFormData ? true : false}
                                size={`large`}
                                type={`submit`}
                                variant='contained'
                                startIcon={<AddIcon />}
                                sx={{ marginTop: '1rem' }}
                            >
                                Добавить
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Modal>
        </Box>
    );
}

export default AddCategoryModal;
