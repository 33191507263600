import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import {
    Card,
    Table,
    Stack,
    Paper,
    Avatar,
    Popover,
    Checkbox,
    TableRow,
    MenuItem,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableContainer,
    TablePagination,
} from '@mui/material';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import {
    WalletListHead,
    WalletListToolbar,
} from 'src/sections/@dashboard/wallets';
// api
import { getWallets } from 'src/api/wallets/getWallets';
import { isoToReadableDate } from 'src/utils/isoToReadableDate';

// ----------------------------------------------------------------------

// IDS SHOULD BE EQUAL TO PROPERTIES IN THE OBJECTS
const TABLE_HEAD = [
    { id: 'username', label: 'Имя пользователя', align: 'left' },
    { id: 'balance', label: 'Баланс', align: 'left' },
    { id: 'lastUpdated', label: 'Обновлён', align: 'left' },
    { id: 'createdAt', label: 'Создан', align: 'left' },
    { id: '' },
];

// ----------------------------------------------------------------------

export default function WalletsPage() {
    console.log('Wallets PAGE');
    const [open, setOpen] = useState(null);

    const [page, setPage] = useState(0);

    const [order, setOrder] = useState('desc');

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState('lastUpdated');

    const [rowsPerPage, setRowsPerPage] = useState(5);

    const [wallets, setWallets] = useState(null);

    const [walletsTotal, setWalletsTotal] = useState(0);

    useEffect(() => {
        setWallets(null);
        setSelected([]);
        const getWalletsData = async () => {
            try {
                console.log('CALLING GET WALLETS');
                const data = await getWallets(
                    rowsPerPage,
                    page,
                    orderBy,
                    order
                );
                if (data && data.data && data.data.wallets) {
                    console.log('DATA GOT WALLETS');
                    setWallets(data.data.wallets);
                }
                if (data && data.data && data.data.totalWallets) {
                    setWalletsTotal(data.data.totalWallets);
                }
            } catch (error) {
                setWallets(null);
                setSelected([]);
            }
        };
        getWalletsData();
    }, [rowsPerPage, page, orderBy, order]);

    const handleOpenMenu = (event) => {
        setOpen(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpen(null);
    };

    const handleRequestSort = (event, property) => {
        setWallets(null);
        setSelected([]);
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        const indeterminate =
            selected.length > 0 &&
            wallets &&
            wallets.length &&
            selected.length < wallets.length;
        if (
            event.target.checked &&
            !indeterminate &&
            wallets &&
            wallets.length > 0
        ) {
            const newSelecteds = wallets.map((wallet) => wallet._id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, walletId) => {
        const selectedIndex = selected.indexOf(walletId);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, walletId);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setWallets(null);
        setSelected([]);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setWallets(null);
        setSelected([]);
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - walletsTotal) : 0;

    const isNotFound = false;

    console.log('ORDER BY: ' + orderBy);
    console.log('ORDER: ' + order);
    console.log('PAGE: ' + page);
    console.log('SELECTED: ' + selected);

    return (
        <>
            <Helmet>
                <title> Wallets | Mentup Dashboard </title>
            </Helmet>

            <Container>
                <Stack
                    direction='row'
                    alignItems='center'
                    justifyContent='space-between'
                    mb={5}
                >
                    <Typography variant='h4' gutterBottom>
                        Кошельки
                    </Typography>
                </Stack>

                <Card>
                    {/* <WalletListToolbar /> */}

                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <WalletListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={
                                        wallets && wallets.length
                                            ? wallets.length
                                            : 0
                                    }
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                />
                                <TableBody>
                                    {wallets &&
                                        wallets.map((wallet, index) => {
                                            const selectedWallet =
                                                selected.indexOf(wallet._id) !==
                                                -1;

                                            return (
                                                <TableRow
                                                    hover
                                                    key={index}
                                                    tabIndex={-1}
                                                    role='checkbox'
                                                    selected={selectedWallet}
                                                >
                                                    {/* <TableCell padding='checkbox'>
                                                        <Checkbox
                                                            checked={
                                                                selectedWallet
                                                            }
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                if (
                                                                    wallet._id
                                                                ) {
                                                                    handleClick(
                                                                        event,
                                                                        wallet._id
                                                                    );
                                                                }
                                                            }}
                                                        />
                                                    </TableCell> */}

                                                    <TableCell align='left'>
                                                        {wallet.user &&
                                                        wallet.user.username ? (
                                                            <Typography>
                                                                {
                                                                    wallet.user
                                                                        .username
                                                                }
                                                            </Typography>
                                                        ) : null}
                                                    </TableCell>

                                                    <TableCell align='left'>
                                                        {wallet.balance ||
                                                        wallet.balance === 0 ? (
                                                            <Typography>
                                                                {`£${wallet.balance}`}
                                                            </Typography>
                                                        ) : null}
                                                    </TableCell>

                                                    <TableCell align='left'>
                                                        {wallet.updatedAt ? (
                                                            <Typography>
                                                                {isoToReadableDate(
                                                                    wallet.updatedAt
                                                                )}
                                                            </Typography>
                                                        ) : null}
                                                    </TableCell>

                                                    <TableCell align='left'>
                                                        {wallet.createdAt ? (
                                                            <Typography>
                                                                {isoToReadableDate(
                                                                    wallet.createdAt
                                                                )}
                                                            </Typography>
                                                        ) : null}
                                                    </TableCell>

                                                    {/* <TableCell align='right'>
                                                        <IconButton
                                                            size='large'
                                                            color='inherit'
                                                            onClick={
                                                                handleOpenMenu
                                                            }
                                                        >
                                                            <Iconify
                                                                icon={
                                                                    'eva:more-vertical-fill'
                                                                }
                                                            />
                                                        </IconButton>
                                                    </TableCell> */}
                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow
                                            style={{ height: 53 * emptyRows }}
                                        >
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>

                                {isNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell
                                                align='center'
                                                colSpan={6}
                                                sx={{ py: 3 }}
                                            >
                                                <Paper
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Typography
                                                        variant='h6'
                                                        paragraph
                                                    >
                                                        Не найдено
                                                    </Typography>

                                                    <Typography variant='body2'>
                                                    Не найдено
                                                        &nbsp;
                                                        <strong>
                                                            &quot;
                                                            {'Search Value'}
                                                            &quot;
                                                        </strong>
                                                        .
                                                        <br /> Попробуйте проверить опечатки или корректируйте запрос.
                                                    </Typography>
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        labelRowsPerPage="Выводить по:"
                        rowsPerPageOptions={[5, 10, 25]}
                        component='div'
                        count={walletsTotal && walletsTotal}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>

            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 140,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            ></Popover>
        </>
    );
}
