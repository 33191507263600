import { Helmet } from 'react-helmet-async';
import Cookies from 'js-cookie';

// @mui
import { styled } from '@mui/material/styles';
import {
    Link,
    Container,
    Typography,
    Divider,
    Stack,
    Button,
} from '@mui/material';

// hooks
import useResponsive from '../hooks/useResponsive';
import { useState, useEffect } from 'react';

// components
import Logo from '../components/logo';
import Iconify from '../components/iconify';

// sections
import { LoginForm } from '../sections/auth/login';
import { Navigate } from 'react-router-dom';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

const StyledSection = styled('div')(({ theme }) => ({
    width: '100%',
    maxWidth: 480,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    boxShadow: theme.customShadows.card,
    backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
    const mdUp = useResponsive('up', 'md');

    const [tokenPresent, setTokenPresent] = useState(null);
    useEffect(() => {
        const token = Cookies.get('refreshToken');
        const user = sessionStorage.getItem('user');
        if (token && user) {
            setTokenPresent(true);
        } else {
            setTokenPresent(false);
        }
    }, []);

    if (tokenPresent === null) {
        return null;
    }

    if (tokenPresent === true) {
        return <Navigate to='/dashboard' replace />;
    }

    if (tokenPresent === false) {
        // Return Login Page
        return (
            <>
                <Helmet>
                    <title> Login | Mentup Dashboard </title>
                </Helmet>

                <StyledRoot>
                    <Logo
                        sx={{
                            position: 'fixed',
                            top: { xs: 16, sm: 24, md: 40 },
                            left: { xs: 16, sm: 24, md: 40 },
                        }}
                    />

                    {mdUp && (
                        <StyledSection>
                            <Typography
                                variant='h3'
                                sx={{ px: 5, mt: 10, mb: 5 }}
                            >
                                Hi, Welcome Back
                            </Typography>
                            <img
                                src='/assets/illustrations/illustration_login.png'
                                alt='login'
                            />
                        </StyledSection>
                    )}

                    <Container maxWidth='sm'>
                        <StyledContent>
                            <Typography variant='h4' gutterBottom>
                                Sign in to Mentup Dashboard
                            </Typography>

                            <Typography variant='body2' sx={{ mb: 5 }}>
                                Don’t have an account? {''}
                                <Link variant='subtitle2'>Get started</Link>
                            </Typography>

                            {/* Social media icons as login options */}

                            {/* <Stack direction="row" spacing={2}>
                  <Button fullWidth size="large" color="inherit" variant="outlined">
                    <Iconify icon="eva:google-fill" color="#DF3E30" width={22} height={22} />
                  </Button>
    
                  <Button fullWidth size="large" color="inherit" variant="outlined">
                    <Iconify icon="eva:facebook-fill" color="#1877F2" width={22} height={22} />
                  </Button>
    
                  <Button fullWidth size="large" color="inherit" variant="outlined">
                    <Iconify icon="eva:twitter-fill" color="#1C9CEA" width={22} height={22} />
                  </Button>
                </Stack>
    
                            <Divider sx={{ my: 3 }}>
                                <Typography
                                    variant='body2'
                                    sx={{ color: 'text.secondary' }}
                                >
                                    OR
                                </Typography>
                            </Divider> */}

                            <LoginForm />
                        </StyledContent>
                    </Container>
                </StyledRoot>
            </>
        );
    } else {
        return null;
    }
}
