import axios from 'axios';
import Cookies from 'js-cookie';

const API = axios.create({
    baseURL: process.env.NODE_ENV.includes('production')
        ? process.env.REACT_APP_SERVER_URL
            ? process.env.REACT_APP_SERVER_URL
            : ''
        : 'http://localhost:3030/api',
});

export const deleteMultipleImages = (codes) => {
    const token = Cookies.get('refreshToken');
    if (token && codes && codes.length > 0) {
        const body = { imageNames: codes };
        console.log('Inside deleteImages');
        return API.delete(`/image/deleteArray`, {
            data: body,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        });
    }
};
