import React from 'react';
import { useState } from 'react';

// mui
import {
    Box,
    Card,
    Button,
    Typography,
    Modal,
    TextField,
    IconButton,
    InputAdornment,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    FormHelperText,
} from '@mui/material';
import { useTheme } from '@mui/material';

// form
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// icons
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';

// api
import { createUser } from 'src/api/users/createUser';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    paddingX: 7,
    paddingTop: 1,
    paddingBottom: 4,
    borderRadius: '10px',
    maxHeight: '90vh',
    overflow: 'scroll',
    '@media (max-width: 650px)': {
        width: '90%',
    },
};

function AddUserModal({ notify }) {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [showPassword, setShowPassword] = useState(false);

    // const [avatar, setAvatar] = useState(null);

    // const [cover, setCover] = useState(null);

    const theme = useTheme();

    const schema = yup.object().shape({
        username: yup.string().min(3).max(20).required(),
        password: yup.string().min(8).max(20).required(),
        email: yup.string().email().required(),
        
        // role: yup.string().oneOf(['admin', 'vendor', 'buyer']).required(),
        // description: yup.string().max(300),
        // theme: yup.string().oneOf(['light', 'dark']).required(),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const submitUserData = (data) => {
        if (data) {
            sendUserData(data);
        }
    };

    const sendUserData = async (userData) => {
        try {
            const response = await createUser({
                ...userData,
                country: 'GB',
            });
            if (response) {
                console.log('RESPONSE:' + response);
                if (notify) {
                    notify('add');
                    resetAll();
                    handleClose();
                }
            }
        } catch (error) {
            console.log(error);
            if (notify) {
                notify('error');
                resetAll();
            }
            handleClose();
        }
    };

    // const handleAvatarChange = (event) => {
    //     const picture = event.target.files[0];
    //     if (picture) {
    //         const tempPictureUrl = URL.createObjectURL(picture);
    //         setAvatar({
    //             file: picture,
    //             url: tempPictureUrl,
    //             externalUrl: null,
    //         });
    //     }
    // };

    // const handleCoverChange = (event) => {
    //     const picture = event.target.files[0];
    //     if (picture) {
    //         const tempPictureUrl = URL.createObjectURL(picture);
    //         setCover({
    //             file: picture,
    //             url: tempPictureUrl,
    //             externalUrl: null,
    //         });
    //     }
    // };

    // const removeAvatar = () => {
    //     setAvatar(null);
    // };

    // const removeCover = () => {
    //     setCover(null);
    // };

    const resetAll = () => {
        reset();
        setShowPassword(false);
    };

    return (
        <Box>
            <Button
                onClick={handleOpen}
                variant='contained'
                startIcon={<AddIcon />}
            >
                Добавить пользователя
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby='add-user-modal'
                aria-describedby='add-user-modal'
            >
                <Box sx={style}>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'end',
                        }}
                    >
                        <IconButton
                            aria-label='close'
                            onClick={() => {
                                handleClose();
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            margin: '0',
                            padding: '0',
                            width: '100%',
                            marginBottom: '0.5rem',
                            textAlign: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                fontSize: '1.8rem',
                            }}
                        >
                            Добавить нового пользователя
                        </Typography>
                    </Box>
                    <form onSubmit={handleSubmit(submitUserData)}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                rowGap: '1rem',
                            }}
                        >
                            <Box
                                sx={{
                                    width: '100%',
                                    height: 'fit-content',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                {/* <Box sx={{ height: '100px', width: '100px' }}>
                                    <input
                                        hidden
                                        accept='image/*'
                                        type='file'
                                        onChange={handleAvatarChange}
                                        onClick={(e) => (e.target.value = null)}
                                        id={`user-image-upload-input`}
                                    />
                                    <label htmlFor={`user-image-upload-input`}>
                                        <Box
                                            sx={{
                                                position: 'relative',
                                                left: '10px',
                                                width: '100%',
                                                height: '100%',
                                            }}
                                        >
                                            <IconButton
                                                sx={{
                                                    position: 'absolute',
                                                    top: '0',
                                                    right: '0',
                                                }}
                                                onClick={removeAvatar}
                                            >
                                                <CloseIcon
                                                    sx={{ fontSize: '1rem' }}
                                                />
                                            </IconButton>
                                            <Button
                                                component='span'
                                                sx={{
                                                    position: 'relative',
                                                    width: '80px',
                                                    height: '80px',
                                                    borderRadius: '50%',
                                                }}
                                            >
                                                {avatar && avatar.url ? (
                                                    <Box
                                                        sx={{
                                                            width: '100%',
                                                            height: '100%',
                                                            backgroundImage: `url("${avatar.url}")`,
                                                            backgroundSize:
                                                                'cover',
                                                            backgroundPosition:
                                                                'center',
                                                            backroundRepeat:
                                                                'no-repeat',
                                                            borderRadius: '50%',
                                                        }}
                                                    ></Box>
                                                ) : (
                                                    <AccountCircleIcon
                                                        sx={{
                                                            fontSize: '5rem',
                                                            position:
                                                                'absolute',
                                                            top: '50%',
                                                            left: '50%',
                                                            translate:
                                                                '-50% -50%',
                                                        }}
                                                    />
                                                )}
                                            </Button>
                                        </Box>
                                    </label>
                                </Box> */}
                            </Box>

                            {/* <Box
                                sx={{
                                    width: '100%',
                                    height: '100px',
                                    textAlign: 'left',
                                    // marginBottom: '1.5rem',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: 'bold',
                                        fontSize: '1rem',
                                    }}
                                >{`Cover Image`}</Typography>
                                <input
                                    hidden
                                    accept='image/*'
                                    type='file'
                                    onChange={handleCoverChange}
                                    onClick={(e) => (e.target.value = null)}
                                    id={`add-user-cover-upload-input`}
                                />
                                <label
                                    htmlFor={`add-user-cover-upload-input`}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                    }}
                                >
                                    <Card
                                        sx={{
                                            width: '100%',
                                            height: '100%',
                                            position: 'relative',
                                            ...(cover &&
                                                cover.file &&
                                                cover.url && {
                                                    backgroundImage: `url('${cover.url}')`,
                                                }),
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                            backgroundRepeat: 'no-repeat',
                                        }}
                                    >
                                        <IconButton
                                            sx={{
                                                position: 'absolute',
                                                top: '0',
                                                right: '0',
                                                zIndex: '1',
                                            }}
                                            onClick={removeCover}
                                        >
                                            <CloseIcon
                                                sx={{ fontSize: '1rem' }}
                                            />
                                        </IconButton>
                                        <Button
                                            component='span'
                                            sx={{
                                                position: 'relative',
                                                borderRadius: '10px',
                                                width: '100%',
                                                height: '100%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'end',
                                                textTransform: 'none',
                                                backgroundColor: '#bfbfbf',
                                                '&:hover': {
                                                    backgroundColor: '#a3a3a3',
                                                },
                                                ...(cover &&
                                                    cover.url && {
                                                        opacity: '0.4',
                                                    }),
                                            }}
                                        >
                                            <CameraAltRoundedIcon
                                                sx={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    translate: '-50% -50%',
                                                }}
                                            />
                                        </Button>
                                    </Card>
                                </label>
                            </Box> */}

                            <TextField
                                required
                                fullWidth
                                variant={`outlined`}
                                label='Имя пользователя'
                                error={errors.username && true}
                                helperText={errors.username?.message}
                                {...register('username')}
                            />
                            <TextField
                                required
                                fullWidth
                                variant={`outlined`}
                                label='E-mail'
                                error={errors.email && true}
                                helperText={errors.email?.message}
                                {...register('email')}
                            />
                            <TextField
                                required
                                fullWidth
                                type={showPassword ? 'text' : `password`}
                                variant={`outlined`}
                                label='Пароль'
                                error={errors.password && true}
                                helperText={errors.password?.message}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <IconButton
                                                onClick={() =>
                                                    setShowPassword(
                                                        (prevState) =>
                                                            !prevState
                                                    )
                                                }
                                                edge='end'
                                            >
                                                <VisibilityIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                {...register('password')}
                            />
                            {/* <FormControl fullWidth>
                                <InputLabel id='add-user-input-role-label'>
                                    Role
                                </InputLabel>
                                <Select
                                    required
                                    labelId='add-user-input-role-label'
                                    id='add-user-role-input'
                                    label='Role'
                                    defaultValue={'buyer'}
                                    error={errors.role && true}
                                    {...register('role')}
                                >
                                    <MenuItem value={`buyer`}>Buyer</MenuItem>
                                    <MenuItem value={`vendor`}>Vendor</MenuItem>
                                    <MenuItem value={`admin`}>Admin</MenuItem>
                                </Select>
                                {errors.role?.message ? (
                                    <FormHelperText
                                        sx={{
                                            color: `${theme.palette.error.main}`,
                                        }}
                                    >
                                        {errors.role?.message}
                                    </FormHelperText>
                                ) : null}
                            </FormControl> */}
                            {/* <TextField
                                multiline
                                rows={4}
                                fullWidth
                                variant={`outlined`}
                                label='Description'
                                error={errors.description && true}
                                helperText={errors.description?.message}
                                {...register('description')}
                            /> */}
                            {/* <FormControl fullWidth>
                                <InputLabel id='add-user-input-theme-label'>
                                    Theme
                                </InputLabel>
                                <Select
                                    required
                                    labelId='add-user-input-theme-label'
                                    id='add-user-theme-input'
                                    label='Theme'
                                    defaultValue={'dark'}
                                    error={errors.theme && true}
                                    {...register('theme')}
                                >
                                    <MenuItem value={`light`}>Light</MenuItem>
                                    <MenuItem value={`dark`}>Dark</MenuItem>
                                </Select>
                                {errors.theme?.message ? (
                                    <FormHelperText
                                        sx={{
                                            color: `${theme.palette.error.main}`,
                                        }}
                                    >
                                        {errors.theme?.message}
                                    </FormHelperText>
                                ) : null}
                            </FormControl> */}
                            <Button
                                size={`large`}
                                type={`submit`}
                                variant='contained'
                                startIcon={<AddIcon />}
                                sx={{ marginTop: '1rem' }}
                            >
                                Добавить пользователя
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Modal>
        </Box>
    );
}

export default AddUserModal;
