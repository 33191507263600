import axios from 'axios';
import Cookies from 'js-cookie';

const API = axios.create({
    baseURL: process.env.NODE_ENV.includes('production')
        ? process.env.REACT_APP_SERVER_URL
            ? process.env.REACT_APP_SERVER_URL
            : ''
        : 'http://localhost:3030/api',
});

export const getPromocodesNoSorting = () => {
    const token = Cookies.get('refreshToken');
    // if (token) {
    console.log('Inside getPromocodesNoSorting');
    return API.get(`/promo`);
    // }
};
