import { useState, useEffect } from 'react';

// mui
import {
    Box,
    Button,
    Typography,
    Modal,
    TextField,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
} from '@mui/material';

// form
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// icons
import CloseIcon from '@mui/icons-material/Close';
import EditRoundedIcon from '@mui/icons-material/EditRounded';

// api
import { updateCategoryType } from '../../api/types/updateType';
import { getAllTypes } from 'src/api/types/getTypes';
import { updateAttrType } from 'src/api/typesAttrs/updateAttrType';
import { getAllCategories } from 'src/api/categories/getAllCategories';
import { editGroupType } from 'src/api/groupTypes/createGroupType';

// api

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    paddingX: 7,
    paddingTop: 1,
    paddingBottom: 4,
    borderRadius: '10px',
    maxHeight: '90vh',
    overflow: 'scroll',
    '@media (max-width: 650px)': {
        paddingX: 4,
        width: '90%',
    },
};

function EditAttrTypeModal({ attr, editFinished }) {

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [readyToSubmit, setReadyToSubmit] = useState(false);

    const [submittedFormData, setSubmittedFormData] = useState(null);

    const [currentAttr, setCurrentAttr] = useState(attr);

    const schema = yup.object().shape({
        name: yup.string().max(20).required(),
        groupType: yup.string().required(),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(schema),
    })

    const submitCategoryTypeEditData = (data) => {
        setSubmittedFormData({...data});
        setReadyToSubmit(true);
    };

    // USE EFFECT WHEN EXTERNAL URLS READY, AND SEND ALL DATA
    useEffect(() => {
        if (submittedFormData && readyToSubmit) {
            const sendUpdatedCategoryData = async () => {
                try {
                    if (submittedFormData.name !== null) {

                        const data = await editGroupType(
                            attr._id,
                            {
                                ...submittedFormData,
                            },
                        );

                        if (data && data.data) {
                            resetAll();
                            if (editFinished) {
                                editFinished('edit');
                            }
                            reset();
                            handleClose();
                        }
                    }
                } catch (error) {
                    console.log(error);
                    if (editFinished) {
                        editFinished('error');
                    }
                    resetAll();
                    handleClose();
                }
            };
            sendUpdatedCategoryData();
        }
    }, [submittedFormData]);


    const resetAll = () => {
        reset();
        setSubmittedFormData(null);
    };

    const handleNameChange = (e) => {
        setReadyToSubmit(false);
        setCurrentAttr({...currentAttr, name: e.target.value});
    }

    const handleSelectChange = (e) => {
        setReadyToSubmit(false);
        setCurrentAttr({...currentAttr, groupType: e.target.value});
    }

    return (
        <Box>
            <MenuItem onClick={handleOpen}>
                <EditRoundedIcon sx={{ mr: 2 }} /> Edit{' '}
            </MenuItem>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby='edit-category-modal'
                aria-describedby='edit-category-modal'
            >
                <Box sx={style}>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'end',
                        }}
                    >
                        <IconButton
                            aria-label='close'
                            onClick={() => {
                                handleClose();
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            margin: '0',
                            padding: '0',
                            width: '100%',
                            marginBottom: '0.5rem',
                            textAlign: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                fontSize: '1.8rem',
                            }}
                        >
                            {currentAttr?.name
                                ? `Edit category type: ${currentAttr.name}`
                                : `Edit Category`}
                        </Typography>
                    </Box>
                    <form onSubmit={handleSubmit(submitCategoryTypeEditData)}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                rowGap: '1rem',
                            }}
                        >

                            <TextField
                                required
                                fullWidth
                                variant={`outlined`}
                                label='Attribute Name'
                                error={errors.name && true}
                                helperText={errors.name?.message}
                                {...register('name')}
                                value={
                                    currentAttr?.name
                                        ? currentAttr.name
                                        : ''
                                }
                                onChange={(e) => handleNameChange(e)}
                            />

                           {/* enum: ['single', 'range', "choice", 'switch'], */}
                           <FormControl
                                fullWidth
                                variant='outlined'
                                error={errors.groupType && true}
                            >
                                <InputLabel id='type-label'>Тип группы</InputLabel>
                                <Select
                                    labelId='type-label'
                                    label='Тип группы'
                                    {...register('groupType')}
                                    value={currentAttr?.groupType}
                                    onChange={(e) => handleSelectChange(e)}
                                >
                                    <MenuItem value='single'>Одиночный</MenuItem>
                                    <MenuItem value='range'>Диапазон</MenuItem>
                                    <MenuItem value='choice'>Выбор</MenuItem>
                                    <MenuItem value='switch'>Переключатель</MenuItem>
                                </Select>
                                <FormHelperText>
                                    {errors.groupType?.message}
                                </FormHelperText>
                            </FormControl>

                            <Button
                                disabled={submittedFormData ? true : false}
                                size={`large`}
                                type={`submit`}
                                variant='contained'
                                sx={{ marginTop: '1rem' }}
                            >
                                Submit
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Modal>
        </Box>
    );
}

export default EditAttrTypeModal;
