import {
  Alert,
  Card,
  Container,
  IconButton,
  Popover,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";

import { deleteFeaturePrice } from "src/api/featurePrices/delete";
import { getFeaturePrices } from "src/api/featurePrices/list";
import AddFeaturePriceModal from "src/components/add-feature-price-modal";
import DeleteItemModal from "src/components/delete-item-modal";
import TableListHead from "src/sections/@dashboard/table/TableListHead";
import { isoToReadableDate } from "src/utils/isoToReadableDate";
import Iconify from "../components/iconify";
import Scrollbar from "../components/scrollbar";

const TABLE_HEAD = [
  { id: "days", label: "Days", align: "center" },
  { id: "price", label: "Price", align: "center" },
  { id: "featureType", label: "Feature Type", align: "center" },
  { id: "createdAt", label: "Created At", align: "right" },
  { id: "" },
];

export default function FeaturePricesPage() {
  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [items, setItems] = useState([]);
  const [order, setOrder] = useState("asc");
  const [success, setSuccess] = useState(false);
  const [orderBy, setOrderBy] = useState("days");
  const [itemsTotal, setItemsTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const getItems = useCallback(async () => {
    try {
      const data = await getFeaturePrices(rowsPerPage, page, orderBy, order);
      if (data?.data?.items) {
        setItems(data.data.items);
      }
      if (data?.data?.totalItems) {
        setItemsTotal(data.data.totalItems);
      }
    } catch (error) {
      setItems([]);
    }
  }, [order, orderBy, page, rowsPerPage]);

  useEffect(() => {
    getItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Display success message when success message is set
  useEffect(() => {
    if (successMessage) {
      handleSuccessOpen();
    }
  }, [successMessage]);

  const handleOpenMenu = (event, item) => {
    if (item) {
      setOpen(event.currentTarget);
      setItemToDelete(item);
    }
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);

    getItems();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getItems();
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
    getItems();
  };

  const notifyItemAdded = (message) => {
    if (message === "add" || message === "delete") {
      setPage(0);
      getItems();
    }
    handleCloseMenu();
    switch (message) {
      case "add":
        setSuccessMessage("Предмет добавлен!");
        break;
      case "delete":
        setSuccessMessage("Предмет удалён!");
        break;
      case "error":
        setSuccessMessage("Произошла ошибка");
        break;
      default:
        setSuccessMessage(null);
        break;
    }
  };

  const handleSuccessOpen = () => {
    setSuccess(true);
  };

  const handleSuccessClose = () => {
    setSuccess(false);
    setSuccessMessage(null);
  };

  const onDelete = async (id) => {
    try {
      const response = await deleteFeaturePrice(id);
      if (response?.data) {
        await getItems();
      }
      notifyItemAdded("delete");
    } catch (error) {
      notifyItemAdded("error");
      setItems([]);
    }
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - itemsTotal) : 0;

  return (
    <>
      <Helmet>
        <title>Feature prices | Mentup Dashboard </title>
      </Helmet>

      <Container>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={success}
          autoHideDuration={6000}
          onClose={handleSuccessClose}
        >
          <Alert
            variant="filled"
            onClose={handleSuccessClose}
            severity={successMessage === "Error occured" ? "error" : "success"}
            sx={{ width: "100%" }}
          >
            {successMessage && successMessage}
          </Alert>
        </Snackbar>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Премиум товар
          </Typography>

          <AddFeaturePriceModal notify={notifyItemAdded} />
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={items && items.length ? items.length : 0}
                  onRequestSort={handleRequestSort}
                />

                <TableBody>
                  {items.map((item, index) => (
                    <TableRow hover key={index} tabIndex={-1} role="checkbox">
                      <TableCell align="center">
                        <Typography>{item.days}</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography>{item.price}₽</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography>{item.featureType}</Typography>
                      </TableCell>

                      <TableCell align="right">
                        <Typography>
                          {isoToReadableDate(item.createdAt)}
                        </Typography>
                      </TableCell>

                      <TableCell align="right">
                        <IconButton
                          size="large"
                          color="inherit"
                          onClick={(e) => {
                            if (item) {
                              handleOpenMenu(e, item);
                            }
                          }}
                        >
                          <Iconify icon={"eva:more-vertical-fill"} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={itemsTotal && itemsTotal}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            "& .MuiMenuItem-root": {
              px: 1,
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        <DeleteItemModal
          displayKey="days"
          item={itemToDelete}
          handleDelete={onDelete}
        />
      </Popover>
    </>
  );
}
