import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { visuallyHidden } from '@mui/utils';
import { nanoid } from 'nanoid';

// function createData(id, productName, price, quantity, total) {
//     return { id, productName, price, quantity, total };
// }

// const rows = [
//     createData(nanoid(), 'Product2', 10, 2, 20),
//     createData(nanoid(), 'Product3', 15, 2, 30),
//     createData(nanoid(), 'Product1', 20, 2, 40),
//     createData(nanoid(), 'Product2', 10, 2, 20),
//     createData(nanoid(), 'Product3', 15, 2, 30),
//     createData(nanoid(), 'Product1', 20, 2, 40),
//     createData(nanoid(), 'Product2', 10, 2, 20),
//     createData(nanoid(), 'Product3', 15, 2, 30),
// ];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'productName',
        numeric: false,
        disablePadding: true,
        label: 'Наименование',
    },
    {
        id: 'price',
        numeric: true,
        disablePadding: false,
        label: 'Цена',
    },
    {
        id: 'quantity',
        numeric: true,
        disablePadding: false,
        label: 'Кол-во',
    },
    {
        id: 'total',
        numeric: true,
        disablePadding: false,
        label: 'Итого',
    },
];

function OrderProductsTableHead(props) {
    const {
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {/* <TableCell padding='checkbox'>
                    <Checkbox
                        color='primary'
                        indeterminate={
                            numSelected > 0 && numSelected < rowCount
                        }
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all products',
                        }}
                    />
                </TableCell> */}
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'center'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        // sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

OrderProductsTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

function OrderProductsTableToolbar(props) {
    const { numSelected } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(
                            theme.palette.primary.main,
                            theme.palette.action.activatedOpacity
                        ),
                }),
            }}
        >
            {/* {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color='inherit'
                    variant='subtitle1'
                    component='div'
                >
                    {numSelected} selected
                </Typography>
            ) : ( */}
            <Typography
                sx={{ flex: '1 1 100%' }}
                variant='h6'
                id='tableTitle'
                component='div'
            >
                Товары
            </Typography>
            {/* )} */}

            {/* {numSelected > 0 ? (
                <Tooltip title='Delete'>
                    <IconButton>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title='Filter list'>
                    <IconButton>
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>
            )} */}
        </Toolbar>
    );
}

OrderProductsTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

export default function OrderProductsTable({ orderData }) {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (
            event.target.checked &&
            orderData &&
            orderData.products &&
            orderData.products.length
        ) {
            const newSelected = orderData.products.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    let emptyRows = 0;
    if (orderData && orderData.products && orderData.products.length > 0) {
        console.log('INSIDE IF');
        emptyRows =
            page > 0
                ? Math.max(
                      0,
                      (1 + page) * rowsPerPage - orderData.products.length
                  )
                : 0;
    }

    // const visibleRows = React.useMemo(
    //     () =>
    //         stableSort(rows, getComparator(order, orderBy)).slice(
    //             page * rowsPerPage,
    //             page * rowsPerPage + rowsPerPage
    //         ),
    //     [order, orderBy, page, rowsPerPage]
    // );

    //console.log('Empty rows: ' + emptyRows);

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <OrderProductsTableToolbar numSelected={selected.length} />
                <TableContainer>
                    <Table
                        sx={{
                            width: '100%',
                            maxWidth: '100%',
                        }}
                        aria-labelledby='tableTitle'
                        size={dense ? 'small' : 'medium'}
                    >
                        <OrderProductsTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={
                                orderData &&
                                orderData.products &&
                                orderData.products.length
                                    ? orderData.products.length
                                    : 0
                            }
                        />
                        <TableBody>
                            {orderData &&
                            orderData.products &&
                            orderData.products.length
                                ? orderData.products.map((product, index) => {
                                      //   const isItemSelected = isSelected(row.id);
                                      const labelId = `order-products-table-checkbox-${index}`;

                                      return (
                                          <TableRow
                                              key={index}
                                              hover
                                              //   onClick={(event) =>
                                              //       handleClick(event, row.id)
                                              //   }
                                              //   role='checkbox'
                                              //   aria-checked={isItemSelected}
                                              tabIndex={-1}
                                              //   selected={isItemSelected}
                                              //   sx={{ cursor: 'pointer' }}
                                          >
                                              {/* <TableCell padding='checkbox'>
                                            <Checkbox
                                                color='primary'
                                                checked={isItemSelected}
                                                inputProps={{
                                                    'aria-labelledby': labelId,
                                                }}
                                            />
                                        </TableCell> */}
                                              <TableCell
                                                  component='th'
                                                  id={labelId}
                                                  scope='row'
                                              >
                                                  {product && product.name
                                                      ? product.name
                                                      : null}
                                              </TableCell>
                                              <TableCell align='center'>
                                                  {/* {row.price} */}
                                                  {product &&
                                                  (product.price ||
                                                      product.price === 0)
                                                      ? `£ ${product.price}`
                                                      : null}
                                              </TableCell>
                                              <TableCell align='center'>
                                                  <Box
                                                      sx={{
                                                          display: 'flex',
                                                          flexDirection: 'row',
                                                          alignItems: 'center',
                                                      }}
                                                  >
                                                      {/* <IconButton
                                                          sx={{
                                                              backgroundColor: (
                                                                  theme
                                                              ) =>
                                                                  theme.palette
                                                                      .background
                                                                      .default,
                                                          }}
                                                      >
                                                          <RemoveIcon />
                                                      </IconButton> */}
                                                      <span
                                                          style={{
                                                              margin: '0 1rem',
                                                          }}
                                                      >
                                                          {/* {row.quantity} */}
                                                          {product &&
                                                          product._id &&
                                                          orderData &&
                                                          orderData.productQauntities &&
                                                          orderData
                                                              .productQauntities[
                                                              product._id
                                                          ]
                                                              ? `${
                                                                    orderData
                                                                        .productQauntities[
                                                                        product
                                                                            ._id
                                                                    ]
                                                                }`
                                                              : null}
                                                      </span>
                                                      {/* <IconButton
                                                          sx={{
                                                              backgroundColor: (
                                                                  theme
                                                              ) =>
                                                                  theme.palette
                                                                      .background
                                                                      .default,
                                                          }}
                                                      >
                                                          <AddIcon />
                                                      </IconButton> */}
                                                  </Box>
                                              </TableCell>
                                              <TableCell align='center'>
                                                  {/* {row.total} */}
                                                  {orderData &&
                                                  orderData.transaction &&
                                                  orderData.transaction.total
                                                      ? `£ ${orderData.transaction.total}`
                                                      : null}
                                              </TableCell>
                                          </TableRow>
                                      );
                                  })
                                : null}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    labelRowsPerPage="Выводить по:"
                    rowsPerPageOptions={[5, 10, 25]}
                    component='div'
                    count={
                        orderData &&
                        orderData.products &&
                        orderData.products.length
                            ? orderData.products.length
                            : 0
                    }
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            {/* <FormControlLabel
                control={
                    <Switch checked={dense} onChange={handleChangeDense} />
                }
                label='Dense padding'
            /> */}
        </Box>
    );
}
