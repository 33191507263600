import axios from 'axios';
import Cookies from 'js-cookie';

const API = axios.create({
    baseURL: process.env.NODE_ENV.includes('production')
        ? process.env.REACT_APP_SERVER_URL
            ? process.env.REACT_APP_SERVER_URL
            : ''
        : 'http://localhost:3030/api',
});

export const changeWithdrawStatus = (newStatus, withdrawalId) => {
    const token = Cookies.get('refreshToken');
    if (token && newStatus && withdrawalId) {
        console.log('Inside changeWithdrawStatus');
        return API.patch(
            `/withdrawal/${withdrawalId}`,
            { status: newStatus },
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            }
        );
    }
};
