import axios from "axios";
import Cookies from "js-cookie";

const API = axios.create({
  baseURL:
    (process.env.NODE_ENV.includes("production") &&
      process.env.REACT_APP_SERVER_URL) ||
    "http://localhost:3030/api",
});

export const createFeaturePrice = (body) => {
  const token = Cookies.get("refreshToken");

  if (token && body) {
    return API.post(`/feature-prices`, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  }
};
