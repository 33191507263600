import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
// @mui
import {
    Card,
    Table,
    Stack,
    Paper,
    Popover,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableContainer,
    TablePagination,
    Alert,
    Snackbar,
    CircularProgress,
    Box,
    MenuItem,
    Tabs,
    Tab,
    FormControl,
    TextField,
    Button,
    Breadcrumbs,
} from '@mui/material';
// Icons
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import EditCategoryModal from '../components/edit-category-modal';
import DeleteCategoryModal from 'src/components/delete-category-modal';
import AddCategoryModal from 'src/components/add-category-modal';
import CategoerySeoForm from 'src/components/category-seo-form/CategorySeoFrom';

// sections
import {
    CategoryListHead,
    CategoryListToolbar,
} from 'src/sections/@dashboard/categories';
import { isoToReadableDate } from 'src/utils/isoToReadableDate';
// api
import { getCategoryData, getCategoryTypes } from 'src/api/categories/getCategoryData';
import { getTypes } from '../api/types/getTypes'

// utils
import { nanoid } from 'nanoid';
import { useNavigate, useParams } from 'react-router-dom';

// ----------------------------------------------------------------------

// IDS SHOULD BE EQUAL TO PROPERTIES IN THE OBJECTS
const TABLE_HEAD = [
    { id: 'name', label: 'Name', align: 'left' },
    { id: 'quantity', label: 'Number of products', align: 'left' },
    { id: 'lastUpdated', label: 'Updated on', align: 'left' },
    { id: '' },
];

// ----------------------------------------------------------------------

export default function CategoriesPage() {

    const {slug} = useParams();
    //console.log('CATEGORIES PAGE');
    const [open, setOpen] = useState(null);

    const [page, setPage] = useState(0);

    const [order, setOrder] = useState('desc');

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState('lastUpdated');

    const [rowsPerPage, setRowsPerPage] = useState(5);

    const [categories, setCategories] = useState(null);

    const [category, setCategory] = useState(null);

    const [categoriesTotal, setCategoriesTotal] = useState(0);

    const [categoryAddedNotification, setCategoryAddedNotification] =
        useState(null);

    const [success, setSuccess] = useState(false);

    const [successMessage, setSuccessMessage] = useState(null);

    const [categoryToEdit, setCategoryToEdit] = useState(null);

    const [loading, setLoading] = useState(false);

    const [allTypes, setAllTypes] = useState(null);

    const [alert, setAlert] = useState(null);

    useEffect(() => {
        if(slug) {
          const getCategory = async () => {
            const res = await getCategoryData(slug);
            if (res.data) {
                setCategory(res.data);
              setAllTypes(res.data.productTypes);
            }
          }
          getCategory();
        }
    }, [slug]);

    // Display success message when success message is set
    useEffect(() => {
        if (successMessage) {
            handleSuccessOpen();
        }
    }, [successMessage]);

    const notifyCategoryAdded = (message) => {
        setPage(0);
        setCategoryAddedNotification(nanoid());
        switch (message) {
            case 'add':
                setSuccessMessage('Тип категории добавлен!');
                break;
            case 'edit':
                setSuccessMessage('Тип категории отредактирован!');
                break;
            case 'delete':
                setSuccessMessage('Категория удалена!');
                break;
            case 'error':
                setSuccessMessage('Произошла ошибка');
                break;
            default:
                setSuccessMessage(null);
                break;
        }
    };

    const handleOpenMenu = (event, category) => {
        if (category) {
            setOpen(event.currentTarget);
            setCategoryToEdit(category);
        }
    };

    const handleCloseMenu = () => {
        setCategoryToEdit(null);
        setOpen(null);
    };

    const handleRequestSort = (event, property) => {
        setCategories(null);
        setSelected([]);
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        const indeterminate =
            selected.length > 0 &&
            categories &&
            categories.length &&
            selected.length < categories.length;
        if (
            event.target.checked &&
            !indeterminate &&
            categories &&
            categories.length > 0
        ) {
            const newSelecteds = categories.map((category) => category._id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, categoryId) => {
        const selectedIndex = selected.indexOf(categoryId);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, categoryId);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setCategories(null);
        setSelected([]);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setCategories(null);
        setSelected([]);
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleSuccessOpen = () => {
        setSuccess(true);
    };

    const handleSuccessClose = () => {
        setSuccess(false);
        setSuccessMessage(null);
    };

    const handleEditFinished = (message) => {
        setCategoryToEdit(null);
        handleCloseMenu();
        if (message) {
            notifyCategoryAdded(message);
        }
    };


    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - categoriesTotal) : 0;

    const isNotFound = false;

    const navigate = useNavigate();

    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
      setTabValue(newValue);
    }

    return (
        <>
            <Helmet>
                <title> Categories SEO | Mentup Dashboard </title>
            </Helmet>

            <Container>
                {/*
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    open={success}
                    autoHideDuration={6000}
                    onClose={handleSuccessClose}
                >
                    <Alert
                        variant='filled'
                        onClose={handleSuccessClose}
                        severity={
                            successMessage === 'Error occured'
                                ? 'Ошибка'
                                : 'Успешно'
                        }
                        sx={{ width: '100%' }}
                    >
                        {successMessage && successMessage}
                    </Alert>
                    </Snackbar> */}
                <Breadcrumbs aria-label='breadcrumb' sx={{ mb: 1 }}>
                    <Typography
                        variant='h6'
                        gutterBottom
                        sx={{ 
                          textTransform: 'capitalize', 
                          alignItems: 'center', 
                          display: 'flex',
                          transition: 'all 0.2s ease-in-out',
                          borderRadius: '0.5rem',
                          py: 0.5,
                          px: 1,
                          '&:hover': {
                            cursor: 'pointer',
                            backgroundColor: 'grey.400',
                          }

                        }}
                        onClick={() => navigate('/dashboard/categories')}
                    >
                        <ArrowBackIcon sx={{ mr: 1 }}/> Back to categories
                    </Typography>
                </Breadcrumbs>
                <Stack
                    direction='row'
                    alignItems='center'
                    justifyContent='space-between'
                    mb={3}
                >
                    <Typography variant='h4' gutterBottom>
                        Categories SEO - {category && category.name}
                    </Typography>
                    {/*
                    <AddCategoryModal notify={notifyCategoryAdded} /> */}
                </Stack>

                <Card>
                    {/*
                    <CategoryListToolbar 
                        handleSearchGlobal={handleSearch}
                      />  */}
                    {loading ? (
                      <CircularProgress 
                        sx={{
                          position: 'relative',
                          top: '50%',
                          left: '50%',
                        }}
                      />
                    ) : (
                      <>
                         <Tabs
                          value={tabValue}
                          onChange={handleTabChange}
                          variant="scrollable"
                          scrollButtons="auto"
                          aria-label="scrollable auto tabs example"
                        >
                          {allTypes && allTypes.map((type, index) => (
                            <Tab key={index} label={type.name} value={index} />
                          ))}
                        </Tabs>

                        {allTypes && allTypes.map((type, index) => (
                          tabValue === index && (
                            <CategoerySeoForm 
                              key={index}
                              type={type}
                              notify={notifyCategoryAdded}
                              categoryName={category.name}
                            />
                          )
                        ))}
                      </>
                    )}
                </Card>
            </Container>

          {/*
            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 140,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <EditCategoryModal
                    category={categoryToEdit}
                    editFinished={handleEditFinished}
                    notify={notifyCategoryAdded}
                />
                <Box>
                    <MenuItem onClick={() => navigate('/dashboard/categories/seo/'+ categoryToEdit._id)}>
                        <EditRoundedIcon sx={{ mr: 2 }} /> Edit SEO{' '}
                    </MenuItem>
                </Box>
            </Popover>
            */}
        </>
    );
}
