import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
// @mui
import {
    Card,
    Stack,
    Container,
    Typography,
    Alert,
    Snackbar,
    CircularProgress,
    Breadcrumbs,
    Grid,
    FormControl,
    Button,
    Box,
    TextField,
} from '@mui/material';

// Icons
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// utils
import { useNavigate, useParams } from 'react-router-dom';

// api
import { getPageSeoStaticSingle } from '../api/seo/getPageSeoStaticSingle';

import { updatePageSeoStatic } from '../api/seo/updatePageSeoStatic';




export default function SeoPageSingle() {

    const {name} = useParams();

    const [alert, setAlert] =
        useState(null);
    const [success, setSuccess] = useState(false);

    // States
    const [metaTitle, setMetaTitle] = useState('')
    const [graphImage, setGraphImage] = useState('')
    const [chronicalUrl, setChronicalUrl] = useState('')
    const [metaDescription, setMetaDescription] = useState('')
    const [metaKeywords, setMetaKeywords] = useState('')
    const [graphTitle, setGraphTitle] = useState('')
    const [graphType, setGraphType] = useState('')
    const [graphUrl, setGraphUrl] = useState('')
    const [graphSiteName, setGraphSiteName] = useState('')
    const [graphDescription, setGraphDescription] = useState('')
    const [url, setUrl] = useState('')

    // Handlers
    const handleMetaTitle = (e) => {
        setMetaTitle(e.target.value)
    }
    const handleGraphImage = (e) => {
        setGraphImage(e.target.value)
    }
    const handleChronicalUrl = (e) => {
        setChronicalUrl(e.target.value)
    }
    const handleMetaDescription = (e) => {
        setMetaDescription(e.target.value)
    }
    const handleMetaKeywords = (e) => {
        setMetaKeywords(e.target.value)
    }
    const handleGraphTitle = (e) => {
        setGraphTitle(e.target.value)
    }
    const handleGraphType = (e) => {
        setGraphType(e.target.value)
    }
    const handleGraphUrl = (e) => {
        setGraphUrl(e.target.value)
    }
    const handleGraphSiteName = (e) => {
        setGraphSiteName(e.target.value)
    }
    const handleGraphDescription = (e) => {
        setGraphDescription(e.target.value)
    }
    const handleUrl = (e) => {
        setUrl(e.target.value)
    }

    const handleSuccessClose = () => {
        setSuccess(false);
    };

    const handleSuccessOpen = () => {
        setSuccess(true);
    };

    const [successMessage, setSuccessMessage] = useState(null);

    const [loading, setLoading] = useState(false);

    const [isPageSeo, setIsPageSeo] = useState(false)

    const [id, setId] = useState(null)

    // On mount call api to get seo data
    useEffect(() => {
        // Call api to get seo data
        const getSeoData = async () => {
            if(name) {
                await getPageSeoStaticSingle({name: name})
                    .then((res) => {
                        setIsPageSeo(true)
                        setId(res.data._id)
                        setMetaTitle(res.data.metaTitle)
                        setGraphImage(res.data.graphImage)
                        setChronicalUrl(res.data.chronicalUrl)
                        setMetaDescription(res.data.metaDescription)
                        setMetaKeywords(res.data.metaKeywords)
                        setGraphTitle(res.data.graphTitle)
                        setGraphType(res.data.graphType)
                        setGraphUrl(res.data.graphUrl)
                        setGraphSiteName(res.data.graphSiteName)
                        setGraphDescription(res.data.graphDescription)
                        setUrl(res.data.url)
                    })
                    .catch((err) => {
                        //setAlert('error')
                    })
                }
            }
            if(name) getSeoData()
    
        }, [name])

     // On submit call api to update seo data
     const handleSubmit = () => {
        // Call api to update seo data
        const updateSeoDataSingle = async () => {
            if(name && url) {
                const data = {
                    metaTitle: metaTitle,
                    graphImage: graphImage,
                    chronicalUrl: chronicalUrl,
                    metaDescription: metaDescription,
                    metaKeywords: metaKeywords,
                    graphTitle: graphTitle,
                    graphType: graphType,
                    graphUrl: graphUrl,
                    graphSiteName: graphSiteName,
                    graphDescription: graphDescription,
                    name: name,
                    url: url,
                }
                await updatePageSeoStatic(name, data)
                .then((res) => {
                    //setAlert('')
                })
                .catch((err) => {
                    //setAlert(err.response.data.message)
                })
                
            }
        }
        updateSeoDataSingle()
    }

    const navigate = useNavigate();

    return (
        <>
            <Helmet>
                <title> Seo Pages | Mentup Dashboard </title>
            </Helmet>

            <Container>
                {/*
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    open={success}
                    autoHideDuration={6000}
                    onClose={handleSuccessClose}
                >
                    <Alert
                        variant='filled'
                        onClose={handleSuccessClose}
                        severity={
                            successMessage === 'Error occured'
                                ? 'Ошибка'
                                : 'Успешно'
                        }
                        sx={{ width: '100%' }}
                    >
                        {successMessage && successMessage}
                    </Alert>
                    </Snackbar> */}
                <Breadcrumbs aria-label='breadcrumb' sx={{ mb: 1 }}>
                    <Typography
                        variant='h6'
                        gutterBottom
                        sx={{ 
                          textTransform: 'capitalize', 
                          alignItems: 'center', 
                          display: 'flex',
                          transition: 'all 0.2s ease-in-out',
                          borderRadius: '0.5rem',
                          py: 0.5,
                          px: 1,
                          '&:hover': {
                            cursor: 'pointer',
                            backgroundColor: 'grey.400',
                          }

                        }}
                        onClick={() => navigate('/dashboard/seo')}
                    >
                        <ArrowBackIcon sx={{ mr: 1 }}/> Back to Seo Pages
                    </Typography>
                </Breadcrumbs>
                <Stack
                    direction='row'
                    alignItems='center'
                    justifyContent='space-between'
                    mb={3}
                >
                    <Typography variant='h4' gutterBottom>
                        Seo Page - {name}
                    </Typography>
                </Stack>

                <Card>
                    {loading ? (
                      <CircularProgress 
                        sx={{
                          position: 'relative',
                          top: '50%',
                          left: '50%',
                        }}
                      />
                    ) : (
                      <>
                        <FormControl sx={{
                            width: '100%',
                            padding: '1rem',
                            paddingX: '2rem',
                            gap: '1rem',
                        }}>
                            <Typography variant='h6' gutterBottom>
                                Edit Page {name}
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={6} >
                                    {/* Title Tag */}
                                    <TextField
                                        size='fullWidth'
                                        id="outlined-basic"
                                        label="Title Tag"
                                        variant="outlined"
                                        value={name}
                                        helperText='Page Name'
                                        sx={{
                                            width: '100%',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    {/*  URL */}
                                    <TextField
                                        size='fullWidth'
                                        id="outlined-basic"
                                        label="Mentup URL"
                                        variant="outlined"
                                        value={url}
                                        onChange={(e) => handleUrl(e)}
                                        helperText='Example: /register, /login'
                                        sx={{
                                            width: '100%',
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Typography variant='h6' gutterBottom>
                                Edit Meta Data
                            </Typography>
                            
                            {alert &&
                                <Typography variant='h6' gutterBottom color='red'>
                                    {alert}
                                </Typography>
                            }
                            <Grid container spacing={2}>
                                <Grid item xs={6} >
                                    {/* Title Tag */}
                                    <TextField
                                        size='fullWidth'
                                        id="outlined-basic"
                                        label="Title Tag"
                                        variant="outlined"
                                        value={metaTitle}
                                        onChange={(e) => handleMetaTitle(e)}
                                        helperText='The title of the webpage displayed in search engine results. Example: Mentup - Category - Type'
                                        sx={{
                                            width: '100%',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    {/* Canonical URLS */}
                                    <TextField
                                        size='fullWidth'
                                        id="outlined-basic"
                                        label="Canonical URLS"
                                        variant="outlined"
                                        value={chronicalUrl}
                                        onChange={(e) => handleChronicalUrl(e)}
                                        helperText='The canonical URL for this webpage. ( Example: https://mentup.com/ )'
                                        sx={{
                                            width: '100%',
                                        }}
                                    />
                                </Grid>
                            </Grid>


                            {/* Meta Description */}
                            <TextField
                            id="outlined-basic"
                            label="Meta Description"
                            variant="outlined"
                            value={metaDescription}
                            onChange={(e) => handleMetaDescription(e)}
                            helperText='The description of the webpage displayed in search engine results. ( Example: Mentup is a marketplace. )'
                            />

                            {/* Meta Keywords */}
                            <TextField
                            id="outlined-basic"
                            label="Meta Keywords"
                            variant="outlined"
                            value={metaKeywords}
                                onChange={(e) => handleMetaKeywords(e)}
                            helperText='Example:  Keyword1, Keyword2, Keyword3'
                            />

                            {/* Open Graph Meta Tags */}
                            <Typography variant='h6' gutterBottom>
                            Open Graph Meta Tags
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={3} >

                                    {/* Open Graph Title */}
                                    <TextField
                                        id="outlined-basic"
                                        label="Open Graph Title"
                                        variant="outlined"
                                        value={graphTitle}
                                        onChange={(e) => handleGraphTitle(e)}
                                        helperText='The title of the webpage displayed in social media. ( Example: Mentup )'
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    {/* Open Graph Type */}
                                    <TextField
                                        id="outlined-basic"
                                        label="Open Graph Type"
                                        variant="outlined"
                                        value={graphType}
                                        onChange={(e) => handleGraphType(e)}
                                        helperText='The type of the webpage displayed in social media. Example(website, article, product, etc.)'
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    {/* Open Graph URL */}
                                    <TextField
                                        id="outlined-basic"
                                        label="Open Graph URL"
                                        variant="outlined"
                                        value={graphUrl}
                                        onChange={(e) => handleGraphUrl(e)}
                                        helperText='The URL of the webpage displayed in social media.( Example: https://mentup.com/ )'
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    {/* Open Graph Site Name */}
                                    <TextField
                                        id="outlined-basic"
                                        label="Open Graph Site Name"
                                        variant="outlined"
                                        value={graphSiteName}
                                        onChange={(e) => handleGraphSiteName(e)}
                                        helperText='The site name of the webpage displayed in social media. ( Example: Mentup )'
                                    />
                                </Grid>
                            </Grid>
                                
                            {/* Open Graph Description */}  
                            <TextField
                                id="outlined-basic"
                                label="Open Graph Description"
                                variant="outlined"
                                value={graphDescription}
                                onChange={(e) => handleGraphDescription(e)}
                                helperText='The description of the webpage displayed in social media. ( Example: Mentup is a marketplace. )'
                            />
                                    
                            {/* Open Graph Image */}
                            <TextField
                                id="outlined-basic"
                                label="Open Graph Image"
                                variant="outlined"
                                value={graphImage}
                                onChange={(e) => handleGraphImage(e)}
                                helperText='The image of the webpage displayed in social media. ( Default: https://mentup.com/logoGraph.png )'
                            />  

                            <Box sx={{ mt: 2 }} >
                            <Button 
                                variant="contained" 
                                sx={{ mr: 2 }}
                                onClick={() => handleSubmit()}
                                >
                                Save
                            </Button>
                            </Box>
                        </FormControl>
                      </>
                    )}
                </Card>
            </Container>

        </>
    );
}
