import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import { nanoid } from 'nanoid';

// @mui
import {
    Card,
    Table,
    Stack,
    Paper,
    Avatar,
    Button,
    Popover,
    Checkbox,
    TableRow,
    MenuItem,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableContainer,
    TablePagination,
    Snackbar,
    Alert,
} from '@mui/material';

// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import AddAdModal from 'src/components/add-ad-modal/AddAdModal';
import DeleteAdModal from 'src/components/delete-ad-modal';

// sections
import { AdListHead, AdListToolbar } from 'src/sections/@dashboard/ads';

// api
import { getAds } from 'src/api/ads/getAds';

// utils
import { isoToReadableDate } from 'src/utils/isoToReadableDate';
import EditAdModal from 'src/components/edit-ad-modal';

// ----------------------------------------------------------------------

// IDS SHOULD BE EQUAL TO PROPERTIES IN THE OBJECTS
const TABLE_HEAD = [
    { id: '_id', label: 'ID', align: 'left' },
    { id: 'name', label: 'Заголовок', align: 'left' },
    { id: 'updatedAt', label: 'Обновлено', align: 'left' },
    { id: 'createdAt', label: 'Дата', align: 'left' },
    { id: '' },
];

// ----------------------------------------------------------------------

export default function AdsPage() {
    console.log('ADS PAGE');
    const [open, setOpen] = useState(null);

    const [page, setPage] = useState(0);

    const [order, setOrder] = useState('desc');

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState('updatedAt');

    const [rowsPerPage, setRowsPerPage] = useState(5);

    const [ads, setAds] = useState(null);

    const [adsTotal, setAdsTotal] = useState(0);

    const [success, setSuccess] = useState(false);

    const [successMessage, setSuccessMessage] = useState(null);

    const [adAddedNotification, setAdAdAddedNotification] = useState(null);

    const [addToEdit, setAdToEdit] = useState(null);

    useEffect(() => {
        setAds(null);
        setSelected([]);
        const getAdsData = async () => {
            try {
                console.log('CALLING GET ADS');
                const data = await getAds(rowsPerPage, page, orderBy, order);
                if (data && data.data && data.data.adverts) {
                    console.log('DATA GOT ADS');
                    setAds(data.data.adverts);
                }
                if (data && data.data && data.data.totalAdverts) {
                    setAdsTotal(data.data.totalAdverts);
                }
            } catch (error) {
                setAds(null);
                setSelected([]);
            }
        };
        getAdsData();
    }, [rowsPerPage, page, orderBy, order, adAddedNotification]);

    // Display success message when success message is set
    useEffect(() => {
        if (successMessage) {
            handleSuccessOpen();
        }
    }, [successMessage]);

    const handleOpenMenu = (event, ad) => {
        if (ad) {
            setOpen(event.currentTarget);
            setAdToEdit(ad);
        }
    };

    const handleCloseMenu = () => {
        setAdToEdit(null);
        setOpen(null);
    };

    const handleRequestSort = (event, property) => {
        setAds(null);
        setSelected([]);
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        const indeterminate =
            selected.length > 0 &&
            ads &&
            ads.length &&
            selected.length < ads.length;
        if (event.target.checked && !indeterminate && ads && ads.length > 0) {
            const newSelecteds = ads.map((ad) => ad._id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, adId) => {
        const selectedIndex = selected.indexOf(adId);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, adId);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setAds(null);
        setSelected([]);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setAds(null);
        setSelected([]);
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const notifyAdAdded = (message) => {
        if (message === 'add' || message === 'delete' || message === 'edit') {
            setPage(0);
            setAdAdAddedNotification(nanoid());
        }
        handleCloseMenu();
        switch (message) {
            case 'add':
                setSuccessMessage('Объявление добавлено!');
                break;
            case 'edit':
                setSuccessMessage('Объявление отредактировано!');
                break;
            case 'delete':
                setSuccessMessage('Объявление удалено!');
                break;
            case 'error':
                setSuccessMessage('Произошла ошибка');
                break;
            default:
                setSuccessMessage(null);
                break;
        }
    };

    const handleSuccessOpen = () => {
        setSuccess(true);
    };

    const handleSuccessClose = () => {
        setSuccess(false);
        setSuccessMessage(null);
    };

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - adsTotal) : 0;

    const isNotFound = false;

    console.log('ORDER BY: ' + orderBy);
    console.log('ORDER: ' + order);
    console.log('PAGE: ' + page);
    console.log('SELECTED: ' + selected);

    return (
        <>
            <Helmet>
                <title> Ads | Mentup Dashboard </title>
            </Helmet>

            <Container>
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    open={success}
                    autoHideDuration={6000}
                    onClose={handleSuccessClose}
                >
                    <Alert
                        variant='filled'
                        onClose={handleSuccessClose}
                        severity={
                            successMessage === 'Error occured'
                                ? 'Ошибка'
                                : 'Успешно'
                        }
                        sx={{ width: '100%' }}
                    >
                        {successMessage && successMessage}
                    </Alert>
                </Snackbar>
                <Stack
                    direction='row'
                    alignItems='center'
                    justifyContent='space-between'
                    mb={5}
                >
                    <Typography variant='h4' gutterBottom>
                        Объявления
                    </Typography>

                    <AddAdModal notify={notifyAdAdded} />
                </Stack>

                <Card>
                    {/* <ReviewListToolbar /> */}
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <AdListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={
                                        ads && ads.length ? ads.length : 0
                                    }
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                />
                                <TableBody>
                                    {ads &&
                                        ads.map((ad, index) => {
                                            const selectedAd =
                                                selected.indexOf(ad._id) !== -1;

                                            return (
                                                <TableRow
                                                    hover
                                                    key={index}
                                                    tabIndex={-1}
                                                    role='checkbox'
                                                    selected={selectedAd}
                                                >
                                                    <TableCell align='left'>
                                                        {ad._id && (
                                                            <Typography>
                                                                {ad._id}
                                                            </Typography>
                                                        )}
                                                    </TableCell>

                                                    <TableCell align='left'>
                                                        {ad.name && (
                                                            <Typography>
                                                                {ad.name}
                                                            </Typography>
                                                        )}
                                                    </TableCell>

                                                    <TableCell align='left'>
                                                        {ad.updatedAt && (
                                                            <Typography>
                                                                {isoToReadableDate(
                                                                    ad.updatedAt
                                                                )}
                                                            </Typography>
                                                        )}
                                                    </TableCell>

                                                    <TableCell align='left'>
                                                        {ad.createdAt && (
                                                            <Typography>
                                                                {isoToReadableDate(
                                                                    ad.createdAt
                                                                )}
                                                            </Typography>
                                                        )}
                                                    </TableCell>

                                                    <TableCell align='right'>
                                                        <IconButton
                                                            size='large'
                                                            color='inherit'
                                                            onClick={(e) => {
                                                                if (ad) {
                                                                    handleOpenMenu(
                                                                        e,
                                                                        ad
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            <Iconify
                                                                icon={
                                                                    'eva:more-vertical-fill'
                                                                }
                                                            />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow
                                            style={{ height: 53 * emptyRows }}
                                        >
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>

                                {isNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell
                                                align='center'
                                                colSpan={6}
                                                sx={{ py: 3 }}
                                            >
                                                <Paper
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Typography
                                                        variant='h6'
                                                        paragraph
                                                    >
                                                        Не найдено
                                                    </Typography>

                                                    <Typography variant='body2'>
                                                        Не найдено
                                                        &nbsp;
                                                        <strong>
                                                            &quot;
                                                            {'Search Value'}
                                                            &quot;
                                                        </strong>
                                                        .
                                                        <br /> Попробуйте проверить опечатки или корректируйте запрос.
                                                    </Typography>
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        labelRowsPerPage="Выводить по:"
                        rowsPerPageOptions={[5, 10, 25]}
                        component='div'
                        count={adsTotal && adsTotal}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>

            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <EditAdModal notify={notifyAdAdded} ad={addToEdit} />
                <DeleteAdModal notify={notifyAdAdded} ad={addToEdit} />
            </Popover>
        </>
    );
}
