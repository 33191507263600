import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
// @mui
import {
    Card,
    Stack,
    Container,
    Typography,
    Alert,
    Snackbar,
    CircularProgress,
    Breadcrumbs,
    Grid,
    FormControl,
    Button,
    Box,
    TextField,
} from '@mui/material';

// Icons
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// utils
import { useNavigate, useParams } from 'react-router-dom';

import { getDisputeSingle } from 'src/api/disputes/getDisputeSingle';

import { executeHandleDisputeBuyer, executeHandleDisputeSeller } from 'src/api/disputes/handleDispute';

// api

export default function DisputePageSingle() {

    const {id} = useParams();

    const [alert, setAlert] =
        useState(null);
    const [success, setSuccess] = useState(false);

    const [dispute, setDispute] = useState(null);


    const handleSuccessClose = () => {
        setSuccess(false);
    };

    const handleSuccessOpen = () => {
        setSuccess(true);
    };

    const [successMessage, setSuccessMessage] = useState(null);

    const [loading, setLoading] = useState(false);

    // On mount call api to get seo data
    useEffect(() => {
        // Call api to get seo data
        const getDisputeData = async () => {
            setLoading(true)
            if(id) {
                await getDisputeSingle(id)
                    .then((res) => {
                        setLoading(false)
                        console.log(res.data)
                        setDispute(res.data)
                    })
                    .catch((err) => {
                        setAlert('error')
                    })
                }
            }
            if(id) getDisputeData()
    
        }, [id])


    const navigate = useNavigate();

    const handleNavigateOrder = (id) => {
        navigate(`/dashboard/order/${id}`)
    }

    const handleOpenChat = (id) =>{
        navigate(`/dashboard/chats?id=${id}`)
    }

    const handleDisputeBuyer = async () => {
        if(dispute?._id){
            const res = await executeHandleDisputeBuyer(dispute._id)
            console.log(res)
        }
    }

    const handleDisputeSeller = async () => {
        if(dispute?._id){
            const res = await executeHandleDisputeSeller(dispute._id)
            console.log(res)
        }
    }

    return (
        <>
            <Helmet>
                <title> Seo Pages | Mentup Dashboard </title>
            </Helmet>

            <Container>
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    open={success}
                    autoHideDuration={6000}
                    onClose={handleSuccessClose}
                >
                    <Alert
                        variant='filled'
                        onClose={handleSuccessClose}
                        severity={
                            successMessage === 'Error occured'
                                ? 'Ошибка'
                                : 'Успешно'
                        }
                        sx={{ width: '100%' }}
                    >
                        {successMessage && successMessage}
                    </Alert>
                </Snackbar>
                <Breadcrumbs aria-label='breadcrumb' sx={{ mb: 1 }}>
                    <Typography
                        variant='h6'
                        gutterBottom
                        sx={{ 
                          textTransform: 'capitalize', 
                          alignItems: 'center', 
                          display: 'flex',
                          transition: 'all 0.2s ease-in-out',
                          borderRadius: '0.5rem',
                          py: 0.5,
                          px: 1,
                          '&:hover': {
                            cursor: 'pointer',
                            backgroundColor: 'grey.400',
                          }

                        }}
                        onClick={() => navigate('/dashboard/disputes')}
                    >
                        <ArrowBackIcon sx={{ mr: 1 }}/> на страницу споров
                    </Typography>
                </Breadcrumbs>
                <Stack
                    direction='row'
                    alignItems='center'
                    justifyContent='space-between'
                    mb={3}
                >
                    <Typography variant='h4' gutterBottom>
                        Страница спора - {id}
                    </Typography>
                </Stack>

                <Box>
                    {loading ? (
                      <CircularProgress 
                        sx={{
                          position: 'relative',
                          top: '50%',
                          left: '50%',
                        }}
                      />
                    ) : (
                      <>
                      {dispute && (
                        <Grid container spacing={3}>

                            {/* ORDER ID */}
                            <Grid item xs={6}>
                                <Card sx={{
                                    m: 0,
                                    p: 2,
                                    paddingBottom: 1,
                                    borderRadius: '1rem',
                                    display: 'flex',
                                    gap: '1rem',
                                    alignItems: 'center',
                                }}>
                                    <Typography variant='h6' gutterBottom>
                                        ID заказа:
                                    </Typography>
                                    <Typography variant='h6' gutterBottom>
                                        {dispute.order._id}
                                    </Typography>
                                </Card>
                            </Grid>

                            {/* STATUS */}
                            <Grid item xs={2}>
                                <Card sx={{
                                    m: 0,
                                    p: 2,
                                    paddingBottom: 1,
                                    borderRadius: '1rem',
                                    display: 'flex',
                                    gap: '1rem',
                                    alignItems: 'center',
                                }}>
                                    <Typography variant='h6' gutterBottom>
                                        Статус:
                                    </Typography>
                                    <Typography variant='h6' gutterBottom sx={{
                                        color: dispute.status === 'pending' ? 'orange' : dispute.status === 'accepted' ? 'green' : 'red'
                                    }}>
                                        {dispute.status}
                                    </Typography>
                                </Card>
                            </Grid>

                            {/* Date */}
                            <Grid item xs={4}>
                                <Card sx={{
                                    m: 0,
                                    p: 2,
                                    paddingBottom: 1,
                                    borderRadius: '1rem',
                                    display: 'flex',
                                    gap: '1rem',
                                    alignItems: 'center',
                                }}>
                                    <Typography variant='h6' gutterBottom>
                                        Создано:
                                    </Typography>
                                    <Typography variant='h6' gutterBottom sx={{
                                    }}>
                                        {dispute.updatedAt.split('T')[0]} : {dispute.updatedAt.split('T')[1].split('.')[0]}
                                    </Typography>
                                </Card>
                            </Grid>

                            {/* REASON */}
                            <Grid item xs={12}>
                                <Card sx={{
                                    m: 0,
                                    p: 2,
                                    paddingBottom: 1,
                                    borderRadius: '1rem',
                                    display: 'flex',
                                    gap: '1rem',
                                    alignItems: 'center',
                                }}>
                                        <Typography variant='h6' gutterBottom>
                                            Причина:
                                        </Typography>
                                        <Typography variant='p' gutterBottom sx={{
                                            fontWeight: 'normal'
                                        }}>
                                            {dispute.reason}
                                        </Typography>   
                                </Card>
                            </Grid>

                            {/* BUYER */}
                            <Grid item xs={6}>
                                <Card sx={{
                                    m: 0,
                                    p: 2,
                                    paddingBottom: 1,
                                    borderRadius: '1rem',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '1rem',
                                    alignItems: 'center',
                                    width: '100%',
                                }}>
                                        <Typography variant='h6' gutterBottom>
                                            О покупателе:
                                        </Typography>

                                        <Grid container sx={{
                                            width: '100%',
                                            alignItems: 'center',
                                        }}>
                                            <Grid item xs={12} sx={{
                                                width: '100%',
                                                textAlign: 'center',
                                            }}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    gap: '1rem',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}>
                                                    <Typography variant='h6' gutterBottom>
                                                        Имя пользователя:
                                                    </Typography>
                                                    <Typography variant='p' gutterBottom sx={{
                                                        fontWeight: 'normal'
                                                    }}>
                                                        {dispute.buyer.username}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    gap: '1rem',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}>
                                                    <Typography variant='h6' gutterBottom>
                                                        Email:
                                                    </Typography>
                                                    <Typography variant='p' gutterBottom sx={{
                                                        fontWeight: 'normal'
                                                    }}>
                                                        {dispute.buyer.email}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} sx={{
                                                width: '100%',
                                                alignItems: 'center',
                                                textAlign: 'center',
                                            }}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    gap: '1rem',
                                                    alignItems: 'center',
                                                    textAlign: 'center',
                                                    justifyContent: 'center',
                                                }}>
                                                    <Typography variant='h6' gutterBottom>
                                                        Транзакция:
                                                    </Typography>
                                                    <Typography variant='p' gutterBottom sx={{
                                                        fontWeight: 'normal'
                                                    }}>
                                                        {dispute.transactionBuyer._id}
                                                    </Typography>
                                                </Box>
                                                <Box sx={{
                                                    display: 'flex',
                                                    gap: '1rem',
                                                    alignItems: 'center',
                                                    textAlign: 'center',
                                                    justifyContent: 'center',
                                                }}>
                                                    <Button variant='contained' 
                                                        onClick={() => handleNavigateOrder(dispute.transactionBuyer._id)}
                                                        sx={{
                                                            mt: 1,
                                                            mb: 2,
                                                    }}>
                                                        Просмотр заказа
                                                    </Button>
                                                    <Button variant='contained' 
                                                        onClick={() => handleOpenChat(dispute.buyer._id)}
                                                        sx={{
                                                            mt: 1,
                                                            mb: 2,
                                                    }}>
                                                        Открыть Чат
                                                    </Button>
                                                </Box>
                                                <Button variant='contained' 
                                                    onClick={() => handleDisputeBuyer()}
                                                    sx={{
                                                        mt: 1,
                                                        mb: 2,
                                                }}>
                                                    Закрыть спор в пользу покупателья
                                                </Button>
                                            </Grid>
                                        </Grid>
                                </Card>
                            </Grid>

                            {/* SELLER */}
                            <Grid item xs={6}>
                                <Card sx={{
                                    m: 0,
                                    p: 2,
                                    paddingBottom: 1,
                                    borderRadius: '1rem',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '1rem',
                                    alignItems: 'center',
                                    width: '100%',
                                }}>
                                        <Typography variant='h6' gutterBottom>
                                            О продавце:
                                        </Typography>

                                        <Grid container sx={{
                                            width: '100%',
                                            alignItems: 'center',
                                        }}>
                                            <Grid item xs={12} sx={{
                                                width: '100%',
                                                textAlign: 'center',
                                            }}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    gap: '1rem',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}>
                                                    <Typography variant='h6' gutterBottom>
                                                        Имя пользователя:
                                                    </Typography>
                                                    <Typography variant='p' gutterBottom sx={{
                                                        fontWeight: 'normal'
                                                    }}>
                                                        {dispute.seller.username}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    gap: '1rem',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}>
                                                    <Typography variant='h6' gutterBottom>
                                                        Email:
                                                    </Typography>
                                                    <Typography variant='p' gutterBottom sx={{
                                                        fontWeight: 'normal'
                                                    }}>
                                                        {dispute.seller.email}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} sx={{
                                                width: '100%',
                                                alignItems: 'center',
                                                textAlign: 'center',
                                            }}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    gap: '1rem',
                                                    alignItems: 'center',
                                                    textAlign: 'center',
                                                    justifyContent: 'center',
                                                }}>
                                                    <Typography variant='h6' gutterBottom>
                                                        Транзакция:
                                                    </Typography>
                                                    <Typography variant='p' gutterBottom sx={{
                                                        fontWeight: 'normal'
                                                    }}>
                                                        {dispute.transactionSeller._id}
                                                    </Typography>
                                                </Box>
                                                <Box sx={{
                                                    display: 'flex',
                                                    gap: '1rem',
                                                    alignItems: 'center',
                                                    textAlign: 'center',
                                                    justifyContent: 'center',
                                                }}>
                                                    <Button variant='contained' 
                                                        onClick={() => handleOpenChat(dispute.seller._id)}
                                                        sx={{
                                                            mt: 1,
                                                            mb: 2,
                                                    }}>
                                                        Открыть Чат
                                                    </Button>
                                                </Box>
                                                <Button variant='contained' 
                                                    onClick={() => handleDisputeSeller()}
                                                    sx={{
                                                        mt: 1,
                                                        mb: 2,
                                                }}>
                                                    Закрыть спор в пользу продвца
                                                </Button>
                                            </Grid>
                                        </Grid>
                                </Card>
                            </Grid>


                        </Grid>
                        )}
                      </>
                    )}
                </Box>
            </Container>

        </>
    );
}
