import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    userData: sessionStorage.getItem('user')
        ? JSON.parse(sessionStorage.getItem('user'))
        : null,
};

const userSlice = createSlice({
    name: 'userSlice',
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.userData = action.payload;
        },
    },
});

export const { setUser } = userSlice.actions;
export default userSlice.reducer;
