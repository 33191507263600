import ReactDOM from 'react-dom/client';

//
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';

// redux
import { Provider } from 'react-redux';
import store from './store/store';

// New Router Version
// const router = createBrowserRouter(
//     createRoutesFromElements(
//         <Route path={'/'} element={<App />} errorElement={<Page404 />}>
//             <Route path={''} element={<ProtectedRoute />}>
//                 <Route path={'dashboard'} element={<DashboardLayout />}>
//                     <Route path={'app'} element={<DashboardAppPage />} />
//                     <Route path={'user'} element={<UserPage />} />
//                     <Route path={'products'} element={<ProductsPage />} />
//                     <Route path={'blog'} element={<BlogPage />} />
//                 </Route>
//             </Route>
//             <Route path={'login'} element={<LoginPage />} />
//             <Route path={''} element={<SimpleLayout />}>
//                 <Route path={'404'} element={<Page404 />} />
//                 <Route path={'*'} element={<Navigate to={'/404'} />} />
//             </Route>
//             <Route path={'*'} element={<Navigate to={'/404'} />} />
//         </Route>
//     )
// );

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    // <RouterProvider router={router}>
    <Provider store={store}>
        <App />
    </Provider>
    // </RouterProvider>
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
