import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { setUser } from 'src/store/slices/userSlice';

// mui
import {
    Container,
    Stack,
    Typography,
    Box,
    TextField,
    MenuItem,
    FormHelperText,
    Button,
    Select,
    FormControl,
    InputLabel,
    InputAdornment,
    IconButton,
    Snackbar,
    Alert,
} from '@mui/material';

// form
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// icons
import VisibilityIcon from '@mui/icons-material/Visibility';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import CameraAltRoundedIcon from '@mui/icons-material/CameraAltRounded';

// components
import UserPasswordModal from 'src/components/user-password-modal';

// api
import { updateUser } from 'src/api/users/updateUser';
import { deleteMultipleImages } from 'src/api/images/deleteMultipleImages';
import { sendMediaToBack } from 'src/api/categories/sendMediaToBack';
function ProfilePage() {
    console.log('Profile PAGE');

    const theme = useTheme();

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const user = useSelector((state) => state.user);

    const [userDetails, setUserDetails] = useState(null);

    const [profileUpdatedNotification, setProfileUpdatedNotification] =
        useState(null);

    const [success, setSuccess] = useState(false);

    const [successMessage, setSuccessMessage] = useState(null);

    const [pictureCodesToDelete, setPictureCodesToDelete] = useState(null);

    const [submittedFormData, setSubmittedFormData] = useState(null);

    const [avatar, setAvatar] = useState(null);

    useEffect(() => {
        resetAll();
        if (user && user.userData) {
            setUserDetails({
                username: user.userData.username,
                email: user.userData.email,
            });
            setAvatar(
                user.userData.avatar
                    ? {
                          file: null,
                          url: null,
                          externalUrl: user.userData.avatar,
                      }
                    : null
            );
        }
    }, [user]);

    const schema = yup.object().shape({
        username: yup.string().min(3).max(20).required(),
        email: yup.string().email().required(),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const uploadPicture = async (file) => {
        try {
            let formData = new FormData();
            formData.append('image', file);
            const data = await sendMediaToBack(formData);
            if (data && data.data && data.data.media) {
                setAvatar((prevState) => {
                    return {
                        ...prevState,
                        url: null,
                        externalUrl: data.data.media,
                    };
                });
            }
        } catch (error) {
            console.log(error);
            notifyProfileUpdated('error');
        }
    };

    const deletePicturesFromBackend = async (codes) => {
        try {
            console.log('INSIDE DELETE IMAGES FROM BACKEND');
            const data = await deleteMultipleImages(codes);
            if (
                data &&
                data.data &&
                data.data.message &&
                data.data.message === 'Images deleted'
            ) {
                console.log('IMAGES DELETED');

                if (avatar && avatar.file && !avatar.externalUrl) {
                    uploadPicture(avatar.file);
                }
            }
        } catch (error) {
            console.log(error);
            notifyProfileUpdated('error');
        }
    };

    const submitUserData = (data) => {
        // TO GET AVATAR FROM STATE IF PRESENT
        console.log(data);
        if (pictureCodesToDelete && pictureCodesToDelete.length > 0) {
            deletePicturesFromBackend(pictureCodesToDelete);
        } else {
            if (avatar && avatar.file && !avatar.externalUrl) {
                uploadPicture(avatar.file);
            }
        }
        setSubmittedFormData(data);
        // TO RESET AVATAR STATE
    };

    useEffect(() => {
        console.log('INSIDE USE EFFECT');
        if (submittedFormData && avatar && avatar.externalUrl) {
            console.log('INSIDE USE EFFECT BEFORE SENDING UPDATED PROFILE');
            const sendUpdatedUserData = async () => {
                try {
                    if (user && user.userData && user.userData._id) {
                        const data = await updateUser(
                            {
                                ...submittedFormData,
                                ...(avatar &&
                                    avatar.file &&
                                    avatar.externalUrl && {
                                        avatar: avatar.externalUrl,
                                    }),
                            },
                            user.userData._id
                        );

                        if (data && data.data) {
                            console.log('USER EDITED');
                            if (data.data.updatedUser) {
                                sessionStorage.setItem(
                                    'user',
                                    JSON.stringify({
                                        _id: data.data.updatedUser._id,
                                        avatar: data.data.updatedUser.avatar,
                                        username:
                                            data.data.updatedUser.username,
                                        email: data.data.updatedUser.email,
                                        role: data.data.updatedUser.role,
                                    })
                                );
                                dispatch(
                                    setUser({
                                        _id: data.data.updatedUser._id,
                                        avatar: data.data.updatedUser.avatar,
                                        username:
                                            data.data.updatedUser.username,
                                        email: data.data.updatedUser.email,
                                        role: data.data.updatedUser.role,
                                    })
                                );
                            }
                            notifyProfileUpdated('edit');
                        }
                    }
                } catch (error) {
                    console.log(error);
                    notifyProfileUpdated('error');
                }
            };
            sendUpdatedUserData();
        }
    }, [submittedFormData, avatar]);

    const removeAvatar = () => {
        if (avatar && avatar.externalUrl) {
            let tempArray = [];
            if (pictureCodesToDelete && pictureCodesToDelete.length > 0) {
                tempArray = [...pictureCodesToDelete];
            }

            const regex = /\/([a-f\d]{64})\?/; // Regular expression to match the hash
            try {
                const match = avatar.externalUrl.match(regex);

                if (match && match[1]) {
                    const extractedHash = match[1];
                    if (
                        extractedHash !==
                        'a1c0f0dad0f6736325135dc69ec6dc0984058f11aa04150231c114efc1508290'
                    ) {
                        tempArray.push(extractedHash);
                    }
                }
            } catch (error) {
                console.log(error);
                notifyProfileUpdated('error');
            }
            setPictureCodesToDelete([...tempArray]);
        }
    };

    const handleAvatarUpload = (event) => {
        const picture = event.target.files[0];
        if (picture) {
            removeAvatar();
            const tempPictureUrl = URL.createObjectURL(picture);
            setAvatar({ file: picture, url: tempPictureUrl });
        }
    };

    const notifyProfileUpdated = (message) => {
        switch (message) {
            case 'edit':
                setSuccessMessage('Profile Updated!');
                setSubmittedFormData(null);
                setPictureCodesToDelete(null);
                break;
            case 'password':
                setSuccessMessage('Password Updated!');
                break;
            case 'error':
                setSuccessMessage('Произошла ошибка');
                break;
            default:
                setSuccessMessage(null);
                break;
        }
    };

    // Display success message when success message is set
    useEffect(() => {
        if (successMessage) {
            handleSuccessOpen();
        }
    }, [successMessage]);

    const handleSuccessOpen = () => {
        setSuccess(true);
    };

    const handleSuccessClose = () => {
        setSuccess(false);
        setSuccessMessage(null);
    };

    const resetAll = () => {
        setUserDetails(null);
        setAvatar(null);
        setSubmittedFormData(null);
        setPictureCodesToDelete(null);
    };

    console.log('USER DETAILS: ' + JSON.stringify(userDetails));
    console.log('AVATAR: ' + JSON.stringify(avatar));
    console.log('Pictures to delete: ' + JSON.stringify(pictureCodesToDelete));
    console.log('SUBMITTED FORM DATA: ' + JSON.stringify(submittedFormData));

    return (
        <>
            <Helmet>
                <title> Profile | Mentup Dashboard </title>
            </Helmet>
            <Container>
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    open={success}
                    autoHideDuration={6000}
                    onClose={handleSuccessClose}
                >
                    <Alert
                        variant='filled'
                        onClose={handleSuccessClose}
                        severity={
                            successMessage === 'Error occured'
                                ? 'Ошибка'
                                : 'Успешно'
                        }
                        sx={{ width: '100%' }}
                    >
                        {successMessage && successMessage}
                    </Alert>
                </Snackbar>
                <Stack
                    direction='row'
                    alignItems='center'
                    justifyContent='left'
                    mb={2}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'start',
                            columnGap: '0.5rem',
                        }}
                    >
                        <IconButton
                            onClick={() => {
                                navigate(-1);
                            }}
                        >
                            <ArrowBackIosNewRoundedIcon />
                        </IconButton>
                        <Typography variant='h4'>Profile</Typography>
                    </Box>
                </Stack>
                <Box>
                    {userDetails ? (
                        <form
                            onSubmit={handleSubmit(submitUserData)}
                            style={{ width: '100%' }}
                        >
                            <Box
                                sx={{
                                    width: '100%',
                                    height: 'fit-content',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'start',
                                    alignItems: 'start',
                                    gap: '1rem',
                                }}
                            >
                                <Box
                                    sx={{
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'start',
                                        justifyContent: 'space-between',
                                        '@media (max-width: 900px)': {
                                            paddingX: '2rem',
                                            width: '100%',
                                        },
                                        '@media (max-width: 650px)': {
                                            paddingX: '0',
                                        },
                                        '@media (max-width: 500px)': {
                                            flexDirection: 'column',
                                        },
                                    }}
                                >
                                    <Box sx={{ height: '80px', width: '80px' }}>
                                        <input
                                            hidden
                                            accept='image/*'
                                            type='file'
                                            onChange={handleAvatarUpload}
                                            onClick={(e) =>
                                                (e.target.value = null)
                                            }
                                            id={`user-image-upload-input`}
                                        />
                                        <label
                                            htmlFor={`user-image-upload-input`}
                                        >
                                            <Button
                                                component='span'
                                                sx={{
                                                    position: 'relative',
                                                    width: '100%',
                                                    height: '100%',
                                                    borderRadius: '50%',
                                                }}
                                            >
                                                {avatar &&
                                                (avatar.url ||
                                                    avatar.externalUrl) ? (
                                                    <>
                                                        <Box
                                                            sx={{
                                                                width: '100%',
                                                                height: '100%',
                                                                ...(avatar.url && {
                                                                    backgroundImage: `url("${avatar.url}")`,
                                                                }),
                                                                ...(avatar.externalUrl && {
                                                                    backgroundImage: `url("${avatar.externalUrl}")`,
                                                                }),
                                                                backgroundSize:
                                                                    'cover',
                                                                backgroundPosition:
                                                                    'center',
                                                                backroundRepeat:
                                                                    'no-repeat',
                                                                borderRadius:
                                                                    '50%',
                                                            }}
                                                        ></Box>
                                                        <CameraAltRoundedIcon
                                                            sx={{
                                                                position:
                                                                    'absolute',
                                                                top: '50%',
                                                                left: '50%',
                                                                translate:
                                                                    '-50% -50%',
                                                            }}
                                                        />
                                                    </>
                                                ) : (
                                                    <AccountCircleIcon
                                                        sx={{
                                                            fontSize: '5rem',
                                                            position:
                                                                'absolute',
                                                            top: '50%',
                                                            left: '50%',
                                                            translate:
                                                                '-50% -50%',
                                                        }}
                                                    />
                                                )}
                                            </Button>
                                        </label>
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        boxSize: 'border-box',
                                        width: '50%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'start',
                                        alignItems: 'start',
                                        gap: '1rem',
                                        '@media (max-width: 900px)': {
                                            paddingX: '2rem',
                                            width: '100%',
                                        },
                                        '@media (max-width: 650px)': {
                                            paddingX: '0',
                                        },
                                    }}
                                >
                                    <TextField
                                        required
                                        fullWidth
                                        variant={`outlined`}
                                        label='Username'
                                        error={errors.username && true}
                                        helperText={errors.username?.message}
                                        {...register('username')}
                                        value={
                                            userDetails && userDetails.username
                                                ? userDetails.username
                                                : ''
                                        }
                                        onChange={(e) => {
                                            setUserDetails((prevState) => {
                                                return {
                                                    ...prevState,
                                                    username: e.target.value,
                                                };
                                            });
                                        }}
                                    />
                                    <TextField
                                        required
                                        fullWidth
                                        variant={`outlined`}
                                        label='Email'
                                        error={errors.email && true}
                                        helperText={errors.email?.message}
                                        {...register('email')}
                                        value={
                                            userDetails && userDetails.email
                                                ? userDetails.email
                                                : ''
                                        }
                                        onChange={(e) => {
                                            setUserDetails((prevState) => {
                                                return {
                                                    ...prevState,
                                                    email: e.target.value,
                                                };
                                            });
                                        }}
                                    />

                                    <UserPasswordModal
                                        notify={notifyProfileUpdated}
                                    />

                                    <Box
                                        sx={{
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'end',
                                            alignItems: 'start',
                                        }}
                                    >
                                        <Button
                                            size={`large`}
                                            type={`submit`}
                                            variant='contained'
                                            sx={{
                                                marginTop: '1rem',
                                                '@media (max-width: 900px)': {
                                                    width: '100%',
                                                },
                                            }}
                                        >
                                            Save
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        </form>
                    ) : null}
                </Box>
            </Container>
        </>
    );
}

export default ProfilePage;
