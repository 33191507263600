import { Box, Container, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { Helmet } from "react-helmet-async";

import { useSocketProvider } from "src/contexts/socket";
import useResponsive from "src/hooks/useResponsive";
import ChatAccordion from "./components/Accordion";
import ChatsList from "./components/ChatList";
import styles from "./styles.module.css";

import { useParams } from "react-router-dom";

export default function ChatsPage() {
  const isDesktop = useResponsive("up", "lg");

  const { unreadChats } = useSocketProvider();
  const [expanded, setExpanded] = useState("panel1");

  const handleChange = (panel) => (_, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      <Helmet>
        <title>Чаты | Mentup Dashboard </title>
      </Helmet>

      <Container className={styles.container}>
        {!isDesktop && (
          <Stack>
            <Typography variant="h4">Чаты</Typography>
          </Stack>
        )}

        <ChatAccordion
          id="panel1"
          header={
            <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
              <Typography>Мой Чаты</Typography>
              {!!unreadChats.count && (
                <Typography
                  sx={{
                    borderRadius: "24px",
                    background: "red",
                    padding: "0 8px",
                    marginLeft: "4px",
                    height: "min-content",
                  }}
                >
                  {unreadChats.count}
                </Typography>
              )}
            </Box>
          }
          expanded={expanded}
          handleChange={handleChange}
          children={<ChatsList assignedToMe />}
        />
        <ChatAccordion
          id="panel2"
          header={<Typography>Другие Чаты</Typography>}
          expanded={expanded}
          handleChange={handleChange}
          children={<ChatsList />}
        />
      </Container>
    </>
  );
}
