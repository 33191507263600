import { Helmet } from 'react-helmet-async';
import { filter, get, set } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import {
    Card,
    Table,
    Stack,
    Paper,
    Avatar,
    Button,
    Popover,
    Checkbox,
    TableRow,
    MenuItem,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableContainer,
    TablePagination,
    Box,
    Modal,
} from '@mui/material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import {
    TransactionListHead,
    TransactionListToolbar,
} from 'src/sections/@dashboard/transactions';
import TableStatusCell from 'src/components/table-status-cell';
import { isoToReadableDate } from 'src/utils/isoToReadableDate';

// Apu
import { getServers } from 'src/api/servers/getServers';
import { deleteServer } from 'src/api/servers/deleteServer';

// Modals
import AddServerModal from 'src/components/add-servers/AddServerModal';
import { CategoryListToolbar } from 'src/sections/@dashboard/categories';


// ----------------------------------------------------------------------

// IDS SHOULD BE EQUAL TO PROPERTIES IN THE OBJECTS
const TABLE_HEAD = [
    { id: 'name', label: 'Наименование', align: 'left' },
    { id: 'category', label: 'Категория', align: 'left' },
    { id: '' },
];

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    paddingX: 7,
    paddingTop: 1,
    paddingBottom: 4,
    borderRadius: '10px',
    maxHeight: '90vh',
    overflow: 'scroll',
    '@media (max-width: 650px)': {
        paddingX: 4,
        width: '90%',
    },
};

// ----------------------------------------------------------------------

export default function ServersPage() {
    const [open, setOpen] = useState(null);

    const [page, setPage] = useState(0);

    const [order, setOrder] = useState('desc');

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState('createdAt');

    const [rowsPerPage, setRowsPerPage] = useState(5);

    const [servers, setServers] = useState(null);

    const [serversTotal, setServersTotal] = useState(0);

    const [openDelete, setDeleteOpen] = useState(false);

    const [selectedServer, setSelectedServer] = useState(null);

    useEffect(() => {
        setServers(null);
        setSelected([]);
        const getServersData = async () => {
            try {

                const data = await getServers(
                    rowsPerPage,
                    page,
                    orderBy,
                    order
                );
                if (data && data.data && data.data.servers) {
                    setServers(data.data.servers);
                }
                if (data && data.data && data.data.totalServers) {
                    setServersTotal(data.data.totalServers);
                }
            } catch (error) {
                setServers(null);
                setSelected([]);
            }
        };
        getServersData();
    }, [rowsPerPage, page, orderBy, order]);

    const handleOpenMenu = (event, server) => {
        setOpen(event.currentTarget);
        setSelectedServer(server);
    };

    const handleCloseMenu = () => {
        setOpen(null);
    };

    const handleRequestSort = (event, property) => {
        setServers(null);
        setSelected([]);
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        const indeterminate =
            selected.length > 0 &&
            servers &&
            servers.length &&
            selected.length < servers.length;
        if (
            event.target.checked &&
            !indeterminate &&
            servers &&
            servers.length > 0
        ) {
            const newSelecteds = servers.map(
                (server) => server._id
            );
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, transactionId) => {
        const selectedIndex = selected.indexOf(transactionId);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, transactionId);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setServers(null);
        setSelected([]);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setServers(null);
        setSelected([]);
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const submitDeleteServer = async (e) => {
        e.preventDefault();
        console.log('Delete server');
        const deleteServerCall = async () => {
            try {
                // Call delete server API
                const res = await deleteServer(selectedServer._id);
                // Close the modal
                if (res && res.status === 200)
                    closeDeleteModal();
                else console.log('Error deleting server');
            } catch (error) {
                console.log('Error deleting server');
            }
        } 
        deleteServerCall();
    }
    
    const openDeleteModal = () => {
        setOpen(null);
        setDeleteOpen(true);
    }

    const closeDeleteModal = () => {
        setDeleteOpen(false);
        setSelectedServer(null);
    }

    const handleSearch = async (searchValue) => {
        setServers(null);
        setSelected([]);
        setPage(0);
        try {
            let data = null;
            if(searchValue.length >= 1) {
                data = await getServers(
                    rowsPerPage,
                    page,
                    orderBy,
                    order,
                    searchValue
                );
            } else if(searchValue.length === 0) {
                data = await getServers(
                    rowsPerPage,
                    page,
                    orderBy,
                    order
                );
            }
            if (data?.data?.servers) {
                setServers(data.data.servers);
            }
            if (data?.data?.totalServers) {
                setServersTotal(data.data.totalServers);
            }
            
        } catch (error) {
            setServers(null);
            setSelected([]);
        }
    }

    const emptyRows =
        page > 0
            ? Math.max(0, (1 + page) * rowsPerPage - serversTotal)
            : 0;

    const isNotFound = false;


    return (
        <>
            <Helmet>
                <title> Servers | Mentup Dashboard </title>
            </Helmet>

            <Container>
                <Stack
                    direction='row'
                    alignItems='center'
                    justifyContent='space-between'
                    mb={5}
                >
                    <Typography variant='h4' gutterBottom>
                        Серверы
                    </Typography>
                    <AddServerModal />
                </Stack>

                <Card>
                    {/* <TransactionListToolbar /> */}
                    <CategoryListToolbar
                        handleSearchGlobal={handleSearch}
                    />
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <TransactionListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={
                                        servers && servers.length
                                            ? servers.length
                                            : 0
                                    }
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                />
                                <TableBody>
                                    {servers &&
                                        servers.map(
                                            (server, index) => {
                                                const selectedTransaction =
                                                    selected.indexOf(
                                                        server._id
                                                    ) !== -1;

                                                return (
                                                    <TableRow
                                                        hover
                                                        key={index}
                                                        tabIndex={-1}
                                                        role='checkbox'
                                                        selected={
                                                            selectedTransaction
                                                        }
                                                    >
                                                        {/* <TableCell padding='checkbox'>
                                                            <Checkbox
                                                                checked={
                                                                    selectedTransaction
                                                                }
                                                                onChange={(
                                                                    event
                                                                ) => {
                                                                    if (
                                                                        transaction._id
                                                                    ) {
                                                                        handleClick(
                                                                            event,
                                                                            transaction._id
                                                                        );
                                                                    }
                                                                }}
                                                            />
                                                        </TableCell> */}

                                                        <TableCell align='left'>
                                                            {server? (
                                                                <Typography>
                                                                    {server.name}
                                                                </Typography>
                                                            ) : null}
                                                        </TableCell>

                                                        <TableCell align='left'>
                                                            {server.category && (
                                                                <TableStatusCell
                                                                    contents={
                                                                        server.category?.name
                                                                    }
                                                                />
                                                            )}
                                                        </TableCell>


                                                        <TableCell align='right'>
                                                        <IconButton
                                                            size='large'
                                                            color='inherit'
                                                            onClick={(e) => {
                                                                if (server) {
                                                                    handleOpenMenu(
                                                                        e,
                                                                        server
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            <Iconify
                                                                icon={
                                                                    'eva:more-vertical-fill'
                                                                }
                                                            />
                                                        </IconButton>
                                                    </TableCell>
                                                    </TableRow>
                                                );
                                            }
                                        )}
                                    {emptyRows > 0 && (
                                        <TableRow
                                            style={{ height: 53 * emptyRows }}
                                        >
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>

                                {isNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell
                                                align='center'
                                                colSpan={6}
                                                sx={{ py: 3 }}
                                            >
                                                <Paper
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Typography
                                                        variant='h6'
                                                        paragraph
                                                    >
                                                        Не найдено
                                                    </Typography>

                                                    <Typography variant='body2'>
                                                        Не найдено
                                                        &nbsp;
                                                        <strong>
                                                            &quot;
                                                            {'Search Value'}
                                                            &quot;
                                                        </strong>
                                                        .
                                                        <br /> Попробуйте проверить опечатки или корректируйте запрос.
                                                    </Typography>
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        labelRowsPerPage="Выводить по:"
                        rowsPerPageOptions={[5, 10, 25]}
                        component='div'
                        count={serversTotal && serversTotal}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>

            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >

                <MenuItem
                    onClick={() => {
                        // Open delete modal
                        openDeleteModal();
                    }}
                >
                    Удалить
                </MenuItem>
            </Popover>

            <Modal open={openDelete} onClose={closeDeleteModal}>
                <Box sx={style}>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'end',
                        }}
                    >
                        <IconButton
                            aria-label='close'
                            onClick={() => {
                                closeDeleteModal();
                            }}
                        >
                            X
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            margin: '0',
                            padding: '0',
                            width: '100%',
                            marginBottom: '0.5rem',
                            textAlign: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                fontSize: '1.8rem',
                            }}
                        >
                            Удалить сервер {selectedServer?.name}
                        </Typography>
                    </Box>
                    <form onSubmit={submitDeleteServer}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '1rem',
                                padding: '1rem',
                            }}
                        >
                            <Typography>
                                Вы точно хотите удалить сервер?
                            </Typography>
                            <Button
                                type='submit'
                                variant='contained'
                                color='error'
                            >
                                Удалить
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Modal>
        </>
    );
}
