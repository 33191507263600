import { BrowserRouter } from "react-router-dom";

import { HelmetProvider } from "react-helmet-async";
// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// components
import { StyledChart } from "./components/chart";
import ScrollToTop from "./components/scroll-to-top";
import { SocketProvider } from "./contexts/socket";
import { TranslateProvider } from "./contexts/translate/translate.context";

// ----------------------------------------------------------------------

export default function App() {
  return (
    <HelmetProvider>
      <TranslateProvider>
        <SocketProvider>
          <BrowserRouter>
            <ThemeProvider>
              <ScrollToTop />
              <StyledChart />
              <Router />
              {/* <Outlet /> */}
            </ThemeProvider>
          </BrowserRouter>
        </SocketProvider>
      </TranslateProvider>
    </HelmetProvider>
  );
}
