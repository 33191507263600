import { Box } from "@mui/material";
import { useEffect, useState } from "react";

import { ChatListItem } from "src/components/chat/chat-list-item";
import { MessagesContainer } from "src/components/chat/messages-container";
import { useChats } from "src/hooks/chats";
import ChatSearch from "../Search";
import styles from "./styles.module.css";
import { useLocation } from 'react-router-dom';

export default function ChatsList({ assignedToMe }) {

  const location = useLocation();

  const [search, setSearch] = useState("");
  const [selectedChat, setSelectedChat] = useState(null);

  const chats = useChats(assignedToMe, search);

  // Get id from URL if the URL is in this format /chats?id=12312312
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');

  const assignChat = (chatId) => {
    chats.assign(chatId);
  };

  useEffect(() => {
    if (id && !selectedChat) {
      console.log('executed');
      // Find the chat where one of the users in chat.item.users has the id
      const chat = chats.items.find(chat => chat.users.find(user => user._id === id));
      const currentAdvisor = JSON.parse(sessionStorage.getItem("user"));
      if (chat) {
        // If chat.advisor is not equal to currentAdvisor._id, then assignChat
        if (chat.advisor !== currentAdvisor._id) {
          assignChat(chat._id);
        }
        setSelectedChat(chat);
      }
    }
  }, [id, chats.items, selectedChat]);

  return (
    <Box className={styles.container}>
      <Box className={styles.chatList}>
        <ChatSearch value={search} handleSearch={setSearch} />

        {chats.items.map((chat) => (
          <ChatListItem
            key={chat._id}
            isCurrent={selectedChat?._id === chat._id}
            chat={chat}
            unreadCount={chat.unread?.[0]?.count || 0}
            open={setSelectedChat}
            assignChat={() => assignChat(chat._id)}
          />
        ))}
      </Box>

      {selectedChat && (
        <MessagesContainer
          key={selectedChat._id}
          chat={selectedChat}
          unreadCount={selectedChat?.unread?.[0]?.count || 0}
          markAsRead={() => chats.markAsRead(selectedChat._id)}
        />
      )}
    </Box>
  );
}
