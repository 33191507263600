import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material';

function TableStatusCell({ contents }) {
    const theme = useTheme();

    let backgroundColor = `${theme.palette.warning.main}`;

    switch (contents) {
        case 'pending':
            backgroundColor = `${theme.palette.warning.main}`;
            break;
        case 'approved':
        case 'succeeded':
        case 'active':
            backgroundColor = `${theme.palette.success.main}`;
            break;
        case 'banned':
        case 'declined':
            backgroundColor = `${theme.palette.error.main}`;
            break;
        default:
            backgroundColor = `${theme.palette.action.disabledBackground}`;
    }

    return (
        <Box
            sx={{
                borderRadius: '8px',
                boxSizing: 'border-box',
                paddingX: '2rem',
                paddingY: '0.5rem',
                textAlign: 'center',
                width: 'fit-content',
                minWidth: '150px',
                backgroundColor,
            }}
        >
            <Typography sx={{ fontWeight: 'bold' }}>
                {contents ? contents : ''}
            </Typography>
        </Box>
    );
}

export default TableStatusCell;
