import { useCallback, useEffect, useState } from "react";
import { createMessage, getMessages } from "src/api/chat/MessageRequests";
import { uploadImage } from "src/api/images/uploadImage";

import { useSocketProvider } from "src/contexts/socket";

export const useMessages = (chat) => {
  const socket = useSocketProvider();

  const [items, setItems] = useState([]);
  const [percent, setPercent] = useState({});
  const [loading, setLoading] = useState(false);
  const [typing, setTyping] = useState([]);

  const onNewMessage = useCallback(
    (message) => {
      if (message.chat === chat._id) {
        setItems((prev) => {
          prev.unshift(message);
          return prev;
        });
      }
    },
    [chat?._id]
  );

  const initListeners = () => {
    socket.instance.current.on("messages/new", onNewMessage.bind(this));
    socket.instance.current.on(
      "messages/typing",
      ({ user, typing, chatId: typingChat }) => {
        if (typing && typingChat === chat._id) {
          setTyping((prev) => [
            ...prev.filter(({ _id }) => _id !== user._id),
            user,
          ]);
        } else {
          setTyping((prev) => prev.filter((i) => i._id !== user._id));
        }
      }
    );
  };

  const registerTyping = (chatId) => {
    socket.instance.current.emit("messages/typing", chatId);
  };

  const init = async () => {
    setItems([]);

    setLoading(true);
    const response = await getMessages(chat._id);
    setItems(response.data);
    setLoading(false);
  };

  useEffect(() => {
    if (chat?._id) {
      init();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chat?._id]);

  useEffect(() => {
    if (socket.inited) {
      initListeners();
    }

    return () => {
      socket.instance.current.off("messages/new", onNewMessage);
      socket.instance.current.off("messages/typing");

      setItems([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket.inited]);

  const clear = () => {
    setItems([]);
    setLoading(false);
  };

  const create = useCallback(
    async (value, options = {}) => {
      const response = await createMessage(chat._id, {
        value,
        ...options,
      });
      setItems((prev) => [response.data, ...prev]);
    },
    [chat._id]
  );

  const uploadFile = async (file) => {
    if (!chat?._id || !file) {
      return;
    }
    const formData = new FormData();
    formData.append("image", file);

    const res = await uploadImage(formData);
    await create("", {
      file: res.data.media,
      type: file.type.split("/")[0],
    });
  };

  return {
    typing,
    loading,
    items,
    percent,
    init,
    clear,
    create,
    uploadFile,
    registerTyping,
  };
};
