import axios from 'axios'
import Cookies from 'js-cookie';

const API = axios.create({
    baseURL: process.env.NODE_ENV.includes('production')
        ? process.env.REACT_APP_SERVER_URL
            ? process.env.REACT_APP_SERVER_URL
            : ''
        : 'http://localhost:3030/api',
});


const header = () => {
    const token = Cookies.get('refreshToken');
    return {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    };
}

export const addProductCode = (productId, code) => API.post('/productCodes/', {productId, code}, header());

export const getProductCode = (id, data) => API.get(`/productCodes/${id}?orderId=${data.orderId}&productCodeId=${data.productCodeId}`, header());

export const updateProductCode = (id, data) => API.put(`/productCodes/${id}`, data, header());