import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { nanoid } from 'nanoid';
import { useTheme } from '@mui/material';

// mui
import {
    Container,
    Stack,
    Typography,
    Box,
    TextField,
    MenuItem,
    FormHelperText,
    Button,
    Select,
    FormControl,
    InputLabel,
    InputAdornment,
    IconButton,
} from '@mui/material';

// components
import OrderProductsTable from 'src/components/orderProductsTable';

// form
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// icons
import VisibilityIcon from '@mui/icons-material/Visibility';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { getOrder } from 'src/api/orders/getOrder';
import { isoToReadableDate } from 'src/utils/isoToReadableDate';

function OrderEditPage() {
    console.log('ORDER PAGE');

    const { id } = useParams();

    const theme = useTheme();

    const navigate = useNavigate();

    const [orderData, setOrderData] = useState(null);

    useEffect(() => {
        resetAll();
        const getOrderData = async (id) => {
            try {
                console.log('CALLING GET ORDER');
                const data = await getOrder(id);
                if (data && data.data) {
                    setOrderData(data.data);
                }
            } catch (error) {
                navigate('/dashboard/orders');
            }
        };
        if (id) {
            getOrderData(id);
        }
    }, [id]);

    const resetAll = () => {
        setOrderData(null);
    };

    // const schema = yup.object().shape({
    //     buyerUsername: yup
    //         .string('Buyer Username must be a string')
    //         .min(3, 'Buyer Username must be at least 3 characters')
    //         .max(20, 'Buyer Username must be at most 20 characters')
    //         .required('Buyer Username is required'),
    //     sellerUsername: yup
    //         .string('Seller Username must be a string')
    //         .min(3, 'Seller Username must be at least 3 characters')
    //         .max(20, 'Seller Username must be at most 20 characters')
    //         .required('Seller Username is required'),
    //     status: yup
    //         .string()
    //         .oneOf([
    //             'pending',
    //             'shipped',
    //             'in-transit',
    //             'delivered',
    //             'code-sent',
    //         ])
    //         .required(),
    //     date: yup.date().required().max(new Date()),
    //     // username: yup.string().min(3).max(20).required(),
    //     // password: yup.string().min(8).max(20).required(),
    //     // email: yup.string().email().required(),
    //     // role: yup.string().oneOf(['admin', 'vendor', 'buyer']).required(),
    //     // description: yup.string().max(300),
    //     // theme: yup.string().oneOf(['light', 'dark']).required(),
    // });

    // const {
    //     register,
    //     handleSubmit,
    //     formState: { errors },
    //     reset,
    // } = useForm({
    //     resolver: yupResolver(schema),
    // });

    // const submitOrderData = (data) => {
    //     console.log(data);
    //     reset();
    // };

    return (
        <>
            <Helmet>
                <title> Order | Mentup Dashboard </title>
            </Helmet>
            {orderData ? (
                <Container>
                    <Stack
                        direction='row'
                        alignItems='center'
                        justifyContent='left'
                        mb={2}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'start',
                                columnGap: '0.5rem',
                            }}
                        >
                            <IconButton
                                onClick={() => {
                                    navigate(-1);
                                }}
                            >
                                <ArrowBackIosNewRoundedIcon />
                            </IconButton>
                            <Typography variant='h4'>
                                Заказ:
                                <span style={{ fontWeight: 'normal' }}>
                                    {orderData && orderData._id
                                        ? ` ${orderData._id}`
                                        : null}
                                </span>
                            </Typography>
                        </Box>
                    </Stack>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',

                                boxSizing: 'border-box',
                                padding: '1rem 1rem',
                                height: 'fit-content',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                gap: '1rem',
                                alignItems: 'start',
                                borderRadius: '12px',
                                backgroundColor: (theme) =>
                                    theme.palette.background.paper,
                                '@media (max-width: 900px)': {
                                    flexDirection: 'column',
                                },
                            }}
                        >
                            {/* <form
                            onSubmit={handleSubmit(submitOrderData)}
                            style={{ width: '48%' }}
                        > */}
                            <Box
                                sx={{
                                    boxSize: 'border-box',
                                    width: '48%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'start',
                                    alignItems: 'start',
                                    gap: '1rem',
                                    '@media (max-width: 900px)': {
                                        padding: '2rem',
                                        width: '100%',
                                    },
                                    '@media (max-width: 650px)': {
                                        paddingX: '0',
                                    },
                                }}
                            >
                                <Box sx={{ width: '100%' }}>
                                    <Box
                                        sx={{
                                            width: '100%',
                                            height: '64px',
                                            paddingLeft: '16px',
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: '1.125rem',
                                                fontWeight: 'bold',
                                            }}
                                        >{`Детали`}</Typography>
                                    </Box>
                                    <TextField
                                        fullWidth
                                        variant={`outlined`}
                                        label='Никнейм покупателя'
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        value={
                                            orderData &&
                                            orderData.user &&
                                            orderData.user.username
                                                ? orderData.user.username
                                                : ''
                                        }
                                    />
                                    <Button
                                        type='text'
                                        onClick={() => {
                                            if (
                                                orderData &&
                                                orderData.user &&
                                                orderData.user._id
                                            ) {
                                                navigate(
                                                    `/dashboard/user/${orderData.user._id}`
                                                );
                                            }
                                        }}
                                    >
                                        На страницу продавца
                                    </Button>
                                </Box>
                                {/* <FormControl fullWidth>
                                <InputLabel id='edit-order-status-input-label'>
                                    Status
                                </InputLabel>
                                <Select
                                    required
                                    labelId='edit-order-status-input-label'
                                    id='edit-order-status-input'
                                    label='Status'
                                   
                                   
                                >
                                    <MenuItem value={`pending`}>
                                        Pending
                                    </MenuItem>
                                    <MenuItem value={`shipped`}>
                                        Shipped
                                    </MenuItem>
                                    <MenuItem value={`in-transit`}>
                                        In Transit
                                    </MenuItem>
                                    <MenuItem value={`delivered`}>
                                        Delivered
                                    </MenuItem>
                                    <MenuItem value={`code-sent`}>
                                        Code Sent
                                    </MenuItem>
                                    <MenuItem value={`WRONG`}>WRONG</MenuItem>
                                </Select>
                                {errors.status?.message ? (
                                    <FormHelperText
                                        sx={{
                                            color: `${theme.palette.error.main}`,
                                        }}
                                    >
                                        {errors.status?.message}
                                    </FormHelperText>
                                ) : null}
                            </FormControl> */}
                                <TextField
                                    fullWidth
                                    variant={`outlined`}
                                    label='Статус'
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    value={
                                        orderData && orderData.status
                                            ? orderData.status
                                            : ''
                                    }
                                />
                                <TextField
                                    fullWidth
                                    variant={`outlined`}
                                    label='ID транзакции'
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    value={
                                        orderData &&
                                        orderData.transaction &&
                                        orderData.transaction._id
                                            ? orderData.transaction._id
                                            : ''
                                    }
                                />

                                <TextField
                                    fullWidth
                                    type={'date'}
                                    variant={`outlined`}
                                    label='Дата'
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    value={
                                        orderData &&
                                        orderData.createdAt &&
                                        orderData.createdAt.length > 0 &&
                                        orderData.createdAt.slice(0, 10)
                                            ? orderData.createdAt.slice(0, 10)
                                            : ''
                                    }
                                />

                                <TextField
                                    fullWidth
                                    variant={`outlined`}
                                    label='Итого'
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        readOnly: true,
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                <Typography>£</Typography>
                                            </InputAdornment>
                                        ),
                                    }}
                                    value={
                                        orderData &&
                                        orderData.transaction &&
                                        orderData.transaction.total
                                            ? orderData.transaction.total
                                            : ''
                                    }
                                />
                                {/* <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'end',
                                    alignItems: 'start',
                                }}
                            >
                                <Button
                                    size={`large`}
                                    type={`submit`}
                                    variant='contained'
                                    sx={{
                                        marginTop: '1rem',
                                        '@media (max-width: 900px)': {
                                            width: '100%',
                                        },
                                    }}
                                >
                                    Save all
                                </Button>
                            </Box> */}
                            </Box>
                            {/* </form> */}
                            <Box
                                sx={{
                                    width: '48%',
                                    height: '100%',
                                    '@media (max-width: 900px)': {
                                        paddingX: '2rem',
                                        width: '100%',
                                    },
                                }}
                            >
                                <OrderProductsTable orderData={orderData} />
                            </Box>
                        </Box>

                        {/* <Box
                        sx={{
                            width: '50%',
                            boxSizing: 'border-box',
                            paddingLeft: '2rem',
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '1.5rem',
                        }}
                    >
                        <Box>
                            <Typography variant={`h4`}>Listings</Typography>
                            <Typography sx={{ fontSize: '1.5rem' }}>
                                100
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant={`h4`}>Orders</Typography>
                            <Typography sx={{ fontSize: '1.5rem' }}>
                                100
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant={`h4`}>Sales</Typography>
                            <Typography sx={{ fontSize: '1.5rem' }}>
                                100
                            </Typography>
                        </Box>
                    </Box> */}
                    </Box>
                </Container>
            ) : null}
        </>
    );
}

export default OrderEditPage;
