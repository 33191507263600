import { useState, useEffect } from 'react';

// mui
import {
    Box,
    Button,
    Typography,
    Modal,
    TextField,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
} from '@mui/material';

// form
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// icons
import CloseIcon from '@mui/icons-material/Close';
import EditRoundedIcon from '@mui/icons-material/EditRounded';

// api
import { updateCategoryType } from '../../api/types/updateType';
import { getAllTypes } from 'src/api/types/getTypes';
import { updateAttrType } from 'src/api/typesAttrs/updateAttrType';
import { getAllCategories } from 'src/api/categories/getAllCategories';
import { getAllGroupTypes } from 'src/api/groupTypes/createGroupType';

// api

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    paddingX: 7,
    paddingTop: 1,
    paddingBottom: 4,
    borderRadius: '10px',
    maxHeight: '90vh',
    overflow: 'scroll',
    '@media (max-width: 650px)': {
        paddingX: 4,
        width: '90%',
    },
};

function EditAttrTypeModal({ attr , editFinished }) {

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [readyToSubmit, setReadyToSubmit] = useState(false);

    const [submittedFormData, setSubmittedFormData] = useState(null);

    const [currentAttr, setCurrentAttr] = useState(attr);

    const [productTypes, setProductTypes] = useState([]);

    const [selectedProductType, setSelectedProductType] = useState(attr?.productType?._id);

    const [categories, setCategories] = useState([]);

    const [selectedCategory, setSelectedCategory] = useState(attr?.category?._id);

    const schema = yup.object().shape({
        name: yup.string().max(60).required(),
        disabled: yup.boolean(),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(schema),
    })

    const submitCategoryTypeEditData = (data) => {
        setSubmittedFormData({...data});
        setReadyToSubmit(true);
    };

    useEffect(() => {
        if(attr) {
            const getAllProductTypes = async () => {
                try {
                    const data = await getAllTypes();
                    if (data && data.data) {
                        setProductTypes(data.data.types);
                    }
                } catch (error) {
                    console.log(error);
                }
            };
            getAllProductTypes();
        }
    }, []);

    // USE EFFECT WHEN EXTERNAL URLS READY, AND SEND ALL DATA
    useEffect(() => {
        if (submittedFormData && readyToSubmit) {
            const sendUpdatedCategoryData = async () => {
                try {
                    if (submittedFormData.name !== null) {
                        submittedFormData.productType = selectedProductType;
                        submittedFormData.category = selectedCategory;

                        if(selectedGroup) {
                            submittedFormData.attributeGroup = selectedGroup
                        }
    
                        if(allGroupTypes?.find((group) => group._id === selectedGroup)?.groupType === 'range') {
                            submittedFormData.range = {
                                min: Number(submittedFormData.min),
                                max: Number(submittedFormData.max),
                            }
                        }

                        const data = await updateAttrType(
                            {
                                ...submittedFormData,
                            },
                            attr._id
                        );

                        if (data && data.data) {
                            resetAll();
                            if (editFinished) {
                                editFinished('edit');
                            }
                            reset();
                            handleClose();
                        }
                    }
                } catch (error) {
                    console.log(error);
                    if (editFinished) {
                        editFinished('error');
                    }
                    resetAll();
                    handleClose();
                }
            };
            sendUpdatedCategoryData();
        }
    }, [submittedFormData]);


    const resetAll = () => {
        reset();
        setSubmittedFormData(null);
    };

    const handleNameChange = (e) => {
        setReadyToSubmit(false);
        setCurrentAttr({...currentAttr, name: e.target.value});
    }

    const handleDisabledChange = (e) => {
        setReadyToSubmit(false);
        setCurrentAttr({...currentAttr, disabled: e.target.value});
    }

    // Get all categories
    useEffect(() => {
        const fetchAllCategories = async () => {
            try {
                const data = await getAllCategories()
                setCategories(data.data)
            } catch (error) {
                console.log(error)
            }
        }
        fetchAllCategories()
    }, [])

    const [selectedGroup, setSelectedGroup] = useState(attr?.attributeGroup?._id);

    const [allGroupTypes, setAllGroupTypes] = useState(null);

    // Get all attribute groups
    useEffect(() => {
        const fetchAllGroups = async () => {
            try {
                const data = await getAllGroupTypes()
                setAllGroupTypes(data.data.attrs)
            } catch (error) {
                console.log(error)
            }
        }
        fetchAllGroups()
    }, [])

    const handleGroupChange = (e) => {
        setSelectedGroup(e.target.value)
        // reset the min and max values
        reset({min: '', max: ''})
    }


    return (
        <Box>
            <MenuItem onClick={handleOpen}>
                <EditRoundedIcon sx={{ mr: 2 }} /> Edit{' '}
            </MenuItem>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby='edit-category-modal'
                aria-describedby='edit-category-modal'
            >
                <Box sx={style}>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'end',
                        }}
                    >
                        <IconButton
                            aria-label='close'
                            onClick={() => {
                                handleClose();
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            margin: '0',
                            padding: '0',
                            width: '100%',
                            marginBottom: '0.5rem',
                            textAlign: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                fontSize: '1.8rem',
                            }}
                        >
                            {currentAttr?.name
                                ? `Edit category type: ${currentAttr.name}`
                                : `Edit Category`}
                        </Typography>
                    </Box>
                    <form onSubmit={handleSubmit(submitCategoryTypeEditData)}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                rowGap: '1rem',
                            }}
                        >

                            <TextField
                                required
                                fullWidth
                                variant={`outlined`}
                                label='Attribute Name'
                                error={errors.name && true}
                                helperText={errors.name?.message}
                                {...register('name')}
                                value={
                                    currentAttr?.name
                                        ? currentAttr.name
                                        : ''
                                }
                                onChange={(e) => handleNameChange(e)}
                            />

                            {/* Dispaly and Select multiple From All ProductTypes */}
                            <FormControl required fullWidth>
                                <InputLabel id='category-select-label'>
                                    Выбрать категорию
                                </InputLabel>
                                <Select
                                    labelId='category-select-label'
                                    id='category-select'
                                    onChange={(e) => 
                                        (setSelectedCategory(e.target.value),
                                        console.log(e.target.value))
                                    }
                                    value={selectedCategory}
                                >
                                    {categories && categories.map((category) => (
                                        <MenuItem key={category._id} value={category._id}>
                                            {category.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            {/* Dispaly and Select multiple From All ProductTypes */}
                            <FormControl
                                fullWidth
                                error={errors.type && true}
                                variant={`outlined`}
                            >
                                <InputLabel id='category-type-label'>
                                    Вид товара
                                </InputLabel>
                                <Select
                                    disabled={!selectedCategory}
                                    label='Category Types'
                                    {...register('type')}
                                    value={selectedProductType}
                                    onChange={(e) => {
                                        // Add type name to selectedProductTypes
                                            setSelectedProductType(e.target.value);
                                    }}
                                >
                                    {categories?.find((category) => category._id === selectedCategory)?.productTypes.map((type) => (
                                            <MenuItem
                                                key={type._id}
                                                value={type._id}
                                            >
                                                {type.name}
                                            </MenuItem>
                                    ))}

                                </Select>
                                <FormHelperText>
                                    {errors.type?.message}
                                </FormHelperText>
                            </FormControl>

                            <FormControl

                                variant='outlined'
                                fullWidth
                                error={errors.disabled && true}
                            >
                                <InputLabel id='category-disabled'>
                                    Disabled
                                </InputLabel>
                                <Select
                                    labelId='category-disabled'
                                    id='category-disabled'
                                    label='Disabled'
                                    {...register('disabled')}
                                    value={currentAttr?.disabled}
                                    onChange={(e) =>
                                        handleDisabledChange(e)
                                    }
                                >
                                    <MenuItem value={false}>False</MenuItem>
                                    <MenuItem value={true}>True</MenuItem>
                                </Select>
                                <FormHelperText>
                                    {errors.disabled?.message}
                                </FormHelperText>
                            </FormControl>

                            {/* Dispaly and Select multiple From All Group */}
                            <FormControl fullWidth>
                                <InputLabel id='group-select-label'>
                                    Выбрать группу
                                </InputLabel>
                                <Select
                                    labelId='group-select-label'
                                    id='group-select'
                                    onChange={(e) => handleGroupChange(e) }
                                    value={selectedGroup}
                                >
                                    {allGroupTypes?.map((group) => (
                                        <MenuItem key={group._id} value={group._id}>
                                            {group.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                           
                            {allGroupTypes?.find((group) => group._id === selectedGroup)?.groupType === 'range' && (
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '1rem',
                                }}>
                                    <TextField
                                        required
                                        fullWidth
                                        variant={`outlined`}
                                        label='Минимальное значение'
                                        value={currentAttr?.valueRange?.min}
                                        error={errors.min && true}
                                        helperText={errors.min?.message}
                                        {...register('min')}
                                    />
                                    <TextField
                                        required
                                        fullWidth
                                        variant={`outlined`}
                                        label='Максимальное значение'
                                        value={currentAttr?.valueRange?.max}
                                        error={errors.max && true}
                                        helperText={errors.max?.message}
                                        {...register('max')}
                                    />
                                </Box>
                            )}

                            <Button
                                disabled={submittedFormData ? true : false}
                                size={`large`}
                                type={`submit`}
                                variant='contained'
                                sx={{ marginTop: '1rem' }}
                            >
                                Submit
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Modal>
        </Box>
    );
}

export default EditAttrTypeModal;
