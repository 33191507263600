import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material';

// css
import '../index.css';

// mui
import {
    Container,
    Stack,
    Typography,
    Box,
    TextField,
    MenuItem,
    FormHelperText,
    Button,
    Select,
    FormControl,
    InputLabel,
    InputAdornment,
    IconButton,
    Card,
} from '@mui/material';

// Color Picker
import { HexColorPicker } from 'react-colorful';

// form
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// icons
import VisibilityIcon from '@mui/icons-material/Visibility';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import PaletteRoundedIcon from '@mui/icons-material/PaletteRounded';

function GeneralSettingsPage() {
    console.log('SETTINGS PAGE');

    const theme = useTheme();

    const [color, setColor] = useState('#8c53cf');

    const [showPassword, setShowPassword] = useState(false);
    const [avatar, setAvatar] = useState(null);

    const navigate = useNavigate();

    const schema = yup.object().shape({
        username: yup.string().min(3).max(20).required(),
        password: yup.string().min(8).max(20).required(),
        email: yup.string().email().required(),
        role: yup.string().oneOf(['admin', 'vendor', 'buyer']).required(),
        description: yup.string().max(300),
        theme: yup.string().oneOf(['light', 'dark']).required(),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const submitUserData = (data) => {
        // TO GET AVATAR FROM STATE IF PRESENT
        console.log(data);
        reset();
        // TO RESET AVATAR STATE
    };

    const handleAvatarUpload = (event) => {
        const picture = event.target.files[0];
        if (picture) {
            const tempPictureUrl = URL.createObjectURL(picture);
            setAvatar({ file: picture, url: tempPictureUrl });
        }
    };

    return (
        <>
            <Helmet>
                <title> Settings | Mentup Dashboard </title>
            </Helmet>
            <Container>
                <Stack
                    direction='row'
                    alignItems='center'
                    justifyContent='left'
                    mb={2}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'start',
                            columnGap: '0.5rem',
                        }}
                    >
                        <IconButton
                            onClick={() => {
                                navigate(-1);
                            }}
                        >
                            <ArrowBackIosNewRoundedIcon />
                        </IconButton>
                        <Typography variant='h4'>General Settings</Typography>
                    </Box>
                </Stack>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <form
                        onSubmit={handleSubmit(submitUserData)}
                        style={{ width: '100%' }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                height: 'fit-content',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'start',
                                alignItems: 'start',
                                gap: '1rem',
                            }}
                        >
                            <Card
                                sx={{
                                    boxSize: 'border-box',
                                    width: '50%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'start',
                                    alignItems: 'start',
                                    gap: '1rem',
                                    padding: '1rem',
                                    '@media (max-width: 900px)': {
                                        padding: '2rem',
                                        width: '100%',
                                    },
                                    '@media (max-width: 650px)': {
                                        paddingX: '0',
                                    },
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'start',
                                        justifyContent: 'start',
                                        rowGap: '1rem',
                                        width: '100%',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            flexWrap: 'wrap',
                                            alignItems: 'center',
                                            columnGap: '0.5rem',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontWeight: 'bold',
                                                fontSize: '1.2rem',
                                            }}
                                        >{`Website Main Color`}</Typography>
                                        <PaletteRoundedIcon
                                            sx={{ ...(color && { color }) }}
                                        />
                                    </Box>

                                    <HexColorPicker
                                        color={color}
                                        onChange={setColor}
                                    />
                                </Box>

                                <Box
                                    sx={{
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'start',
                                        alignItems: 'start',
                                    }}
                                >
                                    <Button
                                        size={`large`}
                                        type={`submit`}
                                        variant='contained'
                                        sx={{
                                            marginTop: '1rem',
                                            '@media (max-width: 900px)': {
                                                width: '100%',
                                            },
                                        }}
                                    >
                                        Save
                                    </Button>
                                </Box>
                            </Card>
                        </Box>
                    </form>
                </Box>
            </Container>
        </>
    );
}

export default GeneralSettingsPage;
