import { Box, Typography } from "@mui/material";
import { useTranslate } from "src/contexts/translate/translate.context";

export const MessageListItem = ({ message }) => {
  const { translate } = useTranslate();
  const currentUser = JSON.parse(sessionStorage.getItem("user"));

  return (
    <Box
      sx={{
        ...([currentUser._id, "support"].includes(message.sender)
          ? { alignSelf: "end", alignItems: "flex-end" }
          : { alignItems: "flex-start" }),
        width: "fit-content",
        padding: "10px",
        display: "flex",
        maxWidth: "80%",
        flexDirection: "column",
      }}
    >
      {message.type === "image" ? (
        <img
          src={message.value}
          alt=""
          style={{
            maxWidth: "80%",
            borderRadius: "10",
            objectFit: "contain",
          }}
        />
      ) : (
        <Typography
          sx={{
            whiteSpace: "pre-line",
            borderRadius: "8px",
            padding: "10px",
            boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.35)",
          }}
        >
          {message.isSupportQuestion
            ? translate(`chat.messages.faq.questions.${message.value}`)
            : message.isSupportAnswer
            ? translate(`chat.messages.faq.answers.${message.value}`)
            : message.value}
        </Typography>
      )}
      <Typography
        sx={{
          fontSize: "0.8rem",
          color: "#9e9e9e",
          whiteSpace: "nowrap",
          ...([currentUser._id, "support"].includes(message.sender)
            ? { alignSelf: "flex-end" }
            : {}),
        }}
      >
        {new Date(message.createdAt).toLocaleString()}
      </Typography>
    </Box>
  );
};
