import * as _ from "lodash";
import { createContext, useContext } from "react";
import { useSelector } from "react-redux";

import * as EN from "./en.json";
import * as RU from "./ru.json";

const Context = createContext(null);

export const TranslationsData = {
  en: EN,
  ru: RU,
};

export const TranslateProvider = ({ children }) => {
  const language = useSelector((state) => state.language);

  const translate = (key) => {
    const data = TranslationsData[language] || TranslationsData.en;
    const value = _.get(data, key);

    return value;
  };

  const value = { language, translate };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useTranslate = () => useContext(Context);
