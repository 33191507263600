import React from 'react';
import { useState, useEffect } from 'react';

// mui
import {
    Box,
    Button,
    Typography,
    Modal,
    TextField,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Card,
} from '@mui/material';

// form
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// icons
import CloseIcon from '@mui/icons-material/Close';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import CameraAltRoundedIcon from '@mui/icons-material/CameraAltRounded';

// api
import { sendMediaToBack } from 'src/api/categories/sendMediaToBack';
import { updateCategory } from 'src/api/categories/updateCategory';
import { deleteMultipleImages } from 'src/api/images/deleteMultipleImages';
import { getAllTypes } from 'src/api/types/getTypes';
import { getSoftware } from 'src/api/software/getSoftware';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    paddingX: 7,
    paddingTop: 1,
    paddingBottom: 4,
    borderRadius: '10px',
    maxHeight: '90vh',
    overflow: 'scroll',
    '@media (max-width: 650px)': {
        paddingX: 4,
        width: '90%',
    },
};

function EditCategoryModal({ category, editFinished }) {

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [currentCategory, setCurrentCategory] = useState(null);

    const [media, setMedia] = useState(null);
    const [banner, setBanner] = useState(null);

    const [submittedFormData, setSubmittedFormData] = useState(null);

    const [pictureCodesToDelete, setPictureCodesToDelete] = useState(null);

         // Get All Product Types
    const [productTypes, setProductTypes] = useState([]);
    const [selectedProductTypes, setSelectedProductTypes] = useState([]);
    const [allSelectedProductTypes, setAllSelectedProductTypes] = useState([]);
    const [selectedPrimaryType, setSelectedPrimaryType] = useState('');
    const [software, setSoftware] = useState([]);
    const [selectedSoftware, setSelectedSoftware] = useState([]);
     

    useEffect(() => {
        resetAll();
        const setInitalValues = () => {
            setCurrentCategory(category);
            setSelectedProductTypes(category.primaryProductType);
            // convert category.software to array
            if(category.software) {
                setSelectedSoftware(category.software);
            } 
            if (category.media) {
                setMedia({
                    file: null,
                    url: null,
                    externalUrl: category.media,
                });
            }
            if (category.banner) {
                setBanner({
                    file: null,
                    url: null,
                    externalUrl: category.banner,
                });
            }
    };

        if(category) {
            setInitalValues();
        }

    }, [category]);

    const schema = yup.object().shape({
        name: yup.string().max(60).required(),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const deleteImagesFromBackend = async (codes) => {
        try {
            if (codes && codes.length > 0) {
                const data = await deleteMultipleImages(codes);
                if (
                    data &&
                    data.data &&
                    data.data.message &&
                    data.data.message === 'Images deleted'
                ) {
                    if (media && media.file) {
                        uploadMedia();
                    }
                    if (banner && banner.file) {
                        uploadBanner();
                    }
                }
            }
        } catch (error) {
            console.log(error);
            if (editFinished) {
                editFinished('error');
            }
            resetAll();
            handleClose();
        }
    };

    const submitCategoryData = (data) => {
        if (
            media &&
            (media.file || media.externalUrl) &&
            banner &&
            (banner.file || banner.externalUrl)
        ) {
            if (pictureCodesToDelete && pictureCodesToDelete.length > 0) {
                deleteImagesFromBackend(pictureCodesToDelete);
            }

            if (currentCategory) {
                setSubmittedFormData({
                    ...data,
                    ...(media.file && { media: media.externalUrl }),
                    ...(banner.file && { banner: banner.externalUrl }),
                });
            }
        }
    };

    // USE EFFECT WHEN EXTERNAL URLS READY, AND SEND ALL DATA
    useEffect(() => {
        if (
            submittedFormData &&
            media &&
            media.externalUrl &&
            banner &&
            banner.externalUrl
        ) {
            const sendUpdatedCategoryData = async () => {
                try {
                    if (category && category._id) {
                        const data = await updateCategory(
                            {
                                ...submittedFormData,
                                ...(media.file && { media: media.externalUrl }),
                                ...(banner.file && {
                                    banner: banner.externalUrl,
                                }),
                                ...(selectedProductTypes && { type: selectedProductTypes }),
                                ...(selectedPrimaryType && { primaryType: selectedPrimaryType}),
                                ...(selectedSoftware && { software: selectedSoftware}),
                            },
                            category._id
                        );

                        if (data && data.data) {
                            resetAll();
                            if (editFinished) {
                                editFinished('edit');
                            }
                            reset();
                            handleClose();
                        }
                    }
                } catch (error) {
                    console.log(error);
                    if (editFinished) {
                        editFinished('error');
                    }
                    resetAll();
                    handleClose();
                }
            };
            sendUpdatedCategoryData();
        }
    }, [media, banner, submittedFormData, pictureCodesToDelete]);

    const handleMediaChange = (e) => {
        const picture = e.target.files[0];
        if (picture) {
            if (media && media.externalUrl) {
                let tempArray = [];
                if (pictureCodesToDelete && pictureCodesToDelete.length > 0) {
                    tempArray = [...pictureCodesToDelete];
                }

                const regex = /\/([a-f\d]{64})\?/; // Regular expression to match the hash
                try {
                    const match = media.externalUrl.match(regex);

                    if (match && match[1]) {
                        const extractedHash = match[1];
                        tempArray.push(extractedHash);
                    }
                } catch (error) {
                    console.log(error);
                    if (editFinished) {
                        editFinished('error');
                    }
                    resetAll();
                    handleClose();
                }
                setPictureCodesToDelete([...tempArray]);
            }
            setMedia({
                file: picture,
                url: URL.createObjectURL(picture),
                externalUrl: null,
            });
        }
    };

    const handleBannerChange = (e) => {
        const picture = e.target.files[0];
        if (picture) {
            if (banner && banner.externalUrl) {
                let tempArray = [];
                if (pictureCodesToDelete && pictureCodesToDelete.length > 0) {
                    tempArray = [...pictureCodesToDelete];
                }
                const regex = /\/([a-f\d]{64})\?/; // Regular expression to match the hash
                try {
                    const match = banner.externalUrl.match(regex);

                    if (match && match[1]) {
                        const extractedHash = match[1];
                        tempArray.push(extractedHash);
                    }
                } catch (error) {
                    console.log(error);
                    if (editFinished) {
                        editFinished('error');
                    }
                    resetAll();
                    handleClose();
                }
                setPictureCodesToDelete([...tempArray]);
            }
            setBanner({
                file: picture,
                url: URL.createObjectURL(picture),
                externalUrl: null,
            });
        }
    };

    const uploadMedia = async () => {
        try {
            let formData = new FormData();
            formData.append('image', media.file);
            const imageType = 'catMedia'
            const data = await sendMediaToBack(formData, imageType);
            if (data && data.data && data.data.media) {
                setMedia({ ...media, externalUrl: data.data.media });
            }
        } catch (error) {
            console.log(error);
            if (editFinished) {
                editFinished('error');
            }
            resetAll();
            handleClose();
        }
    };

    const uploadBanner = async () => {
        try {
            let formData = new FormData();
            formData.append('image', banner.file);
            const imageType = 'catBanner'
            const data = await sendMediaToBack(formData, imageType);
            if (data && data.data && data.data.media) {
                setBanner({ ...banner, externalUrl: data.data.media });
            }
        } catch (error) {
            console.log(error);
            if (editFinished) {
                editFinished('error');
            }
            resetAll();
            handleClose();
        }
    };


    const resetAll = () => {
        reset();
        setCurrentCategory(null);
        setBanner(null);
        setMedia(null);
        setSubmittedFormData(null);
        setPictureCodesToDelete(null);
        setSelectedProductTypes([]);
        setAllSelectedProductTypes([]);
        setSelectedPrimaryType(null);
    };

    // Get All Product Types
     useEffect(() => {
        if(category) {
         const getAllProductTypes = async () => {
             try {
                 const data = await getAllTypes();
                 if (data && data.data) {
                    setProductTypes(data.data.types); 
                    if(category.primaryProductType) {
                        setSelectedPrimaryType(category.primaryProductType);
                    }
                    if(category.productTypes) {
                        setSelectedProductTypes(category.productTypes);
                    }
                 }
             } catch (error) {
                 console.log(error);
             }
         };

         getAllProductTypes();
        }
     }, [category]);

    // Get All Selected Product Types
    useEffect(() => {
        if(productTypes && productTypes.length > 0) {
            const tempArray = [];
            productTypes.forEach(type => {
                if(selectedProductTypes.includes(type._id)) {
                    tempArray.push(type);
                }
            });
            setAllSelectedProductTypes(tempArray);   
        }
    }, [selectedProductTypes])

    // Get All Software
    useEffect(() => {
        if (software.length === 0) {
            const getAllSoft = async () => {
                try {
                    const res = await getSoftware();
                    const softwareData = res.data;
                    if (softwareData && softwareData.length > 0) {
                        setSoftware(softwareData);
                    }
                } catch (err) {
                    console.log(err);
                }
            };
            getAllSoft();
        }
    }, []); // Remove 'software' from the dependency array


    return (
        <Box>
            <MenuItem onClick={handleOpen}>
                <EditRoundedIcon sx={{ mr: 2 }} />Редактировать{' '}
            </MenuItem>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby='edit-category-modal'
                aria-describedby='edit-category-modal'
            >
                <Box sx={style}>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'end',
                        }}
                    >
                        <IconButton
                            aria-label='close'
                            onClick={() => {
                                handleClose();
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            margin: '0',
                            padding: '0',
                            width: '100%',
                            marginBottom: '0.5rem',
                            textAlign: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                fontSize: '1.8rem',
                            }}
                        >
                            {category && category.name
                                ? `Edit category: ${category.name}`
                                : `Edit Category`}
                        </Typography>
                    </Box>
                    <form onSubmit={handleSubmit(submitCategoryData)}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                rowGap: '1rem',
                            }}
                        >
                            <Box
                                sx={{
                                    width: '100%',
                                    height: '200px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    marginTop: '1rem',
                                    marginBottom: '2.5rem',
                                }}
                            >
                                <Box
                                    sx={{
                                        width: '32%',
                                        height: '100%',
                                        textAlign: 'center',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: 'bold',
                                            fontSize: '1.2rem',
                                        }}
                                    >{`Media*`}</Typography>
                                    <input
                                        hidden
                                        accept='image/*'
                                        type='file'
                                        onChange={handleMediaChange}
                                        onClick={(e) => (e.target.value = null)}
                                        id={`edit-category-media-upload-input`}
                                    />
                                    <label
                                        htmlFor={`edit-category-media-upload-input`}
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                        }}
                                    >
                                        <Card
                                            sx={{
                                                width: '100%',
                                                height: '100%',
                                                ...(media &&
                                                    media.file &&
                                                    media.url && {
                                                        backgroundImage: `url('${media.url}')`,
                                                    }),
                                                ...(media &&
                                                    !media.file &&
                                                    media.externalUrl && {
                                                        backgroundImage: `url('${media.externalUrl}')`,
                                                    }),
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center',
                                                backgroundRepeat: 'no-repeat',
                                            }}
                                        >
                                            <Button
                                                component='span'
                                                sx={{
                                                    position: 'relative',
                                                    borderRadius: '10px',
                                                    width: '100%',
                                                    height: '100%',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'end',
                                                    textTransform: 'none',
                                                    backgroundColor: '#bfbfbf',
                                                    '&:hover': {
                                                        backgroundColor:
                                                            '#a3a3a3',
                                                    },
                                                    ...(media &&
                                                        (media.url ||
                                                            media.externalUrl) && {
                                                            opacity: '0.3',
                                                        }),
                                                }}
                                            >
                                                <CameraAltRoundedIcon
                                                    sx={{
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        translate: '-50% -50%',
                                                    }}
                                                />
                                            </Button>
                                        </Card>
                                    </label>
                                </Box>

                                <Box
                                    sx={{
                                        width: '62%',
                                        height: '100%',
                                        textAlign: 'center',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: 'bold',
                                            fontSize: '1.2rem',
                                        }}
                                    >{`Banner*`}</Typography>
                                    <input
                                        hidden
                                        accept='image/*'
                                        type='file'
                                        onChange={handleBannerChange}
                                        onClick={(e) => (e.target.value = null)}
                                        id={`edit-category-banner-upload-input`}
                                    />
                                    <label
                                        htmlFor={`edit-category-banner-upload-input`}
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                        }}
                                    >
                                        <Card
                                            sx={{
                                                width: '100%',
                                                height: '100%',
                                                ...(banner &&
                                                    banner.file &&
                                                    banner.url && {
                                                        backgroundImage: `url('${banner.url}')`,
                                                    }),
                                                ...(banner &&
                                                    !banner.file &&
                                                    banner.externalUrl && {
                                                        backgroundImage: `url('${banner.externalUrl}')`,
                                                    }),

                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center',
                                                backgroundRepeat: 'no-repeat',
                                            }}
                                        >
                                            <Button
                                                component='span'
                                                sx={{
                                                    position: 'relative',
                                                    borderRadius: '10px',
                                                    width: '100%',
                                                    height: '100%',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'end',
                                                    textTransform: 'none',
                                                    backgroundColor: '#bfbfbf',
                                                    '&:hover': {
                                                        backgroundColor:
                                                            '#a3a3a3',
                                                    },
                                                    ...(banner &&
                                                        (banner.url ||
                                                            banner.externalUrl) && {
                                                            opacity: '0.3',
                                                        }),
                                                }}
                                            >
                                                <CameraAltRoundedIcon
                                                    sx={{
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        translate: '-50% -50%',
                                                    }}
                                                />
                                            </Button>
                                        </Card>
                                    </label>
                                </Box>
                            </Box>

                            <TextField
                                required
                                fullWidth
                                variant={`outlined`}
                                label='Category Name'
                                error={errors.name && true}
                                helperText={errors.name?.message}
                                {...register('name')}
                                value={
                                    currentCategory && currentCategory.name
                                        ? currentCategory.name
                                        : ''
                                }
                                onChange={(e) => {
                                    setCurrentCategory({
                                        ...currentCategory,
                                        name: e.target.value,
                                    });
                                }}
                            />

                            <TextField
                                required
                                fullWidth
                                variant={`outlined`}
                                label='Category Description'
                                error={errors.description && true}
                                helperText={errors.description?.message}
                                {...register('description')}
                                value={
                                    currentCategory && currentCategory.description
                                        ? currentCategory.description
                                        : ''
                                }
                                onChange={(e) => {
                                    setCurrentCategory({
                                        ...currentCategory,
                                        description: e.target.value,
                                    });
                                }}
                            />

                            <FormControl

                                fullWidth
                                variant={`outlined`}
                            >
                                <InputLabel id='type-label'>Software</InputLabel>
                                <Select
                                    multiple
                                    labelId='type-label'
                                    label='Software'
                                    value={selectedSoftware}
                                    onChange={(e) => {
                                        setSelectedSoftware(e.target.value);
                                    }}
                                >
                                    {software &&
                                        software.map((soft) => (
                                            <MenuItem
                                                key={soft._id}
                                                value={soft._id}
                                            >
                                                {soft.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                            
                            <FormControl

                                fullWidth
                                variant={`outlined`}
                            >
                                <InputLabel id='type-label'>Category Types</InputLabel>
                                <Select
                                    multiple
                                    labelId='type-label'
                                    label='Category Types'
                                    value={selectedProductTypes}
                                    onChange={(e) => {
                                        setSelectedProductTypes(e.target.value);
                                    }}
                                >
                                    {productTypes &&
                                        productTypes.map((type) => (
                                            <MenuItem
                                                key={type._id}
                                                value={type._id}
                                            >
                                                {type.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>

                            {allSelectedProductTypes &&
                                allSelectedProductTypes.length > 0 && (
                                    <FormControl
                                        fullWidth
                                        variant={`outlined`}
                                    >
                                        <InputLabel id='primary-type-label'>
                                            Primary Type
                                        </InputLabel>
                                        <Select
                                            labelId='primary-type-label'
                                            label='Primary Type'
                                            value={selectedPrimaryType}
                                            onChange={(e) => {
                                                setSelectedPrimaryType(
                                                    e.target.value
                                                );
                                            }}
                                        >
                                            {allSelectedProductTypes && allSelectedProductTypes.length > 0 &&
                                                allSelectedProductTypes.map(
                                                    (type, index) => (
                                                        <MenuItem
                                                            key={type._id}
                                                            value={type._id}
                                                        >
                                                            {type ? type.name : ''}
                                                        </MenuItem>
                                                    )
                                                )}
                                        </Select>
                                    </FormControl>
                                )}

                            <Button
                                disabled={
                                    !(media?.file || media?.externalUrl) ||
                                    !(banner?.file || banner?.externalUrl)
                                }
                                size={`large`}
                                type={`submit`}
                                variant='contained'
                                sx={{ marginTop: '1rem' }}
                            >
                                Submit
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Modal>
        </Box>
    );
}

export default EditCategoryModal;
