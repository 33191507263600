import axios from 'axios';
import Cookies from 'js-cookie';

const API = axios.create({
    baseURL: process.env.NODE_ENV.includes('production')
        ? process.env.REACT_APP_SERVER_URL
            ? process.env.REACT_APP_SERVER_URL
            : ''
        : 'http://localhost:3030/api',
});

const header = () => {
    const token = Cookies.get('refreshToken');
    if (token) {
        return {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        };
    }
}

export const createGroupType = (data) => {
    return API.post(`/attributeGroups/admin`, data, header());
};

export const editGroupType = (id, data) => {
    return API.patch(`/attributeGroups/admin/`+id, data, header());
};

export const deleteGroupType = (id) => {
    return API.delete(`/attributeGroups/admin/`+id, header());
};

export const getAllGroupTypes = (rowsPerPage, page, orderBy, order, searchValue) => {
    if(rowsPerPage) {
     return API.get(`/attributeGroups/admin/all?rowsPerPage=${rowsPerPage}&page=${page}&orderBy=${orderBy}&order=${order}&searchValue=${searchValue}`, header());
    } else {
        return API.get(`/attributeGroups/admin/all`, header());
    }
};
