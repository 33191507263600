import React from 'react';
import { useState } from 'react';

// mui
import {
    Box,
    Button,
    Typography,
    Modal,
    TextField,
    IconButton,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    FormHelperText,
} from '@mui/material';

// form
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// icons
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { min } from 'lodash';

// api
import { createNotification } from 'src/api/notifications/createNotification';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    paddingX: 7,
    paddingTop: 1,
    paddingBottom: 4,
    borderRadius: '10px',
    maxHeight: '90vh',
    overflow: 'scroll',
    '@media (max-width: 650px)': {
        width: '90%',
    },
    '@media (max-width: 500px)': {
        paddingX: 4,
    },
};

function AddNotificationModal({notify}) {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const schema = yup.object().shape({
        userId: yup
            .string('ID пользователя должен быть строкой.')
            .max(50, 'ID пользователя не должен превышать 50 символов.')
            .required('ID пользователя обязателен.'),
        fromUserId: yup
            .string('ID отправителя должен быть строкой.')
            .max(50, 'ID отправителя не должен превышать 50 символов.')
            .required('ID отправителя обязателен.'),
        message: yup.string().max(500).required('Сообщение обязательно.')
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const submitNotificationData = (data) => {
        console.log(data);
        sendNotificationData(data);
        // reset();
        // before submitting, set expiry date to ISO format, in a try/catch block, like in add product modal
    };

    const sendNotificationData = async (data) => {
        if (data) {
            console.log('YES DATA, PREPARING TO CALL API')
            try {
                const response = await createNotification(data);
                if (response) {
                    console.log('RESPONSE:' + response);
                    if (notify) {
                        notify('add');
                    }
                    reset();
                    handleClose();
                }
            } catch (error) {
                console.log(error);
                if (notify) {
                    notify('error');
                }
                handleClose();
            }
        }
    };

    return (
        <Box>
            <Button
                onClick={handleOpen}
                variant='contained'
                startIcon={<AddIcon />}
            >
                Новое уведомление
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby='add-notification-modal'
                aria-describedby='add-notification-modal'
            >
                <Box sx={style}>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'end',
                        }}
                    >
                        <IconButton
                            aria-label='close'
                            onClick={() => {
                                handleClose();
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            margin: '0',
                            padding: '0',
                            width: '100%',
                            marginBottom: '0.5rem',
                            textAlign: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                fontSize: '1.8rem',
                            }}
                        >
                            Добавить новое уведомление
                        </Typography>
                    </Box>
                    <form onSubmit={handleSubmit(submitNotificationData)}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                rowGap: '1rem',
                                padding: '1rem 3rem',
                                '@media (max-width: 650px)': {
                                    paddingX: 0,
                                },
                            }}
                        >
                            <TextField
                                required
                                fullWidth
                                variant={`outlined`}
                                label='ID пользователя'
                                error={errors.userId && true}
                                helperText={errors.userId?.message}
                                {...register('userId')}
                            />

                            <TextField
                                required
                                fullWidth
                                variant={`outlined`}
                                label='ID отправителя'
                                error={errors.fromUserId && true}
                                helperText={errors.fromUserId?.message}
                                {...register('fromUserId')}
                            />

                            <TextField
                                required
                                multiline
                                rows={4}
                                fullWidth
                                variant={`outlined`}
                                label='Сообщение'
                                error={errors.message && true}
                                helperText={errors.message?.message}
                                {...register('message')}
                            />

                            <Button
                                size={`large`}
                                type={`submit`}
                                variant='contained'
                                startIcon={<AddIcon />}
                                sx={{ marginTop: '1rem' }}
                            >
                                Добавить уведомление
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Modal>
        </Box>
    );
}

export default AddNotificationModal;
