import React from 'react';
import { useState, useEffect } from 'react';

// mui
import {
    Box,
    Button,
    Typography,
    Modal,
    TextField,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
    Card,
    ToggleButton,
    ToggleButtonGroup,
} from '@mui/material';

// form
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// icons
import CloseIcon from '@mui/icons-material/Close';
import CameraAltRoundedIcon from '@mui/icons-material/CameraAltRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';

// api
import { sendMediaToBack } from 'src/api/categories/sendMediaToBack';
import { getAllCategories } from 'src/api/categories/getAllCategories';
import { deleteMultipleImages } from 'src/api/images/deleteMultipleImages';
import { updateAd } from 'src/api/ads/updateAd';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    paddingX: 7,
    paddingTop: 1,
    paddingBottom: 4,
    borderRadius: '10px',
    maxHeight: '90vh',
    overflow: 'scroll',
    '@media (max-width: 650px)': {
        paddingX: 4,
        width: '90%',
    },
};

function EditAdModal({ notify, ad }) {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [media, setMedia] = useState(null);

    const [submittedFormData, setSubmittedFormData] = useState(null);

    const [categories, setCategories] = useState(null);

    const [productOrCategory, setProductOrCategory] = useState('product');

    const [currentAd, setCurrentAd] = useState(null);

    const [pictureCodesToDelete, setPictureCodesToDelete] = useState(null);

    useEffect(() => {
        const getCategoriesData = async () => {
            try {
                const data = await getAllCategories();
                if (data && data.data && data.data.length > 0) {
                    setCategories(data.data);
                }
            } catch (error) {
                console.log(error);
            }
        };
        getCategoriesData();
    }, []);

    useEffect(() => {
        resetAll();
        setCurrentAd(ad);
        if (ad && ad.media) {
            setMedia({
                file: null,
                url: null,
                externalUrl: ad.media,
            });
        }
        if (ad && ad.subcategories) {
            setProductOrCategory('category');
        } else if (ad && ad.products) {
            setProductOrCategory('product');
        } else if (ad && ad.link) {
            setProductOrCategory('link');
        }
    }, [ad]);

    const schema = yup.object().shape({
        name: yup.string().max(100).required(),
        description: yup.string().max(1000),

        link: yup.string(),
        ...(categories &&
            categories.length > 0 &&
            productOrCategory === 'category' && {
                subcategories: yup
                    .string('Category must be a string.')
                    .oneOf(
                        [
                            ...categories.map((category) => {
                                if (category._id) {
                                    return category._id;
                                }
                            }),
                        ],
                        'Category must be an option from the provided selection.'
                    )
            }),
        ...(productOrCategory === 'product' && {
            products: yup
                .string('Product ID must be a string.')
                .max(50, 'Product ID must be at most 50 characters.')
        }),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const deleteImagesFromBackend = async (codes) => {
        try {
            if (codes && codes.length > 0) {
                //console.log('INSIDE DELETE IMAGES FROM BACKEND');
                const data = await deleteMultipleImages(codes);
                if (
                    data &&
                    data.data &&
                    data.data.message &&
                    data.data.message === 'Images deleted'
                ) {
                    //console.log('IMAGE DELETED');

                    if (media && media.file && !media.externalUrl) {
                        uploadMedia();
                    }
                }
            }
        } catch (error) {
            console.log(error);
            if (notify) {
                notify('error');
            }
            resetAll();
            handleClose();
        }
    };

    const submitAdData = (data) => {
        if (media && (media.file || media.externalUrl)) {
            //console.log('DATA: ' + JSON.stringify(data));

            if (pictureCodesToDelete && pictureCodesToDelete.length > 0) {
                deleteImagesFromBackend(pictureCodesToDelete);
            } else if (media && media.file && !media.externalUrl) {
                uploadMedia();
            }

            if (productOrCategory === 'product') {
                setSubmittedFormData({
                    ...data,
                    subcategories: null,
                });
            } else if (productOrCategory === 'category') {
                setSubmittedFormData({
                    ...data,
                    products: null,
                });
            } else if (productOrCategory === 'link') {
                setSubmittedFormData({
                    ...data,
                    products: null,
                    subcategories: null,
                });
            }
        }
    };

    // USE EFFECT WHEN EXTERNAL URLS READY, AND SEND ALL DATA
    useEffect(() => {
        if (submittedFormData && ad && ad._id && media && media.externalUrl) {
            const sendAdData = async () => {
                try {
                    const data = await updateAd(
                        {
                            ...submittedFormData,
                            media: media.externalUrl ? media.externalUrl : ad.media,
                        },
                        ad._id
                    );
                    if (data && data.data) {
                        //console.log('AD UPDATED');
                        resetAll();
                        if (notify) {
                            notify('edit');
                        }
                        reset();
                        handleClose();
                    }
                } catch (error) {
                    console.log(error);
                    if (notify) {
                        notify('error');
                    }
                    resetAll();
                    handleClose();
                }
            };
            sendAdData();
        }
    }, [media, submittedFormData]);

    const handleMediaChange = (e) => {
        const picture = e.target.files[0];
        if (picture) {
            if (media && media.externalUrl) {
                let tempArray = [];
                if (pictureCodesToDelete && pictureCodesToDelete.length > 0) {
                    tempArray = [...pictureCodesToDelete];
                }

                const regex = /\/([a-f\d]{64})\?/; // Regular expression to match the hash
                try {
                    const match = media.externalUrl.match(regex);

                    if (match && match[1]) {
                        const extractedHash = match[1];
                        if (
                            extractedHash !==
                            'a1c0f0dad0f6736325135dc69ec6dc0984058f11aa04150231c114efc1508290'
                        ) {
                            tempArray.push(extractedHash);
                        }
                    }
                } catch (error) {
                    console.log(error);
                    if (notify) {
                        notify('error');
                    }
                    resetAll();
                    handleClose();
                }
                setPictureCodesToDelete([...tempArray]);
            }
            setMedia({
                file: picture,
                url: URL.createObjectURL(picture),
                externalUrl: null,
            });
        }
    };

    const uploadMedia = async () => {
        try {
            let formData = new FormData();
            formData.append('image', media.file);
            const data = await sendMediaToBack(formData);
            if (data && data.data && data.data.media) {
                setMedia({ ...media, externalUrl: data.data.media });
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleProductOrCategoryChange = (e, value) => {
        if (value) {
            setProductOrCategory(value);
        }
    };

    const resetAll = () => {
        setMedia(null);
        setSubmittedFormData(null);
        setProductOrCategory('product');
        setCurrentAd(null);
        setPictureCodesToDelete(null);
    };


    return (
        <Box>
            <MenuItem onClick={handleOpen}>
                <EditRoundedIcon sx={{ mr: 2 }} /> Редактировать{' '}
            </MenuItem>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby='add-ad-modal'
                aria-describedby='add-ad-modal'
            >
                <Box sx={style}>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'end',
                        }}
                    >
                        <IconButton
                            aria-label='close'
                            onClick={() => {
                                handleClose();
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            margin: '0',
                            padding: '0',
                            width: '100%',
                            marginBottom: '0.5rem',
                            textAlign: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                fontSize: '1.8rem',
                            }}
                        >
                            {currentAd && currentAd.name
                                ? `Edit ad: ${currentAd.name}`
                                : null}
                        </Typography>
                    </Box>
                    <form onSubmit={handleSubmit(submitAdData)}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                rowGap: '1rem',
                            }}
                        >
                            <Box
                                sx={{
                                    width: '100%',
                                    height: '150px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    marginTop: '1rem',
                                    marginBottom: '1.5rem',
                                }}
                            >
                                <Box
                                    sx={{
                                        width: '100%',
                                        height: '100%',
                                        textAlign: 'center',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: 'bold',
                                            fontSize: '1.2rem',
                                        }}
                                    >{`Media*`}</Typography>
                                    <input
                                        hidden
                                        accept='image/*'
                                        type='file'
                                        onChange={handleMediaChange}
                                        onClick={(e) => (e.target.value = null)}
                                        id={`add-ad-media-upload-input`}
                                    />
                                    <label
                                        htmlFor={`add-ad-media-upload-input`}
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                        }}
                                    >
                                        <Card
                                            sx={{
                                                width: '100%',
                                                height: '100%',
                                                ...(media &&
                                                    media.file &&
                                                    media && {
                                                        backgroundImage: `url('${media}')`,
                                                    }),
                                                ...(media &&
                                                    !media.file &&
                                                    media.externalUrl && {
                                                        backgroundImage: `url('${media.externalUrl}')`,
                                                    }),
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center',
                                                backgroundRepeat: 'no-repeat',
                                            }}
                                        >
                                            <Button
                                                component='span'
                                                sx={{
                                                    position: 'relative',
                                                    borderRadius: '10px',
                                                    width: '100%',
                                                    height: '100%',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'end',
                                                    textTransform: 'none',
                                                    backgroundColor: '#bfbfbf',
                                                    '&:hover': {
                                                        backgroundColor:
                                                            '#a3a3a3',
                                                    },
                                                    ...(media &&
                                                        (media.url ||
                                                            media.externalUrl) && {
                                                            opacity: '0.4',
                                                        }),
                                                }}
                                            >
                                                <CameraAltRoundedIcon
                                                    sx={{
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        translate: '-50% -50%',
                                                    }}
                                                />
                                            </Button>
                                        </Card>
                                    </label>
                                </Box>
                            </Box>

                            <TextField
                                required
                                fullWidth
                                variant={`outlined`}
                                label='Ad Name'
                                error={errors.name && true}
                                helperText={errors.name?.message}
                                {...register('name')}
                                value={
                                    currentAd && currentAd.name
                                        ? currentAd.name
                                        : ''
                                }
                                onChange={(e) => {
                                    setCurrentAd((prevState) => {
                                        return {
                                            ...prevState,
                                            name: e.target.value,
                                        };
                                    });
                                }}
                            />

                            <TextField
                                multiline
                                rows={4}
                                fullWidth
                                variant={`outlined`}
                                label='Ad Description'
                                error={errors.description && true}
                                helperText={errors.description?.message}
                                {...register('description')}
                                value={
                                    currentAd && currentAd.description
                                        ? currentAd.description
                                        : ''
                                }
                                onChange={(e) => {
                                    setCurrentAd((prevState) => {
                                        return {
                                            ...prevState,
                                            description: e.target.value,
                                        };
                                    });
                                }}
                            />

                            <ToggleButtonGroup
                                fullWidth
                                color='primary'
                                value={productOrCategory}
                                exclusive
                                onChange={handleProductOrCategoryChange}
                                aria-label='Product or Category'
                            >
                                <ToggleButton value='product'>
                                    Product
                                </ToggleButton>
                                <ToggleButton value='category'>
                                    Category
                                </ToggleButton>
                                <ToggleButton value='link'>
                                    Link
                                </ToggleButton>
                            </ToggleButtonGroup>

                            {categories &&
                            categories.length > 0 &&
                            productOrCategory === 'category' ? (
                                <FormControl fullWidth>
                                    <InputLabel
                                        id={`add-ad-category-selection-label`}
                                    >
                                        Category
                                    </InputLabel>
                                    <Select
                                        required
                                        labelId={`add-ad-category-selection-label`}
                                        label='Category'
                                        defaultValue={''}
                                        {...register('subcategories')}
                                        value={
                                            currentAd && currentAd.subcategories
                                                ? currentAd.subcategories
                                                : ''
                                        }
                                        onChange={(e) => {
                                            setCurrentAd((prevState) => {
                                                return {
                                                    ...prevState,
                                                    subcategories:
                                                        e.target.value,
                                                };
                                            });
                                        }}
                                    >
                                        {categories.map((category, index) => (
                                            <MenuItem
                                                key={index}
                                                value={category._id}
                                            >
                                                {category && category.name
                                                    ? category.name
                                                    : null}
                                            </MenuItem>
                                        ))}
                                        <MenuItem value={'wrong'}>
                                            WRONG
                                        </MenuItem>
                                    </Select>
                                    {errors.subcategories?.message ? (
                                        <FormHelperText
                                            sx={{
                                                color: (theme) =>
                                                    `${theme.palette.error.main}`,
                                            }}
                                        >
                                            {errors.subcategories?.message}
                                        </FormHelperText>
                                    ) : null}
                                </FormControl>
                            ) : null}

                            {productOrCategory === 'product' ? (
                                <TextField
                                    required
                                    fullWidth
                                    variant={`outlined`}
                                    label='ID продукта'
                                    error={errors.products && true}
                                    helperText={errors.products?.message}
                                    {...register('products')}
                                    value={
                                        currentAd && currentAd.products
                                            ? currentAd.products
                                            : ''
                                    }
                                    onChange={(e) => {
                                        setCurrentAd((prevState) => {
                                            return {
                                                ...prevState,
                                                products: e.target.value,
                                            };
                                        });
                                    }}
                                />
                            ) : null}

                            {productOrCategory === 'link' ? (
                                <TextField
                                    fullWidth
                                    variant={`outlined`}
                                    label='Ad Link'
                                    error={errors.link && true}
                                    helperText={errors.link?.message}
                                    {...register('link')}
                                    value={
                                        currentAd && currentAd.link
                                            ? currentAd.link
                                            : ''
                                    }
                                    onChange={(e) => {
                                        setCurrentAd((prevState) => {
                                            return {
                                                ...prevState,
                                                link: e.target.value,
                                            };
                                        });
                                    }}
                                />
                            ) : null}

                            <Button
                                disabled={!(media?.file || media?.externalUrl)}
                                size={`large`}
                                type={`submit`}
                                variant='contained'
                                sx={{ marginTop: '1rem' }}
                            >
                                Отправить
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Modal>
        </Box>
    );
}

export default EditAdModal;
