import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// @mui
import {
    Card,
    Table,
    Stack,
    Paper,
    Avatar,
    Button,
    Popover,
    Checkbox,
    TableRow,
    MenuItem,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableContainer,
    TablePagination,
} from '@mui/material';

// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import DeleteCategoryModal from 'src/components/delete-category-modal';

// sections
import { OrderListToolbar, OrderListHead } from '../sections/@dashboard/orders';

// icons
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import ReadMoreIcon from '@mui/icons-material/ReadMore';

// utils
import { isoToReadableDate } from 'src/utils/isoToReadableDate';

// api
import { getOrders } from 'src/api/orders/getOrders';

// ----------------------------------------------------------------------

// IDS SHOULD BE EQUAL TO PROPERTIES IN THE OBJECTS
const TABLE_HEAD = [
    { id: 'buyerUsername', label: 'Покупатель', align: 'left' },
    { id: 'quantity', label: 'Количество', align: 'left' },
    { id: 'price', label: 'Всего', align: 'left' },
    { id: 'lastUpdated', label: 'Обновлено', align: 'left' },
    { id: 'createdAt', label: 'Дата', align: 'left' },
    { id: '' },
];

// ----------------------------------------------------------------------

export default function OrdersPage() {
    console.log('ORDERS PAGE');
    const [open, setOpen] = useState(null);

    const [page, setPage] = useState(0);

    const [order, setOrder] = useState('desc');

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState('lastUpdated');

    const [rowsPerPage, setRowsPerPage] = useState(5);

    const [orders, setOrders] = useState(null);

    const [ordersTotal, setOrdersTotal] = useState(0);

    const [totalQuantities, setTotalQuantities] = useState({});

    const [orderToView, setOrderToView] = useState(null);

    useEffect(() => {
        setOrders(null);
        setSelected([]);
        const getOrdersData = async () => {
            try {
                console.log('CALLING GET ORDERS');
                const data = await getOrders(rowsPerPage, page, orderBy, order);
                if (data && data.data && data.data.orders) {
                    console.log('DATA GOT ORDERS');
                    setOrders(data.data.orders);
                }
                if (data && data.data && data.data.totalOrders) {
                    setOrdersTotal(data.data.totalOrders);
                }
            } catch (error) {
                setOrders(null);
                setSelected([]);
            }
        };
        getOrdersData();
    }, [rowsPerPage, page, orderBy, order]);

    useEffect(() => {
        setTotalQuantities({});
        if (orders && orders.length) {
            let total = {};
            orders.forEach((order) => {
                if (
                    order._id &&
                    order.productQuantities &&
                    Object.keys(order.productQuantities).length
                ) {
                    total[order._id] = 0;
                    for (const [key, value] of Object.entries(
                        order.productQuantities
                    )) {
                        if (value) {
                            total[order._id] += value;
                        }
                    }
                }
            });
            setTotalQuantities(total);
        }
    }, [orders]);

    const navigate = useNavigate();

    const handleOpenMenu = (event, id) => {
        if (id) {
            setOrderToView(id);
            setOpen(event.currentTarget);
        }
    };

    const handleCloseMenu = () => {
        setOpen(null);
    };

    const handleRequestSort = (event, property) => {
        setOrders(null);
        setSelected([]);
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        const indeterminate =
            selected.length > 0 &&
            orders &&
            orders.length &&
            selected.length < orders.length;
        if (
            event.target.checked &&
            !indeterminate &&
            orders &&
            orders.length > 0
        ) {
            const newSelecteds = orders.map((order) => order._id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, orderId) => {
        const selectedIndex = selected.indexOf(orderId);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, orderId);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setOrders(null);
        setSelected([]);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setOrders(null);
        setSelected([]);
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - ordersTotal) : 0;

    const isNotFound = false;

    console.log('ORDER BY: ' + orderBy);
    console.log('ORDER: ' + order);
    console.log('PAGE: ' + page);
    console.log('SELECTED: ' + selected);

    return (
        <>
            <Helmet>
                <title> Orders | Mentup Dashboard </title>
            </Helmet>

            <Container>
                <Stack
                    direction='row'
                    alignItems='center'
                    justifyContent='space-between'
                    mb={5}
                >
                    <Typography variant='h4' gutterBottom>
                        Заказы
                    </Typography>
                </Stack>

                <Card>
                    {/* <OrderListToolbar /> */}

                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <OrderListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={
                                        orders && orders.length
                                            ? orders.length
                                            : 0
                                    }
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                />
                                <TableBody>
                                    {orders &&
                                        orders.map((order, index) => {
                                            const selectedOrder =
                                                selected.indexOf(order._id) !==
                                                -1;

                                            return (
                                                <TableRow
                                                    hover
                                                    key={index}
                                                    tabIndex={-1}
                                                    role='checkbox'
                                                    selected={selectedOrder}
                                                >
                                                    {/* <TableCell padding='checkbox'>
                                                        <Checkbox
                                                            checked={
                                                                selectedOrder
                                                            }
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                if (order._id) {
                                                                    handleClick(
                                                                        event,
                                                                        order._id
                                                                    );
                                                                }
                                                            }}
                                                        />
                                                    </TableCell> */}

                                                    <TableCell align='left'>
                                                        {order.user &&
                                                        order.user[0] &&
                                                        order.user[0]
                                                            .username ? (
                                                            <Typography>
                                                                {
                                                                    order
                                                                        .user[0]
                                                                        .username
                                                                }
                                                            </Typography>
                                                        ) : null}
                                                    </TableCell>

                                                    <TableCell align='left'>
                                                        {order._id &&
                                                        totalQuantities[
                                                            order._id
                                                        ] ? (
                                                            <Typography>
                                                                {
                                                                    totalQuantities[
                                                                        order
                                                                            ._id
                                                                    ]
                                                                }
                                                            </Typography>
                                                        ) : (
                                                            <Typography>
                                                                {'n/a'}
                                                            </Typography>
                                                        )}
                                                    </TableCell>

                                                    <TableCell align='left'>
                                                        {order.transaction &&
                                                        order.transaction[0] &&
                                                        order.transaction[0]
                                                            .total ? (
                                                            <Typography>
                                                                {'£' +
                                                                    order
                                                                        .transaction[0]
                                                                        .total}
                                                            </Typography>
                                                        ) : null}
                                                    </TableCell>

                                                    <TableCell align='left'>
                                                        {order.updatedAt && (
                                                            <Typography>
                                                                {isoToReadableDate(
                                                                    order.updatedAt
                                                                )}
                                                            </Typography>
                                                        )}
                                                    </TableCell>

                                                    <TableCell align='left'>
                                                        {order.createdAt && (
                                                            <Typography>
                                                                {isoToReadableDate(
                                                                    order.createdAt
                                                                )}
                                                            </Typography>
                                                        )}
                                                    </TableCell>

                                                    <TableCell align='right'>
                                                        <IconButton
                                                            size='large'
                                                            color='inherit'
                                                            onClick={(e) => {
                                                                if (order._id) {
                                                                    handleOpenMenu(
                                                                        e,
                                                                        order._id
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            <Iconify
                                                                icon={
                                                                    'eva:more-vertical-fill'
                                                                }
                                                            />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow
                                            style={{ height: 53 * emptyRows }}
                                        >
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>

                                {isNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell
                                                align='center'
                                                colSpan={6}
                                                sx={{ py: 3 }}
                                            >
                                                <Paper
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Typography
                                                        variant='h6'
                                                        paragraph
                                                    >
                                                        Не найдено
                                                    </Typography>

                                                    <Typography variant='body2'>
                                                        Не найдено
                                                        &nbsp;
                                                        <strong>
                                                            &quot;
                                                            {'Search Value'}
                                                            &quot;
                                                        </strong>
                                                        .
                                                        <br /> Попробуйте проверить опечатки или корректируйте запрос.
                                                    </Typography>
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        labelRowsPerPage="Выводить по:"
                        rowsPerPageOptions={[5, 10, 25]}
                        component='div'
                        count={ordersTotal && ordersTotal}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>

            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 140,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <MenuItem
                    onClick={() => {
                        if (orderToView) {
                            navigate(`/dashboard/order/${orderToView}`);
                        }
                    }}
                >
                    <ReadMoreIcon sx={{ marginRight: '1rem' }} />
                    Подробнее
                </MenuItem>
            </Popover>
        </>
    );
}
