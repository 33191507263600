import React from 'react';
import axios from 'axios';
import { Navigate, Outlet } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';

// redux
import { useDispatch } from 'react-redux';
import { setUser } from 'src/store/slices/userSlice';
import { useSocketProvider } from './contexts/socket';

function ProtectedRoute() {
    const socket = useSocketProvider();
    const [isAuth, setIsAuth] = useState(null);

    const dispatch = useDispatch();

    //console.log('IN PROTECTED ROUTE CHECKING is Auth: ' + isAuth);

    useEffect(() => {
        // If http, redirect page to  https
        if (
            window.location.protocol !== 'https:' &&
            process.env.NODE_ENV.includes('production')
        ) {
            return (window.location.href =
                'https://' + window.location.host + window.location.pathname);
        }

        const token = Cookies.get('refreshToken');
        const user = sessionStorage.getItem('user');
        if (!token || !user) {
            //console.log('NO TOKEN OR NOT USER');
            Cookies.remove('refreshToken');
            dispatch(setUser(null));
            sessionStorage.removeItem('user');
            setIsAuth(false);
        } else {
            //console.log('YES TOKEN');
            var url = '';
            if (process.env.NODE_ENV.includes('production')) {
                url = process.env.REACT_APP_SERVER_URL
                    ? process.env.REACT_APP_SERVER_URL +
                      '/auth/verifyTokenAdmin'
                    : '';
            } else {
                url = 'http://localhost:3030/api/auth/verifyTokenAdmin';
            }
            //console.log('IN PROTECTED ROUTE USE EFFECT BEFORE AXIOS');
            axios
                .get(url, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then((res) => {
                    //console.log('GOT RES');
                    if (
                        res &&
                        res.data &&
                        res.data.message === 'Token is valid.'
                    ) {
                        //console.log(' RES TRUE');
                        setIsAuth(true);
                        socket.authenticate(token);
                    } else {
                        //console.log(' RES FALSE');
                        Cookies.remove('refreshToken');
                        dispatch(setUser(null));
                        sessionStorage.removeItem('user');
                        setIsAuth(false);
                    }
                })
                .catch((err) => {
                    //console.log(' RES ERROR');
                    //console.log(err);
                    Cookies.remove('refreshToken');
                    dispatch(setUser(null));
                    sessionStorage.removeItem('user');
                    setIsAuth(false);
                });
        }
    });

    useEffect(() => {
        return () => {
            //console.log('UNMOUNTED');
        };
    }, []);

    if (isAuth === null) {
        return null;
    }
    //console.log('RETURNING');
    return isAuth === true ? <Outlet /> : <Navigate to='/login' replace />;
}

export default ProtectedRoute;
