import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Avatar, Box, Button, Stack, Typography } from "@mui/material";
import { useTranslate } from "src/contexts/translate/translate.context";

export const ChatListItem = ({
  isCurrent,
  chat,
  open,
  assignChat,
  unreadCount,
}) => {
  const user = JSON.parse(sessionStorage.getItem("user"));
  const { translate } = useTranslate();

  const otherUser = (chat.users || []).filter((u) => u._id !== user._id)[0];

  return (
    <Box
      sx={{
        cursor: "pointer",
        padding: "4px",
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.15)",
        ":hover": {
          boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.35)",
        },
        ...(isCurrent
          ? {
              backgroundColor: "rgba(0,0,0,0.35)",
              position: "relative",
              boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.35)",
            }
          : {}),
      }}
      onClick={() => open(chat)}
    >
      <Box
        sx={{
          gap: "8px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Stack direction="row" alignItems="center" spacing={2}>
          <Box
            sx={{
              position: "relative",
            }}
          >
            {otherUser?.avatar ? (
              <Avatar
                alt={otherUser?.username ? otherUser?.username : "User avatar"}
                src={otherUser.avatar}
              />
            ) : (
              <AccountCircleIcon
                sx={{
                  height: "2.5rem",
                  width: "2.5rem",
                }}
              />
            )}

            <Box
              style={{
                position: "absolute",
                top: "0",
                right: "0",
                width: "10px",
                height: "10px",
                borderRadius: "50%",
                backgroundColor: otherUser?.online ? "#51e200" : "gray",
              }}
            ></Box>
          </Box>

          <Box
            style={{
              fontSize: "0.8rem",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "-webkit-fill-available",
            }}
          >
            <Typography variant="subtitle2" sx={{ maxWidth: "105px" }} noWrap>
              {otherUser?.username || ""}
            </Typography>

            {!!unreadCount && (
              <Typography
                sx={{
                  borderRadius: "24px",
                  background: "red",
                  padding: "0 8px",
                  marginLeft: "4px",
                  height: "min-content",
                }}
              >
                {unreadCount}
              </Typography>
            )}
          </Box>
        </Stack>

        {!chat.advisor && (
          <Button variant="contained" size="small" onClick={assignChat}>
            <Typography>Взять в работу</Typography>
          </Button>
        )}
        {chat.advisor === user?._id && (
          <Typography variant="caption" sx={{ marginLeft: "auto" }}>
            Взято в работу
          </Typography>
        )}
      </Box>
    </Box>
  );
};
