import { Box, Button, Typography } from "@mui/material";
import { useRef } from "react";

const MessageUpload = ({ chatId, percents, handleUpload }) => {
  const hiddenFileInput = useRef(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleChange = async (event) => {
    const fileUploaded = event.target.files[0];
    await handleUpload(fileUploaded);
  };

  return (
    <Box>
      <Button
        className="button-upload"
        sx={{ minWidth: 0 }}
        disabled={!!percents[chatId]}
        onClick={handleClick}
      >
        {percents[chatId] ? (
          <Typography sx={{ width: "28px", height: "28px" }}>
            {percents[chatId]}%
          </Typography>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.0"
            width="28"
            height="28"
            viewBox="0 0 64.000000 64.000000"
            preserveAspectRatio="xMidYMid meet"
          >
            <g
              transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
              fill="#000000"
              stroke="none"
            >
              <path d="M267 492 c-20 -21 -37 -43 -37 -50 0 -19 25 -14 46 9 19 21 19 20 24 -82 4 -84 8 -104 20 -104 12 0 16 20 20 104 5 102 5 103 24 82 21 -23 46 -28 46 -8 0 18 -72 87 -91 87 -8 0 -32 -17 -52 -38z" />
              <path d="M107 224 c-12 -12 -8 -99 5 -112 19 -19 402 -17 417 2 18 21 10 111 -9 111 -11 0 -16 -13 -18 -47 l-3 -48 -179 0 -179 0 -3 47 c-3 44 -15 62 -31 47z" />
            </g>
          </svg>
        )}
      </Button>
      <input
        type="file"
        accept="video/*|image/*"
        onChange={handleChange}
        ref={hiddenFileInput}
        style={{ display: "none" }} // Make the file input element invisible
      />
    </Box>
  );
};

export default MessageUpload;
