import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Modal,
  Typography,
  useTheme,
} from "@mui/material";
import { useCallback, useState } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  paddingX: 7,
  paddingTop: 1,
  paddingBottom: 4,
  borderRadius: "10px",
  maxHeight: "90vh",
  overflow: "scroll",
  "@media (max-width: 650px)": {
    width: "90%",
    paddingX: 3,
  },
};

function DeleteReviewModal({ item, displayKey, handleDelete }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const theme = useTheme();

  const confirm = useCallback(() => {
    handleDelete(item?._id);
    handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item?.id]);

  return (
    <Box>
      <MenuItem sx={{ color: "error.main" }} onClick={handleOpen}>
        <DeleteIcon sx={{ mr: 2 }} />
        Удалить
      </MenuItem>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-delete-review-confirmation"
        aria-describedby="modal-delete-review-confirmation"
      >
        <Box sx={style}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <IconButton
              aria-label="close"
              onClick={() => {
                handleClose();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              margin: "0",
              padding: "0",
              width: "100%",
              marginBottom: "0.5rem",
              textAlign: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "1.6rem",
              }}
            >
              Удалите выбранный отзыв с помощью {displayKey}:
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: "1.5rem",
                }}
              >
                {item?.[displayKey]}
              </span>
              ?
            </Typography>
            <Box
              sx={{
                boxSizing: "border-box",
                marginTop: "1rem",
                padding: "1rem 2rem",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                "@media (max-width: 650px)": {
                  paddingX: "0",
                },
                "@media (max-width: 400px)": {
                  rowGap: "2rem",
                  flexDirection: "column",
                },
              }}
            >
              <Button variant="contained" size="large" onClick={handleClose}>
                ОТМЕНИТЬ
              </Button>
              <Button
                size="large"
                variant="contained"
                onClick={confirm}
                sx={{ backgroundColor: theme.palette.error.main }}
              >
                УДАЛИТЬ
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default DeleteReviewModal;
